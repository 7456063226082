import NoDataSvg from '../../assets/svg/no-data-svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  variant?: 'small' | 'normal';
  className?: string;
}
const ActivityFeedEmpty = ({ variant = 'normal', className = '' }: Props) => {
  const { t } = useTranslation(['offers']);

  return (
    <div className={className}>
      {variant === 'normal' && (
        <NoDataSvg mainColor="#4f46e5" className="mx-auto mb-4 mt-6 w-1/3" />
      )}
      <h2
        className={`text-center font-bold text-gray-900 ${
          variant === 'normal' ? 'my-2 text-lg' : 'my-1'
        }`}
      >
        {t('offers:timeline.emptyActivityTitle')}
      </h2>
      <p
        className={`mx-auto my-1 text-center ${
          variant === 'normal' ? 'max-w-80' : 'max-w-72 text-sm'
        }`}
      >
        {t('offers:timeline.emptyActivityDetails')}
      </p>
    </div>
  );
};

export default ActivityFeedEmpty;
