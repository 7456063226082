import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PersonInput, { PersonInputProps } from '../person-input/person-input';
import { getClients } from '../../../api/clients/clients';

interface Props extends Omit<PersonInputProps, 'people'> {
  id: string;
}

const ClientInput = ({ value, onChange, ...rest }: Props) => {
  const { data: clients = [] } = useQuery(['clients'], getClients);

  return (
    <PersonInput
      value={value}
      {...rest}
      onChange={onChange}
      people={
        clients.map((c) => ({
          firstName: c.first_name,
          lastName: c.last_name,
          id: c.client_id,
          phoneNumber: c.phone_number,
        })) ?? []
      }
    />
  );
};

export default ClientInput;
export type { Props as ClientInputProps };
