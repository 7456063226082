import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { postLogin } from '../../api/auth/auth';
import CheckboxInputLabeledController from '../../components/inputs/checkbox-input/checkbox-input-labeled-controller';
import TextInputLabeledController from '../../components/inputs/text-input/text-input-labeled-controller';
import { useAuth } from '../../context/auth/use-auth';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/button';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import { defaultErrorToasts } from '../../utils/default-toasts';
import PasswordInputLabeledController from '../../components/inputs/password-input/password-input-labeled-controller';
import logo from '../../assets/logo-transparent.png';
import bg from '../../assets/login-bg.jpg';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS, SignUpMethods } from '../../constants/analytics';
import PageHeader from '../../components/header/page-header';

export interface LoginForm {
  email: string;
  password: string;
  remember: boolean;
}

export enum LoginErrors {
  BAD_CREDENTIALS = 'LOGIN_BAD_CREDENTIALS',
  USER_NOT_ACTIVE = 'USER_NOT_ACTIVE',
  USER_BANNED = 'USER_BANNED',
  USER_DEACTIVATED_BY_ORGANIZATION = 'USER_DEACTIVATED_BY_ORGANIZATION',
}

const Login = () => {
  const { t } = useTranslation(['auth', 'common']);
  const { loadUser } = useAuth();
  const analytics = useAnalytics();
  const location = useLocation() as {
    state: { from: { pathname: string } };
  };
  const { handleSubmit, control, resetField } = useForm<LoginForm>({
    defaultValues: { password: '', email: '', remember: true },
  });

  const { mutate, isLoading } = useMutation(postLogin, {
    onSuccess: async () => {
      await loadUser();
      await analytics.track(ANALYTICS_EVENTS.USER_LOGGED_IN, {
        method: SignUpMethods.EMAIL,
      });
    },
    onError: (e: AxiosError) => {
      const data = e.response?.data as any;
      const status = e.response?.status;
      if (data?.detail === LoginErrors.USER_NOT_ACTIVE) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.loginUserNotActive.title')}
            body={t('auth:alerts.loginUserNotActive.body')}
          />
        ));
        resetField('password');
      } else if (data?.detail === LoginErrors.USER_BANNED) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.loginUserBanned.title')}
            body={t('auth:alerts.loginUserBanned.body')}
          />
        ));
        resetField('password');
      } else if (
        data?.detail === LoginErrors.USER_DEACTIVATED_BY_ORGANIZATION
      ) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.loginUserDeactivatedByOrganization.title')}
            body={t('auth:alerts.loginUserDeactivatedByOrganization.body')}
          />
        ));
        resetField('password');
      } else if (
        status === 422 ||
        data.detail === LoginErrors.BAD_CREDENTIALS
      ) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.loginInvalidCredentials.title')}
            body={t('auth:alerts.loginInvalidCredentials.body')}
          />
        ));
        resetField('password');
      } else {
        defaultErrorToasts(e, t);
      }
    },
  });

  const onSubmit = (form: LoginForm) => {
    mutate(form);
  };

  return (
    <div className="flex h-full flex-row-reverse bg-white">
      <PageHeader title={t('common:pageTitle.login')} />
      <div className="mx-4 my-6 flex flex-1 flex-col sm:mx-6 md:my-12 lg:mx-20 lg:flex-none xl:mx-24">
        <div className="relative mx-auto h-full w-full max-w-sm lg:w-96">
          <div className="flex h-full flex-col justify-center py-16">
            <div className="flex flex-col items-start">
              <img src={logo} alt="Logo" className="-ml-4 h-16" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                {t('login.header')}
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                {t('login.or')}{' '}
                <Link
                  to={'/sign-up'}
                  state={location.state}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {t('login.startTrial')}
                </Link>
              </p>
            </div>
            <div className="mt-8">
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <TextInputLabeledController
                    name="email"
                    control={control}
                    id="email"
                    autoComplete="email"
                    labelClassName="mb-1"
                    label={t('fields.email')}
                  />
                </div>
                <div className="space-y-1">
                  <PasswordInputLabeledController
                    name="password"
                    control={control}
                    id="password"
                    autoComplete="password"
                    labelClassName="mb-1"
                    label={t('fields.password')}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <CheckboxInputLabeledController
                    name="remember"
                    control={control}
                    id="remember"
                    label={t('fields.remember')}
                  />
                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {t('shared.forgotPassword')}
                    </Link>
                  </div>
                </div>
                <div>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    className="flex w-full justify-center px-4 py-2"
                  >
                    {t('login.login')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default Login;
