import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationMembers } from '../../../api/organizations/organizations.api';
import PersonInput from '../person-input/person-input';
import { MINUTES_5 } from '../../../constants/periods';
import { useUser } from '../../../context/auth/use-user';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string | null;
  onChange: (user: string | null) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  isError?: boolean;
  teamId?: string | null;
  saveErrorSpace?: boolean;
  containerClassName?: string;
  allowAssignToMyself?: boolean;
}

const OrganizationMemberInput = ({
  value,
  onChange,
  teamId,
  allowAssignToMyself = false,
  ...rest
}: Props) => {
  const { t } = useTranslation(['common']);
  const user = useUser();

  const { data: members = [] } = useQuery(
    ['organizations', 'members'],
    getOrganizationMembers,
    { staleTime: MINUTES_5, cacheTime: MINUTES_5 },
  );

  const userAsMember = members.find(
    (member) => member.user.user_id === user.userId,
  );

  const canAssignCurrentUser =
    !!userAsMember && (!teamId || teamId === userAsMember.team?.team_id);

  React.useEffect(() => {
    const mem = members.find((m) => m.user.user_id === value);
    if (mem && !!teamId && mem.team?.team_id !== teamId) {
      onChange(null);
    }
  }, [teamId]);

  return (
    <PersonInput
      value={value}
      {...rest}
      onChange={onChange}
      people={
        members
          .filter((m) => !teamId || m.team?.team_id === teamId)
          .map((m) => ({
            firstName: m.user.first_name,
            lastName: m.user.last_name,
            id: m.user.user_id,
            avatarUrl: m.user.avatar_url,
          })) ?? []
      }
    >
      {allowAssignToMyself && !rest.isError && canAssignCurrentUser && (
        <button
          type="button"
          className={`text-xs  font-medium text-indigo-700 ${
            rest.saveErrorSpace
              ? 'absolute bottom-0 right-0'
              : 'ml-auto mt-2 block'
          }`}
          onClick={() => {
            onChange(user.userId);
          }}
        >
          {t('common:organizationMemberInput.assignToMe')}
        </button>
      )}
    </PersonInput>
  );
};

export default OrganizationMemberInput;
