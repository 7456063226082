import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  downloadPieExportedFile,
  getOffersExportFile,
  getOffersExports,
  getPieExportedFiles,
  OffersDataExportResponse,
  OffersDataExportStatusEnum,
  S3File,
} from '../../api/data-export/data-export';
import { CloudArrowDownIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import { useShowPieDataExport } from '../../hooks/use-show-pie-data-export/use-show-pie-data-export';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/header/page-header';

const COLOUR_BY_STATUS = {
  [OffersDataExportStatusEnum.COMPLETED]:
    'text-green-700 bg-green-50 ring-green-600/10',
  [OffersDataExportStatusEnum.FAILED]: 'text-red-700 bg-red-50 ring-red-600/10',
  [OffersDataExportStatusEnum.IN_PROGRESS]:
    'text-yellow-700 bg-yellow-50 ring-yellow-600/10',
  [OffersDataExportStatusEnum.INITIALIZED]:
    'text-yellow-700 bg-yellow-50 ring-yellow-600/10',
};
const DataSetStatus = ({ status }: { status: OffersDataExportStatusEnum }) => {
  const { t } = useTranslation('analytics');
  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${COLOUR_BY_STATUS[status]}`}
    >
      {t(`analytics:dataSets.exportStatus.${status}`)}
    </span>
  );
};

const DataSets = () => {
  const showPieDataExport = useShowPieDataExport();
  const { t } = useTranslation('analytics');

  const { data: pieDataExport, isLoading: isPieDataExportLoading } = useQuery(
    ['pie-data-export'],
    getPieExportedFiles,
    { enabled: showPieDataExport },
  );
  const { data: offersDataExport, isLoading: isOffersDataExportLoading } =
    useQuery(['offerDataExports'], getOffersExports);

  const isLoading =
    isOffersDataExportLoading || (showPieDataExport && isPieDataExportLoading);
  const files = [
    ...(pieDataExport?.files.map((f) => ({ ...f, isOffersExport: false })) ??
      []),
    ...(offersDataExport?.map((f) => ({ ...f, isOffersExport: true })) ?? []),
  ];

  return (
    <main className="h-full flex-1 px-8 pb-16 pt-6">
      <PageHeader title={t('common:pageTitle.dataSets')} />
      <div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {t('analytics:dataSets.table.fileName')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('analytics:dataSets.table.dateCreated')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {t('analytics:dataSets.table.status')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('analytics:dataSets.table.recordCount')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    [...new Array(3).keys()].map((i) => (
                      <tr key={i}>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                          <div className="h-4 w-80 animate-pulse rounded bg-gray-200" />
                        </td>
                        <td className="whitespace-nowrap px-2 py-2">
                          <div className="h-4 w-24 animate-pulse rounded bg-gray-200" />
                        </td>
                        <td className="animate-pulse whitespace-nowrap rounded px-2 py-2">
                          <div className="h-4 w-16 animate-pulse rounded bg-gray-200" />
                        </td>

                        <td className="animate-pulse whitespace-nowrap rounded px-2 py-2">
                          <div className="h-4 w-16 animate-pulse rounded bg-gray-200" />
                        </td>
                        <td className="animate-pulse whitespace-nowrap rounded px-2 py-2">
                          <div className="h-4 w-16 animate-pulse rounded bg-gray-200" />
                        </td>
                      </tr>
                    ))
                  ) : files.length === 0 ? (
                    <tr>
                      <td colSpan={7}>
                        <div className="mt-12 text-center">
                          <CloudArrowDownIcon className="mx-auto h-10 w-10 text-gray-400" />
                          <h3 className="mt-2 text-sm font-medium text-gray-900">
                            {t('analytics:dataSets.noDataSets')}
                          </h3>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    files
                      .sort(
                        (a, b) =>
                          new Date(b.created_at).getTime() -
                          new Date(a.created_at).getTime(),
                      )
                      .map((file) => {
                        if (file.isOffersExport) {
                          const offerExport = file as OffersDataExportResponse;
                          return (
                            <tr key={offerExport.offer_data_export_id}>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                {offerExport.file_name}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                {format(
                                  new Date(offerExport.created_at),
                                  'dd.MM.yyyy HH:mm',
                                )}
                              </td>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                <DataSetStatus status={offerExport.status} />
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                {offerExport.total_results}
                              </td>
                              <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                {offerExport.status ===
                                OffersDataExportStatusEnum.COMPLETED ? (
                                  <button
                                    onClick={() =>
                                      getOffersExportFile(
                                        offerExport.offer_data_export_id,
                                      )
                                    }
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    {t('analytics:dataSets.table.download')}
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        }

                        const s3Resp = file as S3File;

                        return (
                          <tr key={s3Resp.name}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                              {s3Resp.name}
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                              {format(
                                new Date(s3Resp.created_at),
                                'dd.MM.yyyy HH:mm',
                              )}
                            </td>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                              <DataSetStatus
                                status={OffersDataExportStatusEnum.COMPLETED}
                              />
                            </td>

                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                              -
                            </td>
                            <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button
                                onClick={() =>
                                  downloadPieExportedFile({
                                    fileKey: s3Resp.name,
                                  })
                                }
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                {t('analytics:dataSets.table.download')}
                              </button>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DataSets;
