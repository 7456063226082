import React from 'react';
import { PhoneCallResultEnum } from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import {
  CALL_RESULT_CLASSES,
  CALL_RESULT_ICONS,
} from '../../constants/call-result-icons';
import { PhoneIcon } from '@heroicons/react/20/solid';

interface Props {
  offerStatus: PhoneCallResultEnum;
  className?: string;
  isArchived?: boolean | null;
  prefix?: JSX.Element;
}

const CallResultBadge = ({
  offerStatus,
  className = '',
  isArchived,
  prefix,
}: Props) => {
  const { t } = useTranslation('common');
  const Icon = CALL_RESULT_ICONS[offerStatus];
  return (
    <span
      className={`${
        isArchived ? 'line-through opacity-75' : ''
      } flex items-center justify-center rounded px-2 py-0.5 text-xs font-medium uppercase ${
        CALL_RESULT_CLASSES[offerStatus]
      } ${className}`}
    >
      {prefix && <p className="mr-1">{prefix}</p>}
      <PhoneIcon className="h-5 w-5" />
      <span className="whitespace-pre"> → </span>
      <Icon className="h-5 w-5" />
      <span className="ml-1">
        {t(`common:callStatuses.values.${offerStatus}`)}
      </span>
    </span>
  );
};

export default CallResultBadge;
