import React from 'react';
import { OfferTypeEnum } from '../../api/api.types';
import ActivityModal from './activity-modal';
import { HiChatBubbleLeft } from 'react-icons/hi2';

interface Props {
  count: number;
  offerId: string;
  offerType: OfferTypeEnum;
  className?: string;
}

const ActivityIcon = ({ count, offerId, offerType, className }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={`relative flex items-baseline rounded px-1.5 ${className}`}
      >
        <HiChatBubbleLeft className="h-6 w-6 text-gray-500" />
        <span className="absolute -top-1 right-0 h-4 w-4 rounded-full bg-indigo-100 text-center text-xs text-indigo-600 ring-1 ring-white">
          {count >= 10 ? '9+' : count}
        </span>
      </button>

      <ActivityModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        offerId={offerId}
        offerType={offerType}
      />
    </>
  );
};

export default ActivityIcon;
