import React from 'react';
import throttle from 'lodash/throttle';

interface Size {
  width: number;
  totalWidth: number;
  scrollLeft: number;
  height: number;
  totalHeight: number;
  scrollTop: number;
}

interface Props {
  updateOnResize?: boolean;
  updateOnScroll?: boolean;
  updateOnContentChange?: boolean;
}

const DEFAULT_PROPS: Props = {
  updateOnResize: true,
  updateOnScroll: false,
  updateOnContentChange: false,
};

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  props: Props = {},
): [(node: T | null) => void, Size] => {
  const { updateOnResize, updateOnContentChange, updateOnScroll } = {
    ...DEFAULT_PROPS,
    ...props,
  };
  const [ref, setRef] = React.useState<T>();
  const [size, setSize] = React.useState<Size>({
    width: 0,
    totalWidth: 0,
    scrollLeft: 0,
    height: 0,
    totalHeight: 0,
    scrollTop: 0,
  });

  const handleSize = React.useMemo(
    () =>
      throttle(() => {
        setSize(
          ({
            width,
            totalWidth,
            scrollTop,
            height,
            totalHeight,
            scrollLeft,
          }) => ({
            width: ref?.offsetWidth ?? width,
            totalWidth: ref?.scrollWidth ?? totalWidth,
            scrollLeft: ref?.scrollLeft ?? scrollLeft,
            height: ref?.offsetHeight ?? height,
            totalHeight: ref?.scrollHeight ?? totalHeight,
            scrollTop: ref?.scrollTop ?? scrollTop,
          }),
        );
      }, 250),
    [ref],
  );

  React.useLayoutEffect(() => {
    if (!ref) return;

    handleSize();

    const resizeObserver = new ResizeObserver(handleSize);
    const mutationObserver = new MutationObserver(handleSize);

    if (updateOnResize) {
      resizeObserver.observe(ref);
    }
    if (updateOnContentChange) {
      mutationObserver.observe(ref, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }
    if (updateOnScroll) {
      ref.addEventListener('scroll', handleSize);
    }

    return () => {
      if (updateOnResize) {
        resizeObserver.disconnect();
      }
      if (updateOnContentChange) {
        mutationObserver.disconnect();
      }
      if (updateOnScroll) {
        ref.removeEventListener('scroll', handleSize);
      }
    };
  }, [ref, handleSize]);

  return [(node) => setRef(node ?? undefined), size];
};

export default useElementSize;
