import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import AppUserAvatar from '../avatar/app-user-avatar';
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import { useUser } from '../../context/auth/use-user';
import { classNames } from '../../style/class-names';
import { useAuth } from '../../context/auth/use-auth';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { OrganizationType } from '../../api/api.types';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import FastSearchSearchbar from '../fast-search/fast-search-searchbar';
import { useSm } from '../../style/media-queries';
import FastSearchButton from '../fast-search/fast-search-button';
import { HELP_URL } from '../../constants/constants';
import Button from '../button/button';
import { CustomerFeedbackDrawer } from '../feedback/customer-feedback-drawer';

const UserMenu = () => {
  const { t } = useTranslation('navigation');
  const [isFastSearchOpen, setIsFastSearchOpen] = React.useState(false);
  const [isFeedbackDrawerOpen, setIsFeedbackDrawerOpen] = React.useState(false);
  const isSm = useSm();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const user = useUser();
  const { logoutUser } = useAuth();
  return (
    <>
      <Menu as="div" className="relative z-10 ml-1">
        <div className="flex items-center">
          {isSm && user.settings.fastSearch && (
            <div className="mr-2">
              <FastSearchButton onClick={() => setIsFastSearchOpen(true)} />
            </div>
          )}
          <div className="mr-2 flex items-center">
            <a href={HELP_URL} rel="noreferrer noopener" target="_blank">
              <Button variant="secondary" className="p-1">
                <QuestionMarkCircleIcon className="h-6 w-6 text-indigo-500" />
              </Button>
            </a>
          </div>
          <div className="mr-2 flex items-center">
            <Button
              variant="secondary"
              type="button"
              onClick={() => setIsFeedbackDrawerOpen(true)}
              className="p-1"
            >
              <ExclamationCircleIcon className="h-6 w-6 text-indigo-500" />
              <span className="sr-only">
                {t('navigation:feedbackForm.openButton')}
              </span>
            </Button>
          </div>
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
            <AppUserAvatar {...user} size="md" />
            <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
              {user.firstName} {user.lastName}
            </span>
            <ChevronDownIcon
              className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {!isSm && user.settings.fastSearch && (
              <Menu.Item key="fastSearch">
                {({ active }) => (
                  <button
                    type="submit"
                    onClick={() => setIsFastSearchOpen(true)}
                    className="w-full text-left"
                  >
                    <div
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      )}
                    >
                      {t('navigation:userMenu.fastSearch.button')}
                    </div>
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item key="settings">
              {({ active }) => (
                <Link
                  to="/settings"
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700',
                  )}
                >
                  {t('userMenu.settings')}
                </Link>
              )}
            </Menu.Item>
            {user.selectedOrganization.type !== OrganizationType.B2C ? (
              <Menu.Item key="organization">
                {({ active }) => (
                  <Link
                    to="/organization"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    )}
                  >
                    {t('userMenu.organization')}
                  </Link>
                )}
              </Menu.Item>
            ) : null}
            <Menu.Item key="logout">
              {({ active }) => (
                <button
                  onClick={async () => {
                    await logoutUser();
                    navigate('/logout');
                    await analytics.track(ANALYTICS_EVENTS.USER_LOGGED_OUT);
                  }}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block w-full px-4 py-2 text-left text-sm text-gray-700',
                  )}
                >
                  {t('userMenu.logout')}
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <FastSearchSearchbar
        isOpen={isFastSearchOpen}
        setIsOpen={setIsFastSearchOpen}
      />
      <CustomerFeedbackDrawer
        isOpen={isFeedbackDrawerOpen}
        close={() => setIsFeedbackDrawerOpen(false)}
      />
    </>
  );
};

export default UserMenu;
