import React from 'react';
import type { Rectangle } from './use-image-selection';

interface Props {
  rectangle: Rectangle;
}

const ImagePartSelection = ({ rectangle }: Props) => {
  const id = React.useId();
  return (
    <div
      key={id}
      style={{
        left: rectangle.x,
        top: rectangle.y,
        width: rectangle.width,
        height: rectangle.height,
      }}
      className="absolute rounded-sm border-2 border-white outline-dashed outline-2 -outline-offset-2 outline-indigo-700"
    ></div>
  );
};

export default ImagePartSelection;
