import React from 'react';

interface Properties<T> {
  ref?: React.RefObject<T>;
  useTimeout?: boolean;
}

export const useElementFocus = <T extends HTMLElement>({
  ref,
  useTimeout = true,
}: Properties<T> = {}) => {
  const internalRef = React.useRef<T>(null);

  const focus = () => {
    if (ref !== undefined) {
      ref.current?.focus();
    } else {
      internalRef.current?.focus();
    }
  };

  const withTimeout = () => {
    setTimeout(() => {
      focus();
    }, 0);
  };

  return {
    ref: ref ? ref : internalRef,
    focus: useTimeout ? withTimeout : focus,
  } as const;
};
