import { useQueryClient } from '@tanstack/react-query';

export const useOffersInvalidation = () => {
  const queryClient = useQueryClient();
  return async ({ subPaths }: { subPaths?: string[][] } = {}) => {
    if (subPaths && subPaths.length > 0) {
      for (const subPath of subPaths) {
        await queryClient.invalidateQueries(['offers', ...subPath]);
      }
    } else {
      await queryClient.invalidateQueries(['offers']);
    }
    await queryClient.invalidateQueries(['sms', 'feed']);
  };
};
