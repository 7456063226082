import {
  ClientDetailsResponse,
  OrganizationPublicResponse,
  UserPublicResponse,
} from '../api.types';
import { MeetingFieldKeys } from '../../forms/meeting';

export enum MeetingResponseStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

interface MeetingActivityLogBase {
  meeting_id: string;
  time: string;
  user?: UserPublicResponse;
  title: string;
  start_time: string;
  end_time: string;
}

export interface MeetingActivityStatusChangeLog extends MeetingActivityLogBase {
  status_from: MeetingResponseStatus;
  status_to: MeetingResponseStatus;
  note?: string;
}

export interface MeetingActivityReassignLog extends MeetingActivityLogBase {
  reassigned_from_id?: string;
  reassigned_to_id?: string;
  reassigned_from?: UserPublicResponse;
  reassigned_to?: UserPublicResponse;
}

export interface MeetingActivityCreateLog extends MeetingActivityLogBase {}

export interface MeetingActivityLogForOffer {
  create_events: MeetingActivityCreateLog[];
  reassign_events: MeetingActivityReassignLog[];
  status_change_events: MeetingActivityStatusChangeLog[];
}

interface MeetingBase {
  meeting_id: string;
  created_by_id?: string;
  organization_id: string;
  title: string;
  description?: string;
  linked_offer_id?: string;
  linked_client_id?: string;
  response_status: MeetingResponseStatus;
  response_note?: string;
  start_time: string;
  end_time: string;
  result_note?: string;
  created_at: string;
  assigned_user_id?: string;
}

export interface ListMeetingsResponseItem extends MeetingBase {
  linked_client?: ClientDetailsResponse;
  assigned_user?: UserPublicResponse;
}

export interface GetMeetingResponse extends MeetingBase {
  created_by: UserPublicResponse;
  organization: OrganizationPublicResponse;
  linked_client?: ClientDetailsResponse;
  assigned_user?: UserPublicResponse;
}

export interface ListMeetingsResponse {
  results: ListMeetingsResponseItem[];
  page: number;
  total_items: number;
  total_items_no_filters: number;
  total_pages: number;
  has_next_page: boolean;
}

export interface UpdateMeetingRequest {
  update_fields: MeetingFieldKeys[];
  title?: string | null;
  description?: string | null;
  response_status?: MeetingResponseStatus | null;
  response_note?: string | null;
  start_time?: Date | null;
  end_time?: Date | null;
  result_note?: string | null;
  assigned_user_id?: string | null;
  linked_client_id?: string | null;
}

export interface CreateMeetingRequest {
  title: string;
  description?: string | null;
  linked_offer_id?: string | null;
  linked_client_id?: string | null;
  start_time: Date;
  end_time: Date;
  assigned_user_id?: string | null;
}
