import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isEmail, isPhoneSpaceAllowed } from '../constants/validation';

export enum ClientType {
  NEW = 'new',
  EXISTING = 'existing',
}

export enum ClientFieldKeys {
  CLIENT_TYPE = 'client_search_client_type',
  CLIENT_ID = 'client_search_client_id',
  CLIENT_FIRST_NAME = 'client_search_client_first_name',
  CLIENT_LAST_NAME = 'client_search_client_last_name',
  CLIENT_PHONE_NUMBER = 'client_search_client_phone_number',
  CLIENT_EMAIL = 'client_search_client_email',
}

export interface ClientFieldTypesRaw {
  [ClientFieldKeys.CLIENT_TYPE]: string;
  [ClientFieldKeys.CLIENT_ID]?: string | null;
  [ClientFieldKeys.CLIENT_FIRST_NAME]?: string;
  [ClientFieldKeys.CLIENT_LAST_NAME]?: string;
  [ClientFieldKeys.CLIENT_PHONE_NUMBER]?: string;
  [ClientFieldKeys.CLIENT_EMAIL]?: string;
}

export interface ClientFieldTypesValidated {
  [ClientFieldKeys.CLIENT_TYPE]: ClientType;
  [ClientFieldKeys.CLIENT_ID]: string;
  [ClientFieldKeys.CLIENT_FIRST_NAME]: string;
  [ClientFieldKeys.CLIENT_LAST_NAME]: string;
  [ClientFieldKeys.CLIENT_PHONE_NUMBER]: string;
  [ClientFieldKeys.CLIENT_EMAIL]?: string;
}

export const useGetClientFields = () => {
  const { t } = useTranslation(['validation']);

  return ({
    exclude,
    mappings,
  }: {
    exclude?: ClientFieldKeys[];
    mappings?: Partial<
      Record<ClientFieldKeys, (schema: Yup.AnySchema) => Yup.AnySchema>
    >;
  } = {}) =>
    Object.entries({
      [ClientFieldKeys.CLIENT_TYPE]: Yup.string().oneOf(
        Object.values(ClientType),
      ),
      [ClientFieldKeys.CLIENT_ID]: Yup.string().when(
        ClientFieldKeys.CLIENT_TYPE,
        {
          is: ClientType.EXISTING,
          then: Yup.string().required(t('validation:required')).nullable(),
          otherwise: Yup.string().nullable(),
        },
      ),
      [ClientFieldKeys.CLIENT_FIRST_NAME]: Yup.string().when(
        ClientFieldKeys.CLIENT_TYPE,
        {
          is: ClientType.EXISTING,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required(t('validation:required')),
        },
      ),
      [ClientFieldKeys.CLIENT_LAST_NAME]: Yup.string().when(
        ClientFieldKeys.CLIENT_TYPE,
        {
          is: ClientType.EXISTING,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required(t('validation:required')),
        },
      ),
      [ClientFieldKeys.CLIENT_PHONE_NUMBER]: Yup.string().when(
        ClientFieldKeys.CLIENT_TYPE,
        {
          is: ClientType.EXISTING,
          then: Yup.string().nullable(),
          otherwise: isPhoneSpaceAllowed(t, {
            required: true,
          }),
        },
      ),
      [ClientFieldKeys.CLIENT_EMAIL]: Yup.string().when(
        ClientFieldKeys.CLIENT_TYPE,
        {
          is: ClientType.EXISTING,
          then: Yup.string().nullable(),
          otherwise: isEmail(t).transform((v) => (v === '' ? null : v)),
        },
      ),
    }).reduce((acc, [key, value]) => {
      const typedKey = key as ClientFieldKeys;

      if (!(exclude ?? []).includes(typedKey)) {
        acc[typedKey] =
          typedKey in (mappings ?? {}) ? mappings?.[typedKey]?.(value) : value;
      }
      return acc;
    }, {} as Record<ClientFieldKeys, any>);
};
