import React from 'react';

const VARIANT_TO_STYLE_MAP = {
  'extra-large': 'text-2xl sm:text-3xl xl:text-4xl leading-8 font-bold',
  large: 'text-xl sm:text-2xl xl:text-3xl leading-7 font-semibold',
  normal: 'text-lg sm:text-xl xl:text-2xl leading-7 font-semibold',
  small: 'text-base sm:text-lg xl:text-xl  leading-6 font-semibold',
  'extra-small': 'text-sm sm:text-base xl:text-lg leading-6 font-semibold',
};

const VARIANT_TO_DEFAULT_COMPONENT = {
  'extra-large': 'h1',
  large: 'h1',
  normal: 'h1',
  small: 'h2',
  'extra-small': 'h3',
} as const;

interface Props {
  variant?: 'extra-large' | 'large' | 'normal' | 'small' | 'extra-small';
  Component?: 'h1' | 'h2' | 'h3' | 'h4';
  className?: string;
  children?: JSX.Element | string;
}

export const Title = ({
  Component,
  variant = 'normal',
  children,
  className = '',
}: Props) => {
  Component ??= VARIANT_TO_DEFAULT_COMPONENT[variant];
  return (
    <Component className={`${VARIANT_TO_STYLE_MAP[variant]} ${className}`}>
      {children}
    </Component>
  );
};
