import React from 'react';
import OfferSmallCardSkeleton from '../../../../components/offer/offer-small-card-skeleton';

interface Props {}

const OfferStateTableItemSkeleton = ({}: Props) => {
  return (
    <li className="flex items-center justify-between gap-x-6 py-4">
      <div className="flex-1">
        <OfferSmallCardSkeleton />
      </div>
    </li>
  );
};

export default OfferStateTableItemSkeleton;
