import React from 'react';
import { Controller } from 'react-hook-form';
import LocationInputLabeled, {
  LocationInputLabeledProps,
} from './location-input-labeled';
import { AddressSearchType } from './location-input';

interface Props extends Omit<LocationInputLabeledProps, 'onChange' | 'value'> {
  name: string;
  control: any;
  className?: string;
  id: string;
  searchType?: AddressSearchType;
}

const LocationInputLabeledController = ({ name, control, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocationInputLabeled
          onChange={onChange}
          value={value}
          isError={!!error}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default LocationInputLabeledController;
