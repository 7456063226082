import React from 'react';

interface TopNavBarHeightContextProps {
  height: number;
}

const TopNavBarHeightContext = React.createContext<TopNavBarHeightContextProps>(
  { height: 0 },
);

export const useTopNavBarHeight = () => {
  const context = React.useContext(TopNavBarHeightContext);
  if (!context) {
    throw new Error(`useTopNavBarHeight must be used within a Navigation`);
  }
  return context;
};

export const TopNavBarHeightProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navBarHeight, setNavBarHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    const navElement = document.getElementById('main-top-navigation');
    const handleSize = () => {
      setNavBarHeight(navElement?.clientHeight ?? 0);
    };

    handleSize();

    if (!navElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(handleSize);
    resizeObserver.observe(navElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <TopNavBarHeightContext.Provider value={{ height: navBarHeight }}>
      {children}
    </TopNavBarHeightContext.Provider>
  );
};
