import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { OfferTypeEnum } from '../../../api/api.types';
import { usePopper } from 'react-popper';
import OfferDuplicatesPopoverContent from './offer-duplicates-popover-content';
import { useQuery } from '@tanstack/react-query';
import { getDuplicatesForOffer } from '../../../api/offers/offers';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';

interface Props {
  duplicatesCount: number;
  offerId: string;
  offerType: OfferTypeEnum;
  className?: string;
}

const OfferDuplicatesPopover = ({
  duplicatesCount,
  offerId,
  offerType,
  className = '',
}: Props) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });

  const { data: duplicates = [] } = useQuery(
    ['offer', 'duplicates', offerType, offerId],
    () => getDuplicatesForOffer(offerType, offerId),
    { enabled: false },
  );

  const duplicatesCountNew = Math.max(duplicatesCount, duplicates.length);

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={`relative flex items-baseline rounded px-1.5 ${className}`}
            ref={setReferenceElement}
          >
            <DocumentDuplicateIcon className="h-6 w-6 text-gray-500" />
            <span className="absolute -top-1 right-0 h-4 w-4 rounded-full rounded-full bg-amber-100 text-center text-xs text-amber-600 ring-1 ring-white">
              {duplicatesCountNew >= 10 ? '9+' : duplicatesCountNew}
            </span>
          </Popover.Button>
          <div
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper}
            className="z-30 w-96"
          >
            <Transition
              unmount={false}
              beforeEnter={() => setActive(true)}
              as={React.Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              afterLeave={() => setActive(false)}
            >
              <Popover.Panel unmount={false}>
                <div className="flex flex-col overflow-hidden rounded-lg bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5">
                  <OfferDuplicatesPopoverContent
                    offerId={offerId}
                    isOpen={open}
                    offerType={offerType}
                    expectedDuplicatesCount={duplicatesCount}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

export default OfferDuplicatesPopover;
