import { MostRelevantActivity } from '../../api/api.types';
import CallResultBadge from '../badge/call-result-badge';
import CallResultIcon from '../icons/call-result-icon';
import OfferStatusBadge from '../badge/offer-status-badge';
import OfferStatusIcon from '../icons/offer-status-icon';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../context/auth/use-user';
import UserAvatar from '../avatar/user-avatar';

interface Props {
  mostRelevantActivity: MostRelevantActivity;
}

const ActivityIndicator = ({ mostRelevantActivity }: Props) => {
  const user = useUser();
  const { t } = useTranslation(['offers']);

  const isAddedByUser =
    mostRelevantActivity?.call_result?.is_added_by_user ||
    mostRelevantActivity?.offer_status?.is_added_by_user;

  const userAvatarProps = isAddedByUser
    ? {
        prefix: (
          <UserAvatar
            user_id={user.userId}
            first_name={user.firstName}
            last_name={user.lastName}
            avatar_url={user.avatarUrl}
            size="sm"
            className="z-10"
          />
        ),
      }
    : {};

  return (
    <>
      <div
        data-tip={
          isAddedByUser
            ? t('offers:timeline.userActivity')
            : t('offers:timeline.orgActivity')
        }
      >
        {mostRelevantActivity?.call_result && (
          <div className="flex">
            <CallResultBadge
              className="hidden md:flex"
              offerStatus={mostRelevantActivity.call_result.call_result}
              {...userAvatarProps}
            />
            <CallResultIcon
              callResult={mostRelevantActivity.call_result.call_result}
              className="md:hidden"
            />
          </div>
        )}
        {mostRelevantActivity?.offer_status && (
          <>
            <OfferStatusBadge
              className="hidden md:flex"
              offerStatus={mostRelevantActivity.offer_status.offer_status}
              isArchived={mostRelevantActivity.offer_status.is_archived}
              {...userAvatarProps}
            />
            <OfferStatusIcon
              size="md"
              className="md:hidden"
              offerStatus={mostRelevantActivity.offer_status.offer_status}
              isArchived={mostRelevantActivity.offer_status.is_archived}
            />
          </>
        )}
      </div>
      <ReactTooltip />
    </>
  );
};

export default ActivityIndicator;
