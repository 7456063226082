import { enGB } from 'date-fns/esm/locale';
import { pl } from 'date-fns/locale';

export enum SupportedLanguages {
  PL = 'pl',
  EN = 'en',
}

export const ALLOWED_LANGUAGES = new Set(Object.values(SupportedLanguages));

export const DATE_FNS_LOCALES: { [key in SupportedLanguages]: Locale } = {
  en: enGB,
  pl,
};
