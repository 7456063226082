import React from 'react';
import Button, { ButtonProps } from './button';
import { FeatureFlag } from '../../api/api.types';
import { useUser } from '../../context/auth/use-user';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { LockClosedIcon } from '@heroicons/react/20/solid';

interface Props extends ButtonProps {
  requiredFeatureFlags: FeatureFlag[];
}

const FFButton = ({
  requiredFeatureFlags,
  disabled,
  children,
  ...rest
}: Props) => {
  const user = useUser();
  const { t } = useTranslation('common');
  const hasRequiredFeatureFlags = requiredFeatureFlags.every((flag) =>
    user.hasFeatureFlag(flag),
  );

  if (hasRequiredFeatureFlags) {
    return <Button disabled={disabled} children={children} {...rest} />;
  }
  return (
    <>
      <Button
        data-effect="solid"
        data-tip={t('common:featureFlags.disabled')}
        disabled
        data-for="disabledButton"
        {...rest}
      >
        <div className="absolute -right-2 -top-2 flex items-center justify-center text-white">
          <LockClosedIcon
            className={`h-6 w-6 rounded-full bg-indigo-500 p-1`}
          />
        </div>
        {children}
      </Button>
      <ReactTooltip id="disabledButton" />
    </>
  );
};

export default FFButton;
