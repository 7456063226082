import { OfferTypeEnum } from '../../api/api.types';
import React from 'react';
import { useAddToClientSearchClient } from './use-add-to-client-search-client';
import { useAddToClientSearchClientSearch } from './use-add-to-client-search-client-search';
import {
  UseAddToClientSearchState,
  useAddToClientSearchState,
} from './use-add-to-cleint-search-state';
import { useAddToClientSearchSubmit } from './use-add-to-client-search-submit';
import { useAddToClientSearchRedirect } from './use-add-to-client-search-redirect';

interface UseAddToClientSearchProps {
  offerId: string;
  offerType: OfferTypeEnum;
}

export const useAddToClientSearch = ({
  offerId,
  offerType,
}: UseAddToClientSearchProps) => {
  const client = useAddToClientSearchClient();
  const clientSearch = useAddToClientSearchClientSearch({
    client,
  });
  const submit = useAddToClientSearchSubmit({
    client,
    clientSearch,
    offer: {
      id: offerId,
      type: offerType,
    },
  });
  const state = useAddToClientSearchState({ client, clientSearch, submit });
  const redirect = useAddToClientSearchRedirect({
    state: state.value,
    clientSearchId: submit.finalClientSearchId,
  });

  const shouldDisplayClientSelection = ![
    UseAddToClientSearchState.INITIAL_LOADING,
  ].includes(state.value);

  const shouldDisplayClientSearchSelection = ![
    UseAddToClientSearchState.INITIAL_LOADING,
    UseAddToClientSearchState.CLIENT_INPUT,
    UseAddToClientSearchState.CLIENT_SEARCH_LOADING,
  ].includes(state.value);

  React.useEffect(() => {
    if (state.value === UseAddToClientSearchState.CLIENT_SEARCH_LOADING) {
      clientSearch.init();
    }
  }, [state]);

  const reset = () => {
    state.reset();
    client.clear();
    clientSearch.clear();
    redirect.clear();
    submit.clear();
  };

  const init = () => {
    reset();
    client.init();
  };

  return {
    state: state.value,
    shouldDisplayClientSelection,
    shouldDisplayClientSearchSelection,
    client,
    clientSearch,
    canSubmit: submit.canSubmit,
    submit: submit.submit,
    reset,
    init,
    redirect,
  };
};
