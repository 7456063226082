import React from 'react';
import { Combobox } from '@headlessui/react';
import { classNames } from '../../../style/class-names';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import TeamBadge from '../../teams/team-badge';

export interface TeamInputOption {
  teamId: string;
  name: string;
}

interface Props {
  value: string | null;
  onChange: (teamId: string | null) => void;
  label?: string;
  teams?: TeamInputOption[];
  placeholder?: string;
  errorMessage?: string;
  isError?: boolean;
  clearable?: boolean;
}

const TeamInput = ({
  value,
  onChange,
  label = '',
  teams = [],
  placeholder,
  errorMessage,
  isError,
  clearable = true,
}: Props) => {
  const [query, setQuery] = React.useState('');

  const filteredTeams =
    query === ''
      ? teams
      : teams.filter((team) => {
          team.name.toLowerCase().includes(query.toLowerCase());
        });

  const sortedTeams = [...filteredTeams].sort((left, right) =>
    left.name.localeCompare(right.name),
  );

  return (
    <Combobox as="div" value={value} onChange={onChange} nullable>
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          placeholder={placeholder}
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(team: string) =>
            teams.find((t) => t.teamId === team)?.name ?? ''
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          {value && clearable ? (
            <span
              className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}
            >
              <XMarkIcon
                className="h-5 w-5 cursor-pointer text-gray-400"
                aria-hidden="true"
              />
            </span>
          ) : (
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </Combobox.Button>
        {sortedTeams.length > 0 && (
          <Combobox.Options className="absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {sortedTeams.map((team) => (
              <Combobox.Option
                key={team.teamId}
                value={team.teamId}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active }) => (
                  <>
                    <div className="flex items-center">
                      <TeamBadge name={team.name} teamId={team.teamId} />
                    </div>
                    {team.teamId === value && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center rounded-md pr-4',
                          active ? 'text-white' : 'text-indigo-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {isError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </Combobox>
  );
};

export default TeamInput;
