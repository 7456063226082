import React from 'react';
import InputLabel, { InputLabelProps } from '../shared/input-label';
import TextAreaInput, { TextAreaInputProps } from './text-area-input';

export interface TextInputLabeledProps
  extends TextAreaInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
  saveErrorSpace?: boolean;
}

const TextAreaInputLabeled = ({
  className = '',
  label,
  labelClassName,
  id,
  ...rest
}: TextInputLabeledProps) => {
  return (
    <div className={className}>
      <InputLabel labelFor={id} label={label} labelClassName={labelClassName} />
      <TextAreaInput {...rest} />
    </div>
  );
};

export default TextAreaInputLabeled;
