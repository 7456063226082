import React from 'react';
import AppProgressBar from '../app-progress-bar/app-progress-bar';

const NoNav = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative h-full">
      <AppProgressBar />
      <div className="h-full">{children}</div>
    </div>
  );
};

export default NoNav;
