import { OfferTypeEnum } from '../api/api.types';
import enums from '../ui-model/enums.json';
import landSalesSchema from './offers/land_sales_json_schema.json';
import landRentsSchema from './offers/land_rents_json_schema.json';
import officeSalesSchema from './offers/office_sales_json_schema.json';
import officeRentsSchema from './offers/office_rents_json_schema.json';
import industrialBuildingSalesSchema from './offers/industrial_building_sales_json_schema.json';
import industrialBuildingRentsSchema from './offers/industrial_building_rents_json_schema.json';
import warehouseSalesSchema from './offers/warehouse_sales_json_schema.json';
import warehouseRentsSchema from './offers/warehouse_rents_json_schema.json';
import commercialBuildingSalesSchema from './offers/commercial_building_sales_json_schema.json';
import commercialBuildingRentsSchema from './offers/commercial_building_rents_json_schema.json';
import apartmentSalesSchema from './offers/apartment_sales_json_schema.json';
import apartmentRentsSchema from './offers/apartment_rents_json_schema.json';
import houseSalesSchema from './offers/house_sales_json_schema.json';
import houseRentsSchema from './offers/house_rents_json_schema.json';
import garageSalesSchema from './offers/garage_sales_json_schema.json';
import garageRentsSchema from './offers/garage_rents_json_schema.json';
import roomRentsSchema from './offers/room_rents_json_schema.json';
import auctionsSchema from './offers/auctions_json_schema.json';

const SCHEMA_FOR_OFFERS: Record<OfferTypeEnum, any> = {
  [OfferTypeEnum.LAND_SALES]: landSalesSchema,
  [OfferTypeEnum.LAND_RENTS]: landRentsSchema,
  [OfferTypeEnum.OFFICE_SALES]: officeSalesSchema,
  [OfferTypeEnum.OFFICE_RENTS]: officeRentsSchema,
  [OfferTypeEnum.INDUSTRIAL_BUILDING_SALES]: industrialBuildingSalesSchema,
  [OfferTypeEnum.INDUSTRIAL_BUILDING_RENTS]: industrialBuildingRentsSchema,
  [OfferTypeEnum.WAREHOUSE_SALES]: warehouseSalesSchema,
  [OfferTypeEnum.WAREHOUSE_RENTS]: warehouseRentsSchema,
  [OfferTypeEnum.COMMERCIAL_BUILDING_SALES]: commercialBuildingSalesSchema,
  [OfferTypeEnum.COMMERCIAL_BUILDING_RENTS]: commercialBuildingRentsSchema,
  [OfferTypeEnum.APARTMENT_SALES]: apartmentSalesSchema,
  [OfferTypeEnum.APARTMENT_RENTS]: apartmentRentsSchema,
  [OfferTypeEnum.HOUSE_SALES]: houseSalesSchema,
  [OfferTypeEnum.HOUSE_RENTS]: houseRentsSchema,
  [OfferTypeEnum.GARAGE_SALES]: garageSalesSchema,
  [OfferTypeEnum.GARAGE_RENTS]: garageRentsSchema,
  [OfferTypeEnum.ROOM_RENTS]: roomRentsSchema,
  [OfferTypeEnum.AUCTIONS]: auctionsSchema,
};

export const schemaHasField = (
  offerType: OfferTypeEnum,
  field: string,
): boolean => {
  return !!SCHEMA_FOR_OFFERS[offerType]?.properties?.[field];
};

export type OfferEnums = keyof typeof enums;
export const getEnumOptions = (
  enumName: OfferEnums,
  t: (transKey: string) => string,
) => {
  const enumValues = enums[enumName];
  return enumValues.map((value) => ({
    label: t(`data-model:enumLabels.${enumName}.${value}`),
    value,
  }));
};

export const convertToSchema = (offerType: OfferTypeEnum, offer: any) => {
  const schema = SCHEMA_FOR_OFFERS[offerType];
  const properties = schema.properties;

  const copiedOffer = { ...offer };

  Object.keys(copiedOffer).forEach((key) => {
    if (copiedOffer[key] === '') {
      copiedOffer[key] = null;
    }
  });
  const convertedOffer = Object.keys(properties).reduce((acc, key) => {
    const property = properties[key];
    const value = copiedOffer[key];
    if (property.type === 'boolean') {
      if (value === 'false' || value === false) {
        acc[key] = false;
      } else if (value === 'true' || value === true) {
        acc[key] = true;
      } else {
        acc[key] = null;
      }
    } else if (property.type === 'number') {
      if (value === '' || value === null || value === undefined) {
        acc[key] = null;
      } else {
        acc[key] = Number(value);
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as any);

  // create a new object without null keys using reduce
  return Object.keys(convertedOffer).reduce((acc, key) => {
    if (convertedOffer[key] !== null && convertedOffer[key] !== undefined) {
      acc[key] = convertedOffer[key];
    }
    return acc;
  }, {} as any);
};

export const formToOfferSchemaAvm = (form: any, offerType: OfferTypeEnum) => {
  const {
    address,
    location,
    floor_no_lower_bound,
    total_levels_in_building_lower_bound,
    ...filteredForm
  } = form;

  const addressValue = address?.[0]?.value;

  const offerData = {
    ...filteredForm,
    location_latitude: location.latitude,
    location_longitude: location.longitude,
    location_approximation: location.approximation,
    address_city: addressValue?.address_city,
    address_state: addressValue?.address_state,
    address_county: addressValue?.address_county,
    address_community: addressValue?.address_community,
    ...(addressValue?.address_district
      ? { address_district: addressValue.address_district }
      : {}),
    ...(addressValue?.address_street
      ? { address_street: addressValue.address_street }
      : {}),
    ...(addressValue?.address_street_number
      ? { address_street_number: addressValue.address_street_number }
      : {}),
    floor_no_lower_bound: floor_no_lower_bound ?? null,
    floor_no_upper_bound: floor_no_lower_bound ?? null,
    total_levels_in_building_lower_bound:
      total_levels_in_building_lower_bound ?? null,
    total_levels_in_building_upper_bound:
      total_levels_in_building_lower_bound ?? null,
    offer_type: offerType,
  };

  return convertToSchema(offerType, offerData);
};
