import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postAssignOffer } from '../../api/offer-assignments/offer-assignments';
import { useTranslation } from 'react-i18next';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import React from 'react';
import { useUser } from '../../context/auth/use-user';

export const useAssignOffer = () => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const user = useUser();
  const { mutate: assignOffer, isLoading: isAssignmentLoading } = useMutation(
    postAssignOffer,
    {
      onSuccess: () => {
        return client.invalidateQueries(['offers']);
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 422) {
          toast.custom((toast) => (
            <ErrorToast
              {...toast}
              title={t('common:alerts.offerAlreadyAssigned.title')}
              body={t('common:alerts.offerAlreadyAssigned.body')}
            />
          ));
        } else {
          defaultErrorToasts(error, t);
        }
      },
    },
  );

  return {
    assignOffer: ({ offerId }: { offerId: string }) =>
      assignOffer({ offerId, body: { team_id: user.team?.teamId } }),
    isAssignmentLoading,
  };
};
