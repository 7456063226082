import * as yup from 'yup';

export const isPhone = (
  t: (str: string) => string,
  { required = false } = {},
) => {
  if (required) {
    return yup
      .string()
      .matches(/\d{9}/, t('common:validation.phone.invalid'))
      .required(t('common:validation.phone.empty'));
  } else {
    return yup
      .string()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/\d{9}/, t('common:validation.phone.invalid'))
      .notRequired()
      .nullable(true);
  }
};

export const isPhoneSpaceAllowed = (
  t: (str: string) => string,
  { required = false } = {},
) => {
  if (required) {
    return yup
      .string()
      .transform((v) => v.replaceAll(/\D/g, ''))
      .matches(/\d{9}/, t('common:validation.phone.invalid'))
      .required(t('common:validation.phone.empty'));
  } else {
    return yup
      .string()
      .transform((v) => v.replaceAll(/\D/g, ''))
      .transform((v, o) => (o === '' ? null : v))
      .matches(/\d{9}/, t('common:validation.phone.invalid'))
      .notRequired()
      .nullable(true);
  }
};

export const isEmail = (
  t: (str: string) => string,
  { required = false } = {},
) => {
  if (required) {
    return yup
      .string()
      .required(t('common:validation.email.empty'))
      .email(t('common:validation.email.invalid'));
  } else {
    return yup.string().email(t('common:validation.email.invalid')).nullable();
  }
};
