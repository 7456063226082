import React from 'react';
import { useTranslation } from 'react-i18next';
import bg from '../../assets/error-bg.png';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import Button from '../../components/button/button';
import { useQueryClient } from '@tanstack/react-query';
import { UserContext, useUser } from '../../context/auth/use-user';

interface Props {
  message: string;
  errorStack: string;
  componentStack: string;
  errorName: string;
}

const Error = ({ message, errorName, errorStack, componentStack }: Props) => {
  const { t } = useTranslation('common');

  const instance = useAnalytics();
  const userContext = React.useContext(UserContext);

  const pageTitle = `${t('common:pageTitle.error')} | ${t(
    'common:pageTitle.suffix',
  )}`;

  const queryClient = useQueryClient();

  React.useEffect(() => {
    document.title = pageTitle;
    instance.page();
    instance.track(ANALYTICS_EVENTS.APP_ERROR, {
      message,
      errorName,
      errorStack,
      componentStack,
      userId: userContext?.user.userId,
    });
  }, []);

  const reload = () => {
    queryClient.clear();
    window.location.reload();
  };

  return (
    <div className="flex h-full flex-row bg-white lg:relative">
      <div className="flex flex-grow flex-col">
        <main className="flex flex-grow flex-col bg-white">
          <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-6 sm:px-8 lg:px-10">
            <div className="my-auto flex-shrink-0 py-16 sm:py-32">
              <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
                500 Error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                {t('common:error.header')}
              </h1>
              <p className="mt-2 text-base text-gray-500">
                {t('common:error.description')}
              </p>
              <Button className="mt-6 px-4 py-2" onClick={reload}>
                {t('common:error.reload')}
              </Button>
            </div>
          </div>
        </main>
      </div>
      <div className="relative hidden w-1/2 md:block md:block lg:w-2/3">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default Error;
