import React from 'react';
import TextInput from '../text-input/text-input';

export enum I18nPhoneFormat {
  POLAND,
}
export interface Props {
  value: string;
  onChange: (newVal: string) => void;
  id?: string;
  isError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  format?: I18nPhoneFormat;
  className?: string;
  saveErrorSpace?: boolean;
}

const PhoneInput = ({
  value = '',
  onChange,
  id,
  isError = false,
  errorMessage = '',
  placeholder,
  format = I18nPhoneFormat.POLAND,
  saveErrorSpace = true,
  ...rest
}: Props) => {
  const [displayValue, setDisplayValue] = React.useState(value);
  const [prevValue, setPrevValue] = React.useState('');

  React.useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
  }, [value]);

  const calcNewValue = (newValue: string) => {
    if (prevValue.length > newValue.length) {
      setPrevValue(newValue);
      setDisplayValue(newValue);
      return newValue;
    }
    const result = formatFunction[format](newValue, prevValue);
    setPrevValue(result);
    setDisplayValue(result);
    return result;
  };

  return (
    <TextInput
      id={id}
      value={displayValue}
      onChange={(v) => onChange(calcNewValue(v))}
      type="tel"
      placeholder={placeholder}
      isError={isError}
      errorMessage={errorMessage}
      saveErrorSpace={saveErrorSpace}
      prefixIcon={<div className="sm:text-sm">+48</div>}
      {...rest}
    />
  );
};

const formatFunction: {
  [key in I18nPhoneFormat]: (value: string, prevValue: string) => string;
} = {
  [I18nPhoneFormat.POLAND]: (newValue) => {
    const withoutSpaces = newValue.replace(' ', '');
    if (withoutSpaces.length > 9 || !withoutSpaces.match(/^\d+$/)) {
      return newValue;
    }
    return withoutSpaces.replace(/(.{3})/g, '$1 ');
  },
};

export default PhoneInput;
