import Modal from '../../../components/modal/modal';
import { OfferVariantResponse } from '../../../api/api.types';
import ImagePartsSelector from './image-parts-selector';
import React from 'react';
import { Rectangle, useImageSelection } from './use-image-selection';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/20/solid';
import Button from '../../../components/button/button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getWatermarkRemovalQuota,
  startWatermarkRemoval,
} from '../../../api/watermark_remover/watermark_remover';
import ToggleInputLabeled from '../../../components/inputs/toggle-input/toggle-input-labeled';
import ImagePartSelection from './image-part-selection';
import ImagePartSelectionButtons from './image-part-selection-buttons';
import useElementSize from '../../../hooks/use-element-dimentions/use-element-size';
import SpinnerIcon from '../../../components/spinner/spinner-icon';
import { AxiosError } from 'axios';
import { useToast } from '../../../hooks/use-toast/use-toast';
import { useTranslation } from 'react-i18next';
import ImageDisplay from './image-display';
import ReactTooltip from 'react-tooltip';

enum Status {
  IMAGES_LOADING = 'IMAGES_LOADING',
  RECTANGLES_SELECTION = 'RECTANGLES_SELECTION',
  SUBMITTING = 'SUBMITTING',
}

interface Props {
  isOpen: boolean;
  close: () => void;
  offerVariant: OfferVariantResponse;
  onSubmit?: () => void;
}

const WatermarkRemoverModal = ({
  isOpen,
  close,
  offerVariant,
  onSubmit,
}: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('client-searches');
  const { displayErrorToast, displayDefaultErrorToasts, displaySuccessToast } =
    useToast();
  const [status, setStatus] = React.useState(Status.IMAGES_LOADING);
  React.useEffect(() => {
    setStatus(Status.IMAGES_LOADING);
  }, [offerVariant.photos_urls]);

  const { photo, rectangles, scale } = useImageSelection({
    isEnabled: isOpen,
    imageUrls: offerVariant.photos_urls,
    initialUrl: offerVariant.photos_urls[0],
    onImagesDimensionsLoaded: () => {
      setStatus(Status.RECTANGLES_SELECTION);
    },
  });

  const [isFullScreen, setFullScreen] = React.useState(false);
  const [imgRef, { width: imageWidth, height: imageHeight }] = useElementSize();
  const [containerRef, { width: wrapperWidth, height: wrapperHeight }] =
    useElementSize();
  const [isSelecting, useIsSelecting] = React.useState(false);
  const [isImgLoading, setIsImageLoading] = React.useState(true);

  const { mutateAsync } = useMutation(startWatermarkRemoval);
  const { data: quotaInfo } = useQuery(
    ['watermark-removal', 'quota'],
    getWatermarkRemovalQuota,
  );

  const submit = async () => {
    setStatus(Status.SUBMITTING);
    await mutateAsync({
      offer_variant_id: offerVariant.offer_variant_id,
      images: Object.entries(rectangles.data)
        .filter(([, rectangles]) => rectangles.length > 0)
        .map(([url, rectangles]) => ({
          original_photo_url: url,
          rectangles: rectangles.map((rectangle) => ({
            ...rectangle,
          })),
        })),
    })
      .then(() => {
        queryClient.invalidateQueries(['watermark-removal', 'quota']);
        displaySuccessToast({
          title: t(
            'client-searches:clientSearchDetails.watermarkRemoval.startSuccess.title',
          ),
          body: t(
            'client-searches:clientSearchDetails.watermarkRemoval.startSuccess.details',
          ),
        });
        onSubmit?.();
        rectangles.clear();
        close();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          displayErrorToast({
            title: t(
              'client-searches:clientSearchDetails.watermarkRemoval.startQuotaFailed.title',
            ),
            body: t(
              'client-searches:clientSearchDetails.watermarkRemoval.startQuotaFailed.details',
              { quota: quotaInfo?.limit ?? 200 },
            ),
          });
        } else {
          displayDefaultErrorToasts(error);
        }
      })
      .finally(() => {
        setStatus(Status.RECTANGLES_SELECTION);
      });
  };

  const scaleToCurrentDisplayedImage = (rectangle: Rectangle) => {
    return scale.toDisplayedImageDimensions({
      rectangle,
      scaleToUrl: photo.url,
      displayedImageWidth: imageWidth,
      displayedImageHeight: imageHeight,
    });
  };

  const scaleToRealImageDimensions = (rectangle: Rectangle) => {
    return scale.toRealImageDimensions({
      rectangle,
      scaleToUrl: photo.url,
      displayedImageWidth: imageWidth,
      displayedImageHeight: imageHeight,
    });
  };

  const removeRectangle = (rectangle: Rectangle, forAllImages: boolean) => {
    if (forAllImages) {
      rectangles.removeAll(scaleToRealImageDimensions(rectangle));
    } else {
      rectangles.remove(scaleToRealImageDimensions(rectangle));
    }
  };

  const copyRectangleToAll = (rectangle: Rectangle) => {
    rectangles.copyToAll(scaleToRealImageDimensions(rectangle));
  };

  const currentRectangles = (rectangles.data[photo.url] ?? []).map(
    (rectangle) => ({
      ...rectangle,
      ...scaleToCurrentDisplayedImage(rectangle),
    }),
  );

  const whiteSpaces = {
    width: (wrapperWidth - imageWidth) / 2,
    height: (wrapperHeight - imageHeight) / 2,
  };

  const imageDimensions = {
    width: imageWidth,
    height: imageHeight,
  };

  const quotaTooltipId = React.useId();

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        rectangles.clear();
        close();
      }}
      wrapperClassName={`${
        isFullScreen
          ? '!min-h-screen !w-screen !my-0 !rounded-none !max-w-[100vw]'
          : ''
      }`}
    >
      <div>
        <h1 className="w-screen  text-base font-semibold leading-6 text-gray-900">
          {t('client-searches:clientSearchDetails.watermarkRemoval.title')} (
          {offerVariant.photos_urls.indexOf(photo.url) + 1}/
          {offerVariant.photos_urls.length})
        </h1>
        <span className="prose prose-sm mt-1 line-clamp-3 max-w-full break-words text-sm text-gray-500">
          {t(
            'client-searches:clientSearchDetails.watermarkRemoval.description',
          )}
        </span>
      </div>
      {status === Status.IMAGES_LOADING && (
        <div className="my-4 flex h-[65vh] w-full items-center justify-center">
          <SpinnerIcon className="h-10 w-10 text-indigo-600" />
        </div>
      )}
      {status !== Status.IMAGES_LOADING && (
        <div
          ref={containerRef}
          className="relative my-4 flex w-full items-center justify-center overflow-hidden p-1"
        >
          <ImageDisplay
            width={imageWidth}
            height={imageHeight}
            url={photo.url}
            isFullScreen={isFullScreen}
            refObj={imgRef}
            onChangeIsImageLoading={setIsImageLoading}
          />
          {!isImgLoading && (
            <div
              style={{ width: imageWidth, height: imageHeight }}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
              {currentRectangles.map((rectangle) => (
                <ImagePartSelection rectangle={rectangle} />
              ))}
            </div>
          )}
          {!isImgLoading && (
            <ImagePartsSelector
              width={imageWidth}
              height={imageHeight}
              onSelect={(rectangle) =>
                rectangles.add(scaleToRealImageDimensions(rectangle))
              }
              onSelectChange={useIsSelecting}
            />
          )}
          {!isSelecting && !isImgLoading && (
            <>
              {currentRectangles.map((rectangle) => (
                <ImagePartSelectionButtons
                  rectangle={rectangle}
                  margins={whiteSpaces}
                  imageDimensions={imageDimensions}
                  removeRectangle={removeRectangle}
                  copyRectangleToAll={copyRectangleToAll}
                  removeOtodom={rectangles.removeOtodom}
                  removeSprzedajemy={rectangles.removeSprzedajemy}
                  removeOkolica={rectangles.removeOkolica}
                />
              ))}
              {photo.canMoveLeft && (
                <button
                  onClick={() => {
                    photo.moveLeft();
                    setIsImageLoading(true);
                  }}
                  type="button"
                  className="absolute left-3 top-1/2 -translate-y-1/2 transform rounded-full bg-white"
                >
                  <ArrowLeftCircleIcon className="h-10 w-10 text-indigo-600" />
                </button>
              )}
              {photo.canMoveRight && (
                <button
                  onClick={() => {
                    photo.moveRight();
                    setIsImageLoading(true);
                  }}
                  type="button"
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform rounded-full bg-white"
                >
                  <ArrowRightCircleIcon className="h-10 w-10 text-indigo-600" />
                </button>
              )}
            </>
          )}
        </div>
      )}
      <div className="mb-2 flex w-full justify-between">
        <div className="flex gap-2">
          <Button
            onClick={rectangles.addOtodom}
            className="px-4 py-2"
            variant="secondary"
          >
            {t(
              'client-searches:clientSearchDetails.watermarkRemoval.selectOtodom',
            )}
          </Button>
          <Button
            onClick={rectangles.addSprzedajemy}
            className="px-4 py-2"
            variant="secondary"
          >
            {t(
              'client-searches:clientSearchDetails.watermarkRemoval.selectSprzedajemy',
            )}
          </Button>
          <Button
            onClick={rectangles.addOkolica}
            className="px-4 py-2"
            variant="secondary"
          >
            {t(
              'client-searches:clientSearchDetails.watermarkRemoval.selectOkolica',
            )}
          </Button>
        </div>
        <div></div>
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-2">
        <div>
          <ToggleInputLabeled
            value={isFullScreen}
            onChange={setFullScreen}
            id="fullScreenSelect"
            label={t(
              'client-searches:clientSearchDetails.watermarkRemoval.fullScreen',
            )}
          />
        </div>
        <div className="flex flex-wrap justify-center gap-2">
          <Button
            onClick={rectangles.clear}
            className="px-4 py-2"
            variant="secondary"
          >
            {t(
              'client-searches:clientSearchDetails.watermarkRemoval.deselectAll',
            )}
          </Button>
          <Button
            data-for={quotaTooltipId}
            data-tip={t(
              'client-searches:clientSearchDetails.watermarkRemoval.quotaWarning',
              {
                willUse: rectangles.photosWithRectangleCount,
                limit: quotaInfo?.limit ?? 200,
                left: quotaInfo?.used
                  ? (quotaInfo?.limit ?? 200) - quotaInfo.used
                  : '??',
              },
            )}
            onClick={submit}
            isLoading={status === Status.SUBMITTING}
            disabled={status !== Status.RECTANGLES_SELECTION}
            className="px-4 py-2"
            variant="primary"
          >
            {t('client-searches:clientSearchDetails.watermarkRemoval.submit')}
          </Button>
          <ReactTooltip
            type={
              rectangles.photosWithRectangleCount >= 10 ? 'error' : undefined
            }
            className="bg-red-800"
            id={quotaTooltipId}
            multiline
          />
        </div>
      </div>
    </Modal>
  );
};

export default WatermarkRemoverModal;
