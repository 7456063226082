import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {
  Control,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form/dist/types/form';
import { useFormState } from 'react-hook-form';
import React from 'react';
import { deepCopy, deepEqual } from '../../utils/basic';

export const countFilters = (value: object): number => {
  return (
    reduce(value, (acc, el) => acc + Number(isSet(el)), 0) -
    2 /* don't count sort and offersTypes */
  );

  function isSet(value: unknown): boolean {
    if (isArray(value)) {
      return value.length > 0;
    } else if (isObject(value)) {
      return reduce(value, (acc, el) => acc || isSet(el), false as boolean);
    } else {
      return !!value;
    }
  }
};

export const useRevalidation = <F, C>({
  control,
  watch,
  trigger,
  validateBeforeSubmit = false,
}: {
  control: Control<F, C>;
  watch: UseFormWatch<F>;
  trigger: UseFormTrigger<F>;
  validateBeforeSubmit?: boolean;
}) => {
  const { isSubmitted } = useFormState({ control });
  const [previousAllValues, setPreviousAllValues] = React.useState<any>();
  const allValues = watch();
  React.useEffect(() => {
    if (!deepEqual(previousAllValues, allValues)) {
      if (validateBeforeSubmit || isSubmitted) {
        trigger();
      }
      setPreviousAllValues(deepCopy(allValues));
    }
  }, [allValues]);
};
