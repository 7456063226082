import React from 'react';
import Modal from '../modal/modal';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import ContactNumberPhoneCall from './contact-number-phone-call';
import ContactNumberSms from './contact-number-sms';
import { classNames } from '../../style/class-names';
import {
  ArrowTopRightOnSquareIcon,
  ClipboardDocumentIcon,
  EnvelopeIcon,
  PhoneArrowUpRightIcon,
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import PhoneInput from '../inputs/phone-input/phone-input';
import { formatPhone } from '../../constants/phone-formatter';
import CopyToClipboardAlerted from '../copy-to-clipboard/copy-to-clipboard-alerted';

export enum ContactNumberModalTabs {
  CONTACT = 'contact',
  SMS = 'sms',
}

const tabs = [
  {
    name: 'common:contact.menu.call',
    icon: PhoneArrowUpRightIcon,
    id: ContactNumberModalTabs.CONTACT,
  },
  {
    name: 'common:contact.menu.sms',
    icon: EnvelopeIcon,
    id: ContactNumberModalTabs.SMS,
  },
];
interface Props {
  phoneNumber: string | null;
  isOpen: boolean;
  close: () => void;
  offerId: string;
  offerOriginalUrl?: string;
  defaultTab?: ContactNumberModalTabs | null;
}

const ContactNumberButtonModal = ({
  phoneNumber: phoneNumberProp,
  offerOriginalUrl,
  defaultTab = ContactNumberModalTabs.CONTACT,
  offerId,
  isOpen,
  close,
}: Props) => {
  const analytics = useAnalytics();
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(
    defaultTab ?? ContactNumberModalTabs.CONTACT,
  );
  const [phoneNumberInput, setPhoneNumberInput] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState(phoneNumberProp ?? '');

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    analytics.track(ANALYTICS_EVENTS.PHONE_CALL_SUMMARY_MODAL_OPEN, {
      phoneNumber,
      offerId,
    });
    if (defaultTab) {
      setSelected(defaultTab);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} close={close}>
      <div className="md:w-[32rem]">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex flex-row space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setSelected(tab.id)}
                className={classNames(
                  tab.id === selected
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 px-2 py-4 text-sm font-medium',
                )}
              >
                <tab.icon
                  className={classNames(
                    tab.id === selected
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  )}
                  aria-hidden="true"
                />
                <span>{t(tab.name)}</span>
              </button>
            ))}
          </nav>
        </div>
        <div className="mt-1 w-full justify-between">
          <div className="mt-8 flex flex-row flex-wrap justify-between gap-y-1">
            {!phoneNumberProp && (
              <div className="mx-auto md:mx-0">
                <PhoneInput
                  value={phoneNumberInput}
                  onChange={(val) => {
                    setPhoneNumberInput(val);
                    setPhoneNumber(`+48${val.replaceAll(' ', '')}`);
                  }}
                />
              </div>
            )}
            {phoneNumberProp && (
              <div className="flex items-center gap-x-2">
                <a
                  className="flex items-center justify-center rounded-md border bg-indigo-600 px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  href={`tel:${phoneNumber}`}
                >
                  {phoneNumberProp && formatPhone(phoneNumber)}
                </a>
              </div>
            )}
            {phoneNumberProp && (
              <div>
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <CopyToClipboardAlerted text={phoneNumber ?? ''}>
                    <button
                      type="button"
                      className="relative inline-flex items-center justify-items-stretch gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    >
                      <ClipboardDocumentIcon
                        className="-ml-0.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {t('common:contact.copyNumber')}
                    </button>
                  </CopyToClipboardAlerted>
                </span>
              </div>
            )}
            {!phoneNumberProp && (
              <a
                className="mx-auto md:mx-0"
                href={offerOriginalUrl}
                rel="noreferrer"
                target="_blank"
              >
                <button className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                  <ArrowTopRightOnSquareIcon
                    className="-ml-0.5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>{t('common:offerMenu.openOriginal')}</span>
                </button>
              </a>
            )}
          </div>
          {selected === 'contact' ? (
            <ContactNumberPhoneCall
              offerId={offerId}
              isOpen={isOpen}
              close={close}
              phoneNumber={phoneNumber}
              offerOriginalUrl={offerOriginalUrl}
            />
          ) : (
            <ContactNumberSms
              offerId={offerId}
              phoneNumber={phoneNumber}
              close={close}
              offerOriginalUrl={offerOriginalUrl}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ContactNumberButtonModal;
