import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../../context/auth/use-user';

const NotAuthenticatedRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const user = useUser();
  const location = useLocation() as { state: { from: { pathname: string } } };

  const { from } = location.state || { from: { pathname: '/dashboard' } };

  return !user.isAuthenticated ? children : <Navigate to={from} replace />;
};

export default NotAuthenticatedRoute;
