import React from 'react';
import Viewer from 'react-viewer';
import { useSwiper } from 'swiper/react';
import { convertDownloadedImagesToSize } from '../../utils/convert-downloaded-image-to-size';

interface Props {
  images: string[];
  canTransform: boolean;
  open: boolean;
  close: () => void;
}

const OfferImageViewer = ({ images, close, open, canTransform }: Props) => {
  const swiper = useSwiper();

  return (
    <Viewer
      visible={open}
      onClose={close}
      images={(canTransform
        ? convertDownloadedImagesToSize(images, '2xl')
        : images
      ).map((url) => ({
        src: url,
      }))}
      onMaskClick={close}
      activeIndex={swiper.realIndex}
      onChange={(_, index) => swiper.slideTo(index + 1)}
    />
  );
};

export default OfferImageViewer;
