import { axios } from '../axios';
import { ExportFileTypeEnum, OfferTypeEnum } from '../api.types';
import { Filter } from '../../models/filter';
import { Sort } from '../../models/sort';

export interface S3File {
  name: string;
  created_at: string;
}

export interface S3FileListResponse {
  files: S3File[];
}

export const getPieExportedFiles = () => {
  return axios
    .get<S3FileListResponse>('/data-export/files')
    .then((resp) => resp.data);
};

export const downloadPieExportedFile = ({ fileKey }: { fileKey: string }) => {
  axios
    .get<{ file_url: string }>(`/data-export/files/${fileKey}`)
    .then((response) => {
      const a = document.createElement('a');

      a.href = response.data.file_url;

      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

export enum OffersDataExportStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIALIZED = 'INITIALIZED',
}

export interface OffersDataExportCheckResponse {
  offer_data_export_id: string;
  status: OffersDataExportStatusEnum;
}

export const postIntializeOffersExport = (
  offerType: OfferTypeEnum[],
  exportType: ExportFileTypeEnum,
  filters: Filter[] = [],
  sorts: Sort[] = [],
): Promise<void> => {
  return axios
    .post('/data-export/offers/initialize', {
      offer_types: offerType,
      filters: filters,
      sorts: sorts,
      file_type: exportType,
    })
    .then((resp) => resp.data);
};

export const getOffersExportStatus = (
  offersDataExportId: string,
): Promise<OffersDataExportCheckResponse> => {
  return axios
    .get(`/data-export/offers/${offersDataExportId}/status`)
    .then((resp) => resp.data);
};

export const getOffersExportFile = (
  offersDataExportId: string,
): Promise<void> => {
  return axios
    .get<{ file_url: string }>(`/data-export/offers/${offersDataExportId}/file`)
    .then((response) => {
      const a = document.createElement('a');

      a.href = response.data.file_url;

      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

export interface OffersDataExportResponse {
  offer_data_export_id: string;
  status: OffersDataExportStatusEnum;
  created_at: string;
  completed_at: string | null;
  file_name: string;
  total_results: number | null;
}

export const getOffersExports = () => {
  return axios
    .get<OffersDataExportResponse[]>('/data-export/offers')
    .then((resp) => resp.data);
};
