import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Button from '../../components/button/button';
import { useParamsRequired } from '../../hooks/use-params-required/use-params-required';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { postResetPasswordChange } from '../../api/auth/auth';
import { toast } from 'react-hot-toast';
import SuccessToast from '../../components/toasts/success-toast';
import { AxiosError } from 'axios';
import ErrorToast from '../../components/toasts/error-toast';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import PasswordInputLabeledController from '../../components/inputs/password-input/password-input-labeled-controller';
import resetPassword from '../../assets/reset-password-bg.png';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import PageHeader from '../../components/header/page-header';

interface PasswordResetForm {
  password: string;
}

enum ResetPasswordChangeErrors {
  PASSWORD_RESET_EXPIRED_OR_INVALID = 'PASSWORD_RESET_EXPIRED_OR_INVALID',
}

const ForgotPasswordChange = () => {
  const { t } = useTranslation(['auth', 'common']);
  const schema = React.useMemo(() => {
    return yup.object({
      password: yup
        .string()
        .min(6, t('auth:fieldErrors.mustBeAtLeast6CharsLong')),
    });
  }, [t]);
  const { resetPasswordId } = useParamsRequired<{ resetPasswordId: string }>();
  const { control, handleSubmit, reset } = useForm<PasswordResetForm>({
    defaultValues: { password: '' },
    resolver: yupResolver(schema),
  });
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(postResetPasswordChange, {
    onSuccess: async (_, data) => {
      toast.custom((toast) => (
        <SuccessToast
          {...toast}
          title={t('auth:alerts.passwordResetChangeSuccessful.title')}
          body={t('auth:alerts.passwordResetChangeSuccessful.body')}
        />
      ));
      navigate('/login');
      await analytics.track(ANALYTICS_EVENTS.FORGOT_PASSWORD, {
        resetId: data.reset_id,
      });
    },
    onError: (e: AxiosError) => {
      const data = e.response?.data as any;
      const status = e.response?.status;
      if (
        status === 422 ||
        data?.detail ===
          ResetPasswordChangeErrors.PASSWORD_RESET_EXPIRED_OR_INVALID
      ) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.passwordResetExpiredOrInvalid.title')}
            body={t('auth:alerts.passwordResetExpiredOrInvalid.body')}
          />
        ));
      } else {
        defaultErrorToasts(e, t);
      }
      reset();
    },
  });

  const onSubmit = async (form: PasswordResetForm) => {
    mutate({ ...form, reset_id: resetPasswordId });
  };

  return (
    <div className="flex h-full flex-row bg-white">
      <PageHeader title={t('common:pageTitle.resetPassword')} />
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mt-8">
            <div className="mt-6">
              <main>
                <div>
                  <div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                      {t('auth:resetPassword.header')}
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                      {t('auth:resetPassword.instructions')}
                    </p>
                  </div>
                  <form
                    className="mt-6 flex flex-col justify-center"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <PasswordInputLabeledController
                      name="password"
                      control={control}
                      id="password"
                      autoComplete="password"
                      labelClassName="mb-1"
                      label={t('auth:fields.newPassword')}
                    />
                    <p className="ml-auto mt-4 text-sm text-gray-600">
                      {t('auth:resetPassword.continueWithPrevious')}{' '}
                      <Link
                        to="/login"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {t('auth:resetPassword.login')}
                      </Link>
                    </p>
                    <Button
                      type="submit"
                      className="mt-4 px-4 py-2"
                      isLoading={isLoading}
                    >
                      {t('auth:resetPassword.confirm')}
                    </Button>
                  </form>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={resetPassword}
          alt="Reset password"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordChange;
