import React from 'react';
import { PinturaEditorModal } from '@pqina/react-pintura';
import '@pqina/pintura/pintura.css';
import './image-editor-modal.css';
import {
  createDefaultColorOptions,
  createDefaultImageReader,
  createDefaultImageScrambler,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  PinturaDefaultImageWriterResult,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_fill,
  plugin_fill_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  plugin_redact,
  plugin_redact_locale_en_gb,
  plugin_resize,
  plugin_resize_locale_en_gb,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  setPlugins,
} from '@pqina/pintura';
import {
  locale_pl,
  markup_editor_locale_pl,
  plugin_annotate_locale_pl,
  plugin_crop_locale_pl,
  plugin_fill_locale_pl,
  plugin_filter_locale_pl,
  plugin_finetune_locale_pl,
  plugin_redact_locale_pl,
  plugin_resize_locale_pl,
  plugin_sticker_locale_pl,
} from './pintura_locale_pl';
import { useTranslation } from 'react-i18next';
import {
  convertDownloadedImageToSize,
  isDownloadedImage,
} from '../../utils/convert-downloaded-image-to-size';
import { useUser } from '../../context/auth/use-user';

const fileToDataURL = (file: any) => {
  return new Promise((resolve, reject) => {
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

setPlugins(
  plugin_crop,
  plugin_finetune,
  plugin_filter,
  plugin_annotate,
  plugin_redact,
  plugin_resize,
  plugin_fill,
  plugin_sticker,
);

const editorDefaults = {
  utils: [
    'crop',
    'resize',
    'finetune',
    'filter',
    'annotate',
    'redact',
    'fill',
    'sticker',
  ],
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter({
    // reduce image size
    quality: 0.95,
    mimeType: 'image/jpeg',
  }),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_pl,
    ...plugin_crop_locale_pl,
    ...plugin_finetune_locale_pl,
    ...plugin_filter_locale_pl,
    ...plugin_annotate_locale_pl,
    ...plugin_redact_locale_pl,
    ...plugin_resize_locale_pl,
    ...markup_editor_locale_pl,
  },
  imageScrambler: createDefaultImageScrambler(),
  fillOptions: [...Object.values(createDefaultColorOptions())],
};

const locale_full_pl = {
  ...locale_pl,
  ...plugin_crop_locale_pl,
  ...plugin_finetune_locale_pl,
  ...plugin_filter_locale_pl,
  ...plugin_annotate_locale_pl,
  ...plugin_redact_locale_pl,
  ...plugin_resize_locale_pl,
  ...markup_editor_locale_pl,
  ...plugin_sticker_locale_pl,
  ...plugin_fill_locale_pl,
};

const locale_full_en_gb = {
  ...locale_en_gb,
  ...plugin_crop_locale_en_gb,
  ...plugin_finetune_locale_en_gb,
  ...plugin_filter_locale_en_gb,
  ...plugin_annotate_locale_en_gb,
  ...plugin_redact_locale_en_gb,
  ...plugin_resize_locale_en_gb,
  ...markup_editor_locale_en_gb,
  ...plugin_sticker_locale_en_gb,
  ...plugin_fill_locale_en_gb,
};

interface Props {
  dataURL: string;
  setDataURL: (dataURL: string) => void;
  isOpen: boolean;
  close: () => void;
}

const ImageEditorModal = ({ isOpen, close, dataURL, setDataURL }: Props) => {
  const { i18n } = useTranslation();

  const user = useUser();

  const defaults = React.useMemo(() => {
    const locale = i18n.language === 'pl' ? locale_full_pl : locale_full_en_gb;

    const stickers = [];

    if (user.selectedOrganization.logoUrl) {
      stickers.push(
        convertDownloadedImageToSize(user.selectedOrganization.logoUrl, 'sm'),
      );
    }
    if (user.avatarUrl) {
      stickers.push(convertDownloadedImageToSize(user.avatarUrl, 'sm'));
    }

    return {
      ...editorDefaults,
      locale,
      stickers,
    };
  }, [i18n.language]);

  const handleEditorProcess = (imageState: PinturaDefaultImageWriterResult) => {
    fileToDataURL(imageState.dest).then((dataURL) => {
      setDataURL(dataURL as string);
    });
  };

  return isOpen ? (
    <PinturaEditorModal
      {...defaults}
      src={
        isDownloadedImage(dataURL)
          ? convertDownloadedImageToSize(dataURL, '2xl')
          : dataURL
      }
      onHide={close}
      onLoaderror={close}
      onProcess={handleEditorProcess}
    />
  ) : null;
};

export default ImageEditorModal;
