import React, { useState } from 'react';
import { FolderPlusIcon } from '@heroicons/react/24/outline';
import { FolderIcon } from '@heroicons/react/24/solid';
import { OfferCollectionsListForOfferDrawer } from './offer-collection-drawers/offer-collections-list-for-offer-drawer';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

interface Props {
  id: string;
  offerTitle: string;
  count: number;
  className?: string;
}

const OffersCollectionsButton = ({
  id,
  offerTitle,
  count,
  className = '',
}: Props) => {
  const { t } = useTranslation(['offer-collections']);
  const tooltipId = React.useId();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const Icon = count === 0 ? FolderPlusIcon : FolderIcon;

  return (
    <>
      <button
        type="button"
        onClick={() => setIsDrawerOpen(true)}
        data-for={tooltipId}
        data-tip={t('offer-collections:icon.label')}
        className={`-mx-1 flex items-baseline rounded ${className}`}
      >
        <div className="relative px-1.5">
          <Icon className="h-6 w-6 text-gray-400" />
          {count > 0 && (
            <span className="absolute -top-1 right-0 h-4 w-4 rounded-full bg-emerald-100 text-center text-xs text-emerald-600 ring-1 ring-white">
              {count >= 10 ? '9+' : count}
            </span>
          )}
        </div>
      </button>
      <ReactTooltip id={tooltipId} />
      <OfferCollectionsListForOfferDrawer
        offerId={id}
        offerTitle={offerTitle}
        isOpen={isDrawerOpen}
        close={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default OffersCollectionsButton;
