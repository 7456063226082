import React from 'react';
import { useIsElementScrollable } from '../../hooks/use-element-dimentions/use-is-element-scrollable';

interface Props {
  refreshOnChangeOf?: any[];
  children?: React.ReactNode;
  className?: string;
}

const ScrollableWithShadowContainer = ({
  refreshOnChangeOf = [],
  className = '',
  children,
}: Props) => {
  const containerRef = React.useRef(null);
  const shouldCastShadow = useIsElementScrollable(containerRef, [
    ...refreshOnChangeOf,
  ]);

  return (
    <div className="relative overflow-hidden rounded-md">
      <div
        className={`relative overflow-scroll ${className}`}
        ref={containerRef}
      >
        {children}
      </div>
      <div
        className={`relative z-50 h-1 w-full ${
          shouldCastShadow ? 'shadow-[0_35px_60px_15px_rgba(0,0,0,0.3)]' : ''
        }`}
      />
    </div>
  );
};

export default ScrollableWithShadowContainer;
