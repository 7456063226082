import DrawerWithDescription, {
  DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION,
} from '../../drawers/drawer-with-description';
import Button from '../../button/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  OfferCollectionFieldKeys,
  OfferCollectionFieldTypesValidated,
  OfferCollectionFormInputs,
  useEditOfferCollectionFields,
  UseEditOfferCollectionFieldValues,
} from '../../../forms/offer-collections';
import { useMutation } from '@tanstack/react-query';
import { ANALYTICS_EVENTS } from '../../../constants/analytics';
import { AxiosError } from 'axios';
import { updateOfferCollection } from '../../../api/offer-collections/offer-collections';
import { useAnalytics } from '../../../context/analytics/use-analytics';
import { useToast } from '../../../hooks/use-toast/use-toast';
import { useOfferCollectionInvalidation } from '../../../hooks/use-invalidation/use-offer-collections-invalidation';
import { useUser } from '../../../context/auth/use-user';

interface Props {
  isOpen: boolean;
  close: () => void;
  currentOfferCollectionData: UseEditOfferCollectionFieldValues;
  offerCollectionId: string;
  createdById: string;
}

export const EditOfferCollectionDrawer = ({
  isOpen,
  close,
  currentOfferCollectionData,
  offerCollectionId,
  createdById,
}: Props) => {
  const user = useUser();
  const analytics = useAnalytics();
  const { displayDefaultErrorToasts, displaySuccessToast } = useToast();
  const { t } = useTranslation(['offer-collections']);
  const invalidate = useOfferCollectionInvalidation();

  const { control, formState, getValues, reset, handleSubmit } =
    useEditOfferCollectionFields(currentOfferCollectionData);

  React.useEffect(() => {
    reset({ ...currentOfferCollectionData });
  }, [currentOfferCollectionData]);

  const { mutate: update, isLoading } = useMutation(updateOfferCollection, {
    onSuccess: async () => {
      await analytics.track(ANALYTICS_EVENTS.OFFER_COLLECTION_CREATED, {
        offerCollectionId,
      });
      displaySuccessToast({
        title: t('offer-collections:edit.successToast.title'),
        body: t('offer-collections:edit.successToast.details', {
          name: getValues(OfferCollectionFieldKeys.NAME),
        }),
      });
      close();
      setTimeout(reset, DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION);
      await invalidate({
        subPaths: [['details', offerCollectionId], ['list']],
      });
    },
    onError: (e: AxiosError) => displayDefaultErrorToasts(e),
  });

  const submit = handleSubmit((values) => {
    const validatedValues = values as OfferCollectionFieldTypesValidated;
    update({ id: offerCollectionId, ...validatedValues });
  });

  const canChangeToPrivate = createdById === user.userId;

  return (
    <DrawerWithDescription
      title={t('offer-collections:edit.title')}
      description={t('offer-collections:edit.description')}
      buttons={
        <div className="flex flex-row">
          <Button
            type="button"
            variant="secondary"
            className="ml-auto mt-3 px-3 py-2"
            onClick={close}
            disabled={isLoading}
          >
            {t('offer-collections:edit.cancel')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            form="edit-offer-collection"
            className="ml-3 mt-3 px-3 py-2"
            isLoading={isLoading}
            disabled={!formState.isValid && formState.isSubmitted}
          >
            {t('offer-collections:edit.save')}
          </Button>
        </div>
      }
      close={close}
      isOpen={isOpen}
    >
      <form
        id="edit-offer-collection"
        className="flex h-full flex-col"
        onSubmit={submit}
      >
        <div className="flex flex-1 flex-col justify-between">
          <div className="px-4 sm:px-6">
            <div className="space-y-2 pb-5 pt-6">
              <OfferCollectionFormInputs
                control={control}
                canChangeToPrivate={canChangeToPrivate}
              />
            </div>
          </div>
        </div>
      </form>
    </DrawerWithDescription>
  );
};
