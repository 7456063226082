import { AxiosError } from 'axios';
import { OfferVariantsErrors } from '../../api/offer-variants/offer-variants';
import { useToast } from '../../hooks/use-toast/use-toast';
import { useTranslation } from 'react-i18next';

export const useOfferVariantErrorHandling = () => {
  const { t } = useTranslation(['offers']);
  const { displayDefaultErrorToasts, displayErrorToast } = useToast();
  return (e: AxiosError) => {
    const data = e.response?.data as any;
    if (data?.detail === OfferVariantsErrors.INCORRECT_SCHEMA) {
      displayErrorToast({
        title: t(
          'client-searches:clientSearchDetails.errors.incorrectSchema.title',
        ),
        body: t(
          'client-searches:clientSearchDetails.errors.incorrectSchema.details',
        ),
      });
    } else if (
      data?.detail === OfferVariantsErrors.PHOTO_FORMAT_NOT_SUPPORTED
    ) {
      displayErrorToast({
        title: t(
          'client-searches:clientSearchDetails.errors.photoFormatNotSupported.title',
        ),
        body: t(
          'client-searches:clientSearchDetails.errors.photoFormatNotSupported.details',
        ),
      });
    } else if (
      data?.detail === OfferVariantsErrors.PHOTO_PROCESSING_UNKNOWN_PROBLEM
    ) {
      displayErrorToast({
        title: t(
          'client-searches:clientSearchDetails.errors.photoProcessing.title',
        ),
        body: t(
          'client-searches:clientSearchDetails.errors.photoProcessing.details',
        ),
      });
    } else {
      displayDefaultErrorToasts(e);
    }
  };
};
