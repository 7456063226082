import {
  GetWatermarkRemovalLroResponse,
  GetWatermarkRemovalQuota,
  PostWatermarkRemovalLroRequest,
  PostWatermarkRemovalLroResponse,
} from '../api.types';
import { axios } from '../axios';

export const startWatermarkRemoval = (
  body: PostWatermarkRemovalLroRequest,
): Promise<PostWatermarkRemovalLroResponse> => {
  return axios.post(`/watermark_remover`, body).then((resp) => resp.data);
};

export const getWatermarkRemoval = (
  lroId: string,
): Promise<GetWatermarkRemovalLroResponse> => {
  return axios.get(`/watermark_remover/${lroId}`).then((resp) => resp.data);
};

export const getWatermarkRemovalQuota =
  (): Promise<GetWatermarkRemovalQuota> => {
    return axios.get(`watermark_remover/quota`).then((resp) => resp.data);
  };
