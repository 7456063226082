import React from 'react';
import { AnalyticsInstance } from 'analytics';

export const AnalyticsContext =
  React.createContext<AnalyticsContextProps | null>(null);

export interface AnalyticsContextProps {
  instance: AnalyticsInstance;
  afterTrack: (afterTrackFunc: (e: string) => void) => void;
}

export const useAnalytics = () => {
  const context = React.useContext(AnalyticsContext);
  if (!context) {
    throw new Error(`useAnalytics must be used within a AnalyticsProvider`);
  }
  return context.instance;
};

export const useAnalyticsCallback = () => {
  const context = React.useContext(AnalyticsContext);
  if (!context) {
    throw new Error(`useAnalytics must be used within a AnalyticsProvider`);
  }
  return { afterTrack: context.afterTrack };
};
