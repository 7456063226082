import { OfferTypeEnum } from '../../api/api.types';
import { generateOfferLink } from '../../utils/generate-offer-link';
import { useScroll } from '../../context/scroll/scroll';
import { useNavigate } from 'react-router-dom';

interface Props {
  offerType: OfferTypeEnum;
  offerId: string;
}

export const useOfferNavigate = ({ offerType, offerId }: Props) => {
  const navigate = useNavigate();
  const scroll = useScroll();

  return () => {
    navigate(generateOfferLink({ id: offerId, offerType }), {
      state: {
        backPathname: location.pathname,
        backSearch: location.search,
        scrollY: scroll.getScrollPosition().y,
      },
    });
  };
};
