import React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const OfferCallListCardSkeleton = ({ className, style }: Props) => {
  return (
    <div
      className={`relative text-black ${className} overflow-hidden rounded`}
      style={style}
    >
      <div className="flex flex-row overflow-hidden rounded border">
        <div className="relative rounded rounded-l-xl pb-16 pr-24 ">
          <div className="absolute h-full w-full animate-pulse bg-gray-200 object-cover" />
        </div>
        <div className="mb-0.5 ml-1 grid w-full p-2">
          <div className="mt-1 h-4 w-full animate-pulse rounded bg-gray-200" />
          <div className="mt-2 h-4 w-full animate-pulse rounded bg-gray-200" />
          <div className="mt-2 h-4 w-full animate-pulse rounded bg-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default OfferCallListCardSkeleton;
