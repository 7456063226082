import React from 'react';
import OfferVariantLocation from './offer-variant-location/offer-variant-location';
import OfferVariantDescription from './offer-variant-description/offer-variant-description';
import { OfferVariantFormTabs } from './offer-variant-form-navigation';
import OfferVariantParameters from './offer-variant-parameters/offer-variant-parameters';
import OfferPhotosUpload, {
  ImageTypeWitId,
} from '../offer-photos/offer-photos-upload';
import { OfferTypeEnum } from '../../api/api.types';

interface Props {
  control: any;
  images: ImageTypeWitId[];
  offerType: OfferTypeEnum;
  selected: OfferVariantFormTabs;
  setImages: (images: ImageTypeWitId[]) => void;
  originalImages: { dataURL: string; id: number }[];
  displayMap: boolean;
}

const OfferVariantFormSections = ({
  control,
  images,
  offerType,
  setImages,
  selected,
  originalImages = [],
  displayMap,
}: Props) => {
  if (selected === OfferVariantFormTabs.LOCATION) {
    return <OfferVariantLocation displayMap={displayMap} control={control} />;
  }

  if (selected === OfferVariantFormTabs.DESCRIPTION) {
    return <OfferVariantDescription control={control} offerType={offerType} />;
  }

  if (selected === OfferVariantFormTabs.IMAGES) {
    return (
      <OfferPhotosUpload
        images={images}
        setImages={(images) => setImages(images)}
        originalImages={originalImages}
      />
    );
  }

  if (selected === OfferVariantFormTabs.PARAMETERS) {
    return <OfferVariantParameters offerType={offerType} control={control} />;
  }
  return <div />;
};

export default OfferVariantFormSections;
