import { useScroll } from '../../context/scroll/scroll';
import { NavLink, useNavigate } from 'react-router-dom';
import { generateClientOfferSearchLink } from '../../models/client-offer-search';
import { generateOfferCollectionLink } from '../../models/offer-collections';

interface Props {
  id: string;
}

export const useOfferCollectionNavigate = ({ id }: Props) => {
  const navigate = useNavigate();
  const scroll = useScroll();

  const navigateToClientOfferSearch = () =>
    navigate(generateOfferCollectionLink({ id }), {
      state: {
        backPathname: location.pathname,
        backSearch: location.search,
        scrollY: scroll.getScrollPosition().y,
      },
    });

  return {
    navLinkProps: {
      to: generateClientOfferSearchLink({ id }),
      onClick: (event) => {
        event.preventDefault();
        navigateToClientOfferSearch();
      },
    } as Parameters<typeof NavLink>[0],
    navigate: () => {
      navigateToClientOfferSearch();
    },
  };
};
