import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import DateTimeInputLabeled, {
  DateTimeInputLabeledProps,
} from './date-time-input-labeled';

interface Props<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<DateTimeInputLabeledProps, 'value' | 'onChange' | 'id'> {}

const DateInputLabeledController = <F extends FieldValues>({
  name,
  control,
  ...rest
}: Props<F>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DateTimeInputLabeled
          id={name}
          onChange={onChange}
          value={value}
          isError={!!error}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default DateInputLabeledController;
