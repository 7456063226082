import * as Yup from 'yup';

type IsEnum<T> = T extends object
  ? keyof T extends never
    ? false
    : true
  : false;

type EnumType<E> = IsEnum<E> extends true ? E : never;

interface OptionalProps<E> {
  enumObject: EnumType<E>;
  typeError: string;
}

export const getNullableEnumField = <T>({
  enumObject,
  typeError,
}: OptionalProps<T>) => {
  return Yup.mixed()
    .transform((v) => (v === '' ? null : v))
    .test({
      name: 'type check',
      message: typeError,
      test: (v) => [...Object.values(enumObject), null].includes(v),
    })
    .nullable();
};
