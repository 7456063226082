import React from 'react';
import TextAreaInput, {
  TextAreaInputProps,
} from '../text-area-input/text-area-input';
import { useTranslation } from 'react-i18next';
import { calculateSmsMessageProperties } from '../../../utils/sms';

export interface SmsMessageInputProps extends TextAreaInputProps {}

const SmsMessageInput = ({
  value,
  errorMessage,
  isError,
  ...rest
}: SmsMessageInputProps) => {
  const { t } = useTranslation(['common']);
  const message = calculateSmsMessageProperties(value);

  return (
    <>
      <TextAreaInput {...rest} value={value} />
      <div className="mt-1 flex flex-row text-xs">
        <span className="text-red-600">
          {message.hasSpecialChars
            ? t('common:smsInput.hasSpecialCharacters')
            : ''}
        </span>
        <div className="ml-auto">
          {message.parts} SMS, {message.charsTillEndOfPart}{' '}
          {t('common:smsInput.charsTillEndOfPart')}
        </div>
      </div>
      {isError && (
        <p className="-mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default SmsMessageInput;
