import React from 'react';
import { OfferAssignmentResponse } from '../../api/offer-assignments/offer-assignments';
import TeamBadge from './team-badge';
import { useUser } from '../../context/auth/use-user';
import { useTranslation } from 'react-i18next';

interface Props {
  assignment?: OfferAssignmentResponse;
  textClassName?: string;
  className?: string;
  showPrefix?: boolean;
}

const TeamAssignmentBadge = ({
  assignment,
  textClassName = '',
  className = '',
  showPrefix = true,
}: Props) => {
  const user = useUser();
  const { t } = useTranslation(['common']);
  if (assignment?.assigned_to_team) {
    return (
      <TeamBadge
        textClassName={textClassName}
        className={className}
        name={`${showPrefix ? t('common:teamBadge.team') : ''} ${
          assignment?.assigned_to_team.name
        }`}
        teamId={assignment?.assigned_to_team?.team_id}
      />
    );
  }
  return (
    <TeamBadge
      textClassName={textClassName}
      className={className}
      name={`${showPrefix ? t('common:teamBadge.org') : ''} ${
        user.selectedOrganization.name
      }`}
    />
  );
};

export default TeamAssignmentBadge;
