import { useQueryClient } from '@tanstack/react-query';

export const useOfferCollectionInvalidation = ({
  removeData = false,
}: { removeData?: boolean } = {}) => {
  const queryClient = useQueryClient();
  return async ({ subPaths }: { subPaths?: string[][] } = {}) => {
    if (subPaths && subPaths.length > 0) {
      for (const subPath of subPaths) {
        await queryClient.invalidateQueries(['offerCollections', ...subPath]);
        if (removeData) {
          await queryClient.removeQueries(['offerCollections', ...subPath]);
        }
      }
    } else {
      await queryClient.invalidateQueries(['offerCollections']);
      if (removeData) {
        await queryClient.removeQueries(['offerCollections']);
      }
    }
  };
};
