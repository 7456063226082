import { isSortArray } from '../../models/sort';
import { useSearchParams } from '../use-search/use-search-params';
import { OFFERS_TYPES } from '../../constants/offers';
import { isFilterArray } from '../../models/filter';
import { isOfferArray } from '../../models/offer';
import { AppError } from '../../models/errors';
import { MapBounds } from './use-search-map-offers-query';

const DEFAULT_BOUNDS = {
  latMin: 40,
  latMax: 60,
  lonMin: 15,
  lonMax: 25,
};

export enum ViewType {
  LIST = 'list',
  MAP = 'map',
}

export const useOfferListSearchParams = () => {
  const {
    page = 1,
    filters = '[]',
    viewId,
    offersTypes = '[]',
    sorts = '[]',
    latMin,
    latMax,
    lonMin,
    lonMax,
    viewType = ViewType.LIST,
    reload = false,
  } = useSearchParams<{
    page: number;
    filters: string;
    sorts: string;
    viewId?: string;
    offersTypes: string;
    latMin?: number;
    latMax?: number;
    lonMin?: number;
    lonMax?: number;
    viewType?: ViewType;
    reload?: boolean;
  }>();

  const parsedFilters = JSON.parse(filters);
  const offersTypesParsed =
    offersTypes === '[]' ? [...OFFERS_TYPES] : JSON.parse(offersTypes);
  const parsedSorts = JSON.parse(sorts);
  const mapBounds =
    latMin && latMax && lonMin && lonMax
      ? {
          latMin,
          latMax,
          lonMin,
          lonMax,
        }
      : (DEFAULT_BOUNDS as MapBounds);

  if (
    !isFilterArray(parsedFilters) ||
    !isOfferArray(offersTypesParsed) ||
    !isSortArray(parsedSorts)
  ) {
    throw AppError.invalidOffersListLink(
      'Cannot parse offers types, filters, or sorts',
    );
  }

  return {
    page,
    viewId,
    filters: parsedFilters,
    offersTypes: offersTypesParsed,
    sorts: parsedSorts,
    mapBounds,
    viewType,
    reload,
  };
};
