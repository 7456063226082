import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { postRegister } from '../../api/auth/auth';
import TextInputLabeledController from '../../components/inputs/text-input/text-input-labeled-controller';
import { RegisterUserRequest } from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import SuccessToast from '../../components/toasts/success-toast';
import PasswordInputLabeledController from '../../components/inputs/password-input/password-input-labeled-controller';
import logo from '../../assets/logo-transparent.png';
import bg from '../../assets/sign-up-bg.jpg';
import CheckboxInputLabeledController from '../../components/inputs/checkbox-input/checkbox-input-labeled-controller';
import { TERMS_URL } from '../../constants/external';
import TextInputController from '../../components/inputs/text-input/text-input-controller';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS, SignUpMethods } from '../../constants/analytics';
import PageHeader from '../../components/header/page-header';
import { isEmail, isPhone } from '../../constants/validation';

export interface RegisterUserForm extends Omit<RegisterUserRequest, 'scope'> {
  remember: boolean;
}

export enum RegisterErrors {
  REGISTER_USER_ALREADY_EXISTS = 'REGISTER_USER_ALREADY_EXISTS',
}

const SignUp = () => {
  const { t } = useTranslation(['auth', 'common']);
  const location = useLocation() as {
    state: { from: { pathname: string } };
  };
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const schema = React.useMemo(() => {
    const result = {
      email: isEmail(t, { required: true }),
      password: yup
        .string()
        .min(6, t('auth:fieldErrors.mustBeAtLeast6CharsLong')),
      first_name: yup.string().required(t('auth:fieldErrors.cannotBeEmpty')),
      last_name: yup.string().required(t('auth:fieldErrors.cannotBeEmpty')),
      accept: yup
        .boolean()
        .isTrue(t('auth:fieldErrors.mustBeAccepted'))
        .required(t('auth:fieldErrors.mustBeAccepted')),
      phone_number: isPhone(t, { required: true }),
      referral_code: yup.string(),
      organization_name: yup
        .string()
        .required(t('auth:fieldErrors.cannotBeEmpty')),
      nip: yup
        .string()
        .matches(/^[0-9]+$/, t('auth:fieldErrors.mustBeDigits'))
        .required(t('auth:fieldErrors.cannotBeEmpty'))
        .length(10, t('auth:fieldErrors.mustBe10CharsLong')),
    } as any;

    return yup.object(result);
  }, [t]);

  const { control, handleSubmit } = useForm<
    RegisterUserForm & { accept: boolean }
  >({
    defaultValues: {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      organization_name: '',
      accept: false,
      referral_code: '',
      nip: '',
    },
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(postRegister, {
    onSuccess: async (_, data) => {
      toast.custom((toast) => (
        <SuccessToast
          {...toast}
          title={t('auth:alerts.signUpSuccessful.title')}
          body={t('auth:alerts.signUpSuccessful.body')}
        />
      ));
      await analytics.track(ANALYTICS_EVENTS.USER_REGISTERED, {
        email: data.email,
        method: SignUpMethods.EMAIL,
        firstName: data.first_name,
        lastName: data.last_name,
        phoneNumber: data.phone_number,
        organizationName: data.organization_name,
        nip: data.nip,
      });
      navigate('/account-created');
    },
    onError: (e: AxiosError) => {
      const data = e.response?.data as any;

      if (data.detail === RegisterErrors.REGISTER_USER_ALREADY_EXISTS) {
        toast.custom((toast) => (
          <ErrorToast
            {...toast}
            title={t('auth:alerts.registerUserAlreadyExists.title')}
            body={t('auth:alerts.registerUserAlreadyExists.body')}
          />
        ));
        return;
      }

      defaultErrorToasts(e, t);
    },
  });

  const onSubmit = (form: RegisterUserForm & { accept: boolean }) => {
    const { accept, ...rest } = form;
    mutate({
      ...rest,
      phone_number: (form.phone_number
        ? `+48${form.phone_number}`
        : null) as string,
      referral_code: form.referral_code === '' ? null : form.referral_code,
    });
  };

  return (
    <div className="flex h-full flex-row bg-white">
      <PageHeader title={t('common:pageTitle.signUp')} />
      <div className="flex flex-1 overflow-auto">
        <div className="mx-4 my-6 grid sm:mx-6 sm:my-12 lg:mx-20 lg:flex-none xl:mx-24">
          <div className="relative mx-auto h-full w-full max-w-sm lg:w-96">
            <div className="flex h-full flex-col justify-center py-16">
              <div className="flex flex-col items-start">
                <img src={logo} alt="Logo" className="-ml-4 h-16" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  {t('signUp.header')}
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  {t('signUp.alreadyHave')}{' '}
                  <Link
                    to={'/login'}
                    state={location.state}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {t('signUp.logIn')}
                  </Link>
                </p>
              </div>
              <div className="mt-8">
                <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                  <TextInputLabeledController
                    label={t('fields.organization')}
                    name="organization_name"
                    control={control}
                    labelClassName="mb-1"
                    id="organization-name"
                  />
                  <TextInputLabeledController
                    label={t('fields.nip')}
                    name="nip"
                    control={control}
                    id="nip"
                    labelClassName="mb-1"
                  />
                  <TextInputLabeledController
                    name="email"
                    control={control}
                    id="email"
                    autoComplete="email"
                    labelClassName="mb-1"
                    label={t('fields.email')}
                  />
                  <div className="flex">
                    <TextInputLabeledController
                      name="first_name"
                      control={control}
                      id="first-name"
                      autoComplete="first-name"
                      labelClassName="mb-1"
                      className="mr-4"
                      label={t('fields.firstName')}
                    />
                    <TextInputLabeledController
                      name="last_name"
                      control={control}
                      id="last-name"
                      autoComplete="last-name"
                      labelClassName="mb-1"
                      label={t('fields.lastName')}
                    />
                  </div>
                  <div>
                    <div className="mb-1 flex justify-between">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('fields.phoneNumber')}
                      </label>
                    </div>
                    <TextInputController
                      name="phone_number"
                      control={control}
                      id="phone-number"
                      prefixIcon={<div className="sm:text-sm">+48</div>}
                      autoComplete="phone"
                    />
                  </div>
                  <PasswordInputLabeledController
                    name="password"
                    control={control}
                    id="password"
                    autoComplete="password"
                    labelClassName="mb-1"
                    label={t('fields.password')}
                  />
                  <div>
                    <div className="mb-1 flex justify-between">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('fields.referralCode')}
                      </label>
                      <span className="text-sm text-gray-500">
                        {t('fields.optional')}
                      </span>
                    </div>
                    <TextInputController
                      name="referral_code"
                      control={control}
                      id="referral-code"
                    />
                  </div>
                  <div className="flex flex-row">
                    <CheckboxInputLabeledController
                      name="accept"
                      control={control}
                      id="accept"
                      changeOnLabelClick={false}
                      label={
                        (
                          <div>
                            {t('fields.accept')}{' '}
                            <a
                              href={TERMS_URL}
                              target="_blank"
                              rel="noreferrer"
                              className="text-indigo-600 hover:text-indigo-500"
                            >
                              {t('fields.terms')}
                            </a>
                          </div>
                        ) as any
                      }
                    />
                  </div>
                  <div>
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      className="flex w-full justify-center px-4 py-2"
                    >
                      {t('signUp.signUp')}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default SignUp;
