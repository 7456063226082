import React from 'react';
import { useUser } from '../../../context/auth/use-user';
import { Navigate, useLocation } from 'react-router-dom';
import { OrganizationType } from '../../../api/api.types';
import AuthenticatedRoute from '../authenticated-route/authenticated-route';

const OrganizationB2bRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const location = useLocation();
  const user = useUser();

  return (
    <AuthenticatedRoute>
      {user.selectedOrganization.type === OrganizationType.B2B ? (
        children
      ) : (
        <Navigate to={'/dashboard'} replace state={{ from: location }} />
      )}
    </AuthenticatedRoute>
  );
};

export default OrganizationB2bRoute;
