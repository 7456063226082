export function calculatePropertyIndexValue(
  estimatedPrice: number,
  realPrice: number,
) {
  return ((realPrice - estimatedPrice) / estimatedPrice) * 100;
}

export function shouldHideIndex(calculatedIndex: number) {
  return Math.abs(calculatedIndex) > 50;
}
