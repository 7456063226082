interface Props {
  mainColor?: string;
  className?: string;
}

const HouseSelectedSvg = ({ mainColor = '#4f46e5', className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 616.25569 454.00271"
      className={className}
    >
      <g>
        <circle cx="300.1558" cy="26.06748" r="26.06619" fill="#ff6884" />
        <path
          d="M274.41395,191.36463l-29.45507,.40631-11.80898-131.67427c-.28653-3.19599,.74576-6.42745,2.83253-8.86538l28.9635-33.83732c4.05449-4.73632,10.94823-5.59088,16.03608-1.98596l36.27621,25.7023c2.95947,2.09676,4.79342,5.37508,5.03134,8.9943l6.52144,99.20877,16.40704,19.42083,.0348,.04107,4.19738,21.5542-75.03627,1.03515Z"
          fill="#e6e6e6"
        />
        <path
          d="M268.24421,48.01041l-.4743-6.11149c-.12519-1.61317,1.0854-3.02749,2.69858-3.15268l8.58675-.6664c1.61317-.12519,3.02728,1.08542,3.15248,2.6986l.4743,6.11149c.12519,1.61317-1.0852,3.02747-2.69838,3.15267l-8.58675,.6664c-1.61317,.12519-3.02749-1.0854-3.15268-2.69858Z"
          fill={mainColor}
        />
        <path
          d="M253.77542,96.85751l-1.75485-22.61184c-.12519-1.61317,1.0854-3.02749,2.69858-3.15268l12.71183-.98653c1.61317-.12519,3.02728,1.08542,3.15248,2.69859l1.75485,22.61184c.12519,1.61317-1.0852,3.02747-2.69838,3.15266l-12.71183,.98653c-1.61317,.12519-3.02749-1.0854-3.15268-2.69858Z"
          fill="#3f3d56"
        />
        <path
          d="M286.61712,147.55695c-1.54065,.06486-2.87635-1.08579-3.04114-2.61909l-2.33887-21.80543c-.0998-.93085,.23677-1.82911,.92334-2.46557,.6866-.63606,1.6084-.90213,2.52893-.73284l.65605,.12173c1.3051,.24198,2.28839,1.33486,2.39109,2.65819l1.68285,21.6841c.06215,.80085-.19726,1.57315-.73035,2.1742-.50942,.57449-1.20385,.91896-1.96522,.97804-.03545,.00275-.07093,.0051-.10668,.00666Z"
          fill="#3f3d56"
        />
        <path
          d="M259.71164,146.6112l-1.75995-22.6775c-.12424-1.60089,1.0299-2.98285,2.62732-3.14654l11.53181-1.17824c1.59977-.14644,3.04482,1.00584,3.21633,2.60752l2.44729,22.90744c.08483,.79544-.14991,1.57232-.66144,2.18749-.51112,.61515-1.23193,.988-2.02935,1.04989l-12.21936,.94831c-1.61317,.12519-3.02749-1.0854-3.15266-2.69838Z"
          fill="#3f3d56"
        />
        <path
          d="M329.20413,156.8074l10.30497,15.39602,2.44462,18.02434,7.24618-.10172-4.13977-21.25837-16.451-19.47294h0c-1.10264,2.40651-.87738,5.21285,.59501,7.41267Z"
          fill="#ccc"
        />
        <rect
          x="311.86013"
          y="186.40891"
          width="29.48179"
          height=".41379"
          transform="translate(-15.6265 30.49403) rotate(-5.22098)"
          fill="#3f3d56"
        />
        <rect
          x="311.31336"
          y="182.75555"
          width="27.95155"
          height=".41376"
          transform="translate(-14.3996 28.36671) rotate(-4.8853)"
          fill="#3f3d56"
        />
        <rect
          x="310.80303"
          y="179.25177"
          width="26.74136"
          height=".41382"
          transform="translate(-15.85355 32.23274) rotate(-5.55661)"
          fill="#3f3d56"
        />
        <rect
          x="310.75863"
          y="175.73958"
          width="25.68962"
          height=".41398"
          transform="translate(-17.44885 36.80738) rotate(-6.33939)"
          fill="#3f3d56"
        />
        <rect
          x="310.27105"
          y="172.50962"
          width="24.68386"
          height=".414"
          transform="translate(-17.36302 37.34127) rotate(-6.45117)"
          fill="#3f3d56"
        />
        <rect
          x="309.01036"
          y="169.28135"
          width="24.02694"
          height=".41375"
          transform="translate(-12.15172 25.34729) rotate(-4.43769)"
          fill="#3f3d56"
        />
        <rect
          x="308.2334"
          y="166.59297"
          width="23.352"
          height=".41377"
          transform="translate(-13.58289 29.15137) rotate(-5.10909)"
          fill="#3f3d56"
        />
        <rect
          x="307.50808"
          y="164.01383"
          width="22.63865"
          height=".41398"
          transform="translate(-16.18333 36.20832) rotate(-6.33939)"
          fill="#3f3d56"
        />
        <rect
          x="306.73407"
          y="161.01582"
          width="22.34629"
          height=".41381"
          transform="translate(-13.86338 30.89224) rotate(-5.44474)"
          fill="#3f3d56"
        />
        <rect
          x="305.81712"
          y="158.38753"
          width="21.40379"
          height=".41395"
          transform="translate(-15.33621 35.27131) rotate(-6.2276)"
          fill="#3f3d56"
        />
        <rect
          x="304.97233"
          y="155.69818"
          width="21.20565"
          height=".41377"
          transform="translate(-12.38091 28.08142) rotate(-4.9972)"
          fill="#3f3d56"
        />
        <path
          d="M302.77445,148.04163l-2.17103-27.97445c-.12521-1.61338,1.08539-3.02769,2.69856-3.15288l13.53685-1.05056c1.61317-.12519,3.02728,1.08542,3.15249,2.6988l2.17103,27.97445c.12518,1.61297-1.08522,3.02727-2.69839,3.15246l-13.53685,1.05056c-1.61317,.12519-3.02749-1.0854-3.15266-2.69838Z"
          fill={mainColor}
        />
        <path
          d="M274.41395,191.36463l-24.08122,.33333-.19413,.0025-1.43924-18.54517c-.18202-2.34534,1.57794-4.40167,3.92328-4.58369l23.66889-1.83688c2.34534-.18202,4.40149,1.57816,4.5835,3.9235l1.5587,20.08442-.20625,.01601-7.81353,.60598Z"
          fill="#ccc"
        />
        <path
          d="M300.48679,97.175l-1.65882-21.37452c-.12519-1.61317,1.0854-3.02749,2.69858-3.15268l4.46166-.34626c1.61317-.12519,3.02728,1.08542,3.15248,2.69859l1.65882,21.37452c.12518,1.61297-1.08522,3.02727-2.69839,3.15246l-4.46166,.34626c-1.61317,.12519-3.02749-1.0854-3.15266-2.69838Z"
          fill="#3f3d56"
        />
        <path
          d="M312.29453,190.50021l-2.64613-18.05434-5.05716-17.03724-1.39943-4.66382h0c-2.23103-1.27658-4.96037,.54787-4.63376,3.09747l.25807,2.01454,5.05716,17.03724,2.64613,18.05434"
          fill="#ccc"
        />
        <path
          d="M171.18668,192.73595l-29.45447,.40626-11.80918-131.67425c-.28653-3.19599,.74576-6.42745,2.83253-8.86538l28.9637-33.83734c4.05425-4.73691,10.94804-5.59067,16.03588-1.98594l36.2762,25.7023c2.95947,2.09676,4.79342,5.37508,5.03134,8.99429l6.52144,99.20878,16.40704,19.42083,.0348,.04107,4.19738,21.5542-75.03667,1.03518h.00001Z"
          fill="#e6e6e6"
        />
        <path
          d="M165.01732,49.3817l-.4743-6.11149c-.12519-1.61317,1.0854-3.02749,2.69878-3.1527l8.58655-.66638c1.61317-.12519,3.02728,1.08542,3.15248,2.6986l.4743,6.11149c.12519,1.61317-1.0852,3.02747-2.69838,3.15267l-8.58655,.66638c-1.61338,.12521-3.02769-1.08539-3.15288-2.69857Z"
          fill={mainColor}
        />
        <path
          d="M150.54852,98.2288l-1.75485-22.61184c-.12519-1.61317,1.0854-3.02749,2.69878-3.1527l12.71163-.98652c1.61297-.12518,3.02728,1.08542,3.15248,2.69859l1.75485,22.61184c.12519,1.61317-1.0854,3.02749-2.69838,3.15266l-12.71163,.98652c-1.61338,.12521-3.02769-1.08539-3.15288-2.69856Z"
          fill="#3f3d56"
        />
        <path
          d="M179.21489,122.34175c-.58982,.54628-.87883,1.31845-.79288,2.11786l2.33867,21.80544c.14153,1.31708,1.28893,2.30523,2.61226,2.24954,.69585-.02604,1.32194-.32954,1.77955-.8457,.45801-.51619,.6809-1.17948,.6275-1.86754l-1.68285-21.6841c-.08823-1.13682-.9327-2.07553-2.05398-2.28314l-.65625-.12171c-.22204-.04113-.44421-.05266-.66214-.03575-.55753,.04327-1.08565,.2723-1.50988,.6651Z"
          fill="#3f3d56"
        />
        <path
          d="M144.34845,151.13427c-.61924-.51162-.9947-1.23465-1.05685-2.0355l-1.68285-21.6841c-.1027-1.32333,.7002-2.55484,1.95234-2.99527l.62962-.22151c.88218-.30919,1.835-.18861,2.61152,.3339,.77695,.52288,1.24791,1.35887,1.29289,2.29357l1.0532,21.9052c.07415,1.54034-1.06782,2.88326-2.60044,3.05692-.03536,.00396-.07118,.00714-.10663,.0099-.76097,.05906-1.50047-.17453-2.09279-.66311Z"
          fill="#3f3d56"
        />
        <path
          d="M225.97724,158.17869l10.30497,15.39602,2.44462,18.02434,7.24618-.10172-4.13977-21.25837-16.451-19.47294h0c-1.10264,2.40651-.87738,5.21285,.59501,7.41267Z"
          fill="#ccc"
        />
        <rect
          x="208.63325"
          y="187.7802"
          width="29.48179"
          height=".41379"
          transform="translate(-16.17956 21.10636) rotate(-5.22098)"
          fill="#3f3d56"
        />
        <rect
          x="208.08647"
          y="184.12684"
          width="27.95155"
          height=".41376"
          transform="translate(-14.89138 19.58075) rotate(-4.8853)"
          fill="#3f3d56"
        />
        <rect
          x="207.57614"
          y="180.62307"
          width="26.74136"
          height=".41382"
          transform="translate(-16.47139 22.24381) rotate(-5.55661)"
          fill="#3f3d56"
        />
        <rect
          x="207.53174"
          y="177.11087"
          width="25.68962"
          height=".41398"
          transform="translate(-18.23147 25.4177) rotate(-6.33939)"
          fill="#3f3d56"
        />
        <rect
          x="207.04417"
          y="173.88091"
          width="24.68386"
          height=".414"
          transform="translate(-18.17073 25.75176) rotate(-6.45117)"
          fill="#3f3d56"
        />
        <rect
          x="205.78347"
          y="170.65264"
          width="24.02694"
          height=".41375"
          transform="translate(-12.56729 17.36423) rotate(-4.43769)"
          fill="#3f3d56"
        />
        <rect
          x="205.00651"
          y="167.96426"
          width="23.352"
          height=".41377"
          transform="translate(-14.11514 19.96423) rotate(-5.10909)"
          fill="#3f3d56"
        />
        <rect
          x="204.28119"
          y="165.38512"
          width="22.63865"
          height=".41398"
          transform="translate(-16.96595 24.81864) rotate(-6.33939)"
          fill="#3f3d56"
        />
        <rect
          x="203.50718"
          y="162.38711"
          width="22.34629"
          height=".41381"
          transform="translate(-14.45924 21.10368) rotate(-5.44474)"
          fill="#3f3d56"
        />
        <rect
          x="202.59023"
          y="159.75882"
          width="21.40379"
          height=".41395"
          transform="translate(-16.09413 24.08153) rotate(-6.2276)"
          fill="#3f3d56"
        />
        <rect
          x="201.74545"
          y="157.06947"
          width="21.20565"
          height=".41377"
          transform="translate(-12.89273 19.09484) rotate(-4.9972)"
          fill="#3f3d56"
        />
        <path
          d="M199.54757,149.41292l-2.17103-27.97445c-.12521-1.61338,1.08539-3.02769,2.69856-3.15288l13.53685-1.05056c1.61317-.12519,3.02728,1.08542,3.15249,2.6988l2.17103,27.97445c.12518,1.61297-1.08522,3.02727-2.69839,3.15246l-13.53685,1.05056c-1.61317,.12519-3.02749-1.0854-3.15266-2.69838Z"
          fill={mainColor}
        />
        <path
          d="M171.18668,192.73595l-24.08102,.33331-.19393,.00249-1.43924-18.54517c-.18202-2.34534,1.57814-4.40169,3.92348-4.5837l23.66889-1.83688c2.34534-.18202,4.40129,1.57818,4.5833,3.92351l1.5587,20.08442-.20625,.01601-7.81393,.60601Z"
          fill="#ccc"
        />
        <path
          d="M197.25989,98.54609l-1.65881-21.37432c-.12519-1.61317,1.0854-3.02749,2.69858-3.15268l4.46166-.34626c1.61317-.12519,3.02728,1.08542,3.15248,2.69859l1.65881,21.37432c.12519,1.61317-1.0852,3.02747-2.69838,3.15266l-4.46166,.34626c-1.61317,.12519-3.02749-1.0854-3.15268-2.69858Z"
          fill="#3f3d56"
        />
        <path
          d="M209.06765,191.8715l-2.64613-18.05434-5.05716-17.03724-1.39943-4.66382h0c-2.23103-1.27658-4.96037,.54787-4.63376,3.09747l.25807,2.01454,5.05716,17.03724,2.64613,18.05434"
          fill="#ccc"
        />
        <path
          d="M67.95979,194.10725l-29.45447,.40626L26.69614,62.83925c-.28653-3.19599,.74576-6.42745,2.83253-8.86538L58.49238,20.13653c4.05426-4.73671,10.94846-5.59049,16.03548-1.98591l36.27661,25.70227c2.95947,2.09676,4.79322,5.3751,5.03114,8.99431l6.52164,99.20876,16.40724,19.42081,.0344,.0411,4.19758,21.55419-75.03667,1.03518Z"
          fill="#e6e6e6"
        />
        <path
          d="M61.79043,50.75299l-.4743-6.11149c-.12519-1.61317,1.0854-3.02749,2.69878-3.1527l8.58655-.66638c1.61297-.12518,3.02728,1.08542,3.15248,2.6986l.4743,6.11149c.12519,1.61317-1.0854,3.02749-2.69838,3.15267l-8.58655,.66638c-1.61338,.12521-3.02769-1.08539-3.15288-2.69857Z"
          fill={mainColor}
        />
        <path
          d="M47.32163,99.6001l-1.75485-22.61184c-.12519-1.61317,1.0854-3.02749,2.69878-3.1527l12.71163-.98652c1.61297-.12518,3.02728,1.08542,3.15248,2.69859l1.75485,22.61184c.12519,1.61317-1.0854,3.02749-2.69838,3.15266l-12.71163,.98652c-1.61338,.12521-3.02769-1.08539-3.15288-2.69856Z"
          fill="#3f3d56"
        />
        <path
          d="M40.40297,125.98588l.6295-.22131c1.7221-.6054,3.5413,.61885,3.62898,2.44215l1.05334,21.90523c.06906,1.43612-.98855,2.67924-2.41718,2.84118h0c-1.53441,.17393-2.90707-.95953-3.02656-2.49913l-1.68284-21.68394c-.09561-1.23201,.64899-2.37437,1.81476-2.7842v.00003Z"
          fill="#3f3d56"
        />
        <path
          d="M53.25786,149.35379l-1.75995-22.6775c-.12424-1.60089,1.0297-2.98284,2.62752-3.14656l11.53161-1.17822c1.60775-.15071,3.04502,1.00583,3.21633,2.60752l2.44729,22.90744c.08483,.79544-.14991,1.57232-.66144,2.18749-.51112,.61515-1.23193,.988-2.02955,1.04991l-12.21896,.94828c-1.61338,.12521-3.02769-1.08539-3.15287-2.69836Z"
          fill="#3f3d56"
        />
        <path
          d="M122.75036,159.54998l10.30497,15.39602,2.44462,18.02434,7.24618-.10172-4.13977-21.25837-16.451-19.47294h0c-1.10264,2.40651-.87738,5.21285,.59501,7.41267Z"
          fill="#ccc"
        />
        <rect
          x="105.40636"
          y="189.15149"
          width="29.48181"
          height=".41379"
          transform="translate(-16.73261 11.71869) rotate(-5.22098)"
          fill="#3f3d56"
        />
        <rect
          x="104.8596"
          y="185.49812"
          width="27.95156"
          height=".41376"
          transform="translate(-15.38317 10.7948) rotate(-4.8853)"
          fill="#3f3d56"
        />
        <rect
          x="104.34924"
          y="181.99437"
          width="26.74135"
          height=".41382"
          transform="translate(-17.08923 12.25488) rotate(-5.55661)"
          fill="#3f3d56"
        />
        <rect
          x="104.30487"
          y="178.48216"
          width="25.68963"
          height=".41398"
          transform="translate(-19.01409 14.02802) rotate(-6.33939)"
          fill="#3f3d56"
        />
        <rect
          x="103.81728"
          y="175.25221"
          width="24.68386"
          height=".414"
          transform="translate(-18.97844 14.16224) rotate(-6.45117)"
          fill="#3f3d56"
        />
        <rect
          x="102.55657"
          y="172.02393"
          width="24.02694"
          height=".41375"
          transform="translate(-12.98286 9.38118) rotate(-4.43769)"
          fill="#3f3d56"
        />
        <rect
          x="101.77962"
          y="169.33554"
          width="23.352"
          height=".41377"
          transform="translate(-14.64738 10.77708) rotate(-5.10909)"
          fill="#3f3d56"
        />
        <rect
          x="101.0543"
          y="166.75641"
          width="22.63866"
          height=".41398"
          transform="translate(-17.74856 13.42896) rotate(-6.33939)"
          fill="#3f3d56"
        />
        <rect
          x="100.28028"
          y="163.75841"
          width="22.34628"
          height=".41381"
          transform="translate(-15.0551 11.31511) rotate(-5.44474)"
          fill="#3f3d56"
        />
        <rect
          x="99.36336"
          y="161.13011"
          width="21.4038"
          height=".41395"
          transform="translate(-16.85204 12.89176) rotate(-6.2276)"
          fill="#3f3d56"
        />
        <rect
          x="98.51855"
          y="158.44076"
          width="21.20565"
          height=".41377"
          transform="translate(-13.40455 10.10827) rotate(-4.9972)"
          fill="#3f3d56"
        />
        <path
          d="M96.32067,150.78421l-2.17103-27.97445c-.12521-1.61338,1.08539-3.02769,2.69876-3.1529l13.53665-1.05055c1.61297-.12518,3.02728,1.08542,3.15249,2.6988l2.17103,27.97445c.12518,1.61297-1.08542,3.02728-2.69839,3.15246l-13.53665,1.05055c-1.61338,.12521-3.02769-1.08539-3.15287-2.69836Z"
          fill={mainColor}
        />
        <path
          d="M67.95979,194.10725l-24.08102,.33331-.19393,.00249-1.43924-18.54517c-.18202-2.34534,1.57814-4.40169,3.92348-4.5837l23.66889-1.83688c2.34534-.18202,4.40129,1.57818,4.5833,3.92351l1.5587,20.08442-.20625,.01601-7.81393,.60601Z"
          fill="#ccc"
        />
        <path
          d="M94.03299,99.91738l-1.65881-21.37432c-.12519-1.61317,1.0854-3.02749,2.69878-3.1527l4.46146-.34624c1.61297-.12518,3.02728,1.08542,3.15248,2.69859l1.65881,21.37432c.12519,1.61317-1.0854,3.02749-2.69838,3.15266l-4.46146,.34624c-1.61338,.12521-3.02769-1.08539-3.15288-2.69856Z"
          fill="#3f3d56"
        />
        <path
          d="M105.84076,193.24279l-2.64613-18.05434-5.05716-17.03724-1.39943-4.66382h0c-2.23103-1.27658-4.96037,.54787-4.63376,3.09747l.25807,2.01454,5.05715,17.03724,2.64613,18.05434"
          fill="#ccc"
        />
        <rect
          x="-.01048"
          y="192.22189"
          width="377.24607"
          height=".8276"
          transform="translate(-2.48112 2.46101) rotate(-.7427)"
          fill="#ccc"
        />
      </g>
      <g>
        <g>
          <path
            d="M125.15736,362.7369c-.8443-1.42365-4.49683,.10068-5.16833-1.19135-.66913-1.28735,2.72449-3.24887,4.66333-7.7298,.34967-.80807,2.55298-5.90033,.92188-7.47162-3.09174-2.97836-17.23962,9.31259-22.19073,5.29041-1.08661-.88269-1.8457-2.64484-5.13428-5.50583-1.30829-1.13815-2.09937-1.65204-2.86499-1.46085-1.08636,.27127-1.17242,1.72522-2.33734,4.58398-1.74701,4.28729-2.78027,4.02841-3.79816,7.44897-.75562,2.53925-.58301,4.01553-1.47217,4.30313-1.25824,.40701-2.41638-2.28632-4.01099-2.04517-1.6272,.24612-2.61096,3.38205-2.92169,5.72998-.58313,4.40607,1.03497,7.31348,1.93182,10.37064,.97461,3.32236,1.55304,8.37494-.69769,15.52759l-22.27719,62.76614c4.91812-12.87943,19.15842-48.18536,25.14218-61.3053,1.72742-3.78745,3.6347-7.62909,7.55096-9.14526,3.77045-1.45978,8.94867-.58823,15.55029-2.17862,.771-.1857,2.91052-.73007,3.17981-1.98849,.22266-1.04037-1.03583-1.62109-.84247-2.59549,.25934-1.30701,2.73413-1.32306,5.77533-2.83096,2.14447-1.06332,3.48047-2.32785,4.62933-3.41531,.34637-.32785,5.49512-5.26166,4.37115-7.1568h-.00006Z"
            fill="#f2f2f2"
          />
          <path
            d="M37.51225,395.77962c-.94235,.0036-1.24805,2.23611-2.07593,2.1929-.82492-.04303-.80847-2.2746-2.4483-4.5191-.29572-.40475-2.1593-2.95547-3.40115-2.60825-2.35385,.65808-.4035,11.14828-3.80618,12.41782-.74673,.27863-1.83066,.14276-4.18369,.93134-.9361,.31372-1.41656,.55371-1.54364,.98465-.1803,.61145,.50845,1.07324,1.57544,2.46979,1.60016,2.09445,1.17496,2.52692,2.56009,4.01343,1.02826,1.10352,1.80264,1.44476,1.68723,1.96414-.16327,.73499-1.81934,.52631-2.16107,1.37851-.34869,.86966,.90659,2.25729,1.9693,3.08725,1.99429,1.5575,3.88812,1.6022,5.64737,2.04413,1.91183,.48026,4.55859,1.65421,7.41974,4.82266l24.37836,29.04382c-4.90228-6.12997-18.12189-23.30609-22.83472-30.0285-1.3605-1.94061-2.69568-3.98517-2.30984-6.34479,.37143-2.27176,2.29327-4.56177,3.41748-8.26077,.13132-.43201,.48145-1.63916-.05847-2.13443-.44638-.40945-1.09451,.04068-1.51697-.33539-.56665-.50443,.13953-1.72369,.27689-3.6514,.09686-1.35931-.13831-2.37988-.34055-3.25754-.06097-.26459-.99695-4.21518-2.25137-4.21036v.00009h-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M56.31187,399.99972l-.56775-5.06818,.2594-.38422c1.20035-1.77667,1.81216-3.51175,1.81949-5.15872,.00122-.26184-.01102-.52371-.02325-.79044-.04895-1.05719-.11011-2.37134,.5751-3.90329,.38422-.85406,1.46344-2.83142,3.08594-2.5867,.43683,.06119,.76721,.26184,1.01804,.49677,.03671-.05872,.07465-.11746,.11502-.18109,.50534-.77087,.90546-1.10123,1.29089-1.41937,.29611-.24472,.60202-.49677,1.08167-1.0939,.21045-.26184,.37442-.49188,.5127-.68521,.41971-.58487,.96786-1.28235,1.93451-1.27011,1.03271,.04895,1.57355,.90302,1.93207,1.46832,.63995,1.00824,.92993,1.73508,1.12204,2.21716,.06976,.17621,.14929,.37442,.18967,.43561,.33282,.48944,3.03824,.0318,4.05746-.13461,2.28937-.38177,4.27039-.71213,5.08655,.73172,.5849,1.03271,.15051,2.39825-1.33008,4.1676-.4613,.55063-.95197,.96909-1.38757,1.29703,.36096,.21536,.68396,.54572,.81494,1.06454h0c.30835,1.22849-.74762,2.45944-3.13733,3.66348-.59344,.30099-1.39246,.70236-2.51205,.89569-.52737,.09055-1.00214,.11258-1.40836,.12482-.00858,.23492-.06363,.48944-.20679,.75128-.41971,.77087-1.29333,1.13794-2.60995,1.04984-1.44876-.07587-2.64175-.35239-3.69406-.59467-.91891-.21045-1.7106-.3891-2.33218-.3475-1.15262,.09299-2.04099,1.01559-3.08347,2.21228l-2.6026,3.04187h-.00005Z"
            fill="#f2f2f2"
          />
          <path
            d="M58.94556,369.82378l-4.75842,1.83472-.43744-.15347c-2.02304-.71045-3.85654-.86215-5.45151-.45148-.2536,.06525-.50378,.14349-.7587,.22299-1.01019,.31549-2.26587,.70801-3.92151,.43378-.92361-.155-3.11002-.69739-3.28485-2.32889-.05161-.43805,.05869-.80853,.22232-1.11075-.06613-.0206-.13257-.04239-.20435-.06531-.87384-.2933-1.29489-.59653-1.70041-.88867-.31183-.22437-.63324-.45633-1.33249-.76883-.30667-.13715-.57077-.2374-.79285-.32211-.67221-.2576-1.4859-.61096-1.71924-1.5491-.2146-1.01135,.47437-1.75113,.93024-2.24133,.81296-.87476,1.44244-1.33963,1.86005-1.64774,.15274-.11215,.32431-.23938,.37326-.29395,.38901-.44608-.73987-2.94693-1.15936-3.89062-.94998-2.11765-1.77203-3.95007-.5824-5.10577,.85059-.8277,2.28165-.75391,4.36868,.22946,.64963,.30655,1.17886,.67502,1.60657,1.01321,.11676-.40378,.35437-.80005,.82303-1.05829h0c1.11011-.60986,2.56863,.09933,4.33945,2.10547,.44168,.49768,1.03259,1.16876,1.50357,2.2027,.22134,.48715,.36307,.9408,.47797,1.33066,.22943-.0513,.48956-.06259,.77917,.00946,.85211,.21045,1.4288,.9624,1.67752,2.2583,.2941,1.42062,.32922,2.64474,.36179,3.72409,.02951,.94226,.0575,1.75336,.25543,2.34406,.38232,1.09134,1.50006,1.71661,2.92206,2.42148l3.60254,1.74591-.00012,.00003Z"
            fill="#f2f2f2"
          />
          <path
            d="M56.31187,354.89278l-.56775-5.06818,.2594-.38422c1.20035-1.77667,1.81216-3.51175,1.81949-5.15872,.00122-.26184-.01102-.52371-.02325-.79044-.04895-1.05719-.11011-2.37134,.5751-3.90329,.38422-.85406,1.46344-2.83142,3.08594-2.5867,.43683,.06119,.76721,.26184,1.01804,.49677,.03671-.05872,.07465-.11746,.11502-.18109,.50534-.77087,.90546-1.10123,1.29089-1.41937,.29611-.24472,.60202-.49677,1.08167-1.0939,.21045-.26184,.37442-.49188,.5127-.68521,.41971-.58487,.96786-1.28235,1.93451-1.27011,1.03271,.04895,1.57355,.90302,1.93207,1.46832,.63995,1.00824,.92993,1.73508,1.12204,2.21716,.06976,.17621,.14929,.37442,.18967,.43561,.33282,.48944,3.03824,.0318,4.05746-.13461,2.28937-.38177,4.27039-.71213,5.08655,.73172,.5849,1.03271,.15051,2.39825-1.33008,4.1676-.4613,.55063-.95197,.96909-1.38757,1.29703,.36096,.21536,.68396,.54572,.81494,1.06454h0c.30835,1.22849-.74762,2.45944-3.13733,3.66348-.59344,.30099-1.39246,.70236-2.51205,.89569-.52737,.09055-1.00214,.11258-1.40836,.12482-.00858,.23492-.06363,.48944-.20679,.75128-.41971,.77087-1.29333,1.13794-2.60995,1.04984-1.44876-.07587-2.64175-.35239-3.69406-.59467-.91891-.21045-1.7106-.3891-2.33218-.3475-1.15262,.09299-2.04099,1.01559-3.08347,2.21228l-2.6026,3.04187h-.00005Z"
            fill="#f2f2f2"
          />
          <path
            d="M61.49751,453.27053l-1.0621-.66565-.25696-1.22604,.25696,1.22604-1.23828,.14194c-.01959-.11502-.08688-.37933-.18842-.79291-.55429-2.26611-2.24408-9.16235-3.65369-20.02304-.9838-7.58145-1.54053-15.37579-1.65555-23.17017-.11502-7.80658,.25452-13.71661,.55063-18.46661,.22391-3.5827,.49557-6.99411,.7623-10.31744,.70969-8.87848,1.379-17.26505,.88098-26.54974-.11011-2.07278-.3414-6.38721-2.862-10.97818-1.46222-2.66257-3.47626-5.02655-5.98709-7.02347l1.56131-1.96265c2.77145,2.20737,4.99963,4.8259,6.62338,7.78214,2.79471,5.09018,3.04678,9.79129,3.16791,12.05005,.50656,9.44867-.17007,17.91602-.88712,26.88013-.26431,3.31107-.53595,6.70779-.75864,10.27582-.29367,4.70599-.65952,10.56458-.54572,18.27084,.11258,7.70135,.66318,15.40027,1.63351,22.88629,1.39246,10.72366,3.0578,17.51712,3.60474,19.74896,.29123,1.1918,.35239,1.44141,.05383,1.91373v-.00003h.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M42.44843,339.40688c-.10278,0-.20679-.00244-.31201-.0098-2.13397-.11502-4.11008-1.39246-5.87451-3.79562-.82715-1.13062-1.25052-2.42029-2.09482-4.99231-.13092-.39645-.76721-2.40805-1.15387-5.17584-.2533-1.8085-.22147-2.56467,.13705-3.23276,.39767-.74396,1.04129-1.26276,1.76566-1.6127-.0318-.23984-.00613-.487,.0979-.73907,.42703-1.04495,1.56253-.9079,2.17679-.84183,.31079,.03915,.69867,.09055,1.11716,.06851,.65829-.0318,1.01193-.2276,1.54785-.52127,.5127-.28143,1.15018-.63138,2.09604-.82471,1.86478-.3891,3.42731,.1395,3.94244,.31323,2.71027,.90302,4.04645,3.01007,5.59308,5.44992,.30835,.48944,1.36676,2.271,2.06543,4.71332,.50412,1.76199,.43317,2.5451,.28143,3.12753-.30835,1.19669-1.03638,1.8819-2.88525,3.39429-1.93085,1.58334-2.89993,2.37625-3.73199,2.85834-1.93695,1.11838-3.15323,1.82071-4.76837,1.82071v.00006Z"
            fill="#f2f2f2"
          />
        </g>
        <path
          d="M602.4134,452.13107h0c0,.46393-.3761,.84003-.84003,.84003H19.37336v-1.68005H601.57337c.46393,0,.84003,.3761,.84003,.84003Z"
          fill="#e6e6e6"
        />
        <g>
          <g>
            <path
              d="M434.34019,186.59678s-31.33896,3.76915-26.05276-21.00919,35.25882-70.54535,35.25882-70.54535l17.00858,9.53851-19.52722,46.15779,8.40622,6.63181-15.09363,29.22643v-.00002Z"
              fill="#ffb6b6"
            />
            <polygon
              points="537.03907 156.43111 532.623 192.75124 421.96516 192.20936 444.1898 153.58425 537.03907 156.43111"
              fill={mainColor}
            />
            <ellipse
              cx="460.23035"
              cy="89.06131"
              rx="21.06186"
              ry="13.5883"
              transform="translate(119.88301 411.40829) rotate(-54.39629)"
              fill="#ffb6b6"
            />
          </g>
          <rect
            x="526.399"
            y="294.90264"
            width="6.79416"
            height="157.6243"
            fill="#3f3d56"
          />
          <rect
            x="493.78707"
            y="294.90264"
            width="6.79416"
            height="157.62427"
            fill="#3f3d56"
          />
          <rect
            x="311.02443"
            y="195.70797"
            width="6.79416"
            height="256.81891"
            fill="#e6e6e6"
          />
          <rect
            x="420.41025"
            y="294.90261"
            width="6.79416"
            height="157.6243"
            fill="#3f3d56"
          />
          <rect
            x="394.41025"
            y="294.90261"
            width="6.79416"
            height="157.6243"
            fill="#3f3d56"
          />
          <polygon
            points="520.28428 291.50552 389.08229 291.50552 363.91389 327.20519 494.4665 334.98809 520.28428 291.50552"
            fill="#3f3d56"
          />
          <g>
            <g>
              <polygon
                points="280.04002 169.36298 280.63603 143.4733 201.45065 149.56406 201.04565 167.10742 280.04002 169.36298"
                fill="#ffb6b6"
              />
              <path
                d="M226.44773,146.47083l-3.78719,9.11165-5.43919,.17362s6.36838,1.95019,8.13751,13.52248l-5.22601,1.56658s-5.01888,4.6825-14.40099,4.22827l-25.15515-2.10423-.09673-8.76942,17.38599-2.00915s3.87392-2.54864-15.30347-13.02688c0,0-6.35277-3.88484-.63927-23.06835,.78528-2.63665,3.15738-4.53039,5.90784-4.58993,1.51788-.03286,2.94026,.51243,3.50806,2.39783,1.44528,4.79918-.01711,9.58351,3.95477,12.57926s31.15379,9.98828,31.15379,9.98828l.00003-.00002Z"
                fill="#2f2e41"
              />
            </g>
            <path
              d="M510.46516,268.34168s-27.32187,46.46931-89.14536,38.09464c-14.71219-1.99294-27.65607-10.73997-35.0303-23.62569-14.25314-24.90591-35.89639-68.51529-28.8797-93.2088l-104.56203-19.95639-8.23843-25.39382,113.16264-14.69994c10.00961-1.30026,19.81473,3.56733,24.83044,12.32666l55.12436,96.26836,16.4332,1.79434,56.30518,28.4006v.00005Z"
              fill="#2f2e41"
            />
          </g>
          <g>
            <g>
              <polygon
                points="282.10429 179.96133 280.61546 154.10763 202.17678 166.54926 203.18449 184.06834 282.10429 179.96133"
                fill="#ffb6b6"
              />
              <path
                d="M226.84399,161.45499l-3.04185,9.3868-5.40759,.61065s6.50462,1.43152,9.19907,12.82397l-5.08302,1.98193s-4.62589,5.07111-14.01413,5.37315l-25.2429-.07362-.80193-8.73322,17.16798-3.40137s3.65631-2.85204-16.3019-11.75346c0,0-6.64471-3.36115-2.49309-22.94215,.57062-2.69128,2.78265-4.76972,5.51942-5.05035,1.51031-.15487,2.97195,.27422,3.68959,2.10782,1.82671,4.66735,.75397,9.55382,4.95398,12.22032s31.85638,7.44951,31.85638,7.44951l-.00002,.00002Z"
                fill="#2f2e41"
              />
            </g>
            <path
              d="M519.74552,260.08099s-23.49475,48.51678-85.7916,45.14308c-14.82483-.80284-28.43048-8.48016-36.81747-20.73083-16.21069-23.67848-41.29224-65.40526-36.28494-90.58322l-105.82863-11.47946-10.25471-24.6487,111.61319-23.75647c9.87256-2.10134,20.03751,1.96163,25.74164,10.28905l62.69067,91.52144,16.52429,.46643,58.40753,23.77869h.00003Z"
              fill="#2f2e41"
            />
          </g>
          <polygon
            points="448.12721 55.20765 442.69187 59.28414 471.22731 98.69022 476.66261 97.33139 448.12721 55.20765"
            fill="#3f3d56"
          />
          <path
            d="M522.32252,142.71362l31.25311-1.35883,13.58832,61.14736s-28.53546,107.34758-62.5062,103.27109c0,0-21.06186-15.62656-21.06186-21.06187s-28.53543-19.02362-28.53543-19.02362l-6.79416-23.10011,20.38245-31.2531s8.15298-24.45894,35.32959-38.04724l18.34421-30.57368-.00003,.00002Z"
            fill={mainColor}
          />
          <polygon
            points="343.63636 190.27267 65.75558 190.27267 65.75558 197.06681 201.63861 197.06681 201.63861 452.52691 208.43275 452.52691 208.43275 197.06681 343.63636 197.06681 343.63636 190.27267"
            fill="#e6e6e6"
          />
          <circle cx="538.62849" cy="100.58987" r="36.68841" fill="#ffb6b6" />
          <polygon
            points="410.70585 147.46951 385.08067 157.66074 435.3574 224.24343 453.0222 210.65513 410.70585 147.46951"
            fill="#e6e6e6"
          />
          <g>
            <path
              d="M510.77247,275.19955s-9.51181,30.09755-29.89426,15.04878-49.68289-61.24901-49.68289-61.24901l15.71213-11.55006,33.97073,36.84891,9.51181-4.9164,20.38245,25.81776,.00003,.00002Z"
              fill="#ffb6b6"
            />
            <polygon
              points="525.71961 169.21081 556.97267 188.23443 510.77247 288.78785 480.87821 248.02296 525.71961 169.21081"
              fill={mainColor}
            />
            <ellipse
              cx="432.63971"
              cy="211.33455"
              rx="13.58829"
              ry="21.06187"
              transform="translate(-47.70454 244.63332) rotate(-30)"
              fill="#ffb6b6"
            />
          </g>
          <path
            d="M542.64076,211.67159c9.41565-30.86861,19.52051-40.63736,30.14417-34.52121,8.08618,4.6553,10.52002,15.25182,5.78107,23.28929-12.07935,20.48726-36.96979,67.42799-31.78455,98.53941,0,0,8.15295,43.48257-57.07086,43.48257s-127.73004-14.94714-127.73004-14.94714l33.97076-5.43533,77.25351,5.5181c17.65051,1.26074,32.52652-13.84164,30.16855-31.37932-.07785-.57893-.17017-1.16431-.27777-1.756-3.8732-21.30067,39.54517-82.79041,39.54517-82.79041v.00003Z"
            fill="#3f3d56"
          />
        </g>
        <path
          d="M524.87763,85.38096c-2.99707,8.3918,3.58841,17.10565,12.47961,16.51291l.21179-.01412h0c5.97687,7.47111,6.14197,18.03956,.40137,25.6937h0c-.47162,9.86528,1.07379,19.72308,4.54169,28.97076l9.74353,25.98268,15-7,7.11847,18.99998,41.88159-21.57536-25.38275-57.11125-7.74609-30.98443c-5.51099-22.04398-27.99719-35.31264-49.95807-29.47928l-16.84879,4.47546c-13.31104,3.53574-20.97241,17.47213-16.82507,30.60546v.00002l25.38278-5.07656"
          fill="#2f2e41"
        />
      </g>
    </svg>
  );
};

export default HouseSelectedSvg;
