import * as Yup from 'yup';
import { OfferTypeEnum } from '../../api/api.types';
import {
  OfferLocationFieldKeys,
  OfferParametersFieldKeys,
  useGetOfferDescriptionFields,
  useGetOfferLocationFields,
  useGetOfferParametersFields,
} from '../../forms/offer';
import { useTranslation } from 'react-i18next';

interface Props {
  offerType: OfferTypeEnum;
}

export const useOfferVariantFormValidation = ({ offerType }: Props) => {
  const { t } = useTranslation(['validation']);

  const getLocationFields = useGetOfferLocationFields();
  const descriptionFields = useGetOfferDescriptionFields();
  const getParametersFields = useGetOfferParametersFields();

  return Yup.object({
    ...getLocationFields({
      mappings: {
        [OfferLocationFieldKeys.ADDRESS]: (validation) =>
          validation.test({
            name: 'at least one location selected',
            message: t('validation:required'),
            test: (value) => !!value && value.length > 0,
          }),
      },
    }),
    ...descriptionFields,
    ...getParametersFields({
      offerType,
      mappings: {
        [OfferParametersFieldKeys.PRICE]: (validation) =>
          validation.required(t('validation:required')),
      },
    }),
  });
};
