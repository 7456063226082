import React from 'react';
import { Controller } from 'react-hook-form';
import TextInputLabeled from './text-input-labeled';
import { InputLabelProps } from '../shared/input-label';

interface Props extends Omit<InputLabelProps, 'labelFor'> {
  name: string;
  className?: string;
  control: any;
  id: string;
  autoComplete?: string;
  type?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  saveErrorSpace?: boolean;
  placeholder?: string;
}

const TextInputLabeledController = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextInputLabeled
          onChange={onChange}
          isError={!!error}
          errorMessage={error?.message}
          value={value}
          {...rest}
        />
      )}
    />
  );
};

export default TextInputLabeledController;
