import {
  AddressAutosuggestionResponse,
  AddressResponse,
  FullAddressAutosuggestionResponse,
} from '../api.types';
import { axios } from '../axios';
import { Address } from '../../models/address';

export const getStates = (): Promise<AddressResponse[]> =>
  axios.get<AddressResponse[]>('/address/states').then((resp) => resp.data);

export const getCounties = (state: string): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(`/address/states/${state}/counties`)
    .then((resp) => resp.data);

export const getCommunities = (
  state: string,
  county: string,
): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(
      `/address/states/${state}/counties/${county}/communities`,
    )
    .then((resp) => resp.data);

export const getCities = (
  state: string,
  county: string,
): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(
      `/address/states/${state}/counties/${county}/cities`,
    )
    .then((resp) => resp.data);

export const getDistricts = (
  state: string,
  county: string,
  city: string,
): Promise<AddressResponse[]> =>
  axios
    .get<AddressResponse[]>(
      `/address/states/${state}/counties/${county}/cities/${city}/districts`,
    )
    .then((resp) => resp.data);

export const getAddressAutocomplete = (query: string, size: number) => {
  return axios
    .get<AddressAutosuggestionResponse[]>(`/address/autocomplete`, {
      params: { query, size },
    })
    .then((resp) => resp.data);
};

export const getFullAddressAutocomplete = (query: string, size: number) => {
  return axios
    .get<FullAddressAutosuggestionResponse[]>(`/full-address/autocomplete`, {
      params: { query, size },
    })
    .then((resp) => resp.data);
};

export const getDefaultAutocomplete = (query: string, size: number) => {
  return axios
    .get<AddressAutosuggestionResponse[]>(`/default-address/autocomplete`, {
      params: { query, size },
    })
    .then((resp) => resp.data);
};

export const getBoundsForLocation = ({
  address_state,
  address_county,
  address_community,
  address_district,
  address_city,
  address_street,
  proximity,
}: Address & { proximity?: number | null }) => {
  return axios
    .get(`/address/geojson`, {
      params: {
        address_state,
        address_county,
        address_community,
        address_district: address_district ? address_district : address_street,
        address_city,
        proximity,
      },
    })
    .then((resp) => resp.data);
};

export const getLatLongForLocation = ({
  address_state,
  address_county,
  address_community,
  address_district,
  address_city,
  address_street,
  address_street_number,
}: Address) => {
  return axios
    .get<{
      latitude: number;
      longitude: number;
    }>(`/address/geocoding`, {
      params: {
        address_state,
        address_county,
        address_community,
        address_district,
        address_city,
        address_street,
        address_street_number,
      },
    })
    .then((resp) => resp.data);
};
