import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MyOffersList from './my-offers-list';
import { useUser } from '../../context/auth/use-user';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationMembers } from '../../api/organizations/organizations.api';
import MyOffersNavigation from './my-offers-navigation';
import PageHeader from '../../components/header/page-header';
import { useTranslation } from 'react-i18next';

const MyOffers = () => {
  const user = useUser();
  const { t } = useTranslation('common');
  const { data: members = [], isLoading: isMembersLoading } = useQuery(
    ['organizations', 'members'],
    getOrganizationMembers,
  );

  return (
    <main className="h-full w-full">
      <PageHeader title={t('common:pageTitle.myOffers')} />
      <div className="mx-auto flex max-w-7xl flex-col bg-white pt-2 sm:pt-4 md:pt-6">
        <div className="w-full px-8">
          <div className="w-full border-b border-gray-200">
            <MyOffersNavigation />
          </div>
        </div>
      </div>
      <Routes>
        <Route
          path="/personal"
          element={
            <MyOffersList
              phoneNumbers={user.phoneNumber ? [user.phoneNumber] : []}
            />
          }
        />
        <Route
          path="/organization"
          element={
            <MyOffersList
              phoneNumbers={
                members
                  .map((m) => m.user.phone_number)
                  .filter((n) => n) as string[]
              }
              isNumbersLoading={isMembersLoading}
            />
          }
        />
        <Route path="*" element={<Navigate to="personal" replace />} />
      </Routes>
    </main>
  );
};

export default MyOffers;
