import React from 'react';
import {
  OfferActivity,
  OfferActivityEventType,
} from '../../models/offer-event';
import ActivityItemBase from './activity-item-base';
import PhoneCall from '../phone-call/phone-call';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import { DATE_FNS_LOCALES, SupportedLanguages } from '../../i18n/languages';
import i18n from 'i18next';
import OfferStatusBadge from '../badge/offer-status-badge';
import CallResultBadge from '../badge/call-result-badge';
import IncomingSms from '../sms/incoming-sms';
import SmsTypeBadge from '../sms/sms-type-badge';
import TeamAssignmentBadge from '../teams/team-assignment-badge';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import TimeRangeFormat from '../meetings/other/time-range-format';
import MeetingStatusBadge from '../meetings/other/meeting-status-badge';
import UserAvatar from '../avatar/user-avatar';
import { UserPublicResponse } from '../../api/api.types';

interface Props {
  event: OfferActivity;
}

const ActivityItem = ({ event }: Props) => {
  const { t } = useTranslation(['offers']);

  const time = (
    <span className="flex-none leading-5 text-gray-500">
      {formatDistanceToNow(event.time, {
        locale: DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
        addSuffix: true,
      })}
    </span>
  );

  switch (event.type) {
    case OfferActivityEventType.MEETING_ADDED:
      return (
        <ActivityItemBase
          user={event.meeting.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1} className="flex items-center gap-1">
                  {createMeetingUserName(event.meeting.user)}
                  <span className="text-gray-500">
                    {t('offers:timeline.addedMeeting')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            createMeetingSummaryItem({
              label: `${t('offers:timeline.meeting')}:`,
              meeting: event.meeting,
            }),
          ]}
        />
      );
    case OfferActivityEventType.MEETING_ASSIGNMENT_CHANGE:
      return (
        <ActivityItemBase
          user={event.meeting.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1} className="flex items-center gap-1">
                  {createMeetingUserName(event.meeting.user)}
                  <span className="text-gray-500">
                    {t('offers:timeline.meetingAssignmentChange')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },

            {
              type: 'row' as const,
              items: event.meeting.reassigned_to
                ? [
                    <p key={1}>
                      <span className="font-medium text-gray-900">
                        {t('offers:timeline.meetingAssignedTo')}{' '}
                      </span>
                    </p>,
                    <div className="flex items-center gap-1" key={2}>
                      <span className="font-medium text-gray-900">
                        {event.meeting.reassigned_to.first_name}{' '}
                        {event.meeting.reassigned_to.last_name && ' '}
                        {event.meeting.reassigned_to.last_name}{' '}
                      </span>
                      <UserAvatar
                        key={3}
                        {...event.meeting.reassigned_to}
                        size="sm"
                      />
                    </div>,
                  ]
                : [
                    <p key={1}>
                      <span className="font-medium text-gray-900">
                        {t('offers:timeline.meetingAssignedRemoved')}{' '}
                      </span>
                    </p>,
                    ...(event.meeting.reassigned_from
                      ? [
                          <div className="flex items-center gap-1" key={2}>
                            <span className="font-medium text-gray-900">
                              {event.meeting.reassigned_from.first_name}{' '}
                              {event.meeting.reassigned_from.last_name && ' '}
                              {event.meeting.reassigned_from.last_name}{' '}
                            </span>
                            <UserAvatar
                              key={3}
                              {...event.meeting.reassigned_from}
                              size="sm"
                            />
                          </div>,
                        ]
                      : []),
                  ],
            },
            createMeetingSummaryItem({
              label: `${t('offers:timeline.meeting')}:`,
              meeting: event.meeting,
            }),
          ]}
        />
      );
    case OfferActivityEventType.MEETING_STATUS_CHANGE:
      return (
        <ActivityItemBase
          user={event.meeting.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1} className="flex items-center gap-1">
                  {createMeetingUserName(event.meeting.user)}
                  <span className="text-gray-500">
                    {t('offers:timeline.meetingStatusChange')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.meetingNewStatus')}:{' '}
                </span>,
                <div key={2} className="flex">
                  <MeetingStatusBadge status={event.meeting.status_to} />
                </div>,
              ],
            },
            createMeetingSummaryItem({
              label: `${t('offers:timeline.meeting')}:`,
              meeting: event.meeting,
            }),
            ...(event.meeting.note
              ? [
                  createCommentItem({
                    label: `${t('offers:timeline.comment')}:`,
                    text: event.meeting.note,
                  }),
                ]
              : []),
          ]}
        />
      );
    case OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED:
      return (
        <>
          <ActivityItemBase
            user={event.phoneCallSummary.user}
            properties={[
              {
                type: 'row',
                items: [
                  <div key={1}>
                    <span className="font-medium text-gray-900">
                      {event.phoneCallSummary.user.first_name}{' '}
                      {event.phoneCallSummary.user.last_name}
                      {event.phoneCallSummary.user.last_name && ' '}
                    </span>
                    <span className="text-gray-500">
                      {t('offers:timeline.addedPhoneCallSummary')}
                    </span>
                  </div>,
                  <React.Fragment key={2}>{time}</React.Fragment>,
                ],
              },
              {
                type: 'row',
                items: [
                  <span key={1} className="font-medium text-gray-900">
                    {t('offers:timeline.result')}:
                  </span>,
                  <CallResultBadge
                    key={2}
                    offerStatus={event.phoneCallSummary.result}
                  />,
                ],
              },
              event.fromStatus
                ? {
                    type: 'row',
                    items: [
                      <span key={1} className="font-medium text-gray-900">
                        {t('offers:timeline.oldStatus')}:
                      </span>,
                      <OfferStatusBadge
                        key={2}
                        offerStatus={event.fromStatus}
                        isDeleted={true}
                      />,
                    ],
                  }
                : undefined,
              event.phoneCallSummary.notes
                ? {
                    type: 'column',
                    items: [
                      <span
                        key={1}
                        className={`font-medium text-gray-900 ${
                          event.fromStatus ? 'mt-1' : ''
                        }`}
                      >
                        {t('offers:timeline.note')}:
                      </span>,
                      <div
                        key={2}
                        className="flex-auto rounded-md p-2 ring-1 ring-inset ring-gray-200"
                      >
                        {event.phoneCallSummary.notes}
                      </div>,
                    ],
                  }
                : undefined,
              event.phoneCallSummary.linked_calls.length > 0
                ? {
                    type: 'column',
                    items: [
                      <div
                        key={0}
                        className={`font-medium text-gray-900 ${
                          event.fromStatus && !event.phoneCallSummary.notes
                            ? 'mt-1'
                            : ''
                        }`}
                      >
                        {t('offers:timeline.calls')}:
                      </div>,
                      ...event.phoneCallSummary.linked_calls.map((c, i) => (
                        <PhoneCall call={c} key={i} />
                      )),
                    ],
                  }
                : undefined,
            ]}
          />
        </>
      );
    case OfferActivityEventType.STATUS_ADDED:
      return (
        <ActivityItemBase
          user={event.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1}>
                  <span className="font-medium text-gray-900">
                    {event.user.first_name} {event.user.last_name && ' '}
                    {event.user.last_name}{' '}
                  </span>
                  <span className="text-gray-500">
                    {t('offers:timeline.addedStatus')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  Status:{' '}
                </span>,
                <div key={2} className="flex">
                  <OfferStatusBadge offerStatus={event.status} />
                </div>,
              ],
            },
          ]}
        />
      );
    case OfferActivityEventType.STATUS_REMOVED:
      return (
        <ActivityItemBase
          user={event.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1}>
                  <span className="font-medium text-gray-900">
                    {event.user.first_name} {event.user.last_name && ' '}
                    {event.user.last_name}{' '}
                  </span>
                  <span className="text-gray-500">
                    {t('offers:timeline.removedStatus')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.status')}
                </span>,
                <OfferStatusBadge
                  key={2}
                  offerStatus={event.status}
                  isDeleted={true}
                />,
              ],
            },
          ]}
        />
      );
    case OfferActivityEventType.STATUS_CHANGED:
      return (
        <ActivityItemBase
          user={event.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1}>
                  <span className="font-medium text-gray-900">
                    {event.user.first_name} {event.user.last_name && ' '}
                    {event.user.last_name}{' '}
                  </span>
                  <span className="text-gray-500">
                    {t('offers:timeline.statusChanged')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.newStatus')}:
                </span>,
                <OfferStatusBadge key={2} offerStatus={event.toStatus} />,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.oldStatus')}:
                </span>,
                <OfferStatusBadge
                  key={2}
                  offerStatus={event.fromStatus}
                  isDeleted={true}
                />,
              ],
            },
          ]}
        />
      );
    case OfferActivityEventType.STATUS_ARCHIVED:
      return (
        <ActivityItemBase
          user={event.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1}>
                  <span className="font-medium text-gray-900">
                    {event.user.first_name} {event.user.last_name && ' '}
                    {event.user.last_name}{' '}
                  </span>
                  <span className="text-gray-500">
                    {t('offers:timeline.archivedStatus')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.oldStatus')}:
                </span>,
                <span key={2}>
                  <OfferStatusBadge offerStatus={event.status} isArchived />
                </span>,
              ],
            },
          ]}
        />
      );
    case OfferActivityEventType.STATUS_ACTIVATED:
      return (
        <ActivityItemBase
          user={event.user}
          properties={[
            {
              type: 'row',
              items: [
                <p key={1}>
                  <span className="font-medium text-gray-900">
                    {event.user.first_name} {event.user.last_name && ' '}
                    {event.user.last_name}{' '}
                  </span>
                  <span className="text-gray-500">
                    {t('offers:timeline.activatedStatus')}
                  </span>
                </p>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.newStatus')}:
                </span>,
                <span key={2}>
                  <OfferStatusBadge offerStatus={event.status} isActivated />
                </span>,
              ],
            },
          ]}
        />
      );
    case OfferActivityEventType.COMMENT_ADDED:
      return (
        <>
          <ActivityItemBase
            user={event.comment.author}
            properties={[
              {
                type: 'row',
                items: [
                  <div key={1}>
                    <span className="font-medium text-gray-900">
                      {event.comment.author.first_name}{' '}
                      {event.comment.author.last_name}
                      {event.comment.author.last_name && ' '}
                    </span>
                    <span className="text-gray-500">
                      {t('offers:timeline.commented')}
                    </span>
                  </div>,
                  <React.Fragment key={2}>{time}</React.Fragment>,
                ],
              },
              createCommentItem({
                label: `${t('offers:timeline.comment')}:`,
                text: event.comment.contents,
              }),
            ]}
          />
        </>
      );
    case OfferActivityEventType.OFFER_ASSIGNED:
      return (
        <ActivityItemBase
          user={event.assignment.created_by}
          properties={[
            {
              type: 'row',
              items: [
                <div key={1} className="flex items-center gap-1">
                  {createMeetingUserName(event.assignment.created_by)}
                  <span className="text-gray-500">
                    {t('offers:timeline.assignedOffer')}
                  </span>
                </div>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'row',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.meetingAssignedTo')}
                </span>,
                <TeamAssignmentBadge assignment={event.assignment} />,
              ],
            },
          ]}
        />
      );
    case OfferActivityEventType.SMS_SENT:
      return (
        <ActivityItemBase
          user={event.sms.created_by}
          properties={[
            {
              type: 'row',
              items: [
                <div key={1}>
                  <span className="font-medium text-gray-900">
                    {event.sms.created_by.first_name}{' '}
                    {event.sms.created_by.last_name}
                    {event.sms.created_by.last_name && ' '}
                  </span>
                  <span className="text-gray-500">
                    {t('offers:timeline.sentSms')}
                  </span>
                </div>,
                <React.Fragment key={2}>{time}</React.Fragment>,
              ],
            },
            {
              type: 'column',
              items: [
                <div className="flex flex-row items-center">
                  <span key={1} className="font-medium text-gray-900">
                    {t('offers:timeline.messageType')}:
                  </span>
                  <div className="ml-auto">
                    <SmsTypeBadge smsType={event.sms.sms_type} />
                  </div>
                </div>,
              ],
            },
            {
              type: 'column',
              items: [
                <span key={1} className="font-medium text-gray-900">
                  {t('offers:timeline.message')}:
                </span>,
                <div
                  key={2}
                  className="flex-auto rounded-md p-2 ring-1 ring-inset ring-gray-200"
                >
                  {event.sms.message}
                </div>,
              ],
            },
            event.sms.responses.length > 0
              ? {
                  type: 'column',
                  items: [
                    <div key={0} className={`font-medium text-gray-900`}>
                      {t('offers:timeline.smsResponses')}:
                    </div>,
                    ...event.sms.responses
                      .sort(
                        (a, b) =>
                          new Date(a.created_at).getTime() -
                          new Date(b.created_at).getTime(),
                      )
                      .map((response, i) => (
                        <div className="pt-2">
                          <IncomingSms
                            receiverPhoneNumber={
                              event.sms.receiver_phone_number
                            }
                            response={response}
                            key={i}
                          />
                        </div>
                      )),
                  ],
                }
              : null,
          ]}
        />
      );

    default:
      return <></>;
  }
};

export default ActivityItem;

const createCommentItem = ({
  label,
  text,
}: {
  label: string;
  text: string;
}) => ({
  type: 'column' as const,
  items: [
    <span key={1} className="font-medium text-gray-900">
      {label}
    </span>,
    <div
      key={2}
      className="flex-auto whitespace-pre-wrap rounded-md p-2 ring-1 ring-inset ring-gray-200"
    >
      {text}
    </div>,
  ],
});

const createMeetingSummaryItem = ({
  label,
  meeting,
}: {
  label: string;
  meeting: {
    title: string;
    start_time: string;
    end_time: string;
  };
}) => ({
  type: 'column' as const,
  items: [
    <span key={1} className="font-medium text-gray-900">
      {label}
    </span>,
    <div
      key={2}
      className="flex-auto rounded-md p-2 ring-1 ring-inset ring-gray-200"
    >
      <span className="flex-wrap">{meeting.title}</span>
      <span className="mt-1 flex gap-x-1 text-gray-600">
        <CalendarDaysIcon className="relative bottom-0.5 h-5 w-5 text-gray-400" />
        <TimeRangeFormat
          from={new Date(meeting.start_time)}
          to={new Date(meeting.end_time)}
        />
      </span>
    </div>,
  ],
});

const createMeetingUserName = (user?: UserPublicResponse) => {
  return user ? (
    <span className="font-medium text-gray-900">
      {user.first_name}
      {user.last_name && ' '}
      {user.last_name}
    </span>
  ) : (
    <span className="font-medium text-gray-900">Propertly.io</span>
  );
};
