import {
  locale_en_gb,
  markup_editor_locale_en_gb,
  plugin_annotate_locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_fill_locale_en_gb,
  plugin_filter_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_redact_locale_en_gb,
  plugin_resize_locale_en_gb,
  plugin_sticker_locale_en_gb,
} from '@pqina/pintura';

export const locale_pl = {
  ...locale_en_gb,
  labelReset: 'Resetuj',
  labelDefault: 'Domyślny',
  labelAuto: 'Automatyczny',
  labelNone: 'Brak',
  labelEdit: 'Edytuj',
  labelClose: 'Zamknij',
  labelSupportError: `{features} nie są obsługiwane w tej przeglądarce`,

  // domyślne
  labelColor: 'Kolor',
  labelWidth: 'Szerokość',
  labelSize: 'Rozmiar',
  labelOffset: 'Przesunięcie',
  labelAmount: 'Ilość',
  labelInset: 'Wstawka',
  labelRadius: 'Promień',

  // sterowanie
  labelColorPalette: 'Paleta kolorów',

  // rozmiary
  labelSizeExtraSmall: 'Bardzo mały',
  labelSizeSmall: 'Mały',
  labelSizeMediumSmall: 'Średnio mały',
  labelSizeMedium: 'Średni',
  labelSizeMediumLarge: 'Średnio duży',
  labelSizeLarge: 'Duży',
  labelSizeExtraLarge: 'Bardzo duży',

  // domyślne przyciski
  labelButtonCancel: 'Anuluj',
  labelButtonUndo: 'Cofnij',
  labelButtonRedo: 'Ponów',
  labelButtonRevert: 'Przywróć',
  labelButtonExport: 'Zapisz',

  // powiększenie
  labelZoom: 'Powiększenie',
  labelZoomIn: 'Powiększ',
  labelZoomOut: 'Pomniejsz',
  labelZoomFit: 'Dopasuj do widoku',
  labelZoomActual: 'Rzeczywisty rozmiar',

  // status
  statusLabelButtonClose: 'Zamknij',

  // nowe opcje statusu
  statusImageLoadNone: 'Oczekiwanie na obraz',
  statusImageLoadMinSize:
    'Minimalny rozmiar obrazu to {minWidth} &times; {minHeight}',
  statusImageLoadPrepare: 'Przygotowywanie obrazu&hellip;',
  statusImageLoadBusy: 'Ładowanie obrazu&hellip;',
  statusImageLoadError: 'Błąd ładowania obrazu',
  statusImageProcessBusy: 'Przetwarzanie obrazu&hellip;',
  statusImageProcessError: 'Błąd przetwarzania obrazu',
  statusImageUploadBusy: 'Przesyłanie obrazu&hellip;',
  statusImageUploadError: 'Błąd przesyłania obrazu',
};

export const plugin_crop_locale_pl = {
  ...plugin_crop_locale_en_gb,
  cropLabel: 'Przytnij',

  cropLabelButtonRecenter: 'Wyśrodkuj',
  cropLabelButtonRotateLeft: 'Obróć w lewo',
  cropLabelButtonRotateRight: 'Obróć w prawo',
  cropLabelButtonFlipHorizontal: 'Odwróć poziomo',
  cropLabelButtonFlipVertical: 'Odwróć pionowo',

  cropLabelSelectPreset: 'Kształt kadrowania',

  cropLabelCropBoundary: 'Granica kadrowania',
  cropLabelCropBoundaryEdge: 'Krawędź obrazu',
  cropLabelCropBoundaryNone: 'Brak',

  cropLabelTabRotation: 'Obrót',
  cropLabelTabZoom: 'Skala',
};

export const plugin_finetune_locale_pl = {
  ...plugin_finetune_locale_en_gb,
  finetuneLabel: 'Dostosuj',
  finetuneLabelBrightness: 'Jasność',
  finetuneLabelContrast: 'Kontrast',
  finetuneLabelSaturation: 'Nasycenie',
  finetuneLabelExposure: 'Ekspozycja',
  finetuneLabelTemperature: 'Temperatura',
  finetuneLabelGamma: 'Gamma',
  finetuneLabelClarity: 'Przejrzystość',
  finetuneLabelVignette: 'Winieta',
};

export const plugin_filter_locale_pl = {
  ...plugin_filter_locale_en_gb,
  filterLabel: 'Filtr',

  filterLabelChrome: 'Chrome',
  filterLabelFade: 'Zanikanie',
  filterLabelCold: 'Zimny',
  filterLabelWarm: 'Ciepły',
  filterLabelPastel: 'Pastelowy',
  filterLabelMonoDefault: 'Mono',
  filterLabelMonoNoir: 'Noir',
  filterLabelMonoWash: 'Zmycie',
  filterLabelMonoStark: 'Ostry',
  filterLabelSepiaDefault: 'Sepia',
  filterLabelSepiaBlues: 'Blues',
  filterLabelSepiaRust: 'Rdza',
  filterLabelSepiaColor: 'Kolor',
};

export const plugin_annotate_locale_pl = {
  ...plugin_annotate_locale_en_gb,
  annotateLabel: 'Rysuj',
};

export const plugin_redact_locale_pl = {
  ...plugin_redact_locale_en_gb,
  redactLabel: 'Zamaż',
};

export const plugin_resize_locale_pl = {
  ...plugin_resize_locale_en_gb,
  resizeLabel: 'Zmień rozmiar',
  resizeLabelFormCaption: 'Rozmiar obrazu',
  resizeLabelInputWidth: 'szer.',
  resizeTitleInputWidth: 'Szerokość',
  resizeLabelInputHeight: 'wys.',
  resizeTitleInputHeight: 'Wysokość',
  resizeTitleButtonMaintainAspectRatio: 'Zachowaj proporcje',
};

export const markup_editor_locale_pl = {
  ...markup_editor_locale_en_gb,
  shapeLabelButtonSelectSticker: 'Wybierz obraz',

  shapeTitleButtonTextLayoutAutoWidth: 'Automatyczna szerokość',
  shapeTitleButtonTextLayoutAutoHeight: 'Automatyczna wysokość',
  shapeTitleButtonTextLayoutFixedSize: 'Stały rozmiar',

  shapeTitleButtonFlipHorizontal: 'Przerzuć poziomo',
  shapeTitleButtonFlipVertical: 'Przerzuć pionowo',
  shapeTitleButtonRemove: 'Usuń',
  shapeTitleButtonDuplicate: 'Duplikuj',
  shapeTitleButtonMoveToFront: 'Przesuń na przód',

  shapeLabelInputText: 'Edytuj tekst',

  shapeLabelInputCancel: 'Anuluj',
  shapeLabelInputConfirm: 'Potwierdź',

  shapeLabelStrokeNone: 'Brak obramowania',

  shapeLabelFontStyleNormal: 'Normalny',
  shapeLabelFontStyleBold: 'Pogrubiony',
  shapeLabelFontStyleItalic: 'Kursywa',
  shapeLabelFontStyleItalicBold: 'Pogrubiona kursywa',

  shapeTitleBackgroundColor: 'Kolor wypełnienia',

  shapeTitleCornerRadius: 'Promień zaokrąglenia',

  shapeTitleFontFamily: 'Czcionka',
  shapeTitleFontSize: 'Rozmiar czcionki',
  shapeTitleFontStyle: 'Styl czcionki',
  shapeTitleLineHeight: 'Wysokość linii',

  shapeTitleLineStart: 'Początek',
  shapeTitleLineEnd: 'Koniec',
  shapeTitleStrokeWidth: 'Szerokość linii',
  shapeTitleStrokeColor: 'Kolor linii',

  shapeTitleLineDecorationBar: 'Belka',
  shapeTitleLineDecorationCircle: 'Okrąg',
  shapeTitleLineDecorationSquare: 'Kwadrat',
  shapeTitleLineDecorationArrow: 'Strzałka',
  shapeTitleLineDecorationCircleSolid: 'Pełny okrąg',
  shapeTitleLineDecorationSquareSolid: 'Pełny kwadrat',
  shapeTitleLineDecorationArrowSolid: 'Pełna strzałka',
  shapeTitleColorTransparent: 'Przezroczysty',
  shapeTitleColorWhite: 'Biały',
  shapeTitleColorSilver: 'Srebrny',
  shapeTitleColorGray: 'Szary',
  shapeTitleColorBlack: 'Czarny',
  shapeTitleColorNavy: 'Granatowy',
  shapeTitleColorBlue: 'Niebieski',
  shapeTitleColorAqua: 'Aqua',
  shapeTitleColorTeal: 'Turkusowy',
  shapeTitleColorOlive: 'Oliwkowy',
  shapeTitleColorGreen: 'Zielony',
  shapeTitleColorLime: 'Limonkowy',
  shapeTitleColorYellow: 'Żółty',
  shapeTitleColorOrange: 'Pomarańczowy',
  shapeTitleColorRed: 'Czerwony',
  shapeTitleColorMaroon: 'Kasztanowy',
  shapeTitleColorFuchsia: 'Fuksja',
  shapeTitleColorPurple: 'Fioletowy',
  shapeTitleColorOther: 'Inny...',
};

export const plugin_sticker_locale_pl = {
  ...plugin_sticker_locale_en_gb,
  stickerLabel: 'Dodaj grafikę',
};

export const plugin_fill_locale_pl = {
  ...plugin_fill_locale_en_gb,
  fillLabel: 'Wypełnij',
};
