import { format } from 'date-fns';
import { Address } from './address';

export const ADDRESS_FILTER_GROUP = 'address';

export enum FilterCondition {
  EQUAL = 'EQUAL',
  ANY_GROUP_EQUAL = 'ANY_GROUP_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESSER_THAN = 'LESSER_THAN',
  GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN',
  LESSER_OR_EQUAL_THAN = 'LESSER_OR_EQUAL_THAN',
  NOT_EQUAL = 'NOT_EQUAL',
  LIKE = 'LIKE',
  ILIKE = 'ILIKE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  GEO_SHAPE = 'GEO_SHAPE',
  LAST_N_DAYS = 'LAST_N_DAYS',
  PROXIMITY = 'PROXIMITY',

  TEXT_CONTAINS_ALL = 'TEXT_CONTAINS_ALL',
  TEXT_CONTAINS_ANY = 'TEXT_CONTAINS_ANY',
  TEXT_NOT_CONTAINS_ANY = 'TEXT_NOT_CONTAINS_ANY',
}

// Values from FilterCondition can be used as keys for useForm fields,
// but types don't work correctly. To make it works correctly, we have to use strings.
// This is a workaround to ensure correct typing
export const GEO_SHAPE = 'GEO_SHAPE';
console.assert(
  GEO_SHAPE === FilterCondition.GEO_SHAPE,
  'Type mismatch: FilterCondition.GEO_SHAPE and GEO_SHAPE',
);
export const GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN';
console.assert(
  GREATER_OR_EQUAL_THAN === FilterCondition.GREATER_OR_EQUAL_THAN,
  'Type mismatch: FilterCondition.GREATER_OR_EQUAL_THAN and GREATER_OR_EQUAL_THAN',
);
export const LESSER_OR_EQUAL_THAN = 'LESSER_OR_EQUAL_THAN';
console.assert(
  LESSER_OR_EQUAL_THAN === FilterCondition.LESSER_OR_EQUAL_THAN,
  'Type mismatch: FilterCondition.LESSER_OR_EQUAL_THAN and LESSER_OR_EQUAL_THAN',
);

export interface Filter {
  field: string;
  value: any;
  condition: FilterCondition;
}

export function formToFilterList(
  form: Record<string, Record<string, any>>,
): Filter[] {
  const result: Filter[] = [];

  for (const [key, valueConditions] of Object.entries(form)) {
    for (const [valueCondition, valueValue] of Object.entries(
      valueConditions,
    )) {
      if (
        valueValue !== false &&
        valueValue !== null &&
        valueValue !== undefined &&
        valueValue.length !== 0 &&
        valueValue !== ''
      ) {
        let value = valueValue;
        if (key === 'contact_number' && !value.startsWith('+48')) {
          value = '+48' + value;
        }

        if (key === ADDRESS_FILTER_GROUP) {
          if (valueValue.length > 0) {
            result.push(addressesToFilter(valueValue));
          }
        } else {
          result.push({
            field: key,
            value,
            condition: valueCondition as FilterCondition,
          });
        }
      }
    }
  }

  return result.sort((a, b) => a.field.localeCompare(b.field));
}

export function addressesToFilter(addresses: Address[]): Filter {
  return {
    field: ADDRESS_FILTER_GROUP,
    condition: FilterCondition.ANY_GROUP_EQUAL,
    value: addresses.map((address) =>
      Object.fromEntries(
        Object.entries({
          address_state: address.address_state,
          address_county: address.address_county,
          address_city: address.address_city,
          address_district: address.address_district,
          address_community: address.address_community,
          address_street: address.address_street,
        }).filter(([_, value]) => value !== null),
      ),
    ),
  };
}

const isDateField = (field: string): boolean => {
  return (
    field.startsWith('date') || ['created_at', 'updated_at'].includes(field)
  );
};

export function filtersToForm(
  filters: Filter[],
  dateFormat: string,
): Record<string, Record<string, any>> {
  const result: Record<string, Record<string, any>> = {};

  for (const filter of filters) {
    let field = filter.field;
    let condition = filter.condition;
    let value = filter.value;
    if (isDateField(field)) {
      if (filter.condition !== FilterCondition.LAST_N_DAYS) {
        value = format(new Date(value), dateFormat);
      }
    }

    if (field === 'contact_number') {
      value = value.replace('+48', '');
    }

    if (field === 'contact_number') {
      value = value.replace('+48', '');
    }

    if (!result[field]) {
      result[field] = {};
    }
    result[field][condition] = value;
  }

  return result;
}

export enum FilterType {
  SINGULAR = 'SINGULAR',
  MERGED = 'MERGED',
}

export interface SingularFilter extends Filter {
  filterType: FilterType.SINGULAR;
}

export interface MergedFilter {
  field: string;
  filterType: FilterType.MERGED;
  firstValue: string;
  secondValue: string;
  firstCondition: FilterCondition;
  secondCondition: FilterCondition;
}

export type SingularOrMergedFilter = SingularFilter | MergedFilter;

export function mergeFilters(filters: Filter[]): SingularOrMergedFilter[] {
  const seen = {} as Record<string, Filter>;
  const duplicated = new Set();

  const result = [] as SingularOrMergedFilter[];

  for (const filter of filters) {
    if (filter.condition === FilterCondition.LAST_N_DAYS) {
      continue;
    }

    if (
      seen[filter.field] &&
      ((filter.condition === FilterCondition.GREATER_OR_EQUAL_THAN &&
        seen[filter.field].condition ===
          FilterCondition.LESSER_OR_EQUAL_THAN) ||
        (filter.condition === FilterCondition.LESSER_OR_EQUAL_THAN &&
          seen[filter.field].condition ===
            FilterCondition.GREATER_OR_EQUAL_THAN))
    ) {
      duplicated.add(filter.field);
      const first =
        filter.condition === FilterCondition.GREATER_OR_EQUAL_THAN
          ? filter
          : seen[filter.field];
      const second =
        filter.condition === FilterCondition.LESSER_OR_EQUAL_THAN
          ? filter
          : seen[filter.field];
      result.push({
        field: filter.field,
        filterType: FilterType.MERGED,
        secondValue: second.value,
        firstValue: first.value,
        secondCondition: second.condition,
        firstCondition: first.condition,
      });
    } else {
      seen[filter.field] = filter;
    }
  }

  filters
    .filter(
      (f) =>
        !duplicated.has(f.field) || f.condition === FilterCondition.LAST_N_DAYS,
    )
    .forEach((f) => result.push({ ...f, filterType: FilterType.SINGULAR }));

  return result;
}

export function formatFilterValues(filters: Filter[], dateFormat: string) {
  const result = [] as Filter[];

  for (const f of filters) {
    if (isDateField(f.field) && f.condition !== FilterCondition.LAST_N_DAYS) {
      const date = new Date(f.value);
      result.push({ ...f, value: format(date, dateFormat) });
    } else {
      result.push(f);
    }
  }

  return result;
}

export function isFilterArray(object: any): object is Filter[] {
  return Array.isArray(object) && object.every(isFilter);
}

export function isFilter(object: any): object is Filter {
  return (
    !!object &&
    typeof object === 'object' &&
    typeof object.field === 'string' &&
    isFilterCondition(object.condition) &&
    'value' in object
  );
}

export function isFilterCondition(object: any): object is FilterCondition {
  return Object.values(FilterCondition).includes(object);
}

export function getAddressesFromFilters(filters: Filter[]): Address[] {
  const addressFilters = filters.find((f) => f.field === ADDRESS_FILTER_GROUP);

  if (!addressFilters) {
    return [];
  }
  return addressFilters.value;
}

export function getProximityFromFilters(filters: Filter[]): number | null {
  const proximityFilter = filters.find(
    (f) => f.field === 'location' && f.condition === FilterCondition.PROXIMITY,
  );

  if (!proximityFilter) {
    return null;
  }

  return proximityFilter.value;
}
