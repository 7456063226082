import { axios } from '../axios';
import {
  AddCommentRequest,
  CommentResponse,
  OfferTypeEnum,
} from '../api.types';

export const getCommentsForOffer = (
  offerType: OfferTypeEnum,
  offerId: string,
): Promise<CommentResponse[]> => {
  return axios
    .get(`/offers/${offerType}/${offerId}/comments`)
    .then((resp) => resp.data);
};

export const addOfferComment = ({
  offerType,
  offerId,
  body,
}: {
  offerType: OfferTypeEnum;
  offerId: string;
  body: AddCommentRequest;
}): Promise<void> => {
  return axios.post(`/offers/${offerType}/${offerId}/comments`, body);
};

export const deleteOfferComment = ({
  offerType,
  offerId,
  commentId,
}: {
  offerType: OfferTypeEnum;
  offerId: string;
  commentId: string;
}): Promise<void> => {
  return axios.delete(`/offers/${offerType}/${offerId}/comments/${commentId}`);
};
