import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../components/inputs/select-input/select-input';

const useSortOptions = () => {
  const { t } = useTranslation('views-builder');

  return SortOptions.map(({ label, value }) => ({
    label: t(label),
    value,
  }));
};

const useTimelinessOptions = () => {
  const { t } = useTranslation('views-builder');

  return TimelinessOptions.map(({ label, value }) => ({
    label: t(label),
    value,
  })) as any as SelectOption[];
};

const SortOptions = [
  {
    label: 'views-builder:sorts.values.newest',
    value: 'date_scraped.descending',
  },
  {
    label: 'views-builder:sorts.values.latest',
    value: 'date_smart_last_seen.descending',
  },
  {
    label: 'views-builder:sorts.values.oldest',
    value: 'date_scraped.ascending',
  },
  { label: 'views-builder:sorts.values.cheapest', value: 'price.ascending' },
  {
    label: 'views-builder:sorts.values.mostExpensive',
    value: 'price.descending',
  },
  {
    label: 'views-builder:sorts.values.cheapestPerMeter',
    value: 'price_per_meter.ascending',
  },
  {
    label: 'views-builder:sorts.values.mostExpensivePerMeter',
    value: 'price_per_meter.descending',
  },
];

export const NO_TIMELINESS = 696969; /* without limit, about last 1900 years  */

const TimelinessOptions = [
  {
    label: 'data-model:timeliness.values.1',
    value: 1,
  },
  {
    label: 'data-model:timeliness.values.14',
    value: 14,
  },
  { label: 'data-model:timeliness.values.30', value: 30 },
  { label: 'data-model:timeliness.values.90', value: 90 },
  {
    label: 'data-model:timeliness.values.all',
    value: NO_TIMELINESS,
  },
];

export { useSortOptions, useTimelinessOptions };
