import ActivityFeedEmpty from './activity-feed-empty';
import { classNames } from '../../style/class-names';
import ActivityItem from './activity-item';
import React from 'react';
import { OfferActivity } from '../../models/offer-event';

interface Props {
  events: OfferActivity[];
}

const ActivityFeedList = ({ events }: Props) => {
  return (
    <ul role="list" className="space-y-8">
      {events.length === 0 && <ActivityFeedEmpty />}
      {events.map((event, activityItemIdx) => (
        <li key={event.time.toISOString()} className="relative flex gap-x-4">
          <div
            className={classNames(
              activityItemIdx === events.length - 1 ? 'h-6' : '-bottom-8',
              'absolute left-0 top-0 flex w-8 justify-center',
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <ActivityItem event={event} />
        </li>
      ))}
    </ul>
  );
};

export default ActivityFeedList;
