import React from 'react';
import TextInputLabeledController from '../../components/inputs/text-input/text-input-labeled-controller';
import { useForm } from 'react-hook-form';
import { useUser } from '../../context/auth/use-user';
import { SupportedLanguages } from '../../i18n/languages';
import { useTranslation } from 'react-i18next';
import SelectInputLabeledController from '../../components/inputs/select-input/select-input-labeled-controller';
import { DEFAULT_DATE_FORMAT } from '../../constants/date-format';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putSettings } from '../../api/auth/auth';
import { useAuth } from '../../context/auth/use-auth';
import Button from '../../components/button/button';
import { toast } from 'react-hot-toast';
import SuccessToast from '../../components/toasts/success-toast';
import ToggleInputLabeledController from '../../components/inputs/toggle-input/toggle-input-labeled-controller';
import LocationInputLabeledController from '../../components/inputs/location-input/location-input-labeled-controller';
import { Address, formatAddress } from '../../models/address';
import { AddressSearchType } from '../../components/inputs/location-input/location-input';
import AppUserAvatar from '../../components/avatar/app-user-avatar';
import { deleteAvatar, putUploadAvatar } from '../../api/users/users';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { AxiosError } from 'axios';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';
import PageHeader from '../../components/header/page-header';
import { IssuerTypeEnum } from '../../api/api.types';
import MultiSelectInputLabeledController from '../../components/inputs/multi-select-input/multi-select-input-labeled-controller';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmail, isPhone } from '../../constants/validation';
import TextInputController from '../../components/inputs/text-input/text-input-controller';
import { formToSortList, sortsToFormValue } from '../../models/sort';
import SelectInputLabeledArrowController from '../../components/inputs/select-input/select-input-arrow-controlled';
import SortExplanationIcon from '../../components/explanaion-icons/sort-explanation-icon';
import { useSortOptions } from '../../hooks/use-options/use-sort-options';
import UserAvatarExplanationIcon from '../../components/explanaion-icons/user-avatar-explanation-icon';

interface SettingsForm {
  first_name: string;
  last_name: string;
  email: string;
  settings: {
    language: SupportedLanguages;
    dateFormat: string;
    showDuplicates: boolean;
    fastSearch: boolean;
    defaultLocation: [{ label: string; value: Address }] | null;
    itemsPerPage: number;
    defaultIssuerType: IssuerTypeEnum[] | null;
    defaultSorts: string | null;
  };
  phone_number: string | null;
  job_title: string | null;
}

export const LANGUAGE_OPTIONS = [
  {
    label: 'lang:polish',
    value: SupportedLanguages.PL,
  },
  { label: 'lang:english', value: SupportedLanguages.EN },
];

const DATE_FORMAT_OPTIONS = [
  { label: DEFAULT_DATE_FORMAT, value: DEFAULT_DATE_FORMAT },
  { label: 'dd-MM-yyyy', value: 'dd-MM-yyyy' },
  { label: 'MM/dd/yyyy', value: 'MM/dd/yyyy' },
  { label: 'MM-dd-yyyy', value: 'MM-dd-yyyy' },
];

const ITEMS_PER_PAGE_OPTIONS = [
  { label: '20', value: '20' },
  { label: '35', value: '35' },
  { label: '50', value: '50' },
];

const AccountSettings = () => {
  const { t } = useTranslation(['lang', 'settings', 'common']);
  const user = useUser();
  const { loadUser } = useAuth();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const sortOptions = useSortOptions();
  const { control, handleSubmit, reset } = useForm<SettingsForm>({
    defaultValues: {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      settings: {
        language: user.settings.language,
        dateFormat: user.settings.dateFormat,
        showDuplicates: user.settings.showDuplicates,
        fastSearch: user.settings.showDuplicates,
        defaultLocation: user.settings.defaultLocation
          ? [
              {
                label: formatAddress(user.settings.defaultLocation),
                value: user.settings.defaultLocation,
              },
            ]
          : [],
        defaultSorts: sortsToFormValue(user.settings.defaultSorts),
        defaultIssuerType: user.settings.defaultIssuerType ?? [],
        itemsPerPage: user.settings.itemsPerPage,
      },
      job_title: user.jobTitle ?? '',
      phone_number: user?.phoneNumber?.replace('+48', '') ?? '',
    },

    resolver: yupResolver(
      yup.object({
        email: isEmail(t, { required: true }),
        phone_number: isPhone(t, { required: false }),
      }),
    ),
  });
  const { mutateAsync: saveSettings, isLoading: isSettingsUpdating } =
    useMutation(putSettings, {
      onSuccess: async (_, variables) => {
        await loadUser();
        await queryClient.invalidateQueries(['organizations', 'members']);
        toast.custom((toast) => (
          <SuccessToast
            {...toast}
            title={t('settings:alerts.accountSettingsChanged.title')}
            body={t('settings:alerts.accountSettingsChanged.body')}
          />
        ));
        await analytics.track(ANALYTICS_EVENTS.SETTINGS_CHANGED, {
          settings: JSON.stringify(variables.settings),
        });
      },
      onError: async (error: AxiosError) => {
        defaultErrorToasts(error, t);
      },
    });

  const { mutate: uploadAvatar, isLoading: isUploading } = useMutation(
    putUploadAvatar,
    {
      onSuccess: async () => {
        await loadUser();
        await analytics.track(ANALYTICS_EVENTS.AVATAR_UPLOADED);
      },
      onError: async (error: AxiosError) => {
        defaultErrorToasts(error, t);
      },
    },
  );

  const { mutate: removeAvatar, isLoading: isRemoving } = useMutation(
    deleteAvatar,
    {
      onSuccess: async () => {
        await loadUser();
        await analytics.track(ANALYTICS_EVENTS.AVATAR_REMOVED);
      },
      onError: async (error: AxiosError) => {
        defaultErrorToasts(error, t);
      },
    },
  );
  const fileInput = React.useRef<null | HTMLInputElement>(null);

  const onSubmit = handleSubmit(async (settings: SettingsForm) => {
    await saveSettings({
      ...settings,
      job_title: settings.job_title ?? null,
      phone_number: (settings.phone_number
        ? `+48${settings.phone_number}`
        : null) as string,
      settings: {
        ...settings.settings,
        defaultLocation: settings.settings?.defaultLocation?.[0]?.value ?? null,
        fastSearch: settings.settings.fastSearch,
        defaultIssuerType:
          settings.settings?.defaultIssuerType &&
          settings.settings.defaultIssuerType.length > 0
            ? settings.settings.defaultIssuerType
            : null,
        defaultSorts: settings.settings?.defaultSorts
          ? formToSortList(settings.settings.defaultSorts)
          : null,
        itemsPerPage: Number(settings.settings.itemsPerPage),
      },
    });
  });

  React.useEffect(() => {
    reset({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      job_title: user.jobTitle ?? '',
      phone_number: user.phoneNumber?.replace('+48', '') ?? '',
      settings: {
        language: user.settings.language,
        dateFormat: user.settings.dateFormat,
        showDuplicates: user.settings.showDuplicates,
        fastSearch: user.settings.fastSearch,
        defaultIssuerType: user.settings.defaultIssuerType ?? [],
        defaultLocation: user.settings.defaultLocation
          ? [
              {
                label: formatAddress(user.settings.defaultLocation),
                value: user.settings.defaultLocation,
              },
            ]
          : [],
        defaultSorts: sortsToFormValue(user.settings.defaultSorts),
        itemsPerPage: user.settings.itemsPerPage,
      },
    });
  }, [user, reset]);

  return (
    <form onSubmit={onSubmit} className="mb-10">
      <PageHeader title={t('common:pageTitle.accountSettings')} />
      <div className="mt-10">
        <div>
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t('settings:account.profileTitle')}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              {t('settings:account.profileDescription')}
            </p>
          </div>
          <div className="sm:col-span-3">
            <TextInputLabeledController
              name="first_name"
              control={control}
              id="first-name"
              label={t('settings:account.firstName')}
              labelClassName="mb-1"
              className="mt-4"
            />
          </div>

          <div className="sm:col-span-3">
            <TextInputLabeledController
              name="last_name"
              control={control}
              id="last-name"
              label={t('settings:account.lastName')}
              labelClassName="mb-1"
              className="mt-4"
            />
          </div>

          <div className="sm:col-span-6">
            <TextInputLabeledController
              name="email"
              control={control}
              id="email"
              label={t('settings:account.email')}
              labelClassName="mb-1"
              className="mt-4"
            />
          </div>
          <div>
            <div className="mb-1 mt-4 flex justify-between">
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700"
              >
                {t('settings:account.phoneNumber')}
              </label>
              <span className="text-sm text-gray-500">
                {t('settings:account.optional')}
              </span>
            </div>
            <TextInputController
              name="phone_number"
              control={control}
              id="phone-number"
              prefixIcon={<div className="sm:text-sm">+48</div>}
              autoComplete="phone"
            />
          </div>
          <div className="sm:col-span-6">
            <div className="mb-1 mt-4 flex justify-between">
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700"
              >
                {t('settings:account.jobTitle')}
              </label>
              <span className="text-sm text-gray-500">
                {t('settings:account.optional')}
              </span>
            </div>
            <TextInputController
              name="job_title"
              control={control}
              id="job-title"
              className="mt-4"
            />
          </div>
          <div className="mt-4 sm:col-span-6">
            <label className="flex flex-row items-center text-sm font-medium text-gray-700">
              <UserAvatarExplanationIcon classNameWrapper="mr-2" />
              {t('settings:account.avatar')}
            </label>
            <div className="mt-2 flex items-center">
              <AppUserAvatar {...user} size="xl" />
              <input
                type="file"
                accept="image/*"
                className="hidden"
                ref={fileInput}
                onChange={(e) => {
                  if (e.target?.files?.[0]) {
                    uploadAvatar({ file: e.target.files[0] });
                  }
                }}
              />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  fileInput.current?.click();
                }}
                variant="secondary"
                isLoading={isUploading}
                className="ml-5 px-3 py-2"
              >
                {t('settings:account.changeAvatar')}
              </Button>
              {user.avatarUrl && (
                <Button
                  variant="danger"
                  isLoading={isRemoving}
                  className="ml-4 px-3 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    removeAvatar();
                  }}
                >
                  {t('settings:account.removeAvatar')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border-blue-gray-200 mt-8 flex flex-shrink-0 border-t" />
      <div className="mt-10">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('settings:account.displayTitle')}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t('settings:account.displayDescription')}
          </p>
        </div>
        <div className="mt-6">
          <div className="sm:col-span-3">
            <SelectInputLabeledController
              name="settings.language"
              options={LANGUAGE_OPTIONS.map((o) => ({
                ...o,
                label: t(o.label),
              }))}
              control={control}
              id="language"
              label={t('settings:account.language')}
              clearable={false}
              labelClassName="mb-1"
              className="mt-4"
            />
          </div>
          <div className="sm:col-span-3">
            <SelectInputLabeledController
              name="settings.dateFormat"
              options={DATE_FORMAT_OPTIONS}
              control={control}
              id="date-format"
              label={t('settings:account.dateFormat')}
              clearable={false}
              labelClassName="mb-1"
              className="mt-4"
            />
          </div>
          <div className="sm:col-span-3">
            <SelectInputLabeledController
              options={ITEMS_PER_PAGE_OPTIONS}
              name="settings.itemsPerPage"
              control={control}
              id="items-per-page"
              clearable={false}
              label={t('settings:account.itemsPerPage')}
              labelClassName="mb-1"
              className="mt-4"
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('settings:account.searchTitle')}
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            {t('settings:account.searchDescription')}
          </p>
        </div>
        <div className="mt-6">
          <LocationInputLabeledController
            name="settings.defaultLocation"
            control={control}
            id="default-location"
            label={t('settings:account.defaultLocation')}
            labelClassName="mb-1"
            className="mt-4"
            searchType={AddressSearchType.DEFAULT}
          />
        </div>
        <div className="mt-4">
          <MultiSelectInputLabeledController
            label={t('settings:account.defaultIssuerType')}
            options={Object.values(IssuerTypeEnum).map((k) => ({
              label: t(`data-model:enumLabels.issuer_type.${k}`),
              value: k,
            }))}
            control={control}
            className="w-full"
            name="settings.defaultIssuerType"
            id="default-issuer-type"
            labelClassName="mb-1"
          />
        </div>
        <div className="relative mt-4">
          <SelectInputLabeledArrowController
            label={t('settings:account.defaultSorts')}
            control={control}
            options={sortOptions}
            showLabel
            name="settings.defaultSorts"
            id="default-sorts"
            className="w-full"
            labelClassName="mb-1 ml-6"
          />
          <SortExplanationIcon classNameWrapper="absolute left-0 top-0.5" />
        </div>
        <div className="mt-6 flex flex-wrap gap-x-4 gap-y-0">
          <div className="max-w-sm sm:col-span-3">
            <ToggleInputLabeledController
              name="settings.showDuplicates"
              control={control}
              id="show-duplicates"
              label={t('settings:account.showDuplicates')}
              className="mt-4"
            />
          </div>
          <div className="max-w-sm sm:col-span-3">
            <ToggleInputLabeledController
              name="settings.fastSearch"
              control={control}
              id="show-duplicates"
              label={t('settings:account.fastSearch')}
              className="mt-4"
            />
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          className="px-4 py-2"
          type="submit"
          isLoading={isSettingsUpdating}
        >
          {t('settings:account.save')}
        </Button>
      </div>
    </form>
  );
};

export default AccountSettings;
