import React from 'react';
import { Controller } from 'react-hook-form';
import TextAreaInputLabeled, {
  TextInputLabeledProps,
} from './text-area-input-labeled';

interface Props
  extends Omit<TextInputLabeledProps, 'labelFor' | 'onChange' | 'value'> {
  name: string;
  control: any;
}

const TextAreaInputLabeledController = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextAreaInputLabeled
          onChange={onChange}
          isError={!!error}
          errorMessage={error?.message}
          value={value}
          {...rest}
        />
      )}
    />
  );
};

export default TextAreaInputLabeledController;
