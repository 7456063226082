import React from 'react';
import {
  getEnumOptions,
  OfferEnums,
  schemaHasField,
} from '../../../schemas/schemas';
import { OfferTypeEnum } from '../../../api/api.types';
import { useTranslation } from 'react-i18next';
import CheckboxInputLabeledController from '../../inputs/checkbox-input/checkbox-input-labeled-controller';
import SelectInputLabeledController from '../../inputs/select-input/select-input-labeled-controller';
import TextInputLabeledController from '../../inputs/text-input/text-input-labeled-controller';

interface Props {
  booleanFields?: string[];
  enumFields?: OfferEnums[];
  textFields?: { name: string; suffix?: string }[];
  offerType: OfferTypeEnum;
  header: string;
  control: any;
  icon: React.JSXElementConstructor<any>;
  className?: string;
}

const OfferVariantParameterGrid = ({
  booleanFields = [],
  enumFields = [],
  offerType,
  textFields = [],
  header,
  control,
  icon: Icon,
  className = '',
}: Props) => {
  const { t } = useTranslation(['data-model']);

  const allFields = [
    ...booleanFields,
    ...enumFields,
    ...textFields.map((f) => f.name),
  ];

  const anyIncluded = allFields.some((f) => schemaHasField(offerType, f));

  if (!anyIncluded) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex flex-row border-b border-gray-200 pb-1">
        <Icon className="mr-2 h-6 w-6 text-indigo-600" />
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          {header}
        </h3>
      </div>
      {enumFields?.length ? (
        <div className="mt-4 gap-4 lg:grid lg:grid-cols-3">
          {enumFields
            .filter((f) => schemaHasField(offerType, f))
            .map((f) => (
              <SelectInputLabeledController
                key={f}
                name={f}
                control={control}
                id={f}
                options={getEnumOptions(f, t)}
                label={t(`data-model:fieldLabels.${f}`)}
                saveErrorSpace={true}
              />
            ))}
        </div>
      ) : null}
      {textFields?.length ? (
        <div className="mt-4 gap-4 lg:grid lg:grid-cols-3">
          {textFields
            .filter((f) => schemaHasField(offerType, f.name))
            .map((f) => (
              <TextInputLabeledController
                key={f.name}
                name={f.name}
                control={control}
                id={f.name}
                label={t(
                  `data-model:fieldLabels.${f.name.replace(
                    '_lower_bound',
                    '',
                  )}`,
                )}
                suffixIcon={f.suffix}
                saveErrorSpace={true}
              />
            ))}
        </div>
      ) : null}

      {booleanFields?.length ? (
        <div className="mt-4 gap-x-4 gap-y-1 lg:grid lg:grid-cols-3">
          {booleanFields
            .filter((f) => schemaHasField(offerType, f))
            .map((f) => (
              <CheckboxInputLabeledController
                name={f}
                key={f}
                control={control}
                id={f}
                label={t(`data-model:fieldLabels.${f}`)}
              />
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default OfferVariantParameterGrid;
