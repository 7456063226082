import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import Button from '../button/button';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const FastSearchButton = ({ onClick }: Props) => {
  const { t } = useTranslation(['navigation']);

  return (
    <Button
      type="button"
      variant="secondary"
      className="p-1 sm:p-2"
      onClick={onClick}
    >
      <MagnifyingGlassIcon className={`mr-0 h-5 w-5 text-indigo-600 sm:mr-2`} />
      <span className="sr-only sm:not-sr-only">
        {t('navigation:userMenu.fastSearch.button')}
      </span>
    </Button>
  );
};

export default FastSearchButton;
