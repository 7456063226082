import { RadioGroup } from '@headlessui/react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import RequiredIcon from '../../explanaion-icons/required-icon';

interface ColorPickerProps<T> {
  value: T;
  onChange: (newValue: T) => void;
  options: {
    name: string;
    colorClassNames: string;
    value: T;
  }[];
  label: string;
  saveErrorSpace?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
}

export const ColorPicker = <T,>({
  value,
  onChange,
  options,
  label,
  saveErrorSpace = true,
  isError = false,
  errorMessage = '',
  isRequired = false,
}: ColorPickerProps<T>) => {
  return (
    <fieldset>
      <legend className="flex items-center gap-1 text-sm font-medium text-gray-700">
        <span>{label}</span>
        {isRequired && (
          <RequiredIcon size="xs" classNameWrapper="relative bottom-1" />
        )}
      </legend>
      <RadioGroup
        value={value}
        onChange={onChange}
        className="mt-4 flex flex-wrap items-center justify-between gap-6"
      >
        {options.map(({ name, colorClassNames, value: itemValue }) => (
          <RadioGroup.Option
            key={name}
            value={itemValue}
            aria-label={name}
            className={`${colorClassNames} ${
              itemValue === value ? 'ring-2 focus:ring-indigo-600' : ''
            } relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none`}
          >
            <span
              aria-hidden="true"
              className="h-8 w-8 rounded-full border border-black border-opacity-10 bg-current"
            />
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <p
        className={`mt-2 text-xs text-red-600 ${
          saveErrorSpace ? 'whitespace-pre-wrap' : ''
        }`}
      >
        {isError ? errorMessage : ' '}
      </p>
    </fieldset>
  );
};

interface ColorPickerControllerProps<F extends FieldValues, V>
  extends Omit<ColorPickerProps<V>, 'onChange' | 'value'> {
  name: Path<F>;
  control: Control<F>;
}

export const ColorPickerController = <F extends FieldValues, V>({
  control,
  name,
  ...rest
}: ColorPickerControllerProps<F, V>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ColorPicker
          onChange={onChange}
          value={value}
          isError={!!error}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};
