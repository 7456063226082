import React, { MutableRefObject } from 'react';

interface ScrollContextProps {
  getScrollPosition: () => { x: number; y: number };
}

export const ScrollContext = React.createContext<null | ScrollContextProps>(
  null,
);

export const useScroll = () => {
  const context = React.useContext(ScrollContext);
  if (!context) {
    throw new Error(`useScroll must be used within a ScrollProvider`);
  }
  return context;
};

export const ScrollProvider = ({
  scrollContainer,
  children,
}: {
  scrollContainer: MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
}) => {
  const getScrollPosition = React.useCallback(() => {
    const w = scrollContainer.current?.scrollLeft;
    const h = scrollContainer.current?.scrollTop;
    if (w === undefined || h === undefined) {
      return { x: 0, y: 0 };
    }
    return { y: h, x: w };
  }, []);
  return (
    <ScrollContext.Provider value={{ getScrollPosition }}>
      {children}
    </ScrollContext.Provider>
  );
};
