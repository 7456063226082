import React from 'react';
import { Controller } from 'react-hook-form';
import OrganizationMemberInput from './organization-member-input';

interface Props {
  name: string;
  className?: string;
  control: any;
  id: string;
  label?: string;
  saveErrorSpace?: boolean;
  containerClassName?: string;
  placeholder?: string;
  allowAssignToMyself?: boolean;
}

const OrganizationMemberInputController = ({
  control,
  name,
  ...rest
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <OrganizationMemberInput
          onChange={onChange}
          value={value}
          isError={!!error}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default OrganizationMemberInputController;
