import React from 'react';
import { Controller } from 'react-hook-form';
import TextInput from './text-input';

interface Props {
  name: string;
  className?: string;
  control: any;
  id: string;
  autoComplete?: string;
  type?: string;
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  placeholder?: string;
  label?: string;
  saveErrorSpace?: boolean;
}

const TextInputController = ({ control, name, label, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {label && (
            <label className="sr-only" htmlFor={name}>
              {label}
            </label>
          )}
          <TextInput
            onChange={onChange}
            isError={!!error}
            errorMessage={error?.message}
            value={value}
            {...rest}
          />
        </>
      )}
    />
  );
};

export default TextInputController;
