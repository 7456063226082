import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import { useUser } from '../../context/auth/use-user';
import { Address, formatAddress } from '../../models/address';
import { useForm } from 'react-hook-form';
import { SupportedLanguages } from '../../i18n/languages';
import { useMutation } from '@tanstack/react-query';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { putSettings } from '../../api/auth/auth';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { AddressSearchType } from '../inputs/location-input/location-input';
import LocationInputLabeledController from '../inputs/location-input/location-input-labeled-controller';
import { LANGUAGE_OPTIONS } from '../../pages/settings/account-settings';
import SelectInputLabeledController from '../inputs/select-input/select-input-labeled-controller';
import Button from '../button/button';
import { useAuth } from '../../context/auth/use-auth';
import { IssuerTypeEnum } from '../../api/api.types';
import MultiSelectInputLabeledController from '../inputs/multi-select-input/multi-select-input-labeled-controller';

interface InitialSettingsForm {
  language: SupportedLanguages;
  defaultIssuerType: IssuerTypeEnum[] | null;
  defaultLocation: [{ label: string; value: Address }] | null;
}

const InitialLoginModalForm = () => {
  const user = useUser();
  const { loadUser } = useAuth();
  const analytics = useAnalytics();
  const { t } = useTranslation(['lang', 'common']);

  const { control, handleSubmit, reset } = useForm<InitialSettingsForm>({
    defaultValues: {
      language: user.settings.language,
      defaultIssuerType: user.settings.defaultIssuerType ?? [],
      defaultLocation: user.settings.defaultLocation
        ? [
            {
              label: formatAddress(user.settings.defaultLocation),
              value: user.settings.defaultLocation,
            },
          ]
        : [],
    },
  });
  const { mutateAsync: saveSettings, isLoading: isSettingsUpdating } =
    useMutation(putSettings, {
      onSuccess: async (_, variables) => {
        await loadUser();
        await analytics.track(ANALYTICS_EVENTS.INITIAL_SETTINGS_SET, {
          settings: JSON.stringify(variables.settings),
        });
      },
      onError: async (error: AxiosError) => {
        defaultErrorToasts(error, t);
      },
    });

  const onSubmit = handleSubmit(async (settings: InitialSettingsForm) => {
    await saveSettings({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      settings: {
        ...user.settings,
        ...settings,
        defaultIssuerType: settings.defaultIssuerType ?? null,
        defaultLocation: settings.defaultLocation?.[0]?.value ?? null,
      },
    });
  });

  React.useEffect(() => {
    reset({
      language: user.settings.language,
      defaultLocation: user.settings.defaultLocation
        ? [
            {
              label: formatAddress(user.settings.defaultLocation),
              value: user.settings.defaultLocation,
            },
          ]
        : [],
      defaultIssuerType: user.settings.defaultIssuerType ?? [],
    });
  }, [user, reset]);

  return (
    <form
      className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      onSubmit={onSubmit}
    >
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('common:initialLogin.header')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('common:initialLogin.description')}
                </p>
              </div>
              <div className="py-6 text-left">
                <div>
                  <SelectInputLabeledController
                    name="language"
                    options={LANGUAGE_OPTIONS.map((o) => ({
                      ...o,
                      label: t(o.label),
                    }))}
                    control={control}
                    id="language"
                    label={t('common:initialLogin.language')}
                    clearable={false}
                    labelClassName="mb-1"
                    className="mt-4"
                  />
                </div>
                <div className="mt-4">
                  <MultiSelectInputLabeledController
                    label={t('common:initialLogin.defaultIssuerType')}
                    options={Object.values(IssuerTypeEnum).map((k) => ({
                      label: t(`data-model:enumLabels.issuer_type.${k}`),
                      value: k,
                    }))}
                    control={control}
                    className="w-full"
                    name="defaultIssuerType"
                    id="default-issuer-type"
                    labelClassName="mb-1"
                  />
                </div>
                <div className="mt-4">
                  <LocationInputLabeledController
                    name="defaultLocation"
                    control={control}
                    id="default-location"
                    label={t('common:initialLogin.defaultLocation')}
                    labelClassName="mb-1"
                    className="mt-4"
                    searchType={AddressSearchType.DEFAULT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 sm:mt-5">
            <Button
              type="submit"
              className="w-full px-4 py-2"
              isLoading={isSettingsUpdating}
            >
              {t('common:initialLogin.continue')}
            </Button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </form>
  );
};

export default InitialLoginModalForm;
