import { OfferStatus } from '../../api/api.types';
import React from 'react';
import {
  OFFER_BACKGROUND_TEXT_CLASSES,
  OFFER_STATUS_BACKGROUND_CLASSES,
  OFFER_STATUS_BACKGROUND_LIGHT_CLASSES,
  OFFER_STATUS_ICONS,
  OFFER_STATUS_TEXT_CLASSES,
} from '../../constants/offer-status-icons';

interface Props {
  offerStatus: OfferStatus;
  className?: string;
  size: 'sm' | 'md' | 'lg';
  isArchived?: boolean;
}

const SIZE_CLASSES = {
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-6 w-6',
};

const OfferStatusIcon = ({
  offerStatus,
  className = '',
  size,
  isArchived = false,
}: Props) => {
  return (
    <>
      <div
        className={`relative rounded-full text-white ${
          size === 'lg' ? 'p-1.5' : 'p-1 '
        } 
        ${
          isArchived
            ? OFFER_STATUS_BACKGROUND_LIGHT_CLASSES[offerStatus]
            : OFFER_STATUS_BACKGROUND_CLASSES[offerStatus]
        }
         flex items-center justify-center ${className}`}
      >
        {OFFER_STATUS_ICONS[offerStatus]({
          className: `${SIZE_CLASSES[size]} ${OFFER_STATUS_TEXT_CLASSES[offerStatus]}`,
        })}
        {isArchived && (
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <div
              style={{ height: '1px' }}
              className={`${OFFER_BACKGROUND_TEXT_CLASSES[offerStatus]} mx-0.5 w-full`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OfferStatusIcon;
