import { PhoneCallResultEnum } from '../api/api.types';
import React from 'react';
import { ClockIcon } from '@heroicons/react/20/solid';
import { BsFillPersonCheckFill, BsFillPersonXFill } from 'react-icons/bs';
import { HiPhoneXMark } from 'react-icons/hi2';
import { MdOutlineBusinessCenter, MdOutlineTask } from 'react-icons/md';
import { TbCashBanknoteOff } from 'react-icons/all';

export const CALL_RESULT_ICONS: {
  [key in PhoneCallResultEnum]: (
    props: React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>,
  ) => React.ReactElement;
} = {
  CALL_LATER: (props) => <ClockIcon {...props} />,
  CLIENT_NOT_INTERESTED: (props) => <BsFillPersonXFill {...props} />,
  NOT_ANSWERED: (props) => <HiPhoneXMark {...props} />,
  CLIENT_CONVERTED: (props) => <BsFillPersonCheckFill {...props} />,
  CLIENT_BUSY: (props) => <MdOutlineBusinessCenter {...props} />,
  COMMISSION_FREE: (props) => <TbCashBanknoteOff {...props} />,
  OFFER_SENT: (props) => <MdOutlineTask {...props} />,
};

export const CALL_RESULT_CLASSES: { [key in PhoneCallResultEnum]: string } = {
  CALL_LATER: 'text-sky-100 bg-sky-800',
  CLIENT_NOT_INTERESTED: 'text-red-100 bg-red-800',
  NOT_ANSWERED: 'text-gray-100 bg-gray-800',
  CLIENT_CONVERTED: 'text-emerald-100 bg-emerald-800',
  CLIENT_BUSY: 'text-amber-100 bg-amber-800',
  COMMISSION_FREE: 'text-purple-100 bg-purple-800',
  OFFER_SENT: 'text-teal-100 bg-teal-800',
};

export const CALL_RESULT_COLOURS: { [key in PhoneCallResultEnum]: string } = {
  CALL_LATER: '#0ea5e9',
  CLIENT_NOT_INTERESTED: '#ef4444',
  NOT_ANSWERED: '#737373',
  CLIENT_CONVERTED: '#10b981',
  CLIENT_BUSY: '#f59e0b',
  COMMISSION_FREE: '#a855f7',
  OFFER_SENT: '#14b8a6',
};

export const CONTACT_RESOLUTION = [
  PhoneCallResultEnum.CLIENT_CONVERTED,
  PhoneCallResultEnum.OFFER_SENT,
  PhoneCallResultEnum.COMMISSION_FREE,
  PhoneCallResultEnum.CALL_LATER,
  PhoneCallResultEnum.NOT_ANSWERED,
  PhoneCallResultEnum.CLIENT_NOT_INTERESTED,
];
