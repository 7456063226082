import { Menu } from '@headlessui/react';
import React from 'react';
import { classNames } from '../../../../style/class-names';
import { useTranslation } from 'react-i18next';
import { useAssignOffer } from '../../../../hooks/use-assign-offer/use-assign-offer';
import { UserGroupIcon } from '@heroicons/react/20/solid';

interface Props {
  offerId: string;
}
const AssignAction = ({ offerId }: Props) => {
  const { t } = useTranslation('common');
  const { assignOffer } = useAssignOffer();

  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={() => assignOffer({ offerId })}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'flex w-full px-4 py-2 text-sm',
            )}
          >
            <UserGroupIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              )}
            >
              {t('common:offerMenu.assign')}
            </span>
          </button>
        )}
      </Menu.Item>
    </>
  );
};

export default AssignAction;
