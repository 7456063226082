interface Props {
  mainColor?: string;
  className?: string;
}

const FolderSvg = ({ mainColor = '#4f46e5', className = '' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      className={className}
      viewBox="0 0 865.67004 576.44344"
    >
      <path
        d="M716.98158,481.91549a11.79841,11.79841,0,0,0,9.17288-13.77266,11.5925,11.5925,0,0,0-.56729-1.96845l31.47108-27.12907-20.57508-5.82065-26.73111,26.65459a11.84062,11.84062,0,0,0-5.66184,15.631,11.52872,11.52872,0,0,0,12.89128,6.40523Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#ffb6b6"
      />
      <path
        d="M921.34506,674.30605l1.14386-25.71947a83.066,83.066,0,0,1,38.74474-9.80778c-18.60844,15.21376-16.28294,44.54069-28.899,64.99961a49.96435,49.96435,0,0,1-36.63939,23.08922L880.123,736.402a83.72449,83.72449,0,0,1,17.647-67.84511A80.87311,80.87311,0,0,1,912.633,654.74678C916.36256,664.58091,921.34506,674.30605,921.34506,674.30605Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#f2f2f2"
      />
      <polygon
        points="714.057 561.388 699.956 561.387 693.248 507 714.059 507.001 714.057 561.388"
        fill="#ffb6b6"
      />
      <path
        d="M884.81747,736.83421l-45.46566-.00168v-.57507a17.69746,17.69746,0,0,1,17.69651-17.69622h.00112l8.3049-6.30054,15.4951,6.3015,3.96888.00016Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#2f2e41"
      />
      <polygon
        points="648.548 561.388 634.448 561.387 627.74 507 648.551 507.001 648.548 561.388"
        fill="#ffb6b6"
      />
      <path
        d="M819.30945,736.83421l-45.46566-.00168v-.57507a17.69744,17.69744,0,0,1,17.6965-17.69622h.00112l8.30491-6.30054,15.4951,6.3015,3.96887.00016Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#2f2e41"
      />
      <path
        d="M809.61006,425.82623l91.71538,2.29289c17.41873,9.461,17.68323,29.93793,11.97593,54.24993,0,0,1.92669,14.45029-1.92672,18.3037s-5.78012,3.85342-3.85342,10.59689-8.253,17.75756-7.01655,18.994,4.12649,7.9799,4.12649,7.9799l-9.63353,54.91114s-6.74347,95.372-8.67018,97.29868-3.85341,0-1.92671,4.81677,3.85342,2.89006,1.92671,4.81677a70.94945,70.94945,0,0,0-4.81677,5.78012H858.21954s-1.756-9.63354-1.756-10.59689-1.92671-6.74347-1.92671-7.70683,1.70294-2.66628,1.70294-2.66628a31.6937,31.6937,0,0,0,1.18712-6.00389c0-1.92671,5.78012-76.10492,5.78012-76.10492l-11.56024-96.33533L823.7095,600.8615s0,82.84838-1.92671,84.77509-1.92671.96335-.96335,4.81676,4.81676,2.89006,1.9267,5.78012-3.85341-1.9267-2.89006,2.89006l.96336,4.81677-28.9006.41341s-3.85342-8.12024-1.92671-11.0103,1.8111-2.10117-.53948-6.8307-3.31393-5.69289-2.35058-6.65624.96335-6.08733.96335-6.08733l4.81677-92.17471s.96335-97.29868.96335-100.18874a9.60908,9.60908,0,0,0-.80026-4.3339v-3.92288l3.69032-13.90035Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#2f2e41"
      />
      <path
        d="M715.92132,450.62325l15.5,12.5,84.5-65.5-5-114h0a46.03861,46.03861,0,0,0-17.02258,31.10257l-14.47736,64.39743Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M795.92132,454.62325c29.91065,3.82254,57.46869-7.75858,83.50018-16.5,11.75165-3.9462,23.59021.17343,34.49988-6.5-4.71759-46.46695-.1372-98.808,9.88373-154.58624a13.21728,13.21728,0,0,0-8.98633-14.94638l-32.29116-9.2644-9.94172-10.96751L846.909,248.3769l-8.98765,16.24635-30.88769,20.87009a11.29194,11.29194,0,0,0-4.80756,11.28048A370.22031,370.22031,0,0,1,795.92132,454.62325Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M891.38192,469.005a11.79841,11.79841,0,0,0,5.06679-15.75295,11.5916,11.5916,0,0,0-1.08288-1.739l22.87441-34.68684-21.38255.01435-18.44377,32.937a11.84063,11.84063,0,0,0-1.18191,16.58276,11.52872,11.52872,0,0,0,14.14984,2.64466Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#ffb6b6"
      />
      <path
        d="M884.92132,436.62325l14,18,49-73-4.23791-74.16342a56.0961,56.0961,0,0,0-28.76209-45.83655h0l1,114Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <circle cx="682.57563" cy="44.50177" r="31.33548" fill="#ffb6b6" />
      <path
        d="M829.77509,187.89a14.80718,14.80718,0,0,1,3.593.47152,51.13867,51.13867,0,0,0,7.03333,1.1794,32.59027,32.59027,0,0,1,29.18165,27.64337c1.82081-1.58751,5.96412-4.50771,9.36014-1.79372h0c.0517.04308.08187.06524.20128.00861,2.2554-1.06983,6.259-14.239,6.38024-25.83425.064-6.15432-.90487-14.01312-5.88225-16.50181l-.26223-.13112-.06894-.285c-.3324-1.38438-4.04789-3.7475-10.50507-5.58678-11.735-3.34247-29.22732-9.85366-39.07744-.27131-.74544,3.23044-3.17061,10.14359-5.62052,10.92719-2.71275.86732-5.05555,1.61583-5.42489,4.94353a101.999,101.999,0,0,0,.0671,14.21933,14.52178,14.52178,0,0,1,5.159-7.24693A10.22518,10.22518,0,0,1,829.77509,187.89Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#2f2e41"
      />
      <path
        d="M1032.835,737.03172a1.18647,1.18647,0,0,1-1.19006,1.19H168.355a1.19,1.19,0,0,1,0-2.38h863.29A1.18651,1.18651,0,0,1,1032.835,737.03172Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#ccc"
      />
      <path
        d="M524.442,262.90072,406.8168,289.36394a12.20139,12.20139,0,0,0-9.215,14.56508l32.25406,143.36459,138.20269-31.09275a6.05643,6.05643,0,0,0,2.99313-1.65173L539.00708,272.11575A12.20141,12.20141,0,0,0,524.442,262.90072Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M481.71486,282.64153l-64.46158,14.50252a11.11125,11.11125,0,0,0-8.39446,13.26082l30.325,134.79013,122.744-27.61485-22.08437-98.16179A48.695,48.695,0,0,0,481.71486,282.64153Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#fff"
      />
      <path
        d="M538.32333,410.31393l-80.92146,18.20566a2.70338,2.70338,0,1,0,1.18675,5.2749l80.92146-18.20565a2.70339,2.70339,0,1,0-1.18675-5.27491Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M535.97648,399.88253,455.055,418.08818a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.28029Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M487.78753,399.7703l-35.07815,7.89186a2.70337,2.70337,0,1,0,1.18674,5.2749l35.07816-7.89185a2.70339,2.70339,0,1,0-1.18675-5.27491Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M528.71675,367.61405l-80.92147,18.20566a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.2803Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M526.37111,357.188l-80.92147,18.20566a2.70338,2.70338,0,1,0,1.18675,5.2749l80.92146-18.20565a2.70338,2.70338,0,1,0-1.18674-5.27491Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M478.18094,357.07042l-35.07815,7.89186a2.70614,2.70614,0,0,0,1.188,5.28029l35.07815-7.89186a2.70614,2.70614,0,1,0-1.188-5.28029Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M519.11016,324.91417l-80.92147,18.20566a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.2803Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M516.76452,314.48815l-80.92147,18.20566a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.2803Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M468.57435,314.37054,433.4962,322.2624a2.70614,2.70614,0,0,0,1.188,5.28029l35.07815-7.89186a2.70614,2.70614,0,1,0-1.188-5.28029Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M289.77873,429.24128a2.89533,2.89533,0,0,0-2.89209,2.89209V731.1807a2.89574,2.89574,0,0,0,2.89209,2.89258h374.9458a2.89537,2.89537,0,0,0,2.8916-2.89258V480.13679a2.89534,2.89534,0,0,0-2.8916-2.89258H449.5302a8.76523,8.76523,0,0,1-5.90674-2.28223l-48.37891-43.95752a6.77,6.77,0,0,0-4.5625-1.76318Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#3f3d56"
      />
      <path
        d="M737.24238,351.02466,536.47946,264.71345a22.11557,22.11557,0,0,0-29.0187,11.56928l-105.19833,244.695,235.8847,101.41061a10.97749,10.97749,0,0,0,6.14956.76065l104.515-243.10564A22.11562,22.11562,0,0,0,737.24238,351.02466Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M653.5401,334.53467,543.51692,287.23394a20.13964,20.13964,0,0,0-26.4269,10.5284L418.18347,527.82247l209.49968,90.06727L699.71249,450.347A88.26188,88.26188,0,0,0,653.5401,334.53467Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#fff"
      />
      <path
        d="M600.77576,582.11364l-138.117-59.3787a4.9,4.9,0,1,0-3.87063,9.00322l138.117,59.3787a4.9,4.9,0,1,0,3.87063-9.00322Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M608.43014,564.30928l-138.117-59.3787a4.905,4.905,0,0,0-3.87458,9.01242l138.117,59.3787a4.905,4.905,0,1,0,3.87459-9.01242Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M537.83508,512.87513,477.9636,487.13542a4.9,4.9,0,1,0-3.87063,9.00323l59.87148,25.73971a4.9,4.9,0,1,0,3.87063-9.00323Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M632.10815,509.23338l-138.117-59.3787a4.905,4.905,0,0,0-3.87459,9.01241l138.117,59.3787a4.905,4.905,0,1,0,3.87458-9.01241Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M639.75858,491.43822l-138.117-59.3787a4.9,4.9,0,1,0-3.87063,9.00322l138.117,59.3787a4.9,4.9,0,1,0,3.87063-9.00322Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M569.16748,439.99487,509.296,414.25516a4.905,4.905,0,0,0-3.87458,9.01242l59.87148,25.73971a4.905,4.905,0,1,0,3.87458-9.01242Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M663.44055,436.35311l-138.117-59.37869a4.905,4.905,0,0,0-3.87458,9.01241l138.117,59.3787a4.905,4.905,0,1,0,3.87458-9.01242Z"
        transform="translate(-167.16498 -161.77828)"
        fill={mainColor}
      />
      <path
        d="M671.091,418.558,532.974,359.17926a4.905,4.905,0,0,0-3.87459,9.01241l138.117,59.3787A4.905,4.905,0,1,0,671.091,418.558Z"
        transform="translate(-167.16498 -161.77828)"
        fill={mainColor}
      />
      <path
        d="M600.49987,367.11461,540.62839,341.3749a4.905,4.905,0,0,0-3.87458,9.01242L596.62529,376.127a4.905,4.905,0,1,0,3.87458-9.01242Z"
        transform="translate(-167.16498 -161.77828)"
        fill={mainColor}
      />
      <path
        d="M451.442,439.90072,333.8168,466.36394a12.20139,12.20139,0,0,0-9.215,14.56508l32.25406,143.36459,138.20269-31.09275a6.05643,6.05643,0,0,0,2.99313-1.65173L466.00708,449.11575A12.20141,12.20141,0,0,0,451.442,439.90072Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M408.71486,459.64153l-64.46158,14.50252a11.11125,11.11125,0,0,0-8.39446,13.26082l30.325,134.79013,122.74395-27.61485-22.08437-98.16179A48.695,48.695,0,0,0,408.71486,459.64153Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#fff"
      />
      <path
        d="M465.32333,587.31393l-80.92146,18.20566a2.70338,2.70338,0,1,0,1.18675,5.2749l80.92146-18.20565a2.70339,2.70339,0,1,0-1.18675-5.27491Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M462.97648,576.88253,382.055,595.08818a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.28029Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M414.78753,576.7703l-35.07815,7.89186a2.70337,2.70337,0,1,0,1.18674,5.2749l35.07816-7.89185a2.70339,2.70339,0,1,0-1.18675-5.27491Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M455.71675,544.61405l-80.92147,18.20566a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.2803Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M453.37111,534.188l-80.92147,18.20566a2.70338,2.70338,0,1,0,1.18675,5.2749l80.92146-18.20565a2.70338,2.70338,0,1,0-1.18674-5.27491Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M405.18094,534.07042l-35.07815,7.89186a2.70614,2.70614,0,0,0,1.188,5.28029l35.07815-7.89186a2.70614,2.70614,0,1,0-1.188-5.28029Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M446.11016,501.91417l-80.92147,18.20566a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.2803Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M443.76452,491.48815l-80.92147,18.20566a2.70614,2.70614,0,0,0,1.188,5.28029l80.92146-18.20565a2.70614,2.70614,0,1,0-1.188-5.2803Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M395.57435,491.37054,360.4962,499.2624a2.70614,2.70614,0,0,0,1.188,5.28029l35.07815-7.89186a2.70614,2.70614,0,1,0-1.188-5.28029Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e6e6e6"
      />
      <path
        d="M287.29436,507.78715a2.89166,2.89166,0,0,0-2.89222,2.89117c0,.10846.089,222.82666.089,222.82666a2.89026,2.89026,0,0,0,2.874,2.56836H690.13762a2.88958,2.88958,0,0,0,2.874-2.56836L718.082,511.003a2.89272,2.89272,0,0,0-.71679-2.251,2.85868,2.85868,0,0,0-2.15625-.96484Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#3f3d56"
      />
      <path
        d="M315.71659,520.11725a15.55483,15.55483,0,0,0-15.50879,15.374l.50293,172.60742A15.50883,15.50883,0,0,0,316.22,723.74322H571.89725A119.17426,119.17426,0,0,0,690.41043,617.83307l9.0669-80.46973a15.50838,15.50838,0,0,0-15.41162-17.24609Zm391.811,0-.0459-1h.0459Z"
        transform="translate(-167.16498 -161.77828)"
        fill={mainColor}
      />
      <path
        d="M427.39952,649.43435H328.3533a3.85894,3.85894,0,1,1,0-7.71789h99.04622C432.37608,641.647,432.42585,649.50444,427.39952,649.43435Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
      <path
        d="M560.8259,669.62041H328.3533a3.85894,3.85894,0,1,1,0-7.71789H560.8259C565.80245,661.83306,565.85223,669.6905,560.8259,669.62041Z"
        transform="translate(-167.16498 -161.77828)"
        fill="#e4e4e4"
      />
    </svg>
  );
};

export default FolderSvg;
