import { Menu } from '@headlessui/react';
import React from 'react';
import { classNames } from '../../../../style/class-names';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { triggerActivenessRefreshProcessForOffer } from '../../../../api/offers/offers';
import { ANALYTICS_EVENTS } from '../../../../constants/analytics';
import { AxiosError } from 'axios';
import { useToast } from '../../../../hooks/use-toast/use-toast';
import { useAnalytics } from '../../../../context/analytics/use-analytics';
import { OfferTypeEnum } from '../../../../api/api.types';
import { FlagIcon } from '@heroicons/react/24/solid';

interface Props {
  offerType: OfferTypeEnum;
  offerId: string;
}

const RefreshOfferActivityAction = ({ offerType, offerId }: Props) => {
  const analytics = useAnalytics();
  const { displaySuccessToast, displayDefaultErrorToasts } = useToast();
  const { t } = useTranslation('common');

  const { mutate: triggerActivenessRefresh } = useMutation(
    triggerActivenessRefreshProcessForOffer,
    {
      onSuccess: async () => {
        await analytics.track(
          ANALYTICS_EVENTS.OFFER_ACTIVENESS_REFRESH_TRIGGERED,
          {
            offerId,
            offerType,
          },
        );
        displaySuccessToast({
          title: t('common:offerActivenessRefresh.title'),
          body: t('common:offerActivenessRefresh.description'),
        });
      },
      onError: (e: AxiosError) => displayDefaultErrorToasts(e),
    },
  );

  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            onClick={() => triggerActivenessRefresh({ offerType, offerId })}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'flex w-full px-4 py-2 text-sm',
            )}
          >
            <FlagIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              )}
            >
              {t('common:offerMenu.refreshActivity')}
            </span>
          </button>
        )}
      </Menu.Item>
    </>
  );
};

export default RefreshOfferActivityAction;
