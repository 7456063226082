import { DEFAULT_MAP_CONFIG } from '../map/utils/default-map-config';
import { ALL_VARIANT_FIELD_NAMES } from './offer-variant-fields';

export const OFFER_VARIANT_FORM_DEFAULT_VALUES = {
  description_clean_text: '',
  title: '',
  location: {
    latitude: DEFAULT_MAP_CONFIG.center.lat,
    longitude: DEFAULT_MAP_CONFIG.center.lng,
    approximation: 0,
  },
  address_street: '',
  address_district: '',
  address: [],
  ...ALL_VARIANT_FIELD_NAMES.reduce((accumulator, field) => {
    return { ...accumulator, [field]: '' };
  }, {}),
};
