import React from 'react';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { useForm } from 'react-hook-form';

import Button from '../button/button';
import { postSendSms } from '../../api/sms/sms';
import SmsMessageInputLabeledController from '../inputs/sms-message-input/sms-message-input-labeled-controller';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { toast } from 'react-hot-toast';
import SuccessToast from '../toasts/success-toast';
import FFButton from '../button/ff-button';
import { FeatureFlag } from '../../api/api.types';

interface SmsForm {
  message: string;
}

const DEFAULT_STATE = {
  message: '',
};

interface Props {
  phoneNumber?: string;
  offerId: string;
  offerOriginalUrl?: string;
  close: () => void;
}

const ContactNumberSms = ({ phoneNumber, offerId, close }: Props) => {
  const analytics = useAnalytics();
  const { t } = useTranslation('common');

  const schema = React.useMemo(() => {
    const result = {
      message: yup.string().required(t('common:validation.string.empty')),
    } as any;

    return yup.object(result);
  }, [t]);

  const queryClient = useQueryClient();
  const { mutate, isLoading: isMutating } = useMutation(postSendSms, {
    onSuccess: async () => {
      toast.custom((toast) => (
        <SuccessToast
          {...toast}
          title={t('common:contact.smsSentToast.title')}
          body={t('common:contact.smsSentToast.body')}
        />
      ));
      await queryClient.invalidateQueries(['sms']);
      analytics.track(ANALYTICS_EVENTS.MANUAL_SMS_SENT, {
        phoneNumber,
        offerId,
      });
      close();
    },
    onError: (e: AxiosError) => defaultErrorToasts(e, t),
  });
  const { handleSubmit, control, reset } = useForm<SmsForm>({
    defaultValues: {
      ...DEFAULT_STATE,
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    reset({ ...DEFAULT_STATE });
  }, [reset, offerId]);

  const onSubmit = (form: SmsForm) => {
    mutate({
      body: {
        message: form.message,
        offer_id: offerId,
        receiver_phone_number: phoneNumber!,
      },
    });
  };
  return (
    <>
      <div className="mt-1 w-full justify-between">
        <form
          className="relative mt-6"
          onSubmit={handleSubmit(onSubmit)}
          id="sms-form"
        >
          <div className="mt-2">
            <span className="text-sm font-medium text-gray-700 ">
              {t('common:contact.smsDisclaimer.part1')}{' '}
              <span className="font-bold">
                {t('common:contact.smsDisclaimer.part2')}
              </span>
              . {t('common:contact.smsDisclaimer.part3')}
            </span>
          </div>
          <div className="mt-4">
            <SmsMessageInputLabeledController
              id="message"
              name="message"
              control={control}
              label={t('common:contact.form.smsMessage')}
              labelClassName="mb-1"
            />
          </div>
        </form>
      </div>
      <div className="mt-5 flex sm:mt-4">
        <Button
          type="button"
          variant="secondary"
          className="ml-auto mt-3 px-3 py-2"
          onClick={close}
        >
          {t('common:contact.form.cancel')}
        </Button>
        <FFButton
          type="submit"
          form="sms-form"
          variant="primary"
          className="ml-3 mt-3 px-3 py-2 "
          isLoading={isMutating}
          requiredFeatureFlags={[FeatureFlag.MANUAL_SMS_ENABLED]}
        >
          {t('common:contact.sendSms')}
        </FFButton>
      </div>
    </>
  );
};

export default ContactNumberSms;
