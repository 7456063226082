import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getClientSearchOffers,
  getClientSearchOffersCount,
} from '../../../../api/client-searches/client-searches';
import { classNames } from '../../../../style/class-names';
import {
  ClientSearchSelectedOfferListResponse,
  OfferSearchStateEnum,
} from '../../../../api/api.types';
import { stringifyUrl } from 'query-string';
import Pagination from '../../../../components/pagination/pagination';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from '../../../../hooks/use-search/use-search-params';
import OfferStateTableItem from './offer-state-table-item';
import OfferStateTableItemSkeleton from './offer-state-table-item-skeleton';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../../components/tabs/tabs';
import { useQueryParam } from '../../../../hooks/use-query-param/use-query-param';
import OfferStateTableEmpty from './offer-state-table-empty';

export enum OfferStateTableSteps {
  PRESELECTED = 'PRESELECTED',
  PREPARING = 'PREPARING',
  SHOWN = 'SHOWN',
  REJECTED = 'REJECTED',
}

const RECORDS_PER_PAGE = 35;
interface Props {
  clientSearchId: string;
}

const OfferStateTable = ({ clientSearchId }: Props) => {
  const { t } = useTranslation(['client-searches', 'common']);
  const tabs: {
    id: OfferStateTableSteps;
    title: string;
    states: OfferSearchStateEnum[];
  }[] = [
    {
      id: OfferStateTableSteps.PRESELECTED,
      title: t('client-searches:clientSearchDetails.menu.preselected'),
      states: [OfferSearchStateEnum.PRESELECTED],
    },
    {
      id: OfferStateTableSteps.PREPARING,
      title: t('client-searches:clientSearchDetails.menu.preparing'),
      states: [OfferSearchStateEnum.PREPARING],
    },
    {
      id: OfferStateTableSteps.SHOWN,
      title: t('client-searches:clientSearchDetails.menu.shownToClient'),
      states: [
        OfferSearchStateEnum.SHOWN_TO_CLIENT,
        OfferSearchStateEnum.ACCEPTED_BY_CLIENT,
        OfferSearchStateEnum.REJECTED_BY_CLIENT,
      ],
    },
    {
      id: OfferStateTableSteps.REJECTED,
      title: t('client-searches:clientSearchDetails.menu.rejected'),
      states: [OfferSearchStateEnum.REJECTED],
    },
  ];

  const location = useLocation();
  const { page = 1, tab } = useSearchParams<{
    page: number;
    tab?: string;
  }>();
  const queryParams = useQueryParam();
  const [selectedTabId, setSelectedTabId] = React.useState(
    isValidState(tab) ? tab : OfferStateTableSteps.PRESELECTED,
  );

  React.useEffect(() => {
    queryParams.upsert('tab', selectedTabId);
  }, [selectedTabId]);

  React.useEffect(() => {
    if (isValidState(tab) && tab !== selectedTabId) {
      setSelectedTabId(tab);
    }
  }, [tab]);

  const { data: offersCounts } = useQuery(
    ['clientSearchesOffers', clientSearchId, 'count'],
    () => getClientSearchOffersCount({ clientSearchId }),
  );

  const selectedTab = tabs.find((t) => t.id === selectedTabId) ?? tabs[1];

  const params = {
    clientSearchId,
    offerSearchState: selectedTab?.states?.[0],
    page: page,
    size: RECORDS_PER_PAGE,
  };

  const client = useQueryClient();
  const { data: offers, isLoading: offersLoading } = useQuery(
    ['clientSearchesOffers', clientSearchId, 'offers', params],
    () => getClientSearchOffers(params),
    { cacheTime: 0 },
  );

  const removeElementFromListImmediately = (id: string) => {
    const currentListData = client.getQueryData([
      'clientSearchesOffers',
      clientSearchId,
      'offers',
      params,
    ]) as ClientSearchSelectedOfferListResponse | undefined;

    if (currentListData) {
      const newListData = { ...currentListData };
      newListData.results = newListData.results.filter(
        (x) => x.client_search_selected_offer_id !== id,
      );
      client.setQueryData(
        ['clientSearchesOffers', clientSearchId, 'offers', params],
        newListData,
      );
    }
  };

  return (
    <main className="isolate py-4">
      <Tabs
        value={selectedTabId}
        onChange={setSelectedTabId as any}
        settings={{
          estimatedNavElWidth: 160,
        }}
        classNames={{
          navContainer: (type) =>
            type === 'list' ? 'border-b  border-gray-200' : '',
          contentContainer: '!pt-0',
          title: (type) =>
            type === 'list'
              ? '!text-lg font-semibold leading-6 text-gray-900'
              : '!text-base font-semibold leading-6 text-gray-900',
        }}
        title={t('client-searches:clientSearchDetails.sections.offers')}
        tabs={tabs.map(({ id, title, states }) => ({
          key: id,
          text: ({ isSelected, select, type, isActive }) =>
            type === 'select' ? (
              <span>
                {title}
                {offersCounts
                  ?.filter((c) => states.includes(c.state))
                  .reduce((a, b) => a + b.offers_count, 0) ? (
                  <span
                    className={classNames(
                      isSelected
                        ? 'bg-indigo-100 text-indigo-600'
                        : 'bg-gray-200 text-gray-900',
                      'ml-2 rounded-full px-1.5 py-0.5 text-xs',
                    )}
                  >
                    {offersCounts
                      .filter((c) => states.includes(c.state))
                      .reduce((a, b) => a + b.offers_count, 0)}
                  </span>
                ) : null}
                {isSelected && (
                  <span
                    className={classNames(
                      isActive ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </span>
            ) : (
              <button
                type="button"
                onClick={select}
                className={classNames(
                  isSelected
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                )}
              >
                <span>{title}</span>
                {offersCounts
                  ?.filter((c) => states.includes(c.state))
                  .reduce((a, b) => a + b.offers_count, 0) ? (
                  <span
                    className={classNames(
                      isSelected
                        ? 'bg-indigo-100 text-indigo-600'
                        : 'bg-gray-100 text-gray-900',
                      'ml-2 rounded-full px-2.5 py-0.5 text-xs font-medium',
                    )}
                  >
                    {offersCounts
                      .filter((c) => states.includes(c.state))
                      .reduce((a, b) => a + b.offers_count, 0)}
                  </span>
                ) : null}
              </button>
            ),
        }))}
      >
        {tabs.map(({ id }) => (
          <Tabs.Tab key={id}>
            <ul role="list" className="divide-y divide-gray-200 pt-4">
              {offersLoading &&
                [...Array(RECORDS_PER_PAGE).keys()].map((i) => (
                  <OfferStateTableItemSkeleton key={i} />
                ))}
              {offers?.results.map((selectedOffer) => (
                <OfferStateTableItem
                  key={selectedOffer.client_search_selected_offer_id}
                  selectedOffer={selectedOffer}
                  step={selectedTab.id}
                  removeElementFromListImmediately={
                    removeElementFromListImmediately
                  }
                />
              ))}
              {offers && offers.results.length === 0 && (
                <OfferStateTableEmpty step={selectedTab.id} />
              )}
            </ul>
            {offers && offers.results.length !== 0 && (
              <Pagination
                totalPages={offers.total_pages}
                basePath={stringifyUrl({
                  url: location.pathname,
                  query: { page, tab },
                })}
                page={offers.page}
                totalRecords={offers.total_items}
                recordsPerPage={RECORDS_PER_PAGE}
              />
            )}
          </Tabs.Tab>
        ))}
      </Tabs>
    </main>
  );
};

export default OfferStateTable;

const isValidState = (tab: any): tab is OfferStateTableSteps => {
  return Object.values(OfferStateTableSteps).includes(tab);
};
