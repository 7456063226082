import React from 'react';
import throttle from 'lodash/throttle';

export const useElementWidth = (
  elementRef: React.RefObject<HTMLElement>,
): number => {
  const [width, setWidth] = React.useState(getTotalWidth(elementRef));

  React.useEffect(() => {
    if (elementRef.current) {
      setWidth(getTotalWidth(elementRef));
    } else {
      setWidth(0);
    }
  }, [elementRef]);

  React.useEffect(() => {
    const update = throttle(() => setWidth(getTotalWidth(elementRef)));
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [elementRef]);

  return width;
};

function getTotalWidth(ref: React.RefObject<HTMLElement>): number {
  if (!ref.current) {
    return 0;
  }
  let style = window.getComputedStyle(ref.current);
  return ['width', 'padding-left', 'padding-right']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((prev, cur) => prev + cur);
}

export function getTotalWidthDOM(el?: HTMLElement): number {
  if (!el) {
    return 0;
  }
  let style = window.getComputedStyle(el);
  return ['width', 'padding-left', 'padding-right']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((prev, cur) => prev + cur);
}
