import React from 'react';
import { Menu } from '@headlessui/react';
import { classNames } from '../../../../style/class-names';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { useOfferPdfDownload } from '../../../../hooks/use-pdf-download/use-pdf-download';
import { OfferTypeEnum } from '../../../../api/api.types';
import { useTranslation } from 'react-i18next';

interface Props {
  offerId: string;
  offerTitle: string;
  offerType: OfferTypeEnum;
}

const DownloadAsPdfAction = ({ offerType, offerId, offerTitle }: Props) => {
  const { t } = useTranslation(['common']);
  const { download: downloadPdf } = useOfferPdfDownload({
    offerType,
    offerId,
    fileName: `${offerTitle?.replace(/\s/g, '_')}.pdf` ?? 'offer.pdf',
  });

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'relative flex w-full px-4 py-2 text-sm',
          )}
          onClick={downloadPdf}
        >
          <DocumentArrowDownIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            )}
          >
            {t('common:offerMenu.pdfDownload')}
          </span>
        </button>
      )}
    </Menu.Item>
  );
};

export default DownloadAsPdfAction;
