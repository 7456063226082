import React from 'react';
import PasswordInput, { TextInputProps } from './password-input';
import InputLabel, { InputLabelProps } from '../shared/input-label';

interface TextInputLabeledProps
  extends TextInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
}

const PasswordInputLabeled = ({
  className = '',
  label,
  labelClassName,
  id,
  ...rest
}: TextInputLabeledProps) => {
  return (
    <div className={className}>
      <InputLabel labelFor={id} label={label} labelClassName={labelClassName} />
      <PasswordInput {...rest} />
    </div>
  );
};

export default PasswordInputLabeled;
