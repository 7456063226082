import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UseAddToClientSearchState } from './use-add-to-cleint-search-state';
import { OfferSearchStateEnum } from '../../api/api.types';

interface Props {
  state: UseAddToClientSearchState;
  clientSearchId?: string | null;
}

export const useAddToClientSearchRedirect = ({
  state,
  clientSearchId,
}: Props) => {
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state === UseAddToClientSearchState.SUBMITTED && shouldRedirect) {
      navigate(
        `/client-searches/details/${clientSearchId}?tab=${OfferSearchStateEnum.PREPARING}`,
      );
    }
  }, [state]);

  return {
    value: shouldRedirect,
    set: setShouldRedirect,
    clear: () => setShouldRedirect(false),
  };
};

export type UseAddToClientSearchRedirectData = ReturnType<
  typeof useAddToClientSearchRedirect
>;
