import React from 'react';
import Button, { ButtonProps } from './button';
import { useUser } from '../../context/auth/use-user';
import ReactTooltip from 'react-tooltip';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { SubscriptionTierType } from '../../models/user';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  requiredSubscriptionTier: SubscriptionTierType;
}

/*
  Use this only when the user can actually see the button (enter the page) with lower subscription tier than the specific feature requires.
*/

const STButton = ({
  requiredSubscriptionTier,
  disabled,
  children,
  ...rest
}: Props) => {
  const user = useUser();
  const { t } = useTranslation('common');

  if (!requiredSubscriptionTier) {
    return <Button disabled={disabled} children={children} {...rest} />;
  }

  const isSubscriptionTierSufficient = user.hasSufficientSubscriptionTier(
    requiredSubscriptionTier,
  );

  const isActive = user.hasActiveSubscription();

  if (isSubscriptionTierSufficient && isActive) {
    return <Button disabled={disabled} children={children} {...rest} />;
  }

  return (
    <>
      <Button
        data-effect="solid"
        data-tip={
          isSubscriptionTierSufficient
            ? t('common:subscription.constrains.inactiveSubscription')
            : t('common:subscription.constrains.insufficientTier', {
                tier: t(`common:subscription.tier.${requiredSubscriptionTier}`),
              })
        }
        disabled
        data-for="disabledButtonBySubscription"
        {...rest}
      >
        <div className="absolute -right-2 -top-2 flex items-center justify-center text-white">
          <LockClosedIcon
            className={`h-6 w-6 rounded-full bg-indigo-500 p-1`}
          />
        </div>
        {children}
      </Button>
      <ReactTooltip id="disabledButtonBySubscription" />
    </>
  );
};

export default STButton;
