import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getNullableEnumField } from './helpers';
import { OfferTypeEnum } from '../api/api.types';
import { schemaHasField } from '../schemas/schemas';
import { Address } from '../models/address';

export enum OfferTypeFieldKeys {
  OFFER_TYPE = 'offer_type',
}

export interface OfferTypeFieldTypes {
  [OfferTypeFieldKeys.OFFER_TYPE]: OfferTypeEnum | null;
}

export const useGetOfferTypeFields = () => {
  const { t } = useTranslation(['validation']);
  return {
    [OfferTypeFieldKeys.OFFER_TYPE]: getNullableEnumField({
      enumObject: OfferTypeEnum,
      typeError: t('validation:typeEnum', {
        values: Object.keys(OfferTypeEnum).join(', '),
      }),
    }),
  };
};

export enum OfferLocationFieldKeys {
  ADDRESS = 'address',
  ADDRESS_DISTRICT = 'address_district',
  ADDRESS_STREET = 'address_street',
  ADDRESS_STREET_NUMBER = 'address_street_number',
  LOCATION = 'location',
}

export interface OfferLocationFieldTypes {
  [OfferLocationFieldKeys.ADDRESS]: { label: string; value: Address }[];
  [OfferLocationFieldKeys.ADDRESS_DISTRICT]: string | null;
  [OfferLocationFieldKeys.ADDRESS_STREET]: string | null;
  [OfferLocationFieldKeys.ADDRESS_STREET_NUMBER]: string | null;
  [OfferLocationFieldKeys.LOCATION]: {
    latitude: number | null;
    longitude: number | null;
    approximation: number | null;
  };
}

export const useGetOfferLocationFields = () => {
  return ({
    exclude,
    mappings,
  }: {
    exclude?: OfferLocationFieldKeys[];
    mappings?: Partial<
      Record<OfferLocationFieldKeys, (schema: Yup.AnySchema) => Yup.AnySchema>
    >;
  } = {}) =>
    Object.entries({
      [OfferLocationFieldKeys.ADDRESS]: Yup.array(),
      [OfferLocationFieldKeys.ADDRESS_DISTRICT]: Yup.string(),
      [OfferLocationFieldKeys.ADDRESS_STREET]: Yup.string(),
      [OfferLocationFieldKeys.LOCATION]: Yup.object({
        latitude: Yup.number().nullable(),
        longitude: Yup.number().nullable(),
        approximation: Yup.number().nullable(),
      }),
    }).reduce((acc, [key, value]) => {
      const typedKey = key as OfferLocationFieldKeys;

      if (!(exclude ?? []).includes(typedKey)) {
        acc[typedKey] =
          typedKey in (mappings ?? {}) ? mappings?.[typedKey]?.(value) : value;
      }
      return acc;
    }, {} as Record<OfferLocationFieldKeys, any>);
};

export const useGetOfferDescriptionFields = () => {
  const { t } = useTranslation(['validation']);
  return {
    title: Yup.string().required(t('validation:required')),
    description: Yup.string().required(t('validation:required')),
  };
};

export enum OfferParametersFieldKeys {
  PRICE = 'price',
  PROPERTY_SIZE = 'property_size',
  RENT = 'rent',
  GARDEN_SIZE = 'garden_size',
  ROOMS = 'rooms',
  MARKET_TYPE = 'market_type',
  BUILDING_TYPE = 'building_type',
  HOUSE_TYPE = 'house_type',
  KITCHEN_TYPE = 'kitchen_type',
  ATTIC = 'attic',
  BUILDING_STAGE = 'building_stage',
  BUILDING_MATERIAL = 'building_material',
  ROOF_MATERIAL = 'roof_material',
  WINDOWS_MATERIAL = 'windows_material',
  YEAR_BUILT = 'year_built',
  FLOOR_NO_LOWER_BOUND = 'floor_no_lower_bound',
  TOTAL_LEVELS_IN_BUILDING_LOWER_BOUND = 'total_levels_in_building_lower_bound',
  LEVELS = 'levels',
  BATHROOM_COUNT = 'bathroom_count',
  TO_BE_RENOVATED = 'to_be_renovated',
  ACCESS_ROAD = 'access_road',
  FENCE = 'fence',
  LAND_TYPE = 'land_type',
  LAND_SHAPE = 'land_shape',
  LAND_WIDTH = 'land_width',
  LAND_LENGTH = 'land_length',
  HAS_LAND_AND_MORTGAGE_REGISTER = 'has_land_and_mortgage_register',
  OWNERSHIP_TYPE = 'ownership_type',
  HAS_CABLE_TELEPHONE_ACCESS = 'has_cable_telephone_access',
  HAS_CABLE_TELEVISION_ACCESS = 'has_cable_television_access',
  HAS_CABLE_INTERNET_ACCESS = 'has_cable_internet_access',
  HAS_ELECTRICITY_ACCESS = 'has_electricity_access',
  HAS_GAS_ACCESS = 'has_gas_access',
  HAS_HIGH_VOLTAGE_PLUG = 'has_high_voltage_plug',
  HEATED_WATER = 'heated_water',
  SEWAGE = 'sewage',
  WATER_ACCESS = 'water_access',
  HEATING = 'heating',
  HAS_BALCONY = 'has_balcony',
  HAS_TERRACE = 'has_terrace',
  HAS_BASEMENT = 'has_basement',
  HAS_GARAGE = 'has_garage',
  HAS_ELEVATOR = 'has_elevator',
  IS_FURNISHED = 'is_furnished',
  HAS_AIR_CONDITIONING = 'has_air_conditioning',
  IS_GATED_COMMUNITY = 'is_gated_community',
  HAS_ANTI_THEFT_PROTECTION = 'has_anti_theft_protection',
  PARKING = 'parking',
  VIRTUAL_WALK_URL = 'virtual_walk_url',
  FILM_URL = 'film_url',
}

export interface OfferParametersFieldTypes {
  [OfferParametersFieldKeys.PRICE]: number;
  [OfferParametersFieldKeys.PROPERTY_SIZE]: number | null;
  [OfferParametersFieldKeys.RENT]: number | null;
  [OfferParametersFieldKeys.GARDEN_SIZE]: number | null;
  [OfferParametersFieldKeys.ROOMS]: number | null;
  [OfferParametersFieldKeys.MARKET_TYPE]: string;
  [OfferParametersFieldKeys.BUILDING_TYPE]: string;
  [OfferParametersFieldKeys.HOUSE_TYPE]: string;
  [OfferParametersFieldKeys.KITCHEN_TYPE]: string;
  [OfferParametersFieldKeys.ATTIC]: string;
  [OfferParametersFieldKeys.BUILDING_STAGE]: string;
  [OfferParametersFieldKeys.BUILDING_MATERIAL]: string;
  [OfferParametersFieldKeys.ROOF_MATERIAL]: string;
  [OfferParametersFieldKeys.WINDOWS_MATERIAL]: string;
  [OfferParametersFieldKeys.YEAR_BUILT]: number | null;
  [OfferParametersFieldKeys.FLOOR_NO_LOWER_BOUND]: number | null;
  [OfferParametersFieldKeys.TOTAL_LEVELS_IN_BUILDING_LOWER_BOUND]:
    | number
    | null;
  [OfferParametersFieldKeys.LEVELS]: number | null;
  [OfferParametersFieldKeys.BATHROOM_COUNT]: number | null;
  [OfferParametersFieldKeys.TO_BE_RENOVATED]: boolean | null;
  [OfferParametersFieldKeys.ACCESS_ROAD]: string;
  [OfferParametersFieldKeys.FENCE]: string;
  [OfferParametersFieldKeys.LAND_TYPE]: string;
  [OfferParametersFieldKeys.LAND_SHAPE]: string;
  [OfferParametersFieldKeys.LAND_WIDTH]: number | null;
  [OfferParametersFieldKeys.LAND_LENGTH]: number | null;
  [OfferParametersFieldKeys.HAS_LAND_AND_MORTGAGE_REGISTER]: boolean | null;
  [OfferParametersFieldKeys.OWNERSHIP_TYPE]: string;
  [OfferParametersFieldKeys.HAS_CABLE_TELEPHONE_ACCESS]: boolean | null;
  [OfferParametersFieldKeys.HAS_CABLE_TELEVISION_ACCESS]: boolean | null;
  [OfferParametersFieldKeys.HAS_CABLE_INTERNET_ACCESS]: boolean | null;
  [OfferParametersFieldKeys.HAS_ELECTRICITY_ACCESS]: boolean | null;
  [OfferParametersFieldKeys.HAS_GAS_ACCESS]: boolean | null;
  [OfferParametersFieldKeys.HAS_HIGH_VOLTAGE_PLUG]: boolean | null;
  [OfferParametersFieldKeys.HEATED_WATER]: string;
  [OfferParametersFieldKeys.SEWAGE]: string;
  [OfferParametersFieldKeys.WATER_ACCESS]: string;
  [OfferParametersFieldKeys.HEATING]: string;
  [OfferParametersFieldKeys.HAS_BALCONY]: boolean | null;
  [OfferParametersFieldKeys.HAS_TERRACE]: boolean | null;
  [OfferParametersFieldKeys.HAS_BASEMENT]: boolean | null;
  [OfferParametersFieldKeys.HAS_GARAGE]: boolean | null;
  [OfferParametersFieldKeys.HAS_ELEVATOR]: boolean | null;
  [OfferParametersFieldKeys.IS_FURNISHED]: boolean | null;
  [OfferParametersFieldKeys.HAS_AIR_CONDITIONING]: boolean | null;
  [OfferParametersFieldKeys.IS_GATED_COMMUNITY]: boolean | null;
  [OfferParametersFieldKeys.HAS_ANTI_THEFT_PROTECTION]: boolean | null;
  [OfferParametersFieldKeys.PARKING]: string;
  [OfferParametersFieldKeys.VIRTUAL_WALK_URL]: string | null;
}

export const useGetOfferParametersFields = () => {
  const { t } = useTranslation(['validation']);
  return ({
    offerType,
    exclude,
    mappings,
  }: {
    offerType: OfferTypeEnum;
    exclude?: OfferParametersFieldKeys[];
    mappings?: Partial<
      Record<OfferParametersFieldKeys, (schema: Yup.AnySchema) => Yup.AnySchema>
    >;
  }) =>
    Object.entries({
      [OfferParametersFieldKeys.PRICE]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .min(0, t('validation:notNegativeNumber')),
      [OfferParametersFieldKeys.PROPERTY_SIZE]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .positive(t('validation:positiveNumber')),
      [OfferParametersFieldKeys.RENT]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .min(0, t('validation:notNegativeNumber')),
      [OfferParametersFieldKeys.GARDEN_SIZE]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .min(0, t('validation:notNegativeNumber')),
      [OfferParametersFieldKeys.ROOMS]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .positive(t('validation:positiveNumber')),
      [OfferParametersFieldKeys.MARKET_TYPE]: Yup.string(),
      [OfferParametersFieldKeys.BUILDING_TYPE]: Yup.string(),
      [OfferParametersFieldKeys.HOUSE_TYPE]: Yup.string(),
      [OfferParametersFieldKeys.KITCHEN_TYPE]: Yup.string(),
      [OfferParametersFieldKeys.ATTIC]: Yup.string(),
      [OfferParametersFieldKeys.BUILDING_STAGE]: Yup.string(),
      [OfferParametersFieldKeys.BUILDING_MATERIAL]: Yup.string(),
      [OfferParametersFieldKeys.ROOF_MATERIAL]: Yup.string(),
      [OfferParametersFieldKeys.WINDOWS_MATERIAL]: Yup.string(),
      [OfferParametersFieldKeys.YEAR_BUILT]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber')),
      [OfferParametersFieldKeys.FLOOR_NO_LOWER_BOUND]: Yup.number()
        .typeError(t('validation:typeNumber'))
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.TOTAL_LEVELS_IN_BUILDING_LOWER_BOUND]:
        Yup.number()
          .typeError(t('validation:typeNumber'))
          .nullable()
          .transform((autoTransformed, original) =>
            original === '' ? null : autoTransformed,
          ),
      [OfferParametersFieldKeys.LEVELS]: Yup.number()
        .typeError(t('validation:typeNumber'))
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.BATHROOM_COUNT]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .min(0, t('validation:notNegativeNumber')),
      [OfferParametersFieldKeys.TO_BE_RENOVATED]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.ACCESS_ROAD]: Yup.string(),
      [OfferParametersFieldKeys.FENCE]: Yup.string(),
      [OfferParametersFieldKeys.LAND_TYPE]: Yup.string(),
      [OfferParametersFieldKeys.LAND_SHAPE]: Yup.string(),
      [OfferParametersFieldKeys.LAND_WIDTH]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .min(0, t('validation:notNegativeNumber')),
      [OfferParametersFieldKeys.LAND_LENGTH]: Yup.number()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        )
        .typeError(t('validation:typeNumber'))
        .min(0, t('validation:notNegativeNumber')),
      [OfferParametersFieldKeys.HAS_LAND_AND_MORTGAGE_REGISTER]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.OWNERSHIP_TYPE]: Yup.string(),
      [OfferParametersFieldKeys.HAS_CABLE_TELEPHONE_ACCESS]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_CABLE_TELEVISION_ACCESS]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_CABLE_INTERNET_ACCESS]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_ELECTRICITY_ACCESS]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_GAS_ACCESS]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_HIGH_VOLTAGE_PLUG]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HEATED_WATER]: Yup.string(),
      [OfferParametersFieldKeys.SEWAGE]: Yup.string(),
      [OfferParametersFieldKeys.WATER_ACCESS]: Yup.string(),
      [OfferParametersFieldKeys.HEATING]: Yup.string(),
      [OfferParametersFieldKeys.HAS_BALCONY]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_TERRACE]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_BASEMENT]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_GARAGE]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_ELEVATOR]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.IS_FURNISHED]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_AIR_CONDITIONING]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.IS_GATED_COMMUNITY]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.HAS_ANTI_THEFT_PROTECTION]: Yup.boolean()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [OfferParametersFieldKeys.PARKING]: Yup.string(),
      [OfferParametersFieldKeys.VIRTUAL_WALK_URL]: Yup.string().url(
        t('validation:invalidUrl'),
      ),
      [OfferParametersFieldKeys.FILM_URL]: Yup.string().url(
        t('validation:invalidUrl'),
      ),
    }).reduce((acc, [key, value]) => {
      const typedKey = key as OfferParametersFieldKeys;

      if (
        schemaHasField(offerType, key) &&
        !(exclude ?? []).includes(typedKey)
      ) {
        acc[typedKey] =
          typedKey in (mappings ?? {}) ? mappings?.[typedKey]?.(value) : value;
      }
      return acc;
    }, {} as Record<OfferParametersFieldKeys, any>);
};
