import React from 'react';

const OfferCardSkeleton = () => {
  return (
    <div className="my-2 flex flex flex-row flex-col rounded-xl border bg-white md:flex-row">
      <div className="relative overflow-hidden rounded-t-xl pb-64 pr-36 md:rounded-l-xl md:rounded-l-xl md:rounded-t-none md:pb-48  md:pr-64">
        <div className="absolute h-full w-full animate-pulse bg-gray-200 object-cover" />
      </div>
      <div className="relative w-full p-3">
        <div className="flex h-full flex-col">
          <div className="grid">
            <div className="row-start-1 mb-2 h-7 w-3/4 animate-pulse rounded bg-gray-200" />
          </div>
          <div className="mb-4 mt-2 flex flex-col space-y-2">
            <div className="h-3.5 animate-pulse rounded bg-gray-200" />
            <div className="h-3.5 animate-pulse rounded bg-gray-200" />
            <div className="h-3.5 animate-pulse rounded bg-gray-200" />
          </div>
          <div className="mt-auto h-4 w-1/2 animate-pulse rounded bg-gray-200" />
          <div className="flex flex-col">
            <div className="mt-2 flex">
              <div className="h-10 w-3/4 animate-pulse rounded bg-gray-200 md:h-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferCardSkeleton;
