import React from 'react';
import RequiredIcon from '../../explanaion-icons/required-icon';

export interface InputLabelProps {
  label: string;
  labelFor: string;
  labelClassName?: string;
  onClick?: () => void;
  isRequired?: boolean;
}

const InputLabel = ({
  label,
  labelFor,
  labelClassName = '',
  onClick,
  isRequired = false,
}: InputLabelProps) => {
  return (
    <label
      onClick={() => {
        onClick?.();
      }}
      htmlFor={labelFor}
      className={`flex items-center gap-1 text-sm font-medium text-gray-700 ${labelClassName}`}
    >
      <span>{label}</span>
      {isRequired && (
        <RequiredIcon size="xs" classNameWrapper="relative bottom-1" />
      )}
    </label>
  );
};

export default InputLabel;
