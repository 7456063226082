import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import { useUser } from '../../context/auth/use-user';
import InitialLoginModalForm from './initial-login-modal-form';
import CommunicationConsentModelForm from './communication-consent-model-form';

interface Props {
  children: React.ReactNode;
}

const InitialLoginModals = ({ children }: Props) => {
  const user = useUser();

  return user.hasEmptySettings ? (
    <React.Fragment>
      <Transition.Root show as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <InitialLoginModalForm />
          </div>
        </Dialog>
      </Transition.Root>
      {children}
    </React.Fragment>
  ) : !user.communicationConsents.isAppConsentGiven ? (
    <React.Fragment>
      <Transition.Root show as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <CommunicationConsentModelForm />
          </div>
        </Dialog>
      </Transition.Root>
      {children}
    </React.Fragment>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default InitialLoginModals;
