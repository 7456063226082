import { axios } from '../axios';
import {
  AddCallSummaryRequest,
  DetailedPhoneCallSummaryCountResult,
  DetailedPhoneCallSummaryResponse,
  PhoneCallResponse,
  PhoneCallSummaryResponse,
} from '../api.types';

export const getPhoneCalls = ({
  phoneNumber,
}: {
  phoneNumber?: string;
} = {}): Promise<PhoneCallResponse[]> => {
  return axios
    .get('/phone-calls', { params: { phone_number: phoneNumber } })
    .then((resp) => resp.data);
};

export const getPhoneCallsForOrganization = ({
  dateFrom,
  dateTo,
  userId,
}: {
  dateFrom?: Date;
  dateTo?: Date;
  userId?: string | null;
} = {}): Promise<PhoneCallResponse[]> => {
  return axios
    .get('/phone-calls/organization', {
      params: { date_from: dateFrom, date_to: dateTo, user_id: userId },
    })
    .then((resp) => resp.data);
};

export const getCallSummariesForOrganization = ({
  dateFrom,
  dateTo,
  userId,
  teamId,
}: {
  dateFrom?: Date;
  dateTo?: Date;
  userId?: string | null;
  teamId?: string | null;
} = {}): Promise<PhoneCallSummaryResponse[]> => {
  return axios
    .get('/phone-call-summaries/organization', {
      params: {
        date_from: dateFrom,
        date_to: dateTo,
        user_id: userId,
        team_id: teamId,
      },
    })
    .then((resp) => resp.data);
};

export const getActivePhoneCalls = (): Promise<PhoneCallResponse[]> => {
  return axios.get('/phone-calls/active').then((resp) => resp.data);
};

export const postPhoneCallSummary = ({
  body,
}: {
  body: AddCallSummaryRequest;
}): Promise<void> => {
  return axios.post('phone-call-summary', body);
};

export const getCallSummariesForOffer = ({
  offerId,
}: {
  offerId: string;
}): Promise<DetailedPhoneCallSummaryResponse[]> => {
  return axios
    .get(`/phone-call-summaries/${offerId}`)
    .then((resp) => resp.data);
};

export const getCallSummariesForNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<DetailedPhoneCallSummaryResponse[]> => {
  return axios
    .get(`/phone-call-summaries-for-number/${phoneNumber}`)
    .then((resp) => resp.data);
};

export const getCallSummariesCountForNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<DetailedPhoneCallSummaryCountResult> => {
  return axios
    .get(`/phone-call-summaries-for-number/${phoneNumber}/count`)
    .then((resp) => resp.data);
};
