import {
  GiAncientRuins,
  GiArchiveRegister,
  GiBrickWall,
  GiFactory,
  GiForest,
  GiGasStove,
  GiGate,
  GiHeatHaze,
  GiHomeGarage,
  GiModernCity,
  GiOfficeChair,
  GiPieChart,
  GiReceiveMoney,
  GiWindow,
  GiWoodenChair,
} from 'react-icons/gi';
import {
  MdAccessTimeFilled,
  MdApartment,
  MdBalcony,
  MdCable,
  MdContactPhone,
  MdDescription,
  MdElevator,
  MdFence,
  MdGarage,
  MdKitchen,
  MdMeetingRoom,
  MdMyLocation,
  MdOtherHouses,
  MdOutlineBalcony,
  MdOutlineElectricalServices,
  MdOutlineHolidayVillage,
  MdOutlineLocationCity,
  MdOutlineRoofing,
  MdOutlineSecurity,
  MdPriceChange,
  MdPriceCheck,
  MdRealEstateAgent,
  MdRoofing,
  MdTexture,
  MdTitle,
  MdWaterDamage,
  MdWorkspacePremium,
} from 'react-icons/md';
import { ImCalendar, ImListNumbered, ImPriceTag, ImRoad } from 'react-icons/im';
import {
  BsBuilding,
  BsFillTelephoneFill,
  BsInputCursorText,
  BsPersonBadgeFill,
  BsPersonFill,
  BsSortNumericDownAlt,
  BsSortNumericUpAlt,
} from 'react-icons/bs';
import {
  FaBath,
  FaHistory,
  FaParking,
  FaPlug,
  FaSignature,
  FaTemperatureLow,
  FaWarehouse,
} from 'react-icons/fa';
import {
  IoMdConstruct,
  IoMdHome,
  IoMdPhotos,
  IoMdResize,
} from 'react-icons/io';
import {
  RiAlignBottom,
  RiAuctionFill,
  RiCommunityLine,
  RiPriceTag2Fill,
} from 'react-icons/ri';
import {
  AiFillFlag,
  AiFillShop,
  AiOutlineColumnHeight,
  AiOutlineColumnWidth,
  AiOutlineFieldNumber,
  AiOutlineFlag,
  AiOutlineLink,
  AiOutlineToTop,
  AiTwotoneShop,
} from 'react-icons/ai';
import {
  BiEqualizer,
  BiShapeTriangle,
  BiTag,
  BiTimeFive,
} from 'react-icons/bi';
import {
  IoCodeWorking,
  IoGlobeOutline,
  IoInformationCircleSharp,
  IoPeople,
  IoTvSharp,
  IoWater,
  IoWaterOutline,
} from 'react-icons/io5';
import { GrCurrency, GrFormView } from 'react-icons/gr';
import { VscTypeHierarchySub } from 'react-icons/vsc';
import { GoLaw } from 'react-icons/go';
import { CgTrees } from 'react-icons/cg';
import { HiOutlineLocationMarker } from 'react-icons/hi';

export const ICONS = {
  is_furnished: GiWoodenChair,
  has_elevator: MdElevator,
  year_built: ImCalendar,
  heating: GiHeatHaze,
  rooms: MdMeetingRoom,
  building_type: BsBuilding,
  windows_material: GiWindow,
  parking: FaParking,
  building_stage: IoMdConstruct,
  building_material: GiBrickWall,
  roof_material: MdOutlineRoofing,
  kitchen_type: MdKitchen,
  floor_no_lower_bound: BsSortNumericUpAlt,
  floor_no_upper_bound: BsSortNumericDownAlt,
  total_levels_in_building_lower_bound: BsSortNumericUpAlt,
  total_levels_in_building_upper_bound: BsSortNumericDownAlt,
  additional_fees: MdPriceChange,
  deposit: MdPriceCheck,
  administrative_rent: MdPriceChange,
  bathroom_count: FaBath,
  levels: ImListNumbered,
  has_basement: RiAlignBottom,
  has_balcony: MdBalcony,
  has_terrace: MdOutlineBalcony,
  is_gated_community: GiGate,
  to_be_renovated: GiAncientRuins,
  interior_height: AiOutlineColumnHeight,
  has_air_conditioning: FaTemperatureLow,
  has_anti_theft_protection: MdOutlineSecurity,
  apartment_rents: MdApartment,
  market_type: MdOtherHouses,
  rent: MdPriceChange,
  planned_completion: BiTimeFive,
  apartment_sales: MdApartment,
  offer_type: MdRealEstateAgent,
  date_scraped: MdAccessTimeFilled,
  date_last_seen: MdAccessTimeFilled,
  date_posted: MdAccessTimeFilled,
  date_modified: MdAccessTimeFilled,
  portal_offer_id: IoInformationCircleSharp,
  agency_offer_id: IoInformationCircleSharp,
  issuer_type: BsPersonBadgeFill,
  is_premium_offer: AiOutlineToTop,
  view_count: GrFormView,
  title: MdTitle,
  description: MdDescription,
  price: ImPriceTag,
  price_currency: GrCurrency,
  offer_original_url: AiOutlineLink,
  address_raw: BsInputCursorText,
  address_state: AiOutlineFlag,
  address_county: VscTypeHierarchySub,
  address_community: RiCommunityLine,
  address_city: GiModernCity,
  address_country: AiFillFlag,
  address_district: MdOutlineLocationCity,
  address_street: ImRoad,
  address_street_number: AiOutlineFieldNumber,
  address_postal_code: IoCodeWorking,
  location_longitude: IoGlobeOutline,
  location_latitude: IoGlobeOutline,
  location_approximation: MdMyLocation,
  property_size: IoMdResize,
  ownership_type: GoLaw,
  has_land_and_mortgage_register: GiArchiveRegister,
  water_access: MdWaterDamage,
  sewage: IoWaterOutline,
  heated_water: IoWater,
  has_electricity_access: MdOutlineElectricalServices,
  has_gas_access: GiGasStove,
  has_cable_internet_access: MdCable,
  has_cable_television_access: IoTvSharp,
  has_cable_telephone_access: BsFillTelephoneFill,
  has_high_voltage_plug: FaPlug,
  contact_number: MdContactPhone,
  photos_urls: IoMdPhotos,
  changes_history: FaHistory,
  tag: BiTag,
  price_per_meter: RiPriceTag2Fill,
  description_clean_text: MdDescription,
  bases: MdRealEstateAgent,
  location_type: AiFillShop,
  parking_lots: FaParking,
  commercial_building_rents: AiTwotoneShop,
  commercial_building_sales: AiTwotoneShop,
  construction_type: MdGarage,
  garage_rents: GiHomeGarage,
  garage_sales: GiHomeGarage,
  garden_size: CgTrees,
  has_garage: GiHomeGarage,
  house_type: MdOutlineHolidayVillage,
  floors: ImListNumbered,
  attic: MdRoofing,
  fence: MdFence,
  access_road: ImRoad,
  land_type: GiForest,
  land_shape: BiShapeTriangle,
  land_length: AiOutlineColumnHeight,
  land_width: AiOutlineColumnWidth,
  house_rents: IoMdHome,
  house_sales: IoMdHome,
  floor_type: MdTexture,
  industrial_building_rents: GiFactory,
  industrial_building_sales: GiFactory,
  land_rents: CgTrees,
  land_sales: CgTrees,
  office_class: GiOfficeChair,
  office_rents: GiOfficeChair,
  office_sales: GiOfficeChair,
  room_type: IoPeople,
  room_rents: MdMeetingRoom,
  warehouse_rents: FaWarehouse,
  warehouse_sales: FaWarehouse,
  initial_pledge: GiReceiveMoney,
  date_of_pledge: MdAccessTimeFilled,
  estimated_price: BiEqualizer,
  bailiff: BsPersonFill,
  court: GoLaw,
  bailiff_office: HiOutlineLocationMarker,
  date_of_auction: MdAccessTimeFilled,
  auction_signature: FaSignature,
  auction_type: MdFence,
  shares: GiPieChart,
  mortgage_register: MdTitle,
  auction_offer_type: MdFence,
  auctions: RiAuctionFill,
  property_condition: MdWorkspacePremium,
};
