import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectOption } from './select-input';
import SelectInputLabeled, {
  SelectInputLabeledProps,
} from './select-input-labeled';

export interface InputLabelControllerProps
  extends Omit<SelectInputLabeledProps, 'labelFor' | 'onChange' | 'value'> {
  name: string;
  options: SelectOption[];
  control: any;
  className?: string;
  id: string;
  clearable?: boolean;
  emptyInput?: (isOpen: boolean) => React.ReactElement;
}

const SelectInputLabeledController = ({
  control,
  name,
  ...rest
}: InputLabelControllerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <SelectInputLabeled onChange={onChange} value={value} {...rest} />
      )}
    />
  );
};

export default SelectInputLabeledController;
