enum AppErrorsTypes {
  INVALID_OFFERS_LIST_LINK,
}

export class AppError extends Error {
  type: AppErrorsTypes;

  constructor(info: string, type: AppErrorsTypes) {
    super(info);
    this.type = type;
  }

  static invalidOffersListLink(info: string): AppError {
    return new AppError(info, AppErrorsTypes.INVALID_OFFERS_LIST_LINK);
  }

  static isInvalidOffersListLink(error: any): error is AppError {
    return !!error && error.type === AppErrorsTypes.INVALID_OFFERS_LIST_LINK;
  }
}
