import React from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { parseUrl, stringifyUrl } from 'query-string';
import { Trans, useTranslation } from 'react-i18next';
import PaginationItemsSummary from './pagination-items-summary';

export interface PaginationProps {
  basePath: string;
  page: number;
  totalRecords: number;
  recordsPerPage: number;
  totalPages: number;
  pageSearch?: string;
  hideMdSummaryAfterNPages?: number | false;
  paginationSummaryClassName?: string;
  wrapperClassName?: string;
}

function generatePath(basePath: string, page: number, pageSearch: string) {
  const parsed = parseUrl(basePath);

  return stringifyUrl({
    url: parsed.url,
    query: { ...parsed.query, [pageSearch]: page },
  });
}

function valueBoundedBy(val: number, min: number, max: number) {
  return Math.min(Math.max(min, val), max);
}

const Pagination = ({
  basePath,
  page,
  totalRecords,
  recordsPerPage,
  totalPages,
  pageSearch = 'page',
  hideMdSummaryAfterNPages = false,
  paginationSummaryClassName = '',
  wrapperClassName = '',
}: PaginationProps) => {
  const { t } = useTranslation('common');
  const maxPage = Math.max(totalPages, 1);
  const prevPage = Math.max(1, page - 1);
  const nextPage = Math.min(maxPage, page + 1);

  const showSummary =
    hideMdSummaryAfterNPages === false || maxPage <= hideMdSummaryAfterNPages;

  const activeClass =
    'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
  const inactiveClass =
    'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
  return (
    <div
      className={`mt-4 flex items-center justify-between border-t border-gray-200 py-3 ${wrapperClassName}`}
    >
      <div className="flex w-full flex-col sm:hidden">
        <PaginationItemsSummary
          page={page}
          recordsPerPage={recordsPerPage}
          totalRecords={totalRecords}
        />
        <div className="mt-2 flex flex-1 justify-between">
          {prevPage === page ? (
            <div className="relative inline-flex cursor-not-allowed select-none items-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700">
              {t('common:pagination.previousPage')}
            </div>
          ) : (
            <Link
              to={generatePath(basePath, prevPage, pageSearch)}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >
              {t('common:pagination.previousPage')}
            </Link>
          )}
          {nextPage === page ? (
            <div className="relative inline-flex cursor-not-allowed select-none items-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700">
              {t('common:pagination.nextPage')}
            </div>
          ) : (
            <Link
              to={generatePath(basePath, nextPage, pageSearch)}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {t('common:pagination.nextPage')}
            </Link>
          )}
        </div>
      </div>
      <div
        className={`hidden sm:flex sm:flex-1 sm:items-center ${
          showSummary ? 'sm:justify-between' : 'sm:justify-center'
        }`}
      >
        {showSummary && (
          <div>
            <p
              className={`text-sm text-gray-700 ${paginationSummaryClassName}`}
            >
              <Trans
                i18nKey="common:pagination.paginationSummary"
                t={t}
                values={{
                  first: Math.min(
                    page * recordsPerPage - recordsPerPage + 1,
                    totalRecords,
                  ),
                  last: Math.min(page * recordsPerPage, totalRecords),
                  total: totalRecords,
                }}
                components={{
                  highlightWrapper: <span className="font-bold font-medium" />,
                }}
              />
            </p>
          </div>
        )}
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {prevPage === page ? (
              <div className="relative inline-flex cursor-not-allowed items-center rounded-l-md border border-gray-300 bg-gray-200 px-2 py-2 text-sm font-medium text-gray-500">
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            ) : (
              <Link
                to={generatePath(basePath, prevPage, pageSearch)}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            )}
            <Link
              to={generatePath(basePath, 1, pageSearch)}
              className={page === 1 ? activeClass : inactiveClass}
            >
              1
            </Link>
            {maxPage - 4 > 0 &&
              valueBoundedBy(page - 1, 2, maxPage - 3) > 2 && (
                <span className="inline-flex items-end border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium text-gray-500">
                  <EllipsisHorizontalIcon className="h-4 w-4" />
                </span>
              )}
            {maxPage - 4 > 0 && (
              <Link
                to={generatePath(
                  basePath,
                  valueBoundedBy(page - 1, 2, maxPage - 3),
                  pageSearch,
                )}
                className={
                  page === valueBoundedBy(page - 1, 2, maxPage - 3)
                    ? activeClass
                    : inactiveClass
                }
              >
                {valueBoundedBy(page - 1, 2, maxPage - 3)}
              </Link>
            )}
            {maxPage - 3 > 0 && (
              <Link
                to={generatePath(
                  basePath,
                  valueBoundedBy(page, 3, maxPage - 2),
                  pageSearch,
                )}
                className={
                  page === valueBoundedBy(page, 3, maxPage - 2)
                    ? activeClass
                    : inactiveClass
                }
              >
                {valueBoundedBy(page, 3, maxPage - 2)}
              </Link>
            )}
            {maxPage - 2 > 0 && (
              <Link
                to={generatePath(
                  basePath,
                  valueBoundedBy(page + 1, 4, maxPage - 1),
                  pageSearch,
                )}
                className={
                  page === valueBoundedBy(page + 1, 4, maxPage - 1)
                    ? activeClass
                    : inactiveClass
                }
              >
                {valueBoundedBy(page + 1, 4, maxPage - 1)}
              </Link>
            )}
            {maxPage - 2 > 0 &&
              maxPage - valueBoundedBy(page + 1, 4, maxPage - 1) > 1 && (
                <span className="relative inline-flex items-end border border-gray-300 bg-white px-3 py-1.5 text-sm font-medium text-gray-500">
                  <EllipsisHorizontalIcon className="h-4 w-4" />
                </span>
              )}
            {maxPage !== 1 && (
              <Link
                to={generatePath(basePath, maxPage, pageSearch)}
                className={page === maxPage ? activeClass : inactiveClass}
              >
                {maxPage}
              </Link>
            )}
            {nextPage === page ? (
              <div className="relative inline-flex cursor-not-allowed items-center rounded-r-md border border-gray-300 bg-gray-200 px-2 py-2 text-sm font-medium text-gray-500">
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            ) : (
              <Link
                to={generatePath(basePath, nextPage, pageSearch)}
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
