import {
  AddClientOfferSearchRequest,
  AddOfferToClientOfferSearch,
  AddOfferToClientSearchResponse,
  ClientOfferSearchCreateResponse,
  ClientOfferSearchListResponse,
  ClientOfferSearchResponse,
  ClientSearchOffersCountResponse,
  ClientSearchPaymentMethodEnum,
  ClientSearchSelectedOfferListResponse,
  ClientSearchSharedOfferListResponse,
  ClientSearchSharedOffersResponse,
  OfferSearchStateEnum,
  ParkingEnum,
  UpdateClientOfferSearchRequest,
} from '../api.types';
import { axios } from '../axios';

export const getClientSearches = ({
  page,
  size,
  clientId,
  createdById,
  assignedToId,
  showClosed,
  paymentMethod,
  price,
  floor,
  propertySize,
  rooms,
  locationLatitude,
  locationLongitude,
  hasBalcony,
  hasBasement,
  hasElevator,
  hasTerrace,
  parking,
  looselyMode,
}: {
  page: number;
  size: number;
  clientId?: string | null;
  createdById?: string | null;
  assignedToId?: string | null;
  showClosed?: boolean | null;
  paymentMethod?: ClientSearchPaymentMethodEnum | null;
  price?: number | null;
  floor?: number | null;
  propertySize?: number | null;
  rooms?: number | null;
  locationLatitude?: number | null;
  locationLongitude?: number | null;
  hasBalcony?: boolean | null;
  hasBasement?: boolean | null;
  hasElevator?: boolean | null;
  hasTerrace?: boolean | null;
  parking?: ParkingEnum | null;
  looselyMode?: boolean | null;
}): Promise<ClientOfferSearchListResponse> => {
  return axios
    .get<ClientOfferSearchListResponse>(`/client-searches`, {
      params: {
        page,
        size,
        client_id: clientId,
        show_closed: showClosed,
        created_by_id: createdById,
        assigned_to_id: assignedToId,
        payment_method: paymentMethod,
        price,
        floor,
        property_size: propertySize,
        rooms,
        location_latitude: locationLatitude,
        location_longitude: locationLongitude,
        has_balcony: hasBalcony,
        has_basement: hasBasement,
        has_elevator: hasElevator,
        has_terrace: hasTerrace,
        parking,
        loosely_mode: looselyMode,
      },
    })
    .then((resp) => resp.data);
};

export const getClientSearchesCount = ({
  clientId,
  createdById,
  showClosed,
}: {
  clientId?: string | null;
  createdById?: string | null;
  showClosed?: boolean | null;
}): Promise<{ total_items: number }> => {
  return axios
    .get<ClientOfferSearchListResponse>(`/client-searches/count`, {
      params: {
        client_id: clientId,
        show_closed: showClosed,
        created_by_id: createdById,
      },
    })
    .then((resp) => resp.data);
};

export const getClientSearchById = (
  clientSearchId: string,
): Promise<ClientOfferSearchResponse> => {
  return axios
    .get<ClientOfferSearchResponse>(`/client-searches/${clientSearchId}`)
    .then((resp) => resp.data);
};

export const postAddClientSearch = ({
  body,
}: {
  body: AddClientOfferSearchRequest;
}): Promise<ClientOfferSearchCreateResponse> => {
  return axios.post(`/client-searches`, body).then((resp) => resp.data);
};

export const putClientSearch = ({
  body,
  clientSearchId,
}: {
  body: UpdateClientOfferSearchRequest;
  clientSearchId: string;
}): Promise<void> => {
  return axios.put(`/client-searches/${clientSearchId}`, body);
};

export const deleteClientSearch = (clientSearchId: string): Promise<void> => {
  return axios.delete(`/client-searches/${clientSearchId}`);
};

export const getClientSearchOffers = ({
  clientSearchId,
  offerSearchState,
  page,
  size,
}: {
  clientSearchId: string;
  offerSearchState?: OfferSearchStateEnum;
  page: number;
  size: number;
}) => {
  return axios
    .get<ClientSearchSelectedOfferListResponse>(
      `/client-searches/${clientSearchId}/offers`,
      {
        params: {
          state: offerSearchState,
          page,
          size,
        },
      },
    )
    .then((resp) => resp.data);
};

export const getClientSearchSharedOffers = ({
  clientSearchId,
  page,
  size,
}: {
  clientSearchId: string;
  page: number;
  size: number;
}) => {
  return axios
    .get<ClientSearchSharedOfferListResponse>(
      `/client-searches/${clientSearchId}/offers/shared`,
      {
        params: {
          page,
          size,
        },
      },
    )
    .then((resp) => resp.data);
};

export const getClientSearchSharedNextOffer = ({
  clientSearchId,
}: {
  clientSearchId: string;
}) => {
  return axios
    .get<ClientSearchSharedOffersResponse>(
      `/client-searches/${clientSearchId}/offers/shared/next`,
    )
    .then((resp) => resp.data);
};

export const postChangeClientSearchOfferStatus = ({
  clientSearchId,
  clientSearchOfferId,
  newState,
  offerVariantId,
}: {
  clientSearchId: string;
  clientSearchOfferId: string;
  newState: OfferSearchStateEnum;
  offerVariantId?: string;
}) => {
  return axios.post(
    `/client-searches/${clientSearchId}/offers/${clientSearchOfferId}/move`,
    {
      new_state: newState,
      offer_variant_id: offerVariantId,
    },
  );
};

export const getClientSearchOffersCount = ({
  clientSearchId,
}: {
  clientSearchId: string;
}) => {
  return axios
    .get<ClientSearchOffersCountResponse[]>(
      `/client-searches/${clientSearchId}/offers/count`,
      {
        params: { client_search_id: clientSearchId },
      },
    )
    .then((resp) => resp.data);
};

export const postAddOfferToClientSearch = ({
  body,
  clientSearchId,
}: {
  body: AddOfferToClientOfferSearch;
  clientSearchId: string;
}) => {
  return axios
    .post<AddOfferToClientSearchResponse>(
      `/client-searches/${clientSearchId}/offers`,
      body,
    )
    .then((resp) => resp.data);
};
