import { MeetingResponseStatus } from '../api/meetings/meetings-dto';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export enum MeetingFieldKeys {
  TITLE = 'title',
  DESCRIPTION = 'description',
  LINKED_CLIENT_ID = 'linked_client_id',
  RESPONSE_STATUS = 'response_status',
  RESPONSE_NOTE = 'response_note',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  RESULT_NOTE = 'result_note',
  ASSIGNED_USER_ID = 'assigned_user_id',
}

export interface MeetingFieldTypes {
  [MeetingFieldKeys.TITLE]: string;
  [MeetingFieldKeys.DESCRIPTION]: string | null;
  [MeetingFieldKeys.LINKED_CLIENT_ID]: string | null;
  [MeetingFieldKeys.RESPONSE_STATUS]: MeetingResponseStatus;
  [MeetingFieldKeys.RESPONSE_NOTE]: string | null;
  [MeetingFieldKeys.START_TIME]: Date;
  [MeetingFieldKeys.END_TIME]: Date;
  [MeetingFieldKeys.RESULT_NOTE]: string | null;
  [MeetingFieldKeys.ASSIGNED_USER_ID]: string | null;
}

export const useGetMeetingFields = () => {
  const { t } = useTranslation(['validation']);

  return ({
    exclude,
    mappings,
  }: {
    exclude?: MeetingFieldKeys[];
    mappings?: Partial<
      Record<MeetingFieldKeys, (schema: Yup.AnySchema) => Yup.AnySchema>
    >;
  } = {}) =>
    Object.entries({
      [MeetingFieldKeys.TITLE]: Yup.string().required(t('validation:required')),
      [MeetingFieldKeys.DESCRIPTION]: Yup.string()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [MeetingFieldKeys.LINKED_CLIENT_ID]: Yup.string()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [MeetingFieldKeys.RESPONSE_STATUS]: Yup.mixed()
        .transform((v, o) => (v === '' ? null : o))
        .oneOf(Object.values(MeetingResponseStatus))
        .test((v) =>
          [...Object.values(MeetingResponseStatus), null].includes(v),
        )
        .nullable()
        .required(t('validation:required')),
      [MeetingFieldKeys.RESPONSE_NOTE]: Yup.string()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [MeetingFieldKeys.START_TIME]: Yup.date()
        .typeError(t('validation:typeDate'))
        .nullable()
        .required(t('validation:required')),
      [MeetingFieldKeys.END_TIME]: Yup.date()
        .typeError(t('validation:typeDate'))
        .nullable()
        .required(t('validation:required')),
      [MeetingFieldKeys.RESULT_NOTE]: Yup.string()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
      [MeetingFieldKeys.ASSIGNED_USER_ID]: Yup.string()
        .nullable()
        .transform((autoTransformed, original) =>
          original === '' ? null : autoTransformed,
        ),
    }).reduce((acc, [key, value]) => {
      const typedKey = key as MeetingFieldKeys;

      if (!(exclude ?? []).includes(typedKey)) {
        acc[typedKey] =
          typedKey in (mappings ?? {}) ? mappings?.[typedKey]?.(value) : value;
      }
      return acc;
    }, {} as Record<MeetingFieldKeys, any>);
};
