import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { OfferTypeEnum } from '../../../api/api.types';
import { getDuplicatesForOffer } from '../../../api/offers/offers';
import OfferSmallCard from '../offer-small-card';
import OfferSmallCardSkeleton from '../offer-small-card-skeleton';

interface Props {
  offerId: string;
  offerType: OfferTypeEnum;
  isOpen: boolean;
  expectedDuplicatesCount: number;
}

const OfferDuplicatesPopoverContent = ({
  offerId,
  offerType,
  isOpen,
  expectedDuplicatesCount,
}: Props) => {
  const { data: duplicates = [], isLoading } = useQuery(
    ['offer', 'duplicates', offerType, offerId],
    () => getDuplicatesForOffer(offerType, offerId),
    { enabled: isOpen },
  );

  return (
    <div className="max-h-72 overflow-y-auto">
      {isLoading ? (
        <div className="mt-2 flex flex-col space-y-4">
          {[...new Array(expectedDuplicatesCount).keys()].map((i) => (
            <OfferSmallCardSkeleton key={i} />
          ))}
        </div>
      ) : (
        <div className="mt-2 flex flex-col space-y-4">
          {duplicates.map((duplicate) => (
            <OfferSmallCard
              key={(duplicate.offer as any).id}
              activityCount={
                (duplicate.comments_count ?? 0) +
                (duplicate.phone_call_summary_count ?? 0)
              }
              {...(duplicate.offer as any)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OfferDuplicatesPopoverContent;
