import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  page: number;
  recordsPerPage: number;
  totalRecords: number;
}

const PaginationItemsSummary = ({
  page,
  recordsPerPage,
  totalRecords,
}: Props) => {
  const { t } = useTranslation('common');
  return (
    <div>
      <p className="text-sm text-gray-700">
        <Trans
          i18nKey="common:pagination.paginationSummary"
          t={t}
          values={{
            first: Math.min(
              page * recordsPerPage - recordsPerPage + 1,
              totalRecords,
            ),
            last: Math.min(page * recordsPerPage, totalRecords),
            total: totalRecords,
          }}
          components={{
            highlightWrapper: <span className="font-bold font-medium" />,
          }}
        />
      </p>
    </div>
  );
};

export default PaginationItemsSummary;
