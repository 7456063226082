import React from 'react';
import { useElementHeight, useElementScrollHeight } from './use-element-height';

export const useIsElementScrollable = (
  elementRef: React.RefObject<HTMLDivElement>,
  deps: any[] = [],
): boolean => {
  const height = useElementHeight(elementRef, deps);
  const scrollHeight = useElementScrollHeight(elementRef, deps);
  return scrollHeight > height;
};
