import { OfferTypeEnum } from '../api/api.types';
import { BaseUser } from './user';
import { stringifyUrl } from 'query-string';
import { Filter, FilterCondition } from './filter';
import { Sort } from './sort';
import { SEARCH_FORM_KEYS } from '../context/search-form/offers-search-form';
import { ViewType } from '../hooks/use-offers/use-offer-list-search-params';

// note: OfferTypeEnum for backward compatibility, may be removed in the future
export function buildDefaultOfferTypeUrl(
  offerTypes: OfferTypeEnum | OfferTypeEnum[],
  user: BaseUser,
  viewType?: ViewType,
) {
  return buildOfferTypeUrl({
    viewType,
    offerTypes: Array.isArray(offerTypes) ? offerTypes : [offerTypes],
    filters: [
      ...user.defaultFilters(),
      {
        field: 'date_smart_last_seen',
        value: 14,
        condition: FilterCondition.LAST_N_DAYS,
      },
      {
        field: 'location',
        value: 0,
        condition: FilterCondition.PROXIMITY,
      },
    ],
    sorts: user.defaultSorts(),
  });
}

export function buildOfferTypeUrl({
  offerTypes,
  filters,
  sorts,
  viewType = ViewType.LIST,
}: {
  offerTypes: OfferTypeEnum[];
  filters?: Filter[];
  sorts?: Sort[];
  viewType?: ViewType;
}) {
  const conditionalQueryParts: { filters?: string; sorts?: string } = {};
  if (Array.isArray(filters) && filters.length > 0) {
    conditionalQueryParts.filters = JSON.stringify(filters);
  }
  if (Array.isArray(sorts) && sorts.length > 0) {
    conditionalQueryParts.sorts = JSON.stringify(sorts);
  }
  return stringifyUrl({
    url: '/offers/list',
    query: {
      offersTypes: JSON.stringify(
        Array.isArray(offerTypes) ? offerTypes : [offerTypes],
      ),
      ...conditionalQueryParts,
      viewType,
    },
  });
}

export function offersTypesToForm(offersTypes: OfferTypeEnum[]) {
  return { [SEARCH_FORM_KEYS.offersTypes]: offersTypes };
}

export function formToOffersList(
  form: Record<string, any> | any[] | undefined,
): OfferTypeEnum[] {
  if (form === undefined) {
    return [];
  }

  if (Array.isArray(form)) {
    return form;
  }

  if (Array.isArray(form[SEARCH_FORM_KEYS.offersTypes])) {
    return form[SEARCH_FORM_KEYS.offersTypes];
  }

  return [];
}

export function isOfferArray(object: any): object is OfferTypeEnum[] {
  return Array.isArray(object) && object.every(isOfferType);
}

export function isOfferType(object: any): object is OfferTypeEnum {
  return Object.values(OfferTypeEnum).includes(object);
}

export const flatterOfferParameters = (offer: any, superOffer: any) => {
  // merge two objects, first use offer, then supperOffer, if a field in super offer is an array, pick first value

  const result = { ...offer, ...superOffer };

  for (const key in result) {
    if (Array.isArray(result[key])) {
      result[key] = result[key][0];
    }
  }
  return result;
};

export const OFFER_TYPE_TO_PROPERTY_TYPE = {
  [OfferTypeEnum.APARTMENT_SALES]: 'apartment',
  [OfferTypeEnum.APARTMENT_RENTS]: 'apartment',
  [OfferTypeEnum.HOUSE_SALES]: 'house',
  [OfferTypeEnum.HOUSE_RENTS]: 'house',
  [OfferTypeEnum.LAND_SALES]: 'land',
  [OfferTypeEnum.LAND_RENTS]: 'land',
  [OfferTypeEnum.OFFICE_SALES]: 'office',
  [OfferTypeEnum.OFFICE_RENTS]: 'office',
  [OfferTypeEnum.INDUSTRIAL_BUILDING_SALES]: 'industrial',
  [OfferTypeEnum.INDUSTRIAL_BUILDING_RENTS]: 'industrial',
  [OfferTypeEnum.WAREHOUSE_SALES]: 'warehouse',
  [OfferTypeEnum.WAREHOUSE_RENTS]: 'warehouse',
  [OfferTypeEnum.COMMERCIAL_BUILDING_SALES]: 'commercial',
  [OfferTypeEnum.COMMERCIAL_BUILDING_RENTS]: 'commercial',
  [OfferTypeEnum.GARAGE_SALES]: 'garage',
  [OfferTypeEnum.GARAGE_RENTS]: 'garage',
  [OfferTypeEnum.ROOM_RENTS]: 'room',
  [OfferTypeEnum.AUCTIONS]: 'auctions',
} as const;
