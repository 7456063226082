import React from 'react';
import { FixedSizeNodeData, FixedSizeTree } from 'react-vtree';
import { NodeData } from 'react-vtree/dist/es/Tree';
import { ListChildComponentProps } from 'react-window';
import {
  getCities,
  getCounties,
  getDistricts,
  getStates,
} from '../../../api/address/address';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { AddressResponse } from '../../../api/api.types';
import { Address, resolveAddressParts } from '../../../models/address';
import { HOURS_24 } from '../../../constants/periods';

export enum LocationDepth {
  STATE = 0,
  COUNTY = 1,
  CITIES = 2,
  DISTRICTS = 3,
}

type TreeNode = Readonly<{
  children: TreeNode[];
  downloaded: boolean;
  id: string;
  name: string;
  fullPath: string;
  isLeaf: boolean;
  depth: LocationDepth;
}>;

type TreeData = FixedSizeNodeData &
  Readonly<{
    downloaded: boolean;
    download?: () => Promise<void>;
    isLeaf: boolean;
    name: string;
    nestingLevel: number;
    isSelected: boolean;
    changeSelected: () => void;
    isPartialSelected: boolean;
  }>;

export type TreeWalkerValue<TData extends NodeData, TMeta = {}> = Readonly<
  { data: TData } & TMeta
>;

type NodeMeta = Readonly<{
  nestingLevel: number;
  node: TreeNode;
}>;

type TreeWalkers<TData extends NodeData, TMeta = {}> = () => Generator<
  TreeWalkerValue<TData, TMeta> | undefined,
  undefined,
  TreeWalkerValue<TData, TMeta>
>;

const getNodeData = (
  node: TreeNode,
  nestingLevel: number,
  download: () => Promise<void>,
  isSelected: boolean,
  changeSelected: () => void,
  isPartialSelected: boolean,
): TreeWalkerValue<TreeData, NodeMeta> => ({
  data: {
    download,
    downloaded: node.downloaded,
    id: node.id.toString(),
    isLeaf: node.isLeaf,
    isOpenByDefault: false,
    name: node.name,
    nestingLevel,
    isSelected,
    changeSelected,
    isPartialSelected,
  },
  nestingLevel,
  node,
});

export type NodePublicState<TData extends NodeData> = Readonly<{
  data: TData;
  setOpen: (val: boolean) => Promise<void>;
}> & {
  isOpen: boolean;
};

export type FixedSizeNodePublicState<TData extends FixedSizeNodeData> =
  NodePublicState<TData>;

export type NodeComponentProps<
  TData extends NodeData,
  TNodePublicState extends NodePublicState<TData>,
> = Readonly<
  Omit<ListChildComponentProps, 'data' | 'index'> &
    TNodePublicState & {
      /**
       * The data provided by user via `itemData` Tree component property.
       */
      treeData?: any;
    }
>;

const Node: React.FC<
  NodeComponentProps<TreeData, FixedSizeNodePublicState<TreeData>>
> = ({
  data: {
    nestingLevel,
    isLeaf,
    name,
    download,
    downloaded,
    isSelected,
    changeSelected,
    isPartialSelected,
  },
  isOpen,
  style,
  setOpen,
}) => {
  const [isLoading, setLoading] = React.useState(false);

  return (
    <div
      style={{
        ...style,
        alignItems: 'center',
        display: 'flex',
        paddingLeft: nestingLevel * 30,
      }}
    >
      {isLeaf ? (
        <div className="flex flex-row">
          <input
            checked={isSelected}
            onChange={changeSelected}
            type="checkbox"
            className="ml-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <div
            className="ml-2 cursor-pointer select-none text-sm"
            onClick={changeSelected}
          >
            {name}
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center">
          {isPartialSelected ? (
            <input
              checked={isPartialSelected}
              onChange={changeSelected}
              type="checkbox"
              className="ml-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          ) : (
            <input
              checked={isSelected}
              onChange={changeSelected}
              type="checkbox"
              className="ml-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          )}
          <div
            className="ml-2 cursor-pointer select-none text-sm"
            onClick={changeSelected}
          >
            {name}
          </div>
          {isLoading ? (
            <svg
              className="ml-1 mt-0.5 h-4 w-4 animate-spin text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : isOpen ? (
            <ChevronUpIcon
              className="h-5 w-5 cursor-pointer text-gray-500"
              onClick={async () => {
                await setOpen(!isOpen);
              }}
            />
          ) : (
            <ChevronDownIcon
              className="h-5 w-5 cursor-pointer text-gray-500"
              onClick={async () => {
                if (!downloaded) {
                  if (download) {
                    setLoading(true);
                    await download();

                    await setOpen(!isOpen);
                    setLoading(false);
                  }
                } else {
                  await setOpen(!isOpen);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

interface Props {
  value: { label: string; value: Address }[];
  onChange: (val: { label: string; value: Address }[]) => void;
}

const createNode = (
  locationResponse: AddressResponse,
  depth: LocationDepth,
  queryClient: QueryClient,
): any => {
  const children =
    depth <= LocationDepth.DISTRICTS
      ? (queryClient.getQueryData([
          'location',
          depth,
          locationResponse.id,
        ]) as AddressResponse[])
      : [];
  return {
    id: locationResponse.id,
    downloaded: !!children,
    name: locationResponse.value.split(', ')[
      locationResponse.value.split(', ').length - 1
    ],
    fullPath: locationResponse.value.split(', ').slice(1).join(', '),
    children:
      children
        ?.map((child) => createNode(child, depth + 1, queryClient))
        ?.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        ) ?? [],
    isLeaf: !locationResponse.has_children || children?.length === 0,
    depth,
  };
};

const getPartial = (strings: string[]) => {
  const result: string[] = [];
  let str = '';
  strings.forEach((s) => {
    str += s;
    result.push(str);
    str += ', ';
  });
  result.pop();

  return result;
};

const LocationTree = ({ value, onChange }: Props) => {
  const queryClient = useQueryClient();
  const [refresh, setRefresh] = React.useState(0);
  const { data: states, isLoading: isStatesLoading } = useQuery(
    ['location', 'states'],
    () => getStates(),
    {
      staleTime: HOURS_24,
      cacheTime: HOURS_24,
    },
  );
  const selected = new Set(value.map((val) => val.label));
  const partialSelected = new Set(
    value.flatMap((val) => getPartial(val.label.split(', '))),
  );
  const rootNodes = React.useMemo(
    () =>
      states
        ?.map((state) => createNode(state, LocationDepth.COUNTY, queryClient))
        ?.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        ) ?? [],
    [refresh, states, queryClient],
  );

  const createDownloader = (node: TreeNode) => (): Promise<void> => {
    const cache = queryClient.getQueryData(['location', node.depth, node.id]);
    if (!cache) {
      const resolver = {
        [LocationDepth.COUNTY]: () => getCounties(node.id),
        [LocationDepth.CITIES]: () => getCities('_', node.id),
        [LocationDepth.DISTRICTS]: () => getDistricts('_', '_', node.id),
      };

      return new Promise((resolve) => {
        queryClient
          .fetchQuery(
            ['location', node.depth, node.id],
            resolver[node.depth as keyof typeof resolver] as () => Promise<
              AddressResponse[]
            >,
            {
              staleTime: HOURS_24,
              cacheTime: HOURS_24,
            },
          )
          .then(() => {
            setRefresh((ref) => ref + 1);
            resolve();
          })
          .catch((e) => {
            if (e.response.status === 404) {
              queryClient.setQueryData(['location', node.depth, node.id], []);
              setRefresh((ref) => ref + 1);
              resolve();
            }
          });
      });
    }
    return new Promise((resolve) => resolve());
  };

  const changeSelection = (node: { name: string; fullPath: string }) => {
    if (selected.has(node.fullPath)) {
      onChange(value.filter((val) => val.label !== node.fullPath));
    } else {
      if (partialSelected.has(node.fullPath)) {
        onChange(value.filter((val) => !val.label.startsWith(node.fullPath)));
      } else {
        onChange([
          { label: node.fullPath, value: resolveAddressParts(node.fullPath) },
        ]);
      }
    }
  };

  const treeWalker = React.useCallback(
    function* treeWalker(): ReturnType<TreeWalkers<TreeData, NodeMeta>> {
      for (const node of rootNodes) {
        yield getNodeData(
          node,
          0,
          createDownloader(node),
          selected.has(node.fullPath),
          () => changeSelection(node),
          partialSelected.has(node.fullPath),
        );
      }

      while (true) {
        const parentMeta = yield;

        if (parentMeta.data.downloaded) {
          for (let i = 0; i < parentMeta.node.children.length; i++) {
            yield getNodeData(
              parentMeta.node.children[i],
              parentMeta.nestingLevel + 1,
              createDownloader(parentMeta.node.children[i]),
              selected.has(parentMeta.node.children[i].fullPath),
              () => changeSelection(parentMeta.node.children[i]),
              partialSelected.has(parentMeta.node.children[i].fullPath),
            );
          }
        }
      }
    },
    [rootNodes, selected, partialSelected],
  );

  return (
    <div className="h-full h-full">
      {isStatesLoading || rootNodes.length === 0 ? (
        <div style={{ width: 320, height: 400 }} className="overflow-x-auto">
          {[...new Array(20).keys()].map((i) => (
            <div key={i} className="px-2">
              <div className="mt-2 h-4 w-full animate-pulse rounded bg-gray-200" />
            </div>
          ))}
        </div>
      ) : (
        <div className="h-full w-full">
          <FixedSizeTree
            treeWalker={treeWalker}
            itemSize={30}
            height={400}
            async={true}
            width={400}
          >
            {Node}
          </FixedSizeTree>
        </div>
      )}
    </div>
  );
};
export default LocationTree;
