import { OfferEnums } from '../../schemas/schemas';
import { OfferParametersFieldKeys } from '../../forms/offer';

export const BASE_FIELDS = [
  OfferParametersFieldKeys.PRICE,
  OfferParametersFieldKeys.PROPERTY_SIZE,
  OfferParametersFieldKeys.RENT,
  OfferParametersFieldKeys.GARDEN_SIZE,
  OfferParametersFieldKeys.ROOMS,
  OfferParametersFieldKeys.MARKET_TYPE,
];
export const LAND_TEXT_FIELDS = [
  { name: OfferParametersFieldKeys.LAND_WIDTH, suffix: 'm' },
  { name: OfferParametersFieldKeys.LAND_LENGTH, suffix: 'm' },
];
export const LAND_ENUM_FIELDS: OfferEnums[] = [
  OfferParametersFieldKeys.ACCESS_ROAD,
  OfferParametersFieldKeys.FENCE,
  OfferParametersFieldKeys.LAND_TYPE,
  OfferParametersFieldKeys.LAND_SHAPE,
];

export const BUILDING_BOOLEAN_FIELDS = [
  OfferParametersFieldKeys.TO_BE_RENOVATED,
];
export const BUILDING_ENUM_FIELDS: OfferEnums[] = [
  OfferParametersFieldKeys.BUILDING_TYPE,
  OfferParametersFieldKeys.HOUSE_TYPE,
  OfferParametersFieldKeys.KITCHEN_TYPE,
  OfferParametersFieldKeys.ATTIC,
  OfferParametersFieldKeys.BUILDING_STAGE,
  OfferParametersFieldKeys.BUILDING_MATERIAL,
  OfferParametersFieldKeys.ROOF_MATERIAL,
  OfferParametersFieldKeys.WINDOWS_MATERIAL,
];

export const BUILDING_TEXT_FIELDS = [
  {
    name: OfferParametersFieldKeys.YEAR_BUILT,
    suffix: 'rok',
  },
  { name: OfferParametersFieldKeys.FLOOR_NO_LOWER_BOUND },
  { name: OfferParametersFieldKeys.TOTAL_LEVELS_IN_BUILDING_LOWER_BOUND },
  { name: OfferParametersFieldKeys.LEVELS },
  { name: OfferParametersFieldKeys.BATHROOM_COUNT },
];

export const LEGAL_BOOLEAN_FIELDS = [
  OfferParametersFieldKeys.HAS_LAND_AND_MORTGAGE_REGISTER,
];
export const LEGAL_ENUM_FIELDS: OfferEnums[] = [
  OfferParametersFieldKeys.OWNERSHIP_TYPE,
];

export const MEDIA_BOOLEAN_FIELDS = [
  OfferParametersFieldKeys.HAS_CABLE_TELEPHONE_ACCESS,
  OfferParametersFieldKeys.HAS_CABLE_TELEVISION_ACCESS,
  OfferParametersFieldKeys.HAS_CABLE_INTERNET_ACCESS,
  OfferParametersFieldKeys.HAS_ELECTRICITY_ACCESS,
  OfferParametersFieldKeys.HAS_GAS_ACCESS,
  OfferParametersFieldKeys.HAS_HIGH_VOLTAGE_PLUG,
];

export const MEDIA_ENUM_FIELDS: OfferEnums[] = [
  OfferParametersFieldKeys.HEATED_WATER,
  OfferParametersFieldKeys.SEWAGE,
  OfferParametersFieldKeys.WATER_ACCESS,
  OfferParametersFieldKeys.HEATING,
];

export const EXTRA_BOOLEAN_FIELDS = [
  OfferParametersFieldKeys.HAS_BALCONY,
  OfferParametersFieldKeys.HAS_TERRACE,
  OfferParametersFieldKeys.HAS_BASEMENT,
  OfferParametersFieldKeys.HAS_GARAGE,
  OfferParametersFieldKeys.HAS_ELEVATOR,
  OfferParametersFieldKeys.IS_FURNISHED,
  OfferParametersFieldKeys.HAS_AIR_CONDITIONING,
  OfferParametersFieldKeys.IS_GATED_COMMUNITY,
  OfferParametersFieldKeys.HAS_ANTI_THEFT_PROTECTION,
];

export const EXTRA_ENUM_FIELDS: OfferEnums[] = [
  OfferParametersFieldKeys.PARKING,
];

export const ALL_VARIANT_FIELD_NAMES = [
  ...BASE_FIELDS,
  ...LAND_TEXT_FIELDS.map((field) => field.name),
  ...LAND_ENUM_FIELDS,
  ...BUILDING_BOOLEAN_FIELDS,
  ...BUILDING_ENUM_FIELDS,
  ...BUILDING_TEXT_FIELDS.map((field) => field.name),
  ...LEGAL_BOOLEAN_FIELDS,
  ...LEGAL_ENUM_FIELDS,
  ...MEDIA_BOOLEAN_FIELDS,
  ...MEDIA_ENUM_FIELDS,
  ...EXTRA_BOOLEAN_FIELDS,
  ...EXTRA_ENUM_FIELDS,
];

export const OFFER_VARIANT_MAIN_FIELDS = [
  OfferParametersFieldKeys.PROPERTY_SIZE,
  OfferParametersFieldKeys.LAND_TYPE,
  OfferParametersFieldKeys.OWNERSHIP_TYPE,
  OfferParametersFieldKeys.GARDEN_SIZE,
  OfferParametersFieldKeys.ROOMS,
  OfferParametersFieldKeys.BUILDING_STAGE,
  OfferParametersFieldKeys.FLOOR_NO_LOWER_BOUND,
  OfferParametersFieldKeys.RENT,
  OfferParametersFieldKeys.HEATING,
  OfferParametersFieldKeys.YEAR_BUILT,
  OfferParametersFieldKeys.MARKET_TYPE,
  OfferParametersFieldKeys.BUILDING_TYPE,
  OfferParametersFieldKeys.PARKING,
];

const OFFER_VARIANT_HIDDEN_FIELDS = [
  OfferParametersFieldKeys.PRICE,
  OfferParametersFieldKeys.TOTAL_LEVELS_IN_BUILDING_LOWER_BOUND,
];

export const OFFER_VARIANT_SECONDARY_FIELDS = ALL_VARIANT_FIELD_NAMES.filter(
  (f) =>
    !OFFER_VARIANT_MAIN_FIELDS.includes(f as any) &&
    !OFFER_VARIANT_HIDDEN_FIELDS.includes(f as any),
);
