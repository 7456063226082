import React from 'react';
import { Controller } from 'react-hook-form';
import ClientInput, { ClientInputProps } from './client-input';

interface Props extends Omit<ClientInputProps, 'onChange' | 'value'> {
  name: string;
  control: any;
}

const ClientInputController = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ClientInput
          onChange={onChange}
          value={value}
          isError={!!error}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default ClientInputController;
