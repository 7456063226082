import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    params: new URLSearchParams(location.search),
    remove: function (name: string) {
      this.params.delete(name);

      navigate({
        pathname: location.pathname,
        search: this.params.toString(),
      });
    },
    upsert: function (name: string, value: string) {
      this.params.set(name, value);
      navigate({
        pathname: location.pathname,
        search: this.params.toString(),
      });
    },
  };
};

export const useQueryParamTyped = <T extends Record<string, any>>({
  defaultValues,
}: {
  defaultValues?: T;
} = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    params: {
      ...defaultValues,
      ...(Object.fromEntries(
        new URLSearchParams(location.search).entries(),
      ) as T),
    },
    remove: function (key: keyof T) {
      const newParams = new URLSearchParams(location.search);
      newParams.delete(String(key));

      navigate({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    },
    upsert: function (key: keyof T, value: T[keyof T]) {
      const newParams = new URLSearchParams(location.search);
      newParams.set(String(key), value);

      navigate({
        pathname: location.pathname,
        search: newParams.toString(),
      });
    },
  };
};
