import React from 'react';
import { OfferStatus } from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import {
  OFFER_STATUS_CLASSES,
  OFFER_STATUS_ICONS,
} from '../../constants/offer-status-icons';
import { ArchiveBoxIcon } from '@heroicons/react/20/solid';

interface Props {
  offerStatus: OfferStatus;
  className?: string;
  isDeleted?: boolean | null;
  isArchived?: boolean | null;
  isActivated?: boolean | null;
  prefix?: JSX.Element;
}

const OfferStatusBadge = ({
  offerStatus,
  className = '',
  isDeleted,
  isArchived,
  isActivated,
  prefix,
}: Props) => {
  const { t } = useTranslation('common');
  const Icon = OFFER_STATUS_ICONS[offerStatus];
  return (
    <span
      className={`${
        isDeleted ? 'line-through opacity-75' : ''
      } flex items-center justify-center rounded px-2 py-0.5 text-xs font-medium uppercase ${
        OFFER_STATUS_CLASSES[offerStatus]
      } ${className}`}
    >
      {prefix && <p className="mr-1">{prefix}</p>}
      {isActivated && <ArchiveBoxIcon className="h-5 w-5" />}
      {isActivated && <span className="whitespace-pre"> → </span>}
      <Icon className="h-5 w-5" />
      <span className="ml-1">
        {t(`common:offerStatuses.values.${offerStatus}`)}
      </span>
      {isArchived && <span className="whitespace-pre"> → </span>}
      {isArchived && <ArchiveBoxIcon className="h-5 w-5" />}
    </span>
  );
};

export default OfferStatusBadge;
