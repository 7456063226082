import React from 'react';
import notFound from '../../assets/image-not-found.png';

interface Props {
  src: string;
  alt: string;
  onClick?: () => void;
  loading: 'eager' | 'lazy' | undefined;
}

const SliderImage = ({ src, alt, onClick, loading }: Props) => {
  const [isFallback, setIsFallback] = React.useState(false);
  return (
    <>
      <img
        className={`block h-full w-full cursor-pointer object-cover`}
        src={isFallback ? notFound : src}
        loading={loading}
        onClick={onClick}
        alt={alt}
        onError={() => setIsFallback(true)}
      />
      <div
        className="swiper-lazy-preloader"
        style={{ '--swiper-preloader-color': '#6366f1' } as any}
      />
    </>
  );
};

export default SliderImage;
