import React from 'react';

export const AuthContext = React.createContext<AuthContextProps | null>(null);

export interface AuthContextProps {
  loadUser: () => Promise<void>;
  logoutUser: () => void;
}

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};
