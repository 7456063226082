import { Menu } from '@headlessui/react';
import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../style/class-names';
import { useTranslation } from 'react-i18next';

interface Props {
  offerOriginalUrl: string;
}

const OpenOriginalOfferAction = ({ offerOriginalUrl }: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <Menu.Item>
      {({ active }) => (
        <a href={offerOriginalUrl} rel="noreferrer" target="_blank">
          <button
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'flex w-full px-4 py-2 text-sm',
            )}
          >
            <ArrowTopRightOnSquareIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>{t('common:offerMenu.openOriginal')}</span>
          </button>
        </a>
      )}
    </Menu.Item>
  );
};

export default OpenOriginalOfferAction;
