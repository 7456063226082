import React from 'react';
import { useTranslation } from 'react-i18next';
import TimeToNow from '../../formatters/time-to-now';
import ReactTooltip from 'react-tooltip';

interface Props {
  isActive?: boolean | null;
  smartDateLastSeen: string;
  className?: string;
}

const OfferLastSeenBadge = ({
  isActive,
  smartDateLastSeen,
  className,
}: Props) => {
  const { t } = useTranslation(['offers']);

  return (
    <>
      <div
        data-effect="solid"
        data-for="lastSeenBadge"
        data-tip={`${t(
          isActive
            ? 'offers:badges.lastSeen.active'
            : 'offers:badges.lastSeen.inactive',
        )} - ${t('offers:badges.lastSeen.tooltip')}`}
        className={`flex select-none items-center rounded px-1.5 py-0.5 text-xs font-medium ${
          isActive === true
            ? 'bg-green-100 text-green-700'
            : isActive === false
            ? 'bg-red-100 text-red-700'
            : 'bg-blue-100 text-blue-700'
        } ${className}`}
      >
        <svg
          className={`mr-1 h-1.5 w-1.5 ${
            isActive === true
              ? 'fill-green-400'
              : isActive === false
              ? 'fill-red-500'
              : 'hidden'
          }`}
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
        <TimeToNow date={smartDateLastSeen} className="ml-1 uppercase" strict />
      </div>
      <ReactTooltip id="lastSeenBadge" />
    </>
  );
};

export default OfferLastSeenBadge;
