import { OfferTypeEnum } from '../api/api.types';

export const generateOfferLink = ({
  id,
  offerType,
}: {
  id: string;
  offerType: OfferTypeEnum;
}) => {
  return `/offers/${offerType}/${id}`;
};
