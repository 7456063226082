import React from 'react';
import { User } from '../../models/user';

export interface UserContextProps {
  user: User;
}

export const UserContext = React.createContext<null | UserContextProps>(null);

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a AuthProvider`);
  }
  return context.user;
};
