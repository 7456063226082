import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postAddClientSearch } from '../../../api/client-searches/client-searches';
import { ANALYTICS_EVENTS } from '../../../constants/analytics';
import { AxiosError } from 'axios';
import { useToast } from '../../../hooks/use-toast/use-toast';
import { useAnalytics } from '../../../context/analytics/use-analytics';
import { isEmail, isPhoneSpaceAllowed } from '../../../constants/validation';
import { postAddClient } from '../../../api/clients/clients';
import { useOffersSearchFormValidation } from '../../../context/search-form/offers-search-form-validation';
import {
  SEARCH_FORM_KEYS,
  SearchFormValues,
} from '../../../context/search-form/offers-search-form';
import { addView } from '../../../api/views/views';
import {
  ClientSearchPaymentMethodEnum,
  OfferTypeEnum,
} from '../../../api/api.types';
import { filtersToForm, formToFilterList } from '../../../models/filter';
import { formToSortList, sortsToForm } from '../../../models/sort';
import { useUser } from '../../../context/auth/use-user';
import { format } from 'date-fns';
import { getOffersCount } from '../../../api/offers/offers';
import { SelectOption } from '../../../components/inputs/select-input/select-input';

interface Props {
  afterSuccessSubmit?: () => void;
}

const useAddClientSearchForm = ({ afterSuccessSubmit }: Props = {}) => {
  const schema = useSchema();
  const defaultValues = useDefaultValues();
  const { handleSubmit, ...rest } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const onSubmit = useOnSubmit({ afterSuccessSubmit });
  const offersCount = useOffersCount(rest.watch());

  return {
    ...rest,
    submit: handleSubmit(onSubmit.mutate),
    isSubmitLoading: onSubmit.isLoading,
    offersCount: offersCount.count,
    isOffersCountLoading: !offersCount.isCountLoaded,
    isOffersCountNotAvailable: offersCount.formNotValid,
  };
};

const useDefaultValues = () => {
  const user = useUser();
  return {
    [FormKeys.TITLE]: '',
    [FormKeys.DESCRIPTION]: '',
    [FormKeys.ASSIGNED_TO_ID]: user.userId,
    [FormKeys.PAYMENT_METHOD]: '',
    [FormKeys.CLIENT_ID]: null,
    [FormKeys.CLIENT_TYPE]: ClientType.EXISTING,
    [FormKeys.CLIENT_FIRST_NAME]: '',
    [FormKeys.CLIENT_LAST_NAME]: '',
    [FormKeys.CLIENT_PHONE_NUMBER]: '',
    [FormKeys.CLIENT_EMAIL]: '',
    [FormKeys.VIEW_TYPE]: ViewType.EXISTING,
    [FormKeys.VIEWS]: [],
    [SEARCH_FORM_KEYS.date_smart_last_seen.lastNDays]: 14,
    [SEARCH_FORM_KEYS.proximity]: 0,
    ...filtersToForm(user.defaultFilters(), user.settings.dateFormat),
    ...sortsToForm(user.defaultSorts()),
  } as Record<string, any>;
};

const useSchema = () => {
  const searchFormSchema = useOffersSearchFormValidation();
  const { t } = useTranslation(['validation', 'forms']);
  const searchSchemaOverride = searchFormSchema.shape({
    [SEARCH_FORM_KEYS.offersTypes]: Yup.mixed().when(FormKeys.VIEW_TYPE, {
      is: ViewType.EXISTING,
      then: Yup.mixed().nullable(),
      otherwise: Yup.array()
        .min(1, t('forms:errors.categoriesEmpty'))
        .of(Yup.mixed().oneOf(Object.values(OfferTypeEnum)))
        .required(t('validation:required')),
    }),
  });
  const clientSearchSchema = Yup.object({
    [FormKeys.TITLE]: Yup.string().required(t('validation:required')),
    [FormKeys.DESCRIPTION]: Yup.string(),
    [FormKeys.ASSIGNED_TO_ID]: Yup.string()
      .required(t('validation:required'))
      .nullable(),
    [FormKeys.PAYMENT_METHOD]: Yup.mixed<
      ClientSearchPaymentMethodEnum | undefined
    >()
      .transform((v) => (v === '' ? undefined : v))
      .oneOf(Object.values(ClientSearchPaymentMethodEnum))
      .notRequired(),
    [FormKeys.CLIENT_TYPE]: Yup.string().oneOf(Object.values(ClientType)),
    [FormKeys.CLIENT_ID]: Yup.string().when(FormKeys.CLIENT_TYPE, {
      is: ClientType.EXISTING,
      then: Yup.string().required(t('validation:required')).nullable(),
      otherwise: Yup.string().nullable(),
    }),
    [FormKeys.CLIENT_FIRST_NAME]: Yup.string().when(FormKeys.CLIENT_TYPE, {
      is: ClientType.EXISTING,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(t('validation:required')),
    }),
    [FormKeys.CLIENT_LAST_NAME]: Yup.string().when(FormKeys.CLIENT_TYPE, {
      is: ClientType.EXISTING,
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(t('validation:required')),
    }),
    [FormKeys.CLIENT_PHONE_NUMBER]: Yup.string().when(FormKeys.CLIENT_TYPE, {
      is: ClientType.EXISTING,
      then: Yup.string().nullable(),
      otherwise: isPhoneSpaceAllowed(t, {
        required: true,
      }),
    }),
    [FormKeys.CLIENT_EMAIL]: Yup.string().when(FormKeys.CLIENT_TYPE, {
      is: ClientType.EXISTING,
      then: Yup.string().nullable(),
      otherwise: isEmail(t).transform((v) => (v === '' ? null : v)),
    }),
    [FormKeys.VIEW_TYPE]: Yup.string().oneOf(Object.values(ViewType)),
  });
  return clientSearchSchema.concat(searchSchemaOverride);
};

const useOffersCount = (formData: FormValues) => {
  const {
    [FormKeys.TITLE]: _title,
    [FormKeys.DESCRIPTION]: _description,
    [FormKeys.ASSIGNED_TO_ID]: _assignedToId,
    [FormKeys.PAYMENT_METHOD]: _paymentMethod,
    [FormKeys.CLIENT_TYPE]: _clientType,
    [FormKeys.CLIENT_ID]: _clientId,
    [FormKeys.CLIENT_FIRST_NAME]: _clientFirstName,
    [FormKeys.CLIENT_LAST_NAME]: _clientLastName,
    [FormKeys.CLIENT_PHONE_NUMBER]: _clientPhoneNumber,
    [FormKeys.CLIENT_EMAIL]: _clientEmail,
    [FormKeys.VIEW_TYPE]: viewType,
    [FormKeys.VIEWS]: views,
    [SEARCH_FORM_KEYS.offersTypes]: offerTypes,
    [SEARCH_FORM_KEYS.sort]: _newViewSortRaw,
    ...newViewFiltersRaw
  } = formData;

  const filters = formToFilterList(newViewFiltersRaw as any);

  const { data, isLoading } = useQuery(
    ['offers', offerTypes, 'count', filters],
    () => getOffersCount(offerTypes as OfferTypeEnum[], filters),
    { enabled: viewType == ViewType.NEW, retry: 0 },
  );

  return {
    formNotValid: offerTypes === undefined || offerTypes.length === 0,
    count: data?.total_items ?? 0,
    isCountLoaded: viewType == ViewType.NEW && !isLoading,
  };
};

const useOnSubmit = ({
  afterSuccessSubmit,
}: {
  afterSuccessSubmit?: () => void;
}) => {
  const user = useUser();
  const { t } = useTranslation(['client-searches']);
  const client = useQueryClient();
  const analytics = useAnalytics();
  const { displaySuccessToast, displayDefaultErrorToasts, displayErrorToast } =
    useToast();
  const { mutate: createClientSearch, isLoading: createClientSearchIsLoading } =
    useMutation(postAddClientSearch, {
      onSuccess: async (data) => {
        displaySuccessToast({
          title: t('client-searches:drawers.add.successfulToastTitle'),
          body: t('client-searches:drawers.add.successfulToastDescription'),
        });
        await client.invalidateQueries(['clientSearches']);
        afterSuccessSubmit?.();
        await analytics.track(ANALYTICS_EVENTS.CLIENT_SEARCH_ADDED, {
          client_search_id: data.client_offer_search_id,
        });
      },
      onError: async (e: AxiosError) => {
        await analytics.track(ANALYTICS_EVENTS.CLIENT_SEARCH_CREATE_FAILED, {
          reason: e.message.substring(0, 1000),
        });
        displayDefaultErrorToasts(e);
      },
    });
  const { mutateAsync: createClient, isLoading: createClientIsLoading } =
    useMutation(postAddClient, {
      onSuccess: async () => {
        await client.invalidateQueries(['clients']);
        await client.invalidateQueries(['contactedClients']);
      },
      onError: async (e: AxiosError, variables) => {
        if (
          e.response?.status === 422 &&
          (e.response?.data as any)?.message?.includes('integrity constraint')
        ) {
          displayErrorToast({
            title: t(
              'client-searches:addOfferToClientSearchDrawer.clientDuplicate.title',
            ),
            body: t(
              'client-searches:addOfferToClientSearchDrawer.clientDuplicate.body',
            ),
          });
        } else {
          await analytics.track(ANALYTICS_EVENTS.CLIENT_SEARCH_CREATE_FAILED, {
            reason: e.message.substring(0, 1000),
            first_name: variables.body.first_name,
            last_name: variables.body.last_name,
            phone_number: variables.body.phone_number,
          });
          displayDefaultErrorToasts(e);
        }
      },
    });
  const { mutateAsync: createView, isLoading: createViewIsLoading } =
    useMutation(addView, {
      onSuccess: async (data) => {
        await analytics.track(ANALYTICS_EVENTS.VIEW_SAVED, {
          view_id: data.view_id,
          where: 'view_creation',
        });
        await client.invalidateQueries(['views']);
      },
      onError: async (error: AxiosError) => {
        await analytics.track(ANALYTICS_EVENTS.CLIENT_SEARCH_CREATE_FAILED, {
          reason: error.message.substring(0, 1000),
        });
        displayDefaultErrorToasts(error);
      },
    });

  return {
    isLoading:
      createClientSearchIsLoading ||
      createClientIsLoading ||
      createViewIsLoading,
    mutate: async (data: FormValues) => {
      const validatedData = data as FormValuesValidated; // we know it's validated, because this function only runs after yup validation

      const {
        [FormKeys.TITLE]: title,
        [FormKeys.DESCRIPTION]: description,
        [FormKeys.CLIENT_TYPE]: clientType,
        [FormKeys.ASSIGNED_TO_ID]: assignedToId,
        [FormKeys.PAYMENT_METHOD]: paymentMethod,
        [FormKeys.CLIENT_ID]: clientId,
        [FormKeys.CLIENT_FIRST_NAME]: clientFirstName,
        [FormKeys.CLIENT_LAST_NAME]: clientLastName,
        [FormKeys.CLIENT_PHONE_NUMBER]: clientPhoneNumber,
        [FormKeys.CLIENT_EMAIL]: clientEmail,
        [FormKeys.VIEW_TYPE]: viewType,
        [FormKeys.VIEWS]: views,
        [SEARCH_FORM_KEYS.offersTypes]: offerTypes,
        [SEARCH_FORM_KEYS.sort]: newViewSortRaw,
        ...newViewFiltersRaw
      } = validatedData;

      let finalClientId = clientId;
      if (clientType === ClientType.NEW) {
        const { client_id } = await createClient({
          body: {
            first_name: clientFirstName,
            last_name: clientLastName,
            phone_number: `+48${clientPhoneNumber}`,
            email: clientEmail ?? undefined,
          },
        });
        finalClientId = client_id;
      }

      let finalViews = views;
      if (viewType === ViewType.NEW) {
        const dateNowFormatted = format(new Date(), user.settings.dateFormat);
        const createViewResponse = await createView({
          body: {
            title: t('client-searches:drawers.add.defaultViewTitle', { title }),
            description: t(
              'client-searches:drawers.add.defaultViewDescription',
              { title, date: dateNowFormatted },
            ),
            offer_types: offerTypes as OfferTypeEnum[],
            filters: formToFilterList(newViewFiltersRaw as any),
            sorts: formToSortList(newViewSortRaw),
            subscription_type: undefined,
          },
        });
        finalViews = [createViewResponse.view_id];
      }

      await createClientSearch({
        body: {
          title: title,
          description: description,
          assigned_to_id: assignedToId,
          client_id: finalClientId,
          views: finalViews,
          payment_method: paymentMethod,
        },
      });
    },
  };
};

const usePaymentMethodOptions = (): SelectOption[] => {
  const { t } = useTranslation(['client-search']);

  return [
    {
      label: t('client-searches:fields.paymentMethodEnum.cash'),
      value: ClientSearchPaymentMethodEnum.CASH,
    },
    {
      label: t('client-searches:fields.paymentMethodEnum.loan'),
      value: ClientSearchPaymentMethodEnum.LOAN,
    },
    {
      label: t('client-searches:fields.paymentMethodEnum.other'),
      value: ClientSearchPaymentMethodEnum.OTHER,
    },
  ];
};

enum FormKeys {
  TITLE = 'client_search_title',
  PAYMENT_METHOD = 'client_search_payment_method',
  DESCRIPTION = 'client_search_description',
  ASSIGNED_TO_ID = 'client_search_assigned_to_id',
  CLIENT_TYPE = 'client_search_client_type',
  CLIENT_ID = 'client_search_client_id',
  CLIENT_FIRST_NAME = 'client_search_client_first_name',
  CLIENT_LAST_NAME = 'client_search_client_last_name',
  CLIENT_PHONE_NUMBER = 'client_search_client_phone_number',
  CLIENT_EMAIL = 'client_search_client_email',
  VIEW_TYPE = 'client_search_view_type',
  VIEWS = 'client_search_views',
}

interface FormValues extends SearchFormValues {
  [FormKeys.TITLE]: string;
  [FormKeys.PAYMENT_METHOD]?: string;
  [FormKeys.DESCRIPTION]: string;
  [FormKeys.CLIENT_TYPE]: string;
  [FormKeys.CLIENT_ID]: string | null;
  [FormKeys.CLIENT_FIRST_NAME]: string;
  [FormKeys.CLIENT_LAST_NAME]: string;
  [FormKeys.CLIENT_PHONE_NUMBER]: string;
  [FormKeys.CLIENT_EMAIL]: string;
  [FormKeys.VIEW_TYPE]: ViewType;
  [FormKeys.VIEWS]: string[];
  [FormKeys.ASSIGNED_TO_ID]: string;
}

interface FormValuesValidated extends FormValues {
  [FormKeys.CLIENT_ID]: string;
  [FormKeys.PAYMENT_METHOD]?: ClientSearchPaymentMethodEnum;
  [FormKeys.CLIENT_TYPE]: ClientType;
  [FormKeys.VIEW_TYPE]: ViewType;
}

enum ClientType {
  NEW = 'new',
  EXISTING = 'existing',
}

enum ViewType {
  NEW = 'new',
  EXISTING = 'existing',
}

export {
  useAddClientSearchForm,
  usePaymentMethodOptions,
  FormKeys as AddClientSearchFormKeys,
  ClientType as AddClientSearchClientType,
  ViewType as AddClientSearchViewType,
};
