import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FaAsterisk } from 'react-icons/all';
import { useTranslation } from 'react-i18next';

interface Props {
  text?: string;
  classNameWrapper?: string;
  classNameIcon?: string;
  size?: 'lg' | 'md' | 'sm' | 'xs';
}

const SIZE_TO_CLASSES = {
  lg: 'h-4 w-4',
  md: 'h-3 w-3',
  sm: 'h-2 w-2',
  xs: 'h-1.5 w-1.5',
};

const RequiredIcon = ({
  text,
  classNameWrapper = '',
  classNameIcon = '',
  size = 'md',
}: Props) => {
  const { t } = useTranslation('forms');
  const id = React.useId();
  return (
    <>
      <div
        data-for={id}
        data-tip={text ?? t('forms:labels.isRequired')}
        className={`rounded-full bg-red-50 p-0.5 ring-1 ring-red-700 ${classNameWrapper}`}
      >
        <FaAsterisk
          className={`text-red-700 ${SIZE_TO_CLASSES[size]} ${classNameIcon}`}
        />
      </div>
      <ReactTooltip id={id} multiline />
    </>
  );
};

export default RequiredIcon;
