import React from 'react';
import { generateColorFromString } from '../../utils/generate-color-from-string';
import { INDIGO_500 } from '../../style/constants';

interface Props {
  name: string;
  teamId?: string;
  color?: string;
  textClassName?: string;
  className?: string;
}

const TeamBadge = ({
  name,
  color,
  teamId,
  textClassName = '',
  className = '',
}: Props) => {
  const teamColor = color
    ? color
    : teamId
    ? generateColorFromString(teamId)
    : INDIGO_500;
  return (
    <span
      className={`${className} inline-flex items-center gap-x-1.5 rounded-full border-2 bg-white px-2 py-0.5 text-xs font-medium text-gray-900`}
      style={{ borderColor: teamColor }}
    >
      <svg
        className="h-1.5 w-1.5"
        fill={teamColor}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      <span className={textClassName}>{name}</span>
    </span>
  );
};

export default TeamBadge;
