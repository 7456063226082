import { axios } from '../axios';
import { OfferListResponse, UserPublicResponse } from '../api.types';
import { TeamResponse } from '../teams/teams.api';

export interface AssignedOffersCountResponse {
  count: number;
}

export enum AssignmentMethodEnum {
  MANUAL = 'MANUAL',
  AUTOMATION = 'AUTOMATION',
}

export enum AssignmentReasonEnum {
  NEW_OFFER = 'NEW_OFFER',
  RESHUFFLE = 'RESHUFFLE',
  MANUALLY_ASSIGNED = 'MANUALLY_ASSIGNED',
}

export interface OfferAssignmentResponse {
  offer_assignment_id: string;
  assigned_to_team: TeamResponse;
  created_at: string;
  assignment_method: AssignmentMethodEnum;
  assignment_reason: AssignmentReasonEnum;
  created_by: UserPublicResponse;
  valid_until?: string;
}

export interface AssignOfferRequest {
  team_id?: string;
}

export const getAssignedOffersCounts = ({
  teamId,
  fromDate,
  unassigned,
}: {
  teamId?: string;
  fromDate?: Date;
  unassigned?: boolean;
} = {}) => {
  return axios
    .get<AssignedOffersCountResponse>('/assigned-offers/count', {
      params: {
        team_id: teamId,
        from_date: fromDate?.toISOString(),
        unassigned,
      },
    })
    .then((resp) => resp.data);
};

export const getAssignedOffers = ({
  page,
  size,
  teamId,
  unassigned,
}: {
  page: number;
  size: number;
  teamId?: string;
  unassigned?: boolean;
}): Promise<OfferListResponse> => {
  return axios
    .get('/assigned-offers', {
      params: { page, size, team_id: teamId, unassigned },
    })
    .then((resp) => resp.data);
};

export const getAssignmentsForOffer = (offerId: string) => {
  return axios
    .get<OfferAssignmentResponse[]>(`/offers/${offerId}/assignments`)
    .then((resp) => resp.data);
};

export const getAssignmentForOffer = (offerId: string) => {
  return axios
    .get<OfferAssignmentResponse>(`/offers/${offerId}/assignment`)
    .then((resp) => resp.data);
};

export const postAssignOffer = ({
  offerId,
  body,
}: {
  offerId: string;
  body: AssignOfferRequest;
}) => {
  return axios
    .post(`/offers/${offerId}/assignment`, body)
    .then((resp) => resp.data);
};
