import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OfferCollectionWithOfferInclusionItem,
  putOfferToOfferCollection,
  removeOfferFromOfferCollection,
} from '../../../api/offer-collections/offer-collections';
import { OfferCollectionCardBase } from './offer-collection-card-base';
import {
  FolderMinusIcon,
  FolderPlusIcon,
  WrenchIcon,
} from '@heroicons/react/20/solid';
import { EditOfferCollectionDrawer } from '../offer-collection-drawers/edit-offer-collection-drawer';
import { useMutation } from '@tanstack/react-query';
import { useOfferCollectionInvalidation } from '../../../hooks/use-invalidation/use-offer-collections-invalidation';
import UserAvatar from '../../avatar/user-avatar';
import { useOffersInvalidation } from '../../../hooks/use-invalidation/use-offers-invalidation';
import { useOfferCollectionPermissions } from '../../../hooks/use-offer-collections/use-offer-collection-permissions';
import { useAnalytics } from '../../../context/analytics/use-analytics';
import { useToast } from '../../../hooks/use-toast/use-toast';
import { AxiosError } from 'axios';
import { ANALYTICS_EVENTS } from '../../../constants/analytics';

interface Props {
  offerId: string;
  data: OfferCollectionWithOfferInclusionItem;
}

export const OfferCollectionCardForOffer = ({ data, offerId }: Props) => {
  const { canEdit, canManageContent } = useOfferCollectionPermissions(data);
  const analytics = useAnalytics();
  const { displaySuccessToast, displayErrorToast, displayDefaultErrorToasts } =
    useToast();

  const [isIncludedOptimisticLoading, setIsIncludedOptimisticLoading] =
    useState<boolean>();
  const invalidateOfferCollections = useOfferCollectionInvalidation();
  const invalidateOffers = useOffersInvalidation();
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation(['offer-collections']);

  const { mutate: addOfferToCollection } = useMutation(
    putOfferToOfferCollection,
    {
      onSuccess: async () => {
        displaySuccessToast({
          title: t('offer-collections:offer.successAddToast.title'),
          body: t('offer-collections:offer.successAddToast.body'),
        });
        await invalidateOfferCollections({
          subPaths: [['forOffer'], ['details']],
        });
        await invalidateOffers();
        await analytics.track(
          ANALYTICS_EVENTS.ADDED_OFFER_TO_OFFER_COLLECTION,
          {
            offerId: offerId,
            offerCollectionId: data.offer_collection_id,
            from: 'OfferCollectionCardForOffer',
          },
        );
      },
      onError: async (error: AxiosError) => {
        if (error.response?.status === 403) {
          displayErrorToast({
            title: t('offer-collections:offer.permissionsToast.title'),
            body: t('offer-collections:offer.permissionsToast.body'),
          });
        } else if (error.response?.status === 409) {
          displayErrorToast({
            title: t('offer-collections:offer.duplicateToast.title'),
            body: t('offer-collections:offer.duplicateToast.body'),
          });
        } else {
          displayDefaultErrorToasts(error);
        }
        setIsIncludedOptimisticLoading(undefined);
      },
    },
  );

  const { mutate: deleteOfferFromCollection } = useMutation(
    removeOfferFromOfferCollection,
    {
      onSuccess: async () => {
        displaySuccessToast({
          title: t('offer-collections:offer.successRemoveToast.title'),
          body: t('offer-collections:offer.successRemoveToast.body'),
        });
        await invalidateOfferCollections({
          subPaths: [['forOffer'], ['details']],
        });
        await invalidateOffers();
        await analytics.track(
          ANALYTICS_EVENTS.DELETED_OFFER_FROM_OFFER_COLLECTION,
          {
            offerId: offerId,
            offerCollectionId: data.offer_collection_id,
            from: 'OfferCollectionCardForOffer',
          },
        );
      },
      onError: async (error: AxiosError) => {
        if (error.response?.status === 403) {
          displayErrorToast({
            title: t('offer-collections:offer.permissionsToast.title'),
            body: t('offer-collections:offer.permissionsToast.body'),
          });
        } else if (error.response?.status === 409) {
          displayErrorToast({
            title: t('offer-collections:offer.notMemberToast.title'),
            body: t('offer-collections:offer.notMemberToast.body'),
          });
        } else {
          displayDefaultErrorToasts(error);
        }
        setIsIncludedOptimisticLoading(undefined);
      },
    },
  );

  const isIncluded =
    isIncludedOptimisticLoading ?? data.offer_inclusion.is_included;

  React.useEffect(() => {
    if (data !== undefined) {
      setIsIncludedOptimisticLoading(undefined);
    }
  }, [data]);

  return (
    <>
      <OfferCollectionCardBase
        content={
          <div className="mt-2 flex gap-x-1 text-gray-600">
            <span className="truncate text-sm">
              <strong>{t('offer-collections:cards.owner')}:</strong>{' '}
              {data.created_by.first_name} {data.created_by.last_name}
            </span>
            <UserAvatar {...data.created_by} size="sm" />
          </div>
        }
        data={data}
        buttons={
          <div className="flex w-full">
            {isIncluded ? (
              <button
                type="button"
                disabled={
                  isIncludedOptimisticLoading !== undefined || !canManageContent
                }
                onClick={() => {
                  setIsIncludedOptimisticLoading(false);
                  deleteOfferFromCollection({
                    offerId,
                    offerCollectionId: data.offer_collection_id,
                  });
                }}
                className="flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-gray-50 disabled:!bg-gray-50 disabled:!text-gray-300"
              >
                <FolderMinusIcon
                  className="h-5 w-5 text-pink-500"
                  aria-hidden="true"
                />
                <span>{t('offer-collections:cards.remove')}</span>
              </button>
            ) : (
              <button
                type="button"
                disabled={
                  isIncludedOptimisticLoading !== undefined || !canManageContent
                }
                onClick={() => {
                  setIsIncludedOptimisticLoading(true);
                  addOfferToCollection({
                    offerId,
                    offerCollectionId: data.offer_collection_id,
                  });
                }}
                className="flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-gray-50 disabled:!bg-gray-50 disabled:!text-gray-400"
              >
                <FolderPlusIcon
                  className="h-5 w-5 text-emerald-500 "
                  aria-hidden="true"
                />
                <span>{t('offer-collections:cards.add')}</span>
              </button>
            )}

            <button
              type="button"
              disabled={!canEdit}
              onClick={() => setIsEditing(true)}
              className="flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-l border-transparent border-l-gray-200 py-4 text-sm font-semibold text-gray-900 hover:bg-gray-50 disabled:!bg-gray-50 disabled:!text-gray-400"
            >
              <WrenchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>{t('offer-collections:cards.edit')}</span>
            </button>
          </div>
        }
      />
      <EditOfferCollectionDrawer
        isOpen={isEditing}
        close={() => setIsEditing(false)}
        offerCollectionId={data.offer_collection_id}
        currentOfferCollectionData={data}
        createdById={data.created_by_id}
      />
    </>
  );
};
