import React from 'react';
import OfferList from '../../components/offer-list/offer-list';
import { useQuery } from '@tanstack/react-query';
import { getAssignedOffers } from '../../api/offer-assignments/offer-assignments';
import { useUser } from '../../context/auth/use-user';
import { UserGroupIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from '../../hooks/use-search/use-search-params';
import TeamInputPopulated from '../../components/inputs/team-input/team-input-populated';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseUrl, stringifyUrl } from 'query-string';

function generatePath(basePath: string, teamId: string | null) {
  const parsed = parseUrl(basePath);

  return stringifyUrl({
    url: parsed.url,
    query: { ...parsed.query, teamId },
  });
}

interface Props {
  teamId?: string;
  enabled?: boolean;
  unassigned?: boolean;
}

const SalesAssistantOfferList = ({
  teamId: teamIdProp,
  enabled = true,
  unassigned,
}: Props) => {
  const { page = '1', teamId } = useSearchParams();
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('sales-assistant');
  const selectedTeam = teamIdProp ? teamIdProp : teamId;

  const { data, isLoading } = useQuery(
    ['offers', 'assigned', { teamId: selectedTeam, page, unassigned }],
    () =>
      getAssignedOffers({
        teamId: selectedTeam,
        unassigned,
        page: Number(page),
        size: user.settings.itemsPerPage,
      }),
    { enabled },
  );

  if (!enabled) {
    return (
      <div className="mt-12 text-center">
        <UserGroupIcon className="mx-auto h-10 w-10 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {t('sales-assistant:offerList.noTeam.title')}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {t('sales-assistant:offerList.noTeam.description')}
        </p>
      </div>
    );
  }

  return (
    <div>
      {!teamIdProp && !unassigned && (
        <div className="mx-auto mt-4 grid max-w-7xl grid-cols-1 space-x-2 px-8 md:grid-cols-2">
          <div>
            <TeamInputPopulated
              label="Zespół"
              value={teamId}
              onChange={(v) =>
                navigate(generatePath(location.pathname + location.search, v), {
                  replace: true,
                })
              }
            />
          </div>
        </div>
      )}
      <OfferList isLoading={isLoading} data={data} page={Number(page)} />
    </div>
  );
};

export default SalesAssistantOfferList;
