import {
  AddClientRequest,
  ClientDetailsResponse,
  ClientListResponse,
  ContactedClientResponse,
  CreateClientResponse,
  UpdateClientRequest,
} from '../api.types';
import { axios } from '../axios';

export const getClients = (): Promise<ClientListResponse[]> => {
  return axios.get(`/clients`).then((resp) => resp.data);
};

export const getContactedClients = (): Promise<ContactedClientResponse[]> => {
  return axios.get(`/contacted-clients`).then((resp) => resp.data);
};

export const getClientDetails = (
  clientId: string,
): Promise<ClientDetailsResponse> => {
  return axios.get(`/clients/${clientId}`).then((resp) => resp.data);
};

export const postAddClient = ({
  body,
}: {
  body: AddClientRequest;
}): Promise<CreateClientResponse> => {
  return axios.post(`/clients`, body).then((resp) => resp.data);
};

export const putUpdateClient = ({
  body,
  clientId,
}: {
  body: UpdateClientRequest;
  clientId: string;
}) => {
  return axios.put(`/clients/${clientId}`, body);
};
