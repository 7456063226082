import React from 'react';
import ClientSearchInput from '../inputs/client-search-input/client-search-input';
import Button from '../button/button';
import { OfferTypeEnum } from '../../api/api.types';
import DrawerWithDescription from '../drawers/drawer-with-description';
import { useTranslation } from 'react-i18next';
import ClientInput from '../inputs/client-input/client-input';
import Tabs from '../tabs/tabs';
import TextInputLabeledController from '../inputs/text-input/text-input-labeled-controller';
import TextInputLabeled from '../inputs/text-input/text-input-labeled';
import { useAddToClientSearch } from '../../hooks/use-add-to-client-search/use-add-to-client-search';
import { UseAddToClientSearchState } from '../../hooks/use-add-to-client-search/use-add-to-cleint-search-state';
import {
  AddClientFormKeys,
  ClientSelection,
  UseAddToClientSearchClientData,
} from '../../hooks/use-add-to-client-search/use-add-to-client-search-client';
import {
  ClientSearchSelection,
  UseAddToClientSearchClientSearchData,
} from '../../hooks/use-add-to-client-search/use-add-to-client-search-client-search';
import SpinnerIcon from '../spinner/spinner-icon';
import { UseAddToClientSearchRedirectData } from '../../hooks/use-add-to-client-search/use-add-to-client-search-redirect';
import CheckboxInputLabeled from '../inputs/checkbox-input/checkbox-input-labeled';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';

interface Props {
  isOpen: boolean;
  close: () => void;
  offerId: string;
  offerType: OfferTypeEnum;
}

const AddToClientSearchDrawer = ({
  isOpen,
  close,
  offerId,
  offerType,
}: Props) => {
  const { t } = useTranslation(['client-searches', 'clients']);
  const analytics = useAnalytics();
  const {
    shouldDisplayClientSelection,
    shouldDisplayClientSearchSelection,
    canSubmit,
    submit,
    state,
    clientSearch,
    client,
    reset,
    init,
    redirect,
  } = useAddToClientSearch({ offerId, offerType });

  const onClose = () => {
    reset();
    close();
  };

  React.useEffect(() => {
    if (state === UseAddToClientSearchState.SUBMITTED) {
      onClose();
    }
  }, [state]);

  React.useEffect(() => {
    if (isOpen) {
      analytics.track(
        ANALYTICS_EVENTS.CLIENT_SEARCH_ADD_FROM_OFFER_PANEL_OPENED,
        {
          offerId,
          offerType,
        },
      );
      init();
    }
  }, [isOpen, offerId]);

  return (
    <DrawerWithDescription
      title={t('client-searches:addOfferToClientSearchDrawer.title')}
      description={t(
        'client-searches:addOfferToClientSearchDrawer.description',
      )}
      buttons={
        <div className="flex flex-row">
          <Button
            type="button"
            variant="secondary"
            className="ml-auto mt-3 px-3 py-2"
            onClick={onClose}
          >
            {t('client-searches:addOfferToClientSearchDrawer.cancel')}
          </Button>
          <Button
            type="button"
            variant="primary"
            className="ml-3 mt-3 px-3 py-2"
            isLoading={state === UseAddToClientSearchState.SUBMITTING}
            disabled={!canSubmit}
            onClick={submit}
          >
            {t('client-searches:addOfferToClientSearchDrawer.add')}
          </Button>
        </div>
      }
      close={onClose}
      isOpen={isOpen}
    >
      <ClientSection display={shouldDisplayClientSelection} client={client} />
      <ClientSearchSection
        display={shouldDisplayClientSearchSelection}
        state={state}
        clientSearch={clientSearch}
        client={client}
        redirect={redirect}
      />
    </DrawerWithDescription>
  );
};

export default AddToClientSearchDrawer;

const ClientSection = ({
  display,
  client,
}: {
  display: boolean;
  client: UseAddToClientSearchClientData;
}) => {
  const { t } = useTranslation(['client-searches', 'clients']);
  return (
    <div className="py-6">
      {display && (
        <Tabs
          tabs={[
            {
              key: ClientSelection.EXISTING,
              text: t(
                'client-searches:addOfferToClientSearchDrawer.client.existing',
              ),
            },
            {
              key: ClientSelection.NEW,
              text: t(
                'client-searches:addOfferToClientSearchDrawer.client.new',
              ),
            },
          ]}
          value={client.selection.value}
          onChange={client.selection.set as any}
          title={t('client-searches:addOfferToClientSearchDrawer.client.title')}
        >
          <Tabs.Tab key={ClientSelection.EXISTING}>
            <div className="mb-2 pt-2">
              <ClientInput
                id="client-input"
                label={t(
                  'client-searches:addOfferToClientSearchDrawer.client.select',
                )}
                onChange={client.existing.set}
                value={client.existing.value}
              />
            </div>
          </Tabs.Tab>
          <Tabs.Tab key={ClientSelection.NEW}>
            <div className="flex justify-between gap-2 pt-2">
              <TextInputLabeledController
                name={AddClientFormKeys.FIRST_NAME}
                control={client.new.control}
                id={AddClientFormKeys.FIRST_NAME}
                labelClassName="mb-1"
                label={t('clients:form.fields.firstName')}
                saveErrorSpace={true}
              />
              <TextInputLabeledController
                name={AddClientFormKeys.LAST_NAME}
                control={client.new.control}
                id={AddClientFormKeys.LAST_NAME}
                labelClassName="mb-1"
                label={t('clients:form.fields.lastName')}
                saveErrorSpace={true}
              />
            </div>
            <div className="pt-2">
              <TextInputLabeledController
                label={t('clients:form.fields.phoneNumber')}
                control={client.new.control}
                prefixIcon={<div className="sm:text-sm">+48</div>}
                name={AddClientFormKeys.PHONE_NUMBER}
                id={AddClientFormKeys.PHONE_NUMBER}
                labelClassName="mb-1"
                saveErrorSpace={true}
              />
            </div>
          </Tabs.Tab>
        </Tabs>
      )}
    </div>
  );
};

const NON_EXISTING_UUID = 'e57bcf6f-7410-4801-835e-df37a4dfad5d';

const ClientSearchSection = ({
  clientSearch,
  client,
  display,
  state,
  redirect,
}: {
  clientSearch: UseAddToClientSearchClientSearchData;
  client: UseAddToClientSearchClientData;
  state: UseAddToClientSearchState;
  redirect: UseAddToClientSearchRedirectData;
  display: boolean;
}) => {
  const { t } = useTranslation(['client-searches']);
  return (
    <div className="py-6">
      {state === UseAddToClientSearchState.CLIENT_SEARCH_LOADING && (
        <SpinnerIcon className="mx-auto my-12 h-5 w-5 text-indigo-600" />
      )}
      {display && (
        <>
          <Tabs
            tabs={[
              ...(client.selection.value === ClientSelection.EXISTING &&
              clientSearch.haveClientSearches
                ? [
                    {
                      key: ClientSearchSelection.EXISTING,
                      text: t(
                        'client-searches:addOfferToClientSearchDrawer.search.existing',
                      ),
                    },
                  ]
                : []),
              {
                key: ClientSearchSelection.NEW,
                text: t(
                  'client-searches:addOfferToClientSearchDrawer.search.new',
                ),
              },
            ]}
            value={clientSearch.selection.value}
            onChange={clientSearch.selection.set as any}
            title={t(
              'client-searches:addOfferToClientSearchDrawer.search.title',
            )}
          >
            <Tabs.Tab key={ClientSearchSelection.EXISTING}>
              <div className="pt-2">
                <ClientSearchInput
                  value={clientSearch.existing.value}
                  onChange={clientSearch.existing.set}
                  clientId={client.clientId ?? NON_EXISTING_UUID}
                  label={t(
                    'client-searches:addOfferToClientSearchDrawer.search.select',
                  )}
                />
              </div>
            </Tabs.Tab>
            <Tabs.Tab key={ClientSearchSelection.NEW}>
              <div className="pt-2">
                <TextInputLabeled
                  id="client-search"
                  labelClassName="mb-2"
                  label={t(
                    'client-searches:addOfferToClientSearchDrawer.search.name',
                  )}
                  value={clientSearch.new.value}
                  onChange={clientSearch.new.set as any}
                />
              </div>
            </Tabs.Tab>
          </Tabs>
          <div
            className={
              clientSearch.selection.value === ClientSearchSelection.NEW
                ? 'pt-3'
                : 'pt-6'
            }
          >
            <CheckboxInputLabeled
              id="should-redirect"
              onChange={redirect.set}
              value={redirect.value}
              label={t('client-searches:addOfferToClientSearchDrawer.redirect')}
            />
          </div>
        </>
      )}
    </div>
  );
};
