import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  currency: string;
  divider?: number;
  fractionDigits?: number;
}

const CurrencyFormatter = ({
  value,
  currency,
  divider,
  fractionDigits = 0,
}: Props) => {
  const { i18n } = useTranslation();

  const val = divider ? value / divider : value;
  return (
    <>
      {Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'symbol',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      }).format(val)}
    </>
  );
};

export default CurrencyFormatter;
