import React from 'react';
import { SubscriptionTierType } from '../../models/user';
import { useUser } from '../../context/auth/use-user';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  requiredSubscriptionTier?: SubscriptionTierType;
  className?: string;
  children: React.ReactNode;
}

const SubscriptionFeatureLock = ({
  requiredSubscriptionTier,
  children,
  className = '',
}: Props) => {
  const user = useUser();
  const { t } = useTranslation('common');

  if (!requiredSubscriptionTier) {
    return <div className={className}>{children}</div>;
  }

  const isSubscriptionTierSufficient = user.hasSufficientSubscriptionTier(
    requiredSubscriptionTier,
  );

  const isValid = user.hasActiveSubscription();

  if (isSubscriptionTierSufficient && isValid) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div
      className={`relative ${className}`}
      data-effect="solid"
      data-tip={
        isSubscriptionTierSufficient
          ? t('common:subscription.constrains.inactiveSubscription')
          : t('common:subscription.constrains.insufficientTier', {
              tier: t(`common:subscription.tier.${requiredSubscriptionTier}`),
            })
      }
      data-for="disabledBySubscription"
    >
      <div className="pointer-events-none opacity-50">{children}</div>
      <div className="absolute bottom-0 right-2 top-0 z-50 flex items-center justify-center text-white">
        <LockClosedIcon className={`h-5 w-5 rounded-full bg-indigo-500 p-1`} />
      </div>
      <ReactTooltip id="disabledBySubscription" />
    </div>
  );
};

export default SubscriptionFeatureLock;
