export interface Sort {
  field: string;
  ascending: boolean;
}

export function formToSortList(sort: string): Sort[] {
  if (!sort) {
    return [];
  }
  const [field, condition] = sort.split('.');

  return [{ field, ascending: condition === 'ascending' }];
}

export function sortsToForm(sorts: Sort[] | null): Record<string, string> {
  if (!sorts) {
    return {};
  }
  if (sorts.length <= 0) {
    return {};
  }

  return {
    sort: `${sorts[0].field}.${
      sorts[0].ascending ? 'ascending' : 'descending'
    }`,
  };
}

export function sortsToFormValue(sorts: Sort[] | null): string | null {
  if (!sorts) {
    return null;
  }
  if (sorts.length <= 0) {
    return null;
  }

  return `${sorts[0].field}.${sorts[0].ascending ? 'ascending' : 'descending'}`;
}

export function isSortArray(object: any): object is Sort[] {
  return Array.isArray(object) && object.every(isSort);
}

export function isSort(object: any): object is Sort {
  return (
    !!object &&
    typeof object === 'object' &&
    typeof object.field === 'string' &&
    typeof object.ascending === 'boolean'
  );
}
