import AppUserAvatar from '../avatar/app-user-avatar';
import React from 'react';
import { useUser } from '../../context/auth/use-user';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addOfferComment } from '../../api/comments/comments';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import * as yup from 'yup';
import { OfferTypeEnum } from '../../api/api.types';
import Button from '../button/button';
import { useSm } from '../../style/media-queries';
import { useOffersInvalidation } from '../../hooks/use-invalidation/use-offers-invalidation';

interface AddCommentFormData {
  contents: string;
}

interface Props {
  offerType: OfferTypeEnum;
  offerId: string;
}

const ActivityCommentForm = ({ offerId, offerType }: Props) => {
  const user = useUser();
  const analytics = useAnalytics();
  const invalidateOffers = useOffersInvalidation();
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');

  const schema = React.useMemo(
    () =>
      yup
        .object({
          contents: yup.string().required(t('common:validation.string.empty')),
        })
        .required(),
    [t],
  );

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<AddCommentFormData>({
    defaultValues: { contents: '' },
    resolver: yupResolver(schema),
  });

  const { mutate: mutateAddComment, isLoading: isCommentAddingMutating } =
    useMutation(addOfferComment, {
      onSuccess: async () => {
        reset();
        await queryClient.invalidateQueries([
          'offerComments',
          offerType,
          offerId,
        ]);
        await invalidateOffers({
          subPaths: [['callList'], [offerType], ['savedOffers', 'recent']],
        });
        await analytics.track(ANALYTICS_EVENTS.COMMENT_ADDED, {
          offerId,
          offerType,
        });
      },
      onError: (e: AxiosError) => defaultErrorToasts(e, t),
    });
  const onSubmit = async (data: AddCommentFormData) => {
    await mutateAddComment({
      offerId,
      offerType,
      body: data,
    });
    reset();
  };

  const isSm = useSm();

  return (
    <>
      <div className="mt-4 flex gap-x-3">
        <AppUserAvatar {...user} size="md" className="z-10" />
        <form className="relative flex-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              {t('offers:timeline.addComment')}
            </label>
            <textarea
              rows={isSm ? 2 : 3}
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder={t('offers:timeline.addCommentPlaceholder')}
              defaultValue={''}
              {...register('contents')}
            />
          </div>
          <p className="mt-2 whitespace-pre text-xs text-red-600">
            {typeof errors?.contents?.message === 'string'
              ? t(errors?.contents?.message)
              : ' '}
          </p>

          <div className="absolute inset-x-0 bottom-8 flex h-8 justify-between pl-3 pr-2">
            <Button
              isLoading={isCommentAddingMutating}
              type="submit"
              variant="primary"
              className="ml-auto px-2.5 py-1.5"
            >
              {t('offers:timeline.addCommentSubmit')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ActivityCommentForm;
