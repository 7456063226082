import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PersonInput from '../person-input/person-input';
import { getClientSearches } from '../../../api/client-searches/client-searches';

// TODO increase if needed
const TEMP_SIZE = 50;
interface Props {
  value: string | null;
  onChange: (user: string | null) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  isError?: boolean;
  enabled?: boolean;
  clientId?: string | null;
}

const ClientSearchInput = ({
  value,
  onChange,
  enabled = true,
  clientId,
  ...rest
}: Props) => {
  const params = { page: 1, size: TEMP_SIZE };
  const { data } = useQuery(
    ['clientSearches', params, clientId],
    () => getClientSearches({ ...params, ...(clientId ? { clientId } : {}) }),
    { enabled },
  );

  React.useEffect(() => {
    if (!data?.results?.some((x) => x.client_offer_search_id === value)) {
      onChange(null);
    }
  }, [value]);

  return (
    <PersonInput
      value={value}
      {...rest}
      onChange={onChange}
      displayFormatter={(person) =>
        person
          ? `${person?.firstName} ${person?.lastName} ${person?.extraText}`
          : ''
      }
      people={
        data?.results.map((c) => ({
          firstName: c.client.first_name,
          lastName: c.client.last_name,
          extraText: c.title,
          id: c.client_offer_search_id,
          phoneNumber: '',
        })) ?? []
      }
    />
  );
};

export default ClientSearchInput;
