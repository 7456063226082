import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import OfferPhoto from './offer-photo';

interface Props {
  dataUrl: string;
  onImageRemove: () => void;
  setDataUrl: (dataUrl: string) => void;
  id: number;
}

const OfferSortablePhoto = ({ id, ...props }: Props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`${isDragging ? 'opacity-50' : ''} mx-auto`}
    >
      <OfferPhoto {...props} />
    </div>
  );
};

export default OfferSortablePhoto;
