import {
  CommentResponse,
  DetailedPhoneCallSummaryResponse,
  OfferStatus,
  SmsLogResponse,
  UserPublicResponse,
} from '../api/api.types';
import { OfferAssignmentResponse } from '../api/offer-assignments/offer-assignments';
import {
  MeetingActivityCreateLog,
  MeetingActivityReassignLog,
  MeetingActivityStatusChangeLog,
} from '../api/meetings/meetings-dto';

export enum OfferActivityEventType {
  STATUS_ADDED,
  STATUS_REMOVED,
  STATUS_CHANGED,
  STATUS_ARCHIVED,
  STATUS_ACTIVATED,
  COMMENT_ADDED,
  PHONE_CALL_SUMMARY_ADDED,
  SMS_SENT,
  OFFER_ASSIGNED,
  MEETING_ADDED,
  MEETING_ASSIGNMENT_CHANGE,
  MEETING_STATUS_CHANGE,
}

interface OfferActivityEvent {
  time: Date;
}

export interface OfferStatusRemoved extends OfferActivityEvent {
  user: UserPublicResponse;
  status: OfferStatus;
  type: OfferActivityEventType.STATUS_REMOVED;
}

export interface OfferStatusAdded extends OfferActivityEvent {
  user: UserPublicResponse;
  status: OfferStatus;
  type: OfferActivityEventType.STATUS_ADDED;
}

export interface OfferStatusArchived extends OfferActivityEvent {
  user: UserPublicResponse;
  status: OfferStatus;
  type: OfferActivityEventType.STATUS_ARCHIVED;
}

export interface OfferStatusActivated extends OfferActivityEvent {
  user: UserPublicResponse;
  status: OfferStatus;
  type: OfferActivityEventType.STATUS_ACTIVATED;
}

export interface OfferStatusChanged extends OfferActivityEvent {
  user: UserPublicResponse;
  fromStatus: OfferStatus;
  toStatus: OfferStatus;
  type: OfferActivityEventType.STATUS_CHANGED;
}

export interface CommentAdded extends OfferActivityEvent {
  comment: CommentResponse;
  type: OfferActivityEventType.COMMENT_ADDED;
}

export interface PhoneCallSummaryAdded extends OfferActivityEvent {
  phoneCallSummary: DetailedPhoneCallSummaryResponse;
  fromStatus?: OfferStatus;
  toStatus?: OfferStatus;
  type: OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED;
}

export interface SmsSent extends OfferActivityEvent {
  type: OfferActivityEventType.SMS_SENT;
  sms: SmsLogResponse;
}

export interface OfferAssigned extends OfferActivityEvent {
  type: OfferActivityEventType.OFFER_ASSIGNED;
  assignment: OfferAssignmentResponse;
}

export interface MeetingAdded extends OfferActivityEvent {
  type: OfferActivityEventType.MEETING_ADDED;
  meeting: MeetingActivityCreateLog;
}

export interface MeetingAssignmentChange extends OfferActivityEvent {
  type: OfferActivityEventType.MEETING_ASSIGNMENT_CHANGE;
  meeting: MeetingActivityReassignLog;
}

export interface MeetingStatusChange extends OfferActivityEvent {
  type: OfferActivityEventType.MEETING_STATUS_CHANGE;
  meeting: MeetingActivityStatusChangeLog;
}

export type OfferActivity =
  | OfferStatusAdded
  | OfferStatusChanged
  | OfferStatusRemoved
  | OfferStatusArchived
  | OfferStatusActivated
  | CommentAdded
  | PhoneCallSummaryAdded
  | SmsSent
  | OfferAssigned
  | MeetingAdded
  | MeetingAssignmentChange
  | MeetingStatusChange;
