import { axios } from '../axios';

export interface TeamResponse {
  team_id: string;
  name: string;
  created_at: string;
}

export interface TeamDetailsResponse {
  team_id: string;
  name: string;
  created_at: string;
  members_count: number;
}

export const getTeams = () => {
  return axios.get<TeamDetailsResponse[]>('/teams').then((resp) => resp.data);
};

export interface CreateTeamRequest {
  name: string;
}

export const postCreateTeam = ({ body }: { body: CreateTeamRequest }) => {
  return axios.post('/teams', body);
};

export interface UpdateTeamRequest {
  name: string;
}

export const putUpdateTeam = ({
  teamId,
  body,
}: {
  teamId: string;
  body: UpdateTeamRequest;
}) => {
  return axios.put(`/teams/${teamId}`, body);
};

export interface AssignUserToTeamRequest {
  user_id: string;
}

export const postAssignUserToTeam = ({
  teamId,
  body,
}: {
  teamId: string;
  body: AssignUserToTeamRequest;
}) => {
  return axios.post(`/teams/${teamId}/users/assign`, body);
};

export const postRemoveUserFromTeam = ({ userId }: { userId: string }) => {
  return axios.delete(`/teams/users/${userId}`);
};
