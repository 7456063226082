import React, { FormEventHandler, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useFastSearch } from '../../hooks/use-fast-search/use-fast-search';
import { useTranslation } from 'react-i18next';
import SpinnerIcon from '../spinner/spinner-icon';
import { useElementFocus } from '../../hooks/use-element-focus/use-element-focus';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';
import OfferSmallCard from '../offer/offer-small-card';
import PhoneSearchSvg from '../../assets/svg/phone-search-svg';
import { useUser } from '../../context/auth/use-user';

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  placeholder?: string;
}

const FastSearchSearchbar = ({ isOpen, setIsOpen, placeholder }: Props) => {
  const user = useUser();
  const analytics = useAnalytics();
  const { t } = useTranslation(['navigation']);
  const { value, offers, action, clear, hint } = useFastSearch();
  const { ref: inputRef, focus: focusInput } =
    useElementFocus<HTMLInputElement>();

  React.useEffect(() => {
    if (isOpen) {
      analytics.track(ANALYTICS_EVENTS.FAST_SEARCH_OPENED);
    }
  }, [isOpen]);

  const onSubmitHandler: FormEventHandler<HTMLFormElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    action
      .perform()
      .then(() => {
        setIsOpen(false);
        analytics.track(ANALYTICS_EVENTS.FAST_SEARCH_PERFORMED, {
          value: value.state,
        });
      })
      .catch((fastSearchError) => {
        analytics.track(ANALYTICS_EVENTS.FAST_SEARCH_FAILED, {
          value: value.state,
          error: JSON.stringify(fastSearchError).substring(0, 500),
        });
        focusInput();
      });
  };

  React.useEffect(() => {
    if (!value.state) {
      return;
    }
    const timeoutId = setTimeout(() => {
      analytics.track(ANALYTICS_EVENTS.FAST_SEARCH_PREVIEW_SETTLED, {
        value: value.state,
        isLoading: offers.isLoading,
        isError: offers.isError,
        ...(offers?.state ? { count: offers.state } : {}),
      });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [value.state]);

  const canShowOffers =
    (value.type === 'PHONE_NUMBER' && value.isValid) ||
    (value.type === 'OFFER_URL' && value.isValid) ||
    (value.type === 'OFFER_TITLE' && value.isValid);

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
      afterLeave={() => clear()}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y overflow-hidden rounded-xl bg-white shadow-2xl transition-all">
              <form className="relative" onSubmit={onSubmitHandler}>
                <input
                  className="inset-0 h-12 w-full border-0 bg-transparent pl-4 pr-11 text-gray-900 outline-none ring-0 placeholder:text-gray-400 sm:text-sm"
                  ref={inputRef}
                  placeholder={
                    placeholder ??
                    t('navigation:userMenu.fastSearch.placeholder')
                  }
                  value={value.state}
                  disabled={action.isLoading}
                  onChange={(event) => value.set(event.target.value)}
                />
                <button className="absolute right-4 top-3.5">
                  {action.isLoading ? (
                    <SpinnerIcon className="h-5 w-5 text-indigo-600" />
                  ) : (
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </button>
                <div className="border-t border-gray-200 pb-2 pl-4 pt-1">
                  <p
                    className={`whitespace-pre text-xs ${
                      action.isError ? 'text-red-600' : 'text-gray-500'
                    }`}
                  >
                    {action.isError ? action.error : hint}
                  </p>
                </div>
                <div className="mx-4 my-1 flex h-[520px] flex-col content-center items-center gap-4">
                  {(!canShowOffers ||
                    (!offers.isLoading && offers.state.length === 0)) && (
                    <div className="mt-24">
                      <PhoneSearchSvg className="mx-auto mb-4 w-[50%]" />
                      <div className="text-center text-xs text-gray-500">
                        {t('navigation:userMenu.fastSearch.empty')}
                      </div>
                    </div>
                  )}
                  {canShowOffers && offers.isLoading && (
                    <SpinnerIcon className="mx-auto my-auto h-5 w-5 text-indigo-600" />
                  )}
                  {canShowOffers &&
                    offers.state &&
                    offers.state.map((offer) => {
                      if (
                        'issuer_type' in offer &&
                        ![
                          'PRIVATE',
                          'REAL_ESTATE_AGENCY',
                          'DEVELOPER',
                          null,
                        ].includes(offer['issuer_type'])
                      ) {
                        analytics.track('fast_search_issuer_type_error', {
                          user_id: user.userId,
                          id: offer?.id,
                          offer: JSON.stringify(offer),
                          offers: JSON.stringify(offers.state),
                          value: value.state,
                          isValid: canShowOffers,
                        });
                        return <></>;
                      }

                      return (
                        <div key={offer?.id} className="w-full">
                          <OfferSmallCard
                            className=""
                            onTitleClick={() => {
                              analytics.track(
                                ANALYTICS_EVENTS.FAST_SEARCH_OFFER_CLICKED,
                                {
                                  id: offer?.id,
                                  value: value.state,
                                },
                              );
                              setIsOpen(false);
                            }}
                            showMenu={false}
                            showContact={false}
                            {...offer}
                          />
                        </div>
                      );
                    })}
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FastSearchSearchbar;
