import { DEFAULT_SETTINGS, UserSettings } from './user-settings';
import {
  FeatureFlag,
  OrganizationMemberPrivilege,
  OrganizationType,
} from '../api/api.types';
import { addressesToFilter, Filter, FilterCondition } from './filter';
import { Sort } from './sort';

export enum SubscriptionTierType {
  LEGACY = 'LEGACY',
  TRIAL = 'TRIAL',
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
  ENTERPRISE = 'ENTERPRISE',
}
export interface AppSubscription {
  tier: SubscriptionTierType;
  expiresAt: Date;
  usersLimit: number;
}

export interface UserOrganization {
  organizationId: string;
  name: string;
  type: OrganizationType;
  logoUrl: string | null;
  phoneNumber: string | null;
  address: string | null;
  website: string | null;
}

export interface CommunicationConsents {
  isAppConsentGiven: boolean;
  isMarketingConsentGiven: boolean;
}

export interface UserProperties {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly settings: UserSettings;
  readonly selectedOrganization: UserOrganization;
  readonly userId: string;
  readonly avatarUrl: string | null;
  readonly hasEmptySettings: boolean;
  readonly performedActions: string[];
  readonly phoneNumber: null | string;
  readonly jobTitle: null | string;
  readonly enabledFeatureFlags: FeatureFlag[];
  readonly organizationMemberPrivilege: OrganizationMemberPrivilege;
  readonly team: UserTeam | null;
  readonly branch: UserBranch | null;
  readonly subscription: AppSubscription;
  readonly communicationConsents: CommunicationConsents;
}

export interface UserTeam {
  readonly teamId: string;
  readonly name: string;
}

export interface UserBranch {
  readonly branchId: string;
  readonly name: string;
}

export abstract class BaseUser implements UserProperties {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly settings: UserSettings;
  readonly selectedOrganization: UserOrganization;
  readonly userId: string;
  readonly avatarUrl: string | null;
  readonly hasEmptySettings: boolean;
  readonly performedActions: string[];
  readonly phoneNumber: null | string;
  readonly enabledFeatureFlags: FeatureFlag[];
  readonly jobTitle: null | string;
  readonly organizationMemberPrivilege: OrganizationMemberPrivilege;
  readonly team: UserTeam | null = null;
  readonly branch: UserBranch | null = null;
  readonly subscription: AppSubscription;
  readonly communicationConsents: CommunicationConsents;

  protected constructor({
    firstName,
    lastName,
    email,
    settings,
    selectedOrganization,
    userId,
    avatarUrl,
    hasEmptySettings,
    performedActions,
    phoneNumber,
    enabledFeatureFlags,
    jobTitle,
    organizationMemberPrivilege,
    team,
    branch,
    subscription,
    communicationConsents,
  }: UserProperties) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.settings = settings;
    this.selectedOrganization = selectedOrganization;
    this.userId = userId;
    this.avatarUrl = avatarUrl;
    this.hasEmptySettings = hasEmptySettings;
    this.performedActions = performedActions;
    this.phoneNumber = phoneNumber;
    this.enabledFeatureFlags = enabledFeatureFlags;
    this.jobTitle = jobTitle;
    this.organizationMemberPrivilege = organizationMemberPrivilege;
    this.team = team;
    this.branch = branch;
    this.subscription = subscription;
    this.communicationConsents = communicationConsents;
  }

  abstract get isAuthenticated(): boolean;

  public personalizedFilters(): Filter[] {
    const result = [];

    if (!this.settings.showDuplicates) {
      result.push({
        field: 'is_unique',
        value: true,
        condition: FilterCondition.EQUAL,
      });
    }

    return result;
  }

  public defaultFilters(): Filter[] {
    const result: Filter[] = [];

    if (this.settings.defaultLocation) {
      result.push(addressesToFilter([this.settings.defaultLocation]));
    }

    if (
      this.settings.defaultIssuerType &&
      this.settings.defaultIssuerType.length > 0
    ) {
      result.push({
        field: 'issuer_type',
        value: this.settings.defaultIssuerType,
        condition: FilterCondition.IN,
      });
    }

    return result;
  }

  public defaultSorts(): Sort[] {
    if (!this.settings.defaultSorts) {
      return [
        {
          field: 'date_smart_last_seen',
          ascending: false,
        },
      ];
    }

    return this.settings.defaultSorts;
  }

  public performedAction(action: string): boolean {
    return this.performedActions.includes(action);
  }

  public hasFeatureFlag(flag: FeatureFlag): boolean {
    return this.enabledFeatureFlags.includes(flag);
  }

  public hasMinimumPrivilege(privilege: OrganizationMemberPrivilege): boolean {
    if (privilege === OrganizationMemberPrivilege.ADMIN) {
      return (
        this.organizationMemberPrivilege === OrganizationMemberPrivilege.ADMIN
      );
    }

    if (privilege === OrganizationMemberPrivilege.MODERATOR) {
      return (
        this.organizationMemberPrivilege ===
          OrganizationMemberPrivilege.MODERATOR ||
        this.organizationMemberPrivilege === OrganizationMemberPrivilege.ADMIN
      );
    }

    return true;
  }

  public hasActiveSubscription(): boolean {
    return this.subscription.expiresAt > new Date();
  }

  public hasSufficientValidSubscriptionTier(
    required: SubscriptionTierType,
  ): boolean {
    return (
      this.hasActiveSubscription() &&
      this.hasSufficientSubscriptionTier(required)
    );
  }

  public hasSufficientSubscriptionTier(
    required: SubscriptionTierType,
  ): boolean {
    if (required === SubscriptionTierType.ENTERPRISE) {
      return [
        SubscriptionTierType.LEGACY,
        SubscriptionTierType.ENTERPRISE,
      ].includes(this.subscription.tier);
    }

    if (required === SubscriptionTierType.PREMIUM) {
      return [
        SubscriptionTierType.TRIAL,
        SubscriptionTierType.PREMIUM,
        SubscriptionTierType.LEGACY,
        SubscriptionTierType.ENTERPRISE,
      ].includes(this.subscription.tier);
    }

    if (required == SubscriptionTierType.BASIC) {
      return [
        SubscriptionTierType.TRIAL,
        SubscriptionTierType.BASIC,
        SubscriptionTierType.PREMIUM,
        SubscriptionTierType.LEGACY,
        SubscriptionTierType.ENTERPRISE,
      ].includes(this.subscription.tier);
    }

    return false;
  }

  abstract withPerformedAction(action: string): User;
}

export class AnonymousUser extends BaseUser {
  constructor() {
    super({
      email: '',
      firstName: '',
      lastName: '',
      settings: DEFAULT_SETTINGS,
      selectedOrganization: {
        name: '',
        organizationId: '',
        type: OrganizationType.B2C,
        logoUrl: null,
        phoneNumber: null,
        address: null,
        website: null,
      },
      userId: '',
      organizationMemberPrivilege: OrganizationMemberPrivilege.MEMBER,
      avatarUrl: null,
      hasEmptySettings: true,
      performedActions: [],
      phoneNumber: null,
      enabledFeatureFlags: [],
      jobTitle: null,
      team: null,
      branch: null,
      // TODO - remove this when we have a proper subscription model
      subscription: {
        tier: SubscriptionTierType.LEGACY,
        expiresAt: new Date(2030, 1),
        usersLimit: 99,
      },
      communicationConsents: {
        isAppConsentGiven: false,
        isMarketingConsentGiven: false,
      },
    });
  }

  get isAuthenticated() {
    return false;
  }

  public withPerformedAction(action: string): User {
    return this;
  }
}

export class AuthenticatedUser extends BaseUser {
  constructor(props: UserProperties) {
    super(props);
  }

  get isAuthenticated() {
    return true;
  }

  public withPerformedAction(action: string): User {
    if (this.performedActions.includes(action)) {
      return this;
    }

    return new AuthenticatedUser({
      ...this,
      performedActions: [...this.performedActions, action],
    });
  }
}

export type User = AnonymousUser | AuthenticatedUser;
