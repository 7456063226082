import React from 'react';
import { useTranslation } from 'react-i18next';
import ExplanationIcon, { ExplanationIconProps } from './explanation-icon';

const SortExplanationIcon = (props: Omit<ExplanationIconProps, 'text'>) => {
  const { t } = useTranslation('forms');
  return <ExplanationIcon text={t('forms:sortExplanation')} {...props} />;
};

export default SortExplanationIcon;
