import React from 'react';
import { getClientSearches } from '../../api/client-searches/client-searches';
import {
  ClientSelection,
  UseAddToClientSearchClientData,
} from './use-add-to-client-search-client';

export enum ClientSearchSelection {
  NEW = 'new',
  EXISTING = 'existing',
}

interface Props {
  client: UseAddToClientSearchClientData;
}

export const useAddToClientSearchClientSearch = ({ client }: Props) => {
  const [clientSearchSelection, setClientSearchSelection] =
    React.useState<ClientSearchSelection>(ClientSearchSelection.NEW);
  const [clientSearchId, setClientSearchId] = React.useState<null | string>(
    null,
  );
  const [haveClientSearches, setHaveClientSearches] = React.useState<boolean>();
  const [clientSearchName, setClientSearchName] = React.useState<string>('');

  React.useEffect(() => {
    if (
      clientSearchSelection === ClientSearchSelection.EXISTING &&
      client.selection.value === ClientSelection.NEW
    ) {
      setClientSearchSelection(ClientSearchSelection.NEW);
    }
  }, [client.selection.value]);

  React.useEffect(() => {
    if (!client.isValid) {
      setHaveClientSearches(undefined);
    }
  }, [client.isValid]);

  const init = () => {
    hasExistingSearches(client.clientId)
      .then((hasExistingSearches) => {
        setClientSearchSelection(
          hasExistingSearches
            ? ClientSearchSelection.EXISTING
            : ClientSearchSelection.NEW,
        );
        setHaveClientSearches(hasExistingSearches);
      })
      .catch(() => setHaveClientSearches(false));
  };

  const clear = () => {
    setClientSearchId(null);
    setClientSearchName('');
    setHaveClientSearches(undefined);
  };

  return {
    selection: {
      value: clientSearchSelection,
      set: setClientSearchSelection,
    },
    new: {
      value: clientSearchName,
      set: setClientSearchName,
    },
    existing: {
      value: clientSearchId,
      set: setClientSearchId,
    },
    isValid: isValidClientSearch({
      clientSearchSelection,
      clientSearchName,
      clientSearchId,
    }),
    init,
    clear,
    haveClientSearches,
    clientSearchId:
      clientSearchSelection === ClientSearchSelection.NEW
        ? null
        : clientSearchId,
  };
};

export type UseAddToClientSearchClientSearchData = ReturnType<
  typeof useAddToClientSearchClientSearch
>;

const isValidClientSearch = ({
  clientSearchSelection,
  clientSearchName,
  clientSearchId,
}: {
  clientSearchSelection: ClientSearchSelection;
  clientSearchName: string;
  clientSearchId?: string | null;
}) => {
  if (clientSearchSelection === ClientSearchSelection.NEW) {
    return clientSearchName !== '';
  } else if (clientSearchSelection === ClientSearchSelection.EXISTING) {
    return !!clientSearchId;
  }
  return false;
};

const hasExistingSearches = async (clientId?: string | null) => {
  if (clientId === undefined || clientId === null) {
    return false;
  }
  const totalItems = await getClientSearches({
    ...{ page: 1, size: 5 },
    ...(clientId ? { clientId } : {}),
  })
    .then((response) => response.total_items)
    .catch(() => 0);
  return totalItems > 0;
};
