import DrawerWithDescription, {
  DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION,
} from '../drawers/drawer-with-description';
import WysiwigTextInputLabeled from '../inputs/wysiwig-text-input/wysiwig-text-input-labeled';
import React, { useId, useState } from 'react';
import { sendCustomerFeedback } from '../../api/customer-feedback/customer-feedback';
import { useMutation } from '@tanstack/react-query';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { AxiosError } from 'axios';
import Button from '../button/button';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { useToast } from '../../hooks/use-toast/use-toast';

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const CustomerFeedbackDrawer = ({ isOpen, close }: Props) => {
  const { t } = useTranslation('navigation');
  const id = useId();
  const analytics = useAnalytics();
  const { displayDefaultErrorToasts, displaySuccessToast } = useToast();
  const [feedbackText, setFeedbackText] = useState('');

  const { mutate: sendFeedback, isLoading } = useMutation(
    sendCustomerFeedback,
    {
      onSuccess: async () => {
        displaySuccessToast({
          title: t('navigation:feedbackForm.successToast.title'),
          body: t('navigation:feedbackForm.successToast.body'),
          duration: 6000 /* 6 seconds */,
        });
        close();
        setTimeout(
          () => setFeedbackText(''),
          DRAWER_WITH_DESCRIPTION_ANIMATION_DURATION,
        );
        await analytics.track(ANALYTICS_EVENTS.CUSTOMER_FEEDBACK_SENT);
      },
      onError: async (e: AxiosError) => {
        displayDefaultErrorToasts(e);
        await analytics.track(ANALYTICS_EVENTS.CUSTOMER_FEEDBACK_SENT, {
          text: feedbackText,
        });
      },
    },
  );

  return (
    <DrawerWithDescription
      isOpen={isOpen}
      title={t('navigation:feedbackForm.title')}
      description={t('navigation:feedbackForm.description')}
      close={close}
      buttons={
        <>
          <Button
            type="button"
            variant="secondary"
            className="px-3 py-2"
            disabled={isLoading}
            onClick={close}
          >
            {t('navigation:feedbackForm.cancelButton')}
          </Button>
          <Button
            type="button"
            variant="primary"
            className="ml-2 px-3 py-2"
            isLoading={isLoading}
            onClick={() =>
              sendFeedback({
                url: window.location.href,
                feedback: feedbackText,
              })
            }
          >
            {t('navigation:feedbackForm.submitButton')}
          </Button>
        </>
      }
    >
      <div className="mt-6">
        <WysiwigTextInputLabeled
          label={t('navigation:feedbackForm.inputLabel')}
          id={id}
          className="mt-2 h-[50vh]"
          value={feedbackText}
          onChange={setFeedbackText}
        />
      </div>
    </DrawerWithDescription>
  );
};
