import React from 'react';
import { useUser } from '../../context/auth/use-user';
import { useAuth } from '../../context/auth/use-auth';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { putConsents } from '../../api/auth/auth';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../button/button';
import ToggleInputLabeledController from '../inputs/toggle-input/toggle-input-labeled-controller';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

interface Form {
  isAppConsentGiven: boolean;
  isMarketingConsentGiven: boolean;
}

const DEFAULT_FORM_VALUES: Form = {
  isAppConsentGiven: true,
  isMarketingConsentGiven: true,
};

const CommunicationConsentModelForm = () => {
  const user = useUser();
  const { loadUser } = useAuth();
  const { t } = useTranslation(['common']);
  const analytics = useAnalytics();

  const schema = React.useMemo(() => {
    const result = {
      isAppConsentGiven: yup
        .boolean()
        .isTrue(t('common:communicationConsents.mustBeAccepted'))
        .required(t('common:communicationConsents.mustBeAccepted')),
      isMarketingConsentGiven: yup.boolean().required(),
    } as any;

    return yup.object(result);
  }, [t]);

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(schema),
  });
  const { mutateAsync: saveConsents, isLoading: isConsentsSaving } =
    useMutation(putConsents, {
      onSuccess: async (_) => {
        await analytics.track(
          ANALYTICS_EVENTS.INITIAL_COMMUNICATION_CONSENTS_SET,
          {
            userId: user.userId,
          },
        );
        await loadUser();
      },
      onError: async (error: AxiosError) => {
        defaultErrorToasts(error, t);
      },
    });

  const onSubmit = handleSubmit(async (form: Form) => {
    await saveConsents({
      is_app_consent_given: form.isAppConsentGiven,
      is_marketing_consent_given: form.isMarketingConsentGiven,
    });
  });

  return (
    <form
      className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      onSubmit={onSubmit}
    >
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('common:communicationConsents.header')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('common:communicationConsents.description')}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-col space-y-4">
            <div>
              <ToggleInputLabeledController
                name="isAppConsentGiven"
                control={control}
                id="isAppConsentGiven"
                isRequired
                label={t('common:communicationConsents.appConsentGiven')}
                className="mt-4"
              />
              <div className="mt-2 text-xs font-light text-gray-600">
                {t('common:communicationConsents.appConsent')}
              </div>
            </div>
            <div>
              <ToggleInputLabeledController
                name="isMarketingConsentGiven"
                control={control}
                id="isMarketingConsentGiven"
                label={t('common:communicationConsents.marketingConsentGiven')}
                className="mt-4"
              />
              <div className="mt-2 text-xs font-light text-gray-600">
                {t('common:communicationConsents.marketingConsent')}
              </div>
            </div>
          </div>
          <div className="mt-4 sm:mt-5">
            <Button
              type="submit"
              className="w-full px-4 py-2"
              isLoading={isConsentsSaving}
            >
              {t('common:communicationConsents.continue')}
            </Button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </form>
  );
};

export default CommunicationConsentModelForm;
