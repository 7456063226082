import React from 'react';
import { Rectangle } from './use-image-selection';

interface Props {
  width: number;
  height: number;
  onSelect: (rectangle: Rectangle) => void;
  onSelectChange: (isSelect: boolean) => void;
  selectionColor?: string;
}

const ImagePartsSelector = ({
  width: eWidth,
  height: eHeight,
  onSelect,
  onSelectChange,
}: Props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [startX, setStartX] = React.useState<number>();
  const [startY, setStartY] = React.useState<number>();
  const [width, setWidth] = React.useState<number>();
  const [height, setHeight] = React.useState<number>();
  const [isDragging, setIsDragging] = React.useState(false);

  /* This part looks like it's not very optimise, and that's true.
   *  But 2 attempts to optimised it failed. So if not critical, do not touch. */
  React.useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!isDragging || !canvasRef.current) return;
      const rect = canvasRef.current.getBoundingClientRect();
      const currentX = event.clientX - rect.left;
      const currentY = event.clientY - rect.top;
      setWidth(currentX - (startX ?? 0));
      setHeight(currentY - (startY ?? 0));
      drawSelection({
        ctx: canvasRef.current.getContext('2d'),
        x: startX ?? 0,
        y: startY ?? 0,
        width: currentX - (startX ?? 0),
        height: currentY - (startY ?? 0),
      });
    };

    const handleMouseUp = (event: MouseEvent) => {
      setIsDragging(false);
      if (!canvasRef.current || !isDragging) return;
      const rect = canvasRef.current.getBoundingClientRect();

      if (startX === undefined || startY === undefined) {
        return;
      }

      const currentX = event.clientX - rect.left;
      const currentY = event.clientY - rect.top;
      const finalHeight = currentY - startY;
      const finalWidth = currentX - startX;
      const finalX = finalWidth >= 0 ? startX : startX + finalWidth;
      const finalY = finalHeight >= 0 ? startY : startY + finalHeight;

      const cutFromLeft = finalX < 0 ? Math.abs(finalX) : 0;
      const cutFromTop = finalY < 0 ? Math.abs(finalY) : 0;
      const cutFromRight =
        finalX + Math.abs(finalWidth) > eWidth
          ? finalX + Math.abs(finalWidth) - eWidth
          : 0;
      const cutFromBottom =
        finalY + Math.abs(finalHeight) > eHeight
          ? finalY + Math.abs(finalHeight) - eHeight
          : 0;

      if (
        Math.abs(finalWidth) - cutFromLeft - cutFromRight === 0 ||
        Math.abs(finalHeight) - cutFromTop - cutFromBottom === 0
      ) {
        return;
      }

      onSelect({
        x: Math.max(0, finalX),
        y: Math.max(0, finalY),
        width: Math.abs(finalWidth) - cutFromLeft - cutFromRight,
        height: Math.abs(finalHeight) - cutFromTop - cutFromBottom,
      });

      canvasRef.current
        .getContext('2d')
        ?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startX, startY, width, height, onSelect]);

  React.useEffect(() => {
    onSelectChange(isDragging);
  }, [isDragging]);

  React.useEffect(() => {
    if (isDragging) {
      document.querySelector('body')!.classList.add('disable-selection');
    } else {
      document.querySelector('body')!.classList.remove('disable-selection');
    }
  }, [isDragging]);

  const handleMouseDown: React.MouseEventHandler<HTMLCanvasElement> = (e) => {
    setIsDragging(true);
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      setStartX(e.clientX - rect.left);
      setStartY(e.clientY - rect.top);
    }
  };

  return (
    <canvas
      width={eWidth}
      height={eHeight}
      ref={canvasRef}
      onMouseDown={handleMouseDown}
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
    />
  );
};

const drawSelection = ({
  ctx,
  x,
  y,
  width,
  height,
}: {
  ctx: CanvasRenderingContext2D | null;
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  if (!ctx) return;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.strokeStyle = 'white';
  ctx.setLineDash([]);
  ctx.strokeRect(x, y, width, height);
  ctx.strokeStyle = '#4338ca';
  ctx.lineWidth = 2;
  ctx.setLineDash([4, 2]);
  ctx.strokeRect(x, y, width, height);
};

export default ImagePartsSelector;
