import React from 'react';
import LocationInputLabeledController from '../../inputs/location-input/location-input-labeled-controller';
import { AddressSearchType } from '../../inputs/location-input/location-input';
import TextInputLabeledController from '../../inputs/text-input/text-input-labeled-controller';
import { useTranslation } from 'react-i18next';
import LocationMapInputController from '../../inputs/location-map-input/location-map-input-controller';
import { useLg } from '../../../style/media-queries';

interface Props {
  displayMap: boolean;
  control: any;
}

const OfferVariantLocation = ({ control, displayMap }: Props) => {
  const isLg = useLg();
  const { t } = useTranslation(['data-model']);
  return (
    <div>
      <LocationInputLabeledController
        name="address"
        control={control}
        label={t('data-model:fieldLabels.address_city')}
        id="address"
        labelClassName="mb-1"
        searchType={AddressSearchType.DEFAULT}
        saveErrorSpace={true}
      />
      <div className="mt-4 lg:flex lg:flex-row lg:gap-x-4">
        <TextInputLabeledController
          name="address_district"
          control={control}
          id="address_district"
          label={t('data-model:fieldLabels.address_district')}
          className="w-full"
          saveErrorSpace={true}
        />
        <TextInputLabeledController
          name="address_street"
          control={control}
          id="address_street"
          label={t('data-model:fieldLabels.address_street')}
          className="w-full"
          saveErrorSpace={true}
        />
      </div>
      <div className="mt-4 px-4">
        {displayMap && (
          <LocationMapInputController
            control={control}
            name="location"
            mapHeight={isLg ? '500px' : '300px'}
          />
        )}
      </div>
    </div>
  );
};

export default OfferVariantLocation;
