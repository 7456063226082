import React from 'react';
import { CallTypeEnum, PhoneCallResponse } from '../../api/api.types';
import { format, intervalToDuration } from 'date-fns';
import { formatPhone } from '../../constants/phone-formatter';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';

const zeroPad = (num?: number) => (num ? String(num).padStart(2, '0') : '00');

const calculateDuration = (start: Date, end: Date): Duration | null => {
  return intervalToDuration({
    start,
    end,
  });
};

const useActiveDuration = (call: {
  started_at?: string | undefined | null;
  ended_at?: string | undefined | null;
}): Duration | null => {
  const [activeDuration, setActiveDuration] = React.useState<Duration | null>(
    call.started_at && call.ended_at
      ? calculateDuration(new Date(call.started_at), new Date(call.ended_at))
      : null,
  );

  React.useEffect(() => {
    const updateDuration = () => {
      if (call.started_at && !call.ended_at) {
        setActiveDuration(
          calculateDuration(new Date(call.started_at), new Date()),
        );
      } else if (call.started_at && call.ended_at) {
        setActiveDuration(
          calculateDuration(new Date(call.started_at), new Date(call.ended_at)),
        );
      } else {
        setActiveDuration(null);
      }
    };

    const intervalId = setInterval(updateDuration, 1000);

    updateDuration();

    return () => clearInterval(intervalId);
  }, [call.started_at, call.ended_at]);

  return activeDuration;
};

interface Props {
  call: PhoneCallResponse;
}

const PhoneCall = ({ call }: Props) => {
  const duration = useActiveDuration(call);

  return (
    <div className="flex w-full overflow-hidden rounded-lg border border-gray-200 bg-white p-2">
      <div className="flex w-full flex-row items-center">
        <div className="mr-3">
          {call.call_type === CallTypeEnum.INCOMING ? (
            <ArrowLeftIcon
              className={`${
                call.started_at === null ? 'text-red-600' : 'text-green-600'
              } h-6 w-6`}
            />
          ) : (
            <ArrowRightIcon className="h-6 w-6 text-green-600" />
          )}
        </div>
        <div className="flex w-full flex-col">
          <div className=" flex flex-row">
            <span className="font-bold">{formatPhone(call.phone_number)}</span>
            <div className="ml-auto text-gray-600" color="coolGray.800">
              {format(new Date(call.created_at), 'dd/MM HH:mm')}
            </div>
          </div>
          <div className="text-gray-600">
            {duration
              ? `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
              : '\u00A0'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneCall;
