import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../../../context/auth/use-user';
import { SubscriptionTierType } from '../../../models/user';
import { AppView, useAppView } from '../../../context/app-views/use-app-view';

const AuthenticatedRoute = ({
  children,
  requiredSubscriptionTier,
  requiredAppView,
}: {
  children: React.ReactElement;
  requiredSubscriptionTier?: SubscriptionTierType;
  requiredAppView?: AppView;
}) => {
  const location = useLocation();
  const user = useUser();
  const appView = useAppView();

  if (!user.isAuthenticated) {
    return <Navigate to={'/login'} replace state={{ from: location }} />;
  }

  if (!requiredSubscriptionTier) {
    return children;
  }

  const isSubscriptionTierSufficient = user.hasSufficientValidSubscriptionTier(
    requiredSubscriptionTier,
  );

  if (!isSubscriptionTierSufficient) {
    return (
      <Navigate
        to={'/organization/subscription'}
        replace
        state={{ from: location }}
      />
    );
  }

  if (requiredAppView && appView.currentView !== requiredAppView) {
    return <Navigate to={'/dashboard'} replace state={{ from: location }} />;
  }
  return children;
};

export default AuthenticatedRoute;
