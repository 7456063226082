import React from 'react';
import {
  DocumentTextIcon,
  MapPinIcon,
  PhotoIcon,
  TableCellsIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '../../style/class-names';
import { useTranslation } from 'react-i18next';

export enum OfferVariantFormTabs {
  PARAMETERS,
  LOCATION,
  DESCRIPTION,
  IMAGES,
}

const tabs = [
  {
    name: 'offers:offerForm.sections.parameters',
    icon: TableCellsIcon,
    id: OfferVariantFormTabs.PARAMETERS,
  },
  {
    name: 'offers:offerForm.sections.location',
    icon: MapPinIcon,
    id: OfferVariantFormTabs.LOCATION,
  },
  {
    name: 'offers:offerForm.sections.description',
    icon: DocumentTextIcon,
    id: OfferVariantFormTabs.DESCRIPTION,
  },
  {
    name: 'offers:offerForm.sections.photos',
    icon: PhotoIcon,
    id: OfferVariantFormTabs.IMAGES,
  },
];

interface Props {
  selected: OfferVariantFormTabs;
  setSelected: (tab: OfferVariantFormTabs) => void;
}

const OfferVariantFormNavigation = ({ selected, setSelected }: Props) => {
  const { t } = useTranslation('offers');
  return (
    <nav className="-mb-px flex flex-row space-x-8 overflow-x-auto">
      {tabs.map((tab) => (
        <button
          key={tab.name}
          onClick={() => setSelected(tab.id)}
          className={classNames(
            tab.id === selected
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'group inline-flex items-center border-b-2 px-2 py-4 text-sm font-medium',
          )}
        >
          <tab.icon
            className={classNames(
              tab.id === selected
                ? 'text-indigo-500'
                : 'text-gray-400 group-hover:text-gray-500',
              '-ml-0.5 mr-2 h-5 w-5',
            )}
            aria-hidden="true"
          />
          <span>{t(tab.name)}</span>
        </button>
      ))}
    </nav>
  );
};

export default OfferVariantFormNavigation;
