import React, { CSSProperties } from 'react';
import {
  Circle,
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import type { Map } from 'leaflet';
import { DivIcon, type Marker as LeafletMarkers } from 'leaflet';
import { MapPinIcon, XMarkIcon } from '@heroicons/react/20/solid';
import ReactDOMServer from 'react-dom/server';
import { INDIGO_500 } from '../../../style/constants';
import ToggleInputLabeled from '../toggle-input/toggle-input-labeled';
import { DEFAULT_MAP_CONFIG } from '../../map/utils/default-map-config';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { MdLocationSearching } from 'react-icons/all';

export interface LocationMapInputProps {
  onChange: (value: {
    latitude: number | null;
    longitude: number | null;
    approximation: number | null;
  }) => void;
  value: {
    latitude: number | null;
    longitude: number | null;
    approximation: number | null;
  };
  isError?: boolean;
  errorMessage?: string;
  saveErrorSpace?: boolean;
  setMap?: (map: Map | null) => void;
  onClear?: () => void;
  defaultZoom?: number;
  mapHeight?: CSSProperties['height'];
  canApproximate?: boolean;
  errorPosition?: 'TOP' | 'BOTTOM';
}

const DEFAULT_APPROXIMATION_METERS = 400;

const LocationMapInput = ({
  value,
  onChange,
  onClear,
  isError = false,
  errorMessage = '',
  saveErrorSpace = false,
  setMap,
  defaultZoom = DEFAULT_MAP_CONFIG.zoom,
  mapHeight = '450px',
  canApproximate = true,
  errorPosition = 'BOTTOM',
}: LocationMapInputProps) => {
  const { t } = useTranslation(['common']);
  const center =
    value.latitude && value.longitude
      ? { lat: value.latitude, lng: value.longitude }
      : DEFAULT_MAP_CONFIG.center;

  const getLatLngDisplay = () => {
    if (!value.longitude || !value.latitude) return null;
    return value.approximation ? (
      <Circle
        center={{ lat: value.latitude, lng: value.longitude }}
        pathOptions={{ color: INDIGO_500 }}
        radius={DEFAULT_APPROXIMATION_METERS}
      />
    ) : (
      <DraggableMarker
        lat={value.latitude}
        lng={value.longitude}
        onChange={(change) =>
          onChange({
            latitude: change.lat,
            longitude: change.lng,
            approximation: value.approximation,
          })
        }
      />
    );
  };

  return (
    <div className="relative">
      {errorPosition === 'TOP' && (
        <p
          className={`mt-2 text-xs text-red-600 ${
            saveErrorSpace ? 'whitespace-pre-wrap' : ''
          }`}
        >
          {isError ? errorMessage : ' '}
        </p>
      )}

      {canApproximate && (
        <ToggleInputLabeled
          id="show-exact-location"
          label={t('common:locationMapInput.exactLocation')}
          className="mb-4"
          value={!value.approximation}
          onChange={(val) =>
            onChange({
              ...value,
              approximation: val ? null : DEFAULT_APPROXIMATION_METERS,
            })
          }
        />
      )}
      <div
        className={`border ${
          isError ? 'border-red-300 focus:border-red-500' : ''
        }`}
      >
        <MapContainer
          {...DEFAULT_MAP_CONFIG}
          style={{
            width: '100%',
            height: mapHeight,
            zIndex: 0,
          }}
          center={center}
          zoom={defaultZoom}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=""
          />
          {getLatLngDisplay()}
          {(value.longitude || value.latitude) && (
            <ZoomToCurrentSelectionButton value={value} />
          )}
          <OnClickListener
            onClick={({ lat, lng }) =>
              onChange({
                latitude: lat,
                longitude: lng,
                approximation: canApproximate ? value.approximation : null,
              })
            }
          />
          {value.latitude && value.longitude && (
            <div className="leaflet-right leaflet-top">
              <div className="leaflet-control leaflet-bar">
                <div
                  className="bold text-md flex cursor-pointer flex-row items-center justify-center rounded-sm bg-white p-1.5 text-lg"
                  onClick={() => {
                    onClear?.();
                    onChange({
                      latitude: null,
                      longitude: null,
                      approximation: null,
                    });
                  }}
                >
                  <XMarkIcon className="h-8 w-8" />
                  <span className="ml-2 mr-1">
                    {t('common:locationMapInput.clear')}
                  </span>
                </div>
              </div>
            </div>
          )}
          <MapRefProvider setMap={setMap} />
        </MapContainer>
      </div>

      {errorPosition === 'BOTTOM' && (
        <p
          className={`mt-2 text-xs text-red-600 ${
            saveErrorSpace ? 'whitespace-pre-wrap' : ''
          }`}
        >
          {isError ? errorMessage : ' '}
        </p>
      )}
    </div>
  );
};

interface OnClickListenerProps {
  onClick: (point: { lat: number; lng: number }) => void;
}

function OnClickListener({ onClick }: OnClickListenerProps) {
  useMapEvents({
    click: (e) => {
      if (
        ['svg', 'BUTTON'].includes(
          (e.originalEvent.target as any).tagName ?? '',
        )
      ) {
        return;
      }
      onClick(e.latlng);
    },
  });
  return null;
}

interface DraggableMarkerProps {
  lat: number;
  lng: number;
  onChange: (value: { lat: number; lng: number }) => void;
}

function DraggableMarker({ lat, lng, onChange }: DraggableMarkerProps) {
  const markerRef = React.useRef<LeafletMarkers>(null);
  const eventHandlers = React.useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onChange(marker.getLatLng());
        }
      },
    }),
    [],
  );

  const leafletIcon = new DivIcon({
    html: ReactDOMServer.renderToString(
      <div className="flex items-center justify-center">
        <MapPinIcon className="h-8 w-8 text-indigo-500" />
      </div>,
    ),
    iconSize: [30, 30],
    className: '',
  });

  return (
    <Marker
      position={{ lat, lng }}
      draggable
      ref={markerRef}
      icon={leafletIcon}
      eventHandlers={eventHandlers}
    />
  );
}

function MapRefProvider({ setMap }: { setMap?: (map: Map | null) => void }) {
  const map = useMap();

  React.useEffect(() => {
    setMap?.(map);
  }, [map]);

  return null;
}

const ZoomToCurrentSelectionButton = ({
  value,
}: {
  value: {
    latitude: number | null;
    longitude: number | null;
    approximation: number | null;
  };
}) => {
  const { t } = useTranslation(['common']);
  const map = useMap();

  const handleZoomToCurrentSelection = () => {
    if (value.latitude && value.longitude) {
      map.setView([value.latitude, value.longitude], 15);
    }
  };

  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control leaflet-bar">
        <button
          className="bold text-md flex cursor-pointer flex-row items-center justify-center rounded-sm bg-white p-1.5 text-lg"
          type="button"
          onClick={handleZoomToCurrentSelection}
        >
          <MdLocationSearching className="h-5 w-5" />
          <span className="sr-only">
            {t('common:locationMapInput.showCurrent')}
          </span>
        </button>
      </div>
    </div>
  );
};

export default LocationMapInput;
