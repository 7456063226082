import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../context/auth/use-user';
import PageHeader from '../../components/header/page-header';
import { classNames } from '../../style/class-names';
import { useQuery } from '@tanstack/react-query';
import { getAssignedOffersCounts } from '../../api/offer-assignments/offer-assignments';
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import SalesAssistantOfferList from './sales-assistant-offer-list';

const SalesAssistant = () => {
  const { t } = useTranslation(['sales-assistant']);
  const user = useUser();
  const location = useLocation();

  const { data: assignedToMe } = useQuery(
    ['offers', 'assignments', user.team?.teamId],
    () => getAssignedOffersCounts({ teamId: user.team?.teamId }),
    { enabled: !!user.team?.teamId },
  );

  const { data: unassigned } = useQuery(
    ['offers', 'assignments', { unassigned: true }],
    () => getAssignedOffersCounts({ unassigned: true }),
  );

  const { data: assignedToAll } = useQuery(['offers', 'assignments'], () =>
    getAssignedOffersCounts(),
  );

  const tabs = [
    {
      name: t('sales-assistant:menu.myTeam'),
      to: '/sales-assistant/me',
      count: assignedToMe?.count ?? 0,
    },
    {
      name: t('sales-assistant:menu.unassigned'),
      to: '/sales-assistant/unassigned',
      count: unassigned?.count ?? 0,
    },
    {
      name: t('sales-assistant:menu.all'),
      to: '/sales-assistant/all',
      count: assignedToAll?.count ?? 0,
    },
  ];

  return (
    <main className="mx-auto max-w-7xl">
      <PageHeader title={t('common:pageTitle.salesAssistant')} />
      <div>
        <div className="sticky top-12 z-30 border-b border-gray-200 bg-white px-6 sm:px-8 md:top-16">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.to}
                className={classNames(
                  location.pathname === tab.to
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                )}
              >
                {tab.name}
                <span
                  className={classNames(
                    location.pathname === tab.to
                      ? 'bg-indigo-100 text-indigo-600'
                      : 'bg-gray-100 text-gray-900',
                    'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                  )}
                >
                  {tab.count}
                </span>
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <Routes>
        <Route
          path="/me"
          element={
            <SalesAssistantOfferList
              teamId={user.team?.teamId}
              enabled={!!user.team}
            />
          }
        />
        <Route
          path="/unassigned"
          element={<SalesAssistantOfferList unassigned />}
        />
        <Route path="/all" element={<SalesAssistantOfferList />} />
        <Route
          path="*"
          element={<Navigate to={'/sales-assistant/me'} replace />}
        />
      </Routes>
    </main>
  );
};

export default SalesAssistant;
