import {
  AddOfferVariantRequest,
  AddOfferVariantResponse,
  UpdateOfferVariantRequest,
  OfferVariantDetailsResponse,
  OfferVariantFeedback,
  OfferVariantFeedbackResponse,
} from '../api.types';
import { axios } from '../axios';

export enum OfferVariantsErrors {
  INCORRECT_SCHEMA = 'INCORRECT_SCHEMA',
  PHOTO_FORMAT_NOT_SUPPORTED = 'PHOTO_FORMAT_NOT_SUPPORTED',
  PHOTO_PROCESSING_UNKNOWN_PROBLEM = 'PHOTO_PROCESSING_UNKNOWN_PROBLEM',
}

export const addOfferVariant = ({
  body,
}: {
  body: AddOfferVariantRequest;
}): Promise<AddOfferVariantResponse> => {
  return axios.post(`/offer-variants`, body).then((response) => response.data);
};

export const updateOfferVariant = ({
  body,
  offerVariantId,
}: {
  offerVariantId: string;
  body: UpdateOfferVariantRequest;
}): Promise<AddOfferVariantResponse> => {
  return axios
    .put(`/offer-variants/${offerVariantId}`, body)
    .then((response) => response.data);
};

export const getOfferVariantById = ({
  offerVariantId,
}: {
  offerVariantId: string;
}) => {
  return axios
    .get<OfferVariantDetailsResponse>(`/offer-variants/${offerVariantId}`)
    .then((response) => response.data);
};

export const upsertOfferVariantFeedback = ({
  offerVariantId,
  feedback,
  comment,
}: {
  offerVariantId: string;
  feedback?: OfferVariantFeedback | null;
  comment?: string | null;
}) => {
  return axios
    .put<void>(`/offer-variants/${offerVariantId}/feedback`, {
      feedback,
      comment,
    })
    .then((response) => response.data);
};

export const getOfferVariantFeedbackById = ({
  offerVariantId,
}: {
  offerVariantId: string;
}) => {
  return axios
    .get<OfferVariantFeedbackResponse>(
      `/offer-variants/${offerVariantId}/feedback`,
    )
    .then((response) => response.data);
};
