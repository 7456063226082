import React from 'react';
import ImageUploading, {
  ImageListType,
  ImageType,
} from 'react-images-uploading';
import { PlusIcon } from '@heroicons/react/20/solid';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import OfferSortablePhoto from './offer-sortable-photo';
import { getNextId } from '../../utils/get-next-id';
import { useTranslation } from 'react-i18next';
import Button from '../button/button';

export interface ImageTypeWitId extends ImageType {
  id: number;
}
interface Props {
  images: ImageTypeWitId[];
  setImages: (images: ImageTypeWitId[]) => void;
  originalImages?: { dataURL: string; id: number }[];
  maxImages?: number;
}

export const MAX_IMAGES = 20;

const OfferPhotosUpload = ({
  images,
  setImages,
  originalImages = [],
  maxImages = MAX_IMAGES,
}: Props) => {
  const { t } = useTranslation(['offers']);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const newIndex = images.findIndex((i) => i.id === over?.id);
        const oldIndex = images.findIndex((i) => i.id === active.id);

        const result = arrayMove(images, oldIndex, newIndex);
        setImages(result);
      }
    },
    [images],
  );

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ) => {
    if (!addUpdateIndex) {
      setImages(imageList as any);
    } else {
      const newImages = addUpdateIndex.map((i) => ({
        ...imageList[i],
        id: getNextId(),
      }));
      const listWithoutNewImages = imageList.filter(
        (_, i) => !addUpdateIndex.includes(i),
      ) as any[];
      setImages([...listWithoutNewImages, ...newImages]);
    }
  };

  const restoreOriginalImages = () => {
    setImages([...images, ...originalImages]);
  };

  return (
    <div className="overflow-hidden">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxImages}
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={imageList as any[]}
                strategy={rectSortingStrategy}
              >
                {imageList.map((image, index) => (
                  <OfferSortablePhoto
                    dataUrl={image.dataURL as string}
                    setDataUrl={(dataURL) =>
                      setImages(
                        images.map((i) =>
                          i.id === image.id ? { ...i, dataURL: dataURL } : i,
                        ),
                      )
                    }
                    id={image.id}
                    key={image.id}
                    onImageRemove={() => onImageRemove(index)}
                  />
                ))}
              </SortableContext>
            </DndContext>
            {[...Array(Math.max(0, maxImages - imageList.length)).keys()].map(
              (i) => (
                <div
                  key={i}
                  {...dragProps}
                  className="mx-auto h-[120px] w-[200px]"
                >
                  <button
                    onClick={onImageUpload}
                    type="button"
                    className={`relative flex h-full w-full items-center justify-center rounded border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                  >
                    <PlusIcon className="mr-2 h-12 w-12 text-indigo-500" />
                    <span className="text-sm">
                      {t('offers:offerForm.images.clickToUpload')}
                    </span>
                  </button>
                </div>
              ),
            )}
          </div>
        )}
      </ImageUploading>
      {originalImages.length > 0 && (
        <Button
          variant="secondary"
          type="button"
          className="ml-auto mr-2 mt-2 p-2"
          onClick={restoreOriginalImages}
        >
          {t('offers:offerForm.images.restore')}
        </Button>
      )}
    </div>
  );
};

export default OfferPhotosUpload;
