import React from 'react';

const PhoneCallSkeleton = () => {
  return (
    <div className="flex w-full animate-pulse overflow-hidden rounded-lg border border-gray-200 bg-white p-2">
      <div className="flex w-full flex-row items-center">
        <div className="mr-4">
          <div className="h-6 w-6 rounded bg-gray-200" />
        </div>
        <div className="flex w-full flex-col">
          <div className="flex flex-row">
            <span className="h-4 w-24 rounded bg-gray-200 font-bold"></span>
            <div className="ml-auto mt-1 h-4 w-16 rounded bg-gray-200"></div>
          </div>
          <div className="mt-1 h-4 w-16 rounded bg-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default PhoneCallSkeleton;
