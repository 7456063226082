const KEY = 'retry-import-refreshed';

export const retryImport = (
  componentImport: () => Promise<any>,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(KEY) || 'false',
    );

    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem(KEY, 'false');
        resolve(component);
      })
      .catch((error: Error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(KEY, 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
};
