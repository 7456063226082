import React from 'react';
import Button from '../button/button';
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/20/solid';
import ImageEditorModal from '../image/image-editor-modal';

interface Props {
  dataUrl: string;
  onImageRemove: () => void;
  setDataUrl: (dataUrl: string) => void;
}

const OfferPhoto = ({ dataUrl, onImageRemove, setDataUrl }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div
      className={`relative flex h-[120px] w-[200px]  justify-center rounded border-2 border-dashed border-gray-300`}
    >
      <ImageEditorModal
        dataURL={dataUrl}
        isOpen={isOpen}
        setDataURL={setDataUrl}
        close={() => setIsOpen(false)}
      />
      <img
        src={dataUrl}
        alt="Image"
        className="inset-0 object-contain object-center"
      />
      <div
        className="absolute right-0 top-0"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Button
          variant="secondary"
          className="p-1"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onImageRemove();
          }}
        >
          <XMarkIcon className="text-indig-500 h-5 w-5" />
        </Button>
      </div>
      <div
        className="absolute bottom-0 right-0"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Button
          variant="secondary"
          className="p-1"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <PencilSquareIcon className="text-indig-500 h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export default OfferPhoto;
