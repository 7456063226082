import React from 'react';
import { PhoneCallResultEnum } from '../../api/api.types';
import {
  CALL_RESULT_CLASSES,
  CALL_RESULT_ICONS,
} from '../../constants/call-result-icons';

interface Props {
  callResult: PhoneCallResultEnum;
  className?: string;
}

const CallResultIcon = ({ callResult, className }: Props) => {
  return (
    <>
      <div
        className={`relative flex items-center justify-center rounded-full p-1 text-white ${CALL_RESULT_CLASSES[callResult]} ${className}`}
      >
        {CALL_RESULT_ICONS[callResult]({ className: 'h-3 w-3' })}
      </div>
    </>
  );
};

export default CallResultIcon;
