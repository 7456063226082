import React from 'react';
import TimeToNow from '../../formatters/time-to-now';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  dateScraped: string;
  tag: string;
}

const OfferDateScrapedBadge = ({ className, dateScraped, tag }: Props) => {
  const { t } = useTranslation(['offers']);
  return (
    <>
      <div
        data-effect="solid"
        data-for="dateScrapedBadge"
        data-tip={`${t('offers:badges.dateScraped.tooltip')} ${t(
          `data-model:enumLabels.tag.${tag}`,
        )}`}
        className={`flex select-none items-center rounded bg-blue-100 px-1.5 py-0.5 text-xs font-medium uppercase text-blue-700 ${className}`}
      >
        <ArrowDownTrayIcon className="mr-1 h-4 w-4" />
        <TimeToNow date={dateScraped} strict />
      </div>
      <ReactTooltip id="dateScrapedBadge" />
    </>
  );
};

export default OfferDateScrapedBadge;
