import {
  ClientSearchSelectedOffersResponse,
  IssuerTypeEnum,
  OfferSearchStateEnum,
  OfferTypeEnum,
  OfferVariantFeedback,
} from '../../../../api/api.types';
import React, { Fragment } from 'react';
import { convertDownloadedImagesToSize } from '../../../../utils/convert-downloaded-image-to-size';
import OfferTagBadge from '../../../../components/offer/offer-badges/offer-tag-badge';
import IssuerTypeIcon from '../../../../components/icons/issuer-type-icon';
import OfferTypeIcon from '../../../../components/icons/offer-type-icon';
import { generateOfferLink } from '../../../../utils/generate-offer-link';
import { Link } from 'react-router-dom';
import CurrencyFormatter from '../../../../components/formatters/currency-formatter';
import SizeFormatter, {
  getValueAndUnit,
} from '../../../../components/formatters/size-formatter';
import { isDefined } from '../../../../utils/is-defined';
import { Menu, Popover, Transition } from '@headlessui/react';
import Button from '../../../../components/button/button';
import {
  ArchiveBoxXMarkIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  LockClosedIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { classNames } from '../../../../style/class-names';
import { useTranslation } from 'react-i18next';
import { Icon } from 'leaflet';
import { IconType } from 'react-icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postChangeClientSearchOfferStatus } from '../../../../api/client-searches/client-searches';
import { OfferStateTableSteps } from './offer-state-table';
import { use2Xl, useLg, useSm, useXl } from '../../../../style/media-queries';
import OfferVariantUpdateModal from '../../../../components/offer-variant/offer-variant-update-modal';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { getVariantUrl } from '../../../../utils/get-public-variant-url';
import { useOfferPdfDownload } from '../../../../hooks/use-pdf-download/use-pdf-download';
import { usePopper } from 'react-popper';
import { HiChatBubbleLeft } from 'react-icons/hi2';
import { BiDoorOpen, BiEraser, IoIosRefreshCircle } from 'react-icons/all';
import { getWatermarkRemoval } from '../../../../api/watermark_remover/watermark_remover';
import { useVariantCreationFromOffer } from '../../../../components/offer-variant/use-variant-creation-from-offer';
import { useToast } from '../../../../hooks/use-toast/use-toast';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../../../context/auth/use-user';
import WatermarkRemoverModal from '../../watermark-remover/watermark-remover-modal';
import OfferImagesSlider from '../../../../components/slider/offer-images-slider';
import { useOfferNavigate } from '../../../../hooks/use-navigate/use-offer-navigate';
import { SubscriptionTierType } from '../../../../models/user';

type ChangeStatusFunction = (arg: {
  clientSearchId: string;
  clientSearchOfferId: string;
  newState: OfferSearchStateEnum;
  offerVariantId?: string | undefined;
}) => void;

interface Action {
  onClick: () => void;
  icon:
    | React.ForwardRefExoticComponent<
        React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
          title?: string;
          titleId?: string;
        } & React.RefAttributes<SVGSVGElement>
      >
    | IconType;
  text: string;
  isLocked?: boolean;
}

interface Props {
  step: OfferStateTableSteps;
  selectedOffer: ClientSearchSelectedOffersResponse;
  removeElementFromListImmediately: (id: string) => void;
}

const OfferStateTableItem = ({
  step,
  selectedOffer,
  removeElementFromListImmediately,
}: Props) => {
  const navigateToOffer = useOfferNavigate({
    offerId: (selectedOffer.offer.offer as any).id,
    offerType: (selectedOffer.offer.offer as any).offer_type,
  });
  const { t } = useTranslation(['client-searches']);
  const client = useQueryClient();
  const { mutate: changeStatus, isLoading: isStatusChanging } = useMutation(
    postChangeClientSearchOfferStatus,
    {
      onSuccess: () => {
        client.invalidateQueries([
          'clientSearchesOffers',
          selectedOffer.client_offer_search_id,
        ]);
        removeElementFromListImmediately(
          selectedOffer.client_search_selected_offer_id,
        );
      },
    },
  );

  const offer = (
    selectedOffer.offer_variant
      ? selectedOffer.offer_variant.variant_data
      : selectedOffer.offer.offer
  ) as Record<string, any>;
  const offerProps = { ...offer } as ItemBaseOfferProps;
  offerProps.photos = offer.downloaded_photos_urls
    ? convertDownloadedImagesToSize(offer.downloaded_photos_urls, 'sm')
    : offer.photos_urls;

  const rejectAction: Action = {
    onClick: () =>
      changeStatus({
        clientSearchId: selectedOffer.client_offer_search_id,
        clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
        newState: OfferSearchStateEnum.REJECTED,
      }),
    icon: ArchiveBoxXMarkIcon,
    text: t('client-searches:clientSearchDetails.actions.reject'),
  };

  const openOriginalAction: Action = {
    onClick: navigateToOffer,
    icon: BiDoorOpen,
    text: t('client-searches:clientSearchDetails.actions.openOriginal'),
  };

  const Item = {
    [OfferStateTableSteps.PRESELECTED]: ItemNew,
    [OfferStateTableSteps.PREPARING]: ItemInPreparation,
    [OfferStateTableSteps.SHOWN]: ItemShared,
    [OfferStateTableSteps.REJECTED]: ItemRejected,
  }[step];

  return (
    <li className="flex items-center justify-between gap-x-6 py-4">
      <Item
        changeStatus={changeStatus}
        offerProps={offerProps}
        selectedOffer={selectedOffer}
        rejectAction={rejectAction}
        openOriginalAction={openOriginalAction}
        isStatusChanging={isStatusChanging}
        feedback={selectedOffer.offer_variant?.offer_variant_feedback?.feedback}
        comment={selectedOffer.offer_variant?.offer_variant_feedback?.comment}
      />
    </li>
  );
};

interface ItemNewProps {
  changeStatus: ChangeStatusFunction;
  offerProps: ItemBaseOfferProps;
  selectedOffer: ClientSearchSelectedOffersResponse;
  rejectAction: Action;
  openOriginalAction: Action;
  isStatusChanging?: boolean;
  feedback?: OfferVariantFeedback;
  comment?: string;
}

const ItemNew = ({
  changeStatus,
  offerProps,
  selectedOffer,
  rejectAction,
  openOriginalAction,
  isStatusChanging = false,
}: ItemNewProps) => {
  const { displayDefaultErrorToasts } = useToast();
  const isSm = useSm();
  const isXl = useXl();
  const { t } = useTranslation(['client-searches']);
  const [isItemInTransitionToPrepared, setIsItemInTransitionToPrepared] =
    React.useState(false);
  const createVariant = useVariantCreationFromOffer({
    offerId: selectedOffer.offer_id,
    offerType: selectedOffer.offer_type,
  });

  const selectAction: Action = {
    onClick: async () => {
      try {
        setIsItemInTransitionToPrepared(true);
        const variantId = await createVariant();
        changeStatus({
          clientSearchId: selectedOffer.client_offer_search_id,
          clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
          newState: OfferSearchStateEnum.PREPARING,
          offerVariantId: variantId,
        });
      } catch (e: any) {
        displayDefaultErrorToasts(e);
      } finally {
        setIsItemInTransitionToPrepared(false);
      }
    },
    icon: ArrowRightCircleIcon,
    text: t('client-searches:clientSearchDetails.actions.prepare'),
  };

  const smVariant = (
    <ActionsMenu
      actions={[selectAction, openOriginalAction, rejectAction]}
      isLoading={isStatusChanging || isItemInTransitionToPrepared}
    />
  );

  const mdVariant = (
    <>
      <ActionsMenu
        actions={[openOriginalAction, rejectAction]}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isItemInTransitionToPrepared}
      />
      <ActionButton
        action={selectAction}
        isDisabled={isStatusChanging}
        isLoading={isItemInTransitionToPrepared}
        type="primary"
      />
    </>
  );

  const lgVariant = (
    <>
      <ActionButton
        action={openOriginalAction}
        isDisabled={isItemInTransitionToPrepared || isStatusChanging}
      />
      <ActionButton
        action={rejectAction}
        isDisabled={isItemInTransitionToPrepared}
        isLoadingAfterClick={isStatusChanging}
      />
      <ActionButton
        action={selectAction}
        isDisabled={isStatusChanging}
        isLoading={isItemInTransitionToPrepared}
        type="primary"
      />
    </>
  );

  return (
    <ItemBase
      {...offerProps}
      bottomPanelContent={
        <div className="flex w-full justify-end gap-2">
          {!isSm && smVariant}
          {isSm && !isXl && mdVariant}
          {isXl && lgVariant}
        </div>
      }
    />
  );
};

const ItemInPreparation = ({
  changeStatus,
  offerProps,
  selectedOffer,
  rejectAction,
  openOriginalAction,
  isStatusChanging = false,
}: ItemNewProps) => {
  const user = useUser();
  const { displaySuccessToast, displayWarningToast, displayErrorToast } =
    useToast();
  const client = useQueryClient();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isWatermarkRemoving, setIsWatermarkRemoving] = React.useState(false);
  const [isOfferRefreshing, setIsOfferRefreshing] = React.useState(false);
  const [forceEditDataReload, setForceEditDataReload] = React.useState(false);
  const isSm = useSm();
  const isXl = useXl();
  const { t } = useTranslation(['client-searches']);
  const createVariant = useVariantCreationFromOffer({
    offerId: selectedOffer.offer_id,
    offerType: selectedOffer.offer_type,
  });

  const { data } = useQuery(
    ['watermark-removal', selectedOffer.watermark_removal_lro?.lro_id],
    () => getWatermarkRemoval(selectedOffer.watermark_removal_lro!.lro_id),
    {
      enabled: selectedOffer.watermark_removal_lro?.status === 'IN_PROGRESS',
      cacheTime: 0,
      refetchInterval: 10_000,
    },
  );

  const [isWatermarkRemovalInProgress, setIsWatermarkRemovalInProgress] =
    React.useState(data?.status === 'IN_PROGRESS');

  React.useEffect(() => {
    if (data?.status === 'IN_PROGRESS') {
      setIsWatermarkRemovalInProgress(true);
    } else {
      if (isWatermarkRemovalInProgress) {
        client.invalidateQueries([
          'clientSearchesOffers',
          selectedOffer.client_offer_search_id,
        ]);
        client.invalidateQueries([
          'offerVariants',
          selectedOffer.offer_variant?.offer_variant_id,
        ]);
        setForceEditDataReload(true);

        if (data?.status === 'SUCCEED') {
          displaySuccessToast({
            title: t(
              'client-searches:clientSearchDetails.success.objectsRemoved.title',
            ),
            body: t(
              'client-searches:clientSearchDetails.success.objectsRemoved.details',
            ),
            duration: 10_000,
          });
        } else if (data?.status === 'FAILED') {
          displayErrorToast({
            title: t(
              'client-searches:clientSearchDetails.errors.objectsNotRemoved.title',
            ),
            body: t(
              'client-searches:clientSearchDetails.errors.objectsNotRemoved.details',
            ),
            duration: 10_000,
          });
        } else if (data?.status === 'PARTIALLY_SUCCEED') {
          displayWarningToast({
            title: t(
              'client-searches:clientSearchDetails.errors.objectsPartiallyRemoved.title',
            ),
            body: t(
              'client-searches:clientSearchDetails.errors.objectsPartiallyRemoved.details',
            ),
            duration: 10_000,
          });
        }
      }
      setIsWatermarkRemovalInProgress(false);
    }
  }, [data?.status]);

  const editAction: Action = {
    onClick: () => setIsUpdating(true),
    icon: PencilSquareIcon,
    text: t('client-searches:clientSearchDetails.actions.update'),
  };

  const watermarkRemovalAction: Action = {
    onClick: () => setIsWatermarkRemoving(true),
    icon: BiEraser,
    text: t('client-searches:clientSearchDetails.actions.erase'),
    isLocked: !user.hasSufficientValidSubscriptionTier(
      SubscriptionTierType.PREMIUM,
    ),
  };

  const shareAction: Action = {
    onClick: () => {
      changeStatus({
        clientSearchId: selectedOffer.client_offer_search_id,
        clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
        newState: OfferSearchStateEnum.SHOWN_TO_CLIENT,
      });
    },
    icon: ArrowRightCircleIcon,
    text: t('client-searches:clientSearchDetails.actions.showToClient'),
  };

  const makeVariant: Action = {
    onClick: async () => {
      try {
        setIsOfferRefreshing(true);
        const variantId = await createVariant();
        changeStatus({
          clientSearchId: selectedOffer.client_offer_search_id,
          clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
          newState: OfferSearchStateEnum.PREPARING,
          offerVariantId: variantId,
        });
      } finally {
        setIsOfferRefreshing(false);
      }
    },
    icon: IoIosRefreshCircle,
    text: t('client-searches:clientSearchDetails.actions.refresh'),
  };

  const smVariant = (
    <ActionsMenu
      actions={[shareAction, editAction, openOriginalAction, rejectAction]}
      isLoading={isStatusChanging || isWatermarkRemovalInProgress}
    />
  );

  const mdVariant = (
    <>
      <ActionsMenu
        actions={[
          editAction,
          watermarkRemovalAction,
          openOriginalAction,
          rejectAction,
        ]}
        isDisabled={isStatusChanging}
        isLoading={isWatermarkRemovalInProgress}
        isLoadingAfterClick={isStatusChanging}
        ignoreLoadAfterClick={[watermarkRemovalAction, editAction]}
      />
      <ActionButton
        action={shareAction}
        isDisabled={isWatermarkRemovalInProgress || isStatusChanging}
        isLoadingAfterClick={isStatusChanging}
        type="primary"
      />
    </>
  );

  const lgVariant = (
    <>
      <ActionsMenu
        actions={[openOriginalAction, rejectAction]}
        isDisabled={isStatusChanging || isWatermarkRemovalInProgress}
        isLoadingAfterClick={isStatusChanging}
        ignoreLoadAfterClick={[watermarkRemovalAction, editAction]}
      />
      <ActionButton
        action={watermarkRemovalAction}
        isLoading={isWatermarkRemovalInProgress}
        isDisabled={isStatusChanging}
      />
      <ActionButton
        action={editAction}
        isDisabled={isWatermarkRemovalInProgress || isStatusChanging}
      />
      <ActionButton
        action={shareAction}
        isDisabled={isStatusChanging || isWatermarkRemovalInProgress}
        isLoadingAfterClick={isStatusChanging}
        type="primary"
      />
    </>
  );

  return (
    <>
      {selectedOffer?.offer_variant?.offer_variant_id && (
        <WatermarkRemoverModal
          isOpen={isWatermarkRemoving}
          close={() => setIsWatermarkRemoving(false)}
          onSubmit={() => {
            client.invalidateQueries([
              'clientSearchesOffers',
              selectedOffer.client_offer_search_id,
            ]);
            client.invalidateQueries([
              'offerVariants',
              selectedOffer.offer_variant?.offer_variant_id,
            ]);
            setForceEditDataReload(true);
          }}
          offerVariant={selectedOffer.offer_variant}
        />
      )}
      <OfferVariantUpdateModal
        offerVariantId={selectedOffer.offer_variant?.offer_variant_id!}
        offerType={selectedOffer.offer_type}
        offerId={selectedOffer.offer_id}
        isOpen={isUpdating}
        close={() => {
          setIsUpdating(false);
          client.invalidateQueries([
            'clientSearchesOffers',
            selectedOffer.client_offer_search_id,
          ]);
        }}
        forceDataReferch={forceEditDataReload}
        setForceDataRefetch={setForceEditDataReload}
      />
      <ItemBase
        {...offerProps}
        bottomPanelContent={
          <div className="flex w-full justify-end gap-2">
            {selectedOffer.offer_variant ? (
              <>
                {!isSm && smVariant}
                {isSm && !isXl && mdVariant}
                {isXl && lgVariant}
              </>
            ) : (
              <>
                <ActionButton
                  action={makeVariant}
                  isLoading={isStatusChanging || isOfferRefreshing}
                  type="primary"
                />
              </>
            )}
          </div>
        }
      />
    </>
  );
};

const ItemShared = ({
  offerProps,
  selectedOffer,
  rejectAction,
  openOriginalAction,
  isStatusChanging = false,
  feedback,
  comment,
  changeStatus,
}: ItemNewProps) => {
  const isSm = useSm();
  const isXl = useXl();
  const is2Xl = use2Xl();
  const { t } = useTranslation(['client-searches']);
  const { isLoading: isPdfLoading, download: downloadPdf } =
    useOfferPdfDownload({
      offerType: selectedOffer.offer_type,
      offerId: selectedOffer.offer_id,
      offerVariantId: selectedOffer.offer_variant?.offer_variant_id,
      fileName: `${offerProps?.title?.replace(/\s/g, '_')}.pdf` ?? 'offer.pdf',
    });

  const downloadPdfAction: Action = {
    onClick: downloadPdf,
    icon: DocumentArrowDownIcon,
    text: t('client-searches:clientSearchDetails.actions.downloadPdf'),
  };

  const openNewWindowAction: Action = {
    onClick: () => {
      window.open(
        getVariantUrl({
          offerVariantId: selectedOffer.offer_variant?.offer_variant_id!,
        }),
        '_blank',
      );
    },
    icon: ArrowTopRightOnSquareIcon,
    text: t('client-searches:clientSearchDetails.actions.openUrl'),
  };

  const toInPrepareAction: Action = {
    onClick: () => {
      changeStatus({
        clientSearchId: selectedOffer.client_offer_search_id,
        clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
        newState: OfferSearchStateEnum.PREPARING,
      });
    },
    icon: ArrowLeftCircleIcon,
    text: t('client-searches:clientSearchDetails.actions.toInPrepare'),
  };

  const smVariant = (
    <ActionsMenu
      actions={[
        openNewWindowAction,
        downloadPdfAction,
        toInPrepareAction,
        openOriginalAction,
        rejectAction,
      ]}
      isLoading={isPdfLoading || isStatusChanging}
    />
  );

  const mdVariant = (
    <>
      <ActionsMenu
        actions={[
          downloadPdfAction,
          toInPrepareAction,
          openOriginalAction,
          rejectAction,
        ]}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isStatusChanging}
        isLoading={isPdfLoading}
      />
      <ActionButton
        action={openNewWindowAction}
        isDisabled={isStatusChanging}
        isLoadingAfterClick={isStatusChanging}
        type="primary"
      />
    </>
  );

  const lgVariant = (
    <>
      <ActionsMenu
        actions={[downloadPdfAction, openOriginalAction, rejectAction]}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isStatusChanging}
        isLoading={isPdfLoading}
      />
      <ActionButton
        action={toInPrepareAction}
        isDisabled={isStatusChanging}
        isLoadingAfterClick={isStatusChanging}
      />
      <ActionButton
        action={openNewWindowAction}
        isDisabled={isStatusChanging}
        type="primary"
      />
    </>
  );

  const xlVariant = (
    <>
      <ActionsMenu
        actions={[openOriginalAction, rejectAction]}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isStatusChanging}
      />
      <ActionButton
        action={toInPrepareAction}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isStatusChanging}
      />
      <ActionButton
        action={downloadPdfAction}
        isDisabled={isStatusChanging}
        isLoading={isPdfLoading}
      />
      <ActionButton
        action={openNewWindowAction}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isStatusChanging}
        type="primary"
      />
    </>
  );

  return (
    <>
      <ItemBase
        {...offerProps}
        extraPropsContent={
          <>
            {feedback && (
              <div className="ml-2 border-l border-gray-300 pl-2">
                <FeedbackBadge feedback={feedback} />
              </div>
            )}
            {comment && (
              <div className="ml-2 border-l border-gray-300 pl-2">
                <CommentMenu comment={comment} />
              </div>
            )}
          </>
        }
        bottomPanelContent={
          <div className="flex w-full justify-end gap-2">
            {!isSm && smVariant}
            {isSm && !isXl && mdVariant}
            {isXl && !is2Xl && lgVariant}
            {is2Xl && xlVariant}
          </div>
        }
      />
    </>
  );
};

const ItemRejected = ({
  changeStatus,
  offerProps,
  selectedOffer,
  openOriginalAction,
  isStatusChanging = false,
  feedback,
  comment,
}: ItemNewProps) => {
  const isSm = useSm();
  const isLg = useLg();
  const isXl = useXl();
  const { t } = useTranslation(['client-searches']);

  const showToClientAgain: Action = {
    onClick: () =>
      changeStatus({
        clientSearchId: selectedOffer.client_offer_search_id,
        clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
        newState: OfferSearchStateEnum.SHOWN_TO_CLIENT,
      }),
    icon: ArrowLeftCircleIcon,
    text: t('client-searches:clientSearchDetails.actions.showToClientAgain'),
  };
  const prepare: Action = {
    onClick: () =>
      changeStatus({
        clientSearchId: selectedOffer.client_offer_search_id,
        clientSearchOfferId: selectedOffer.client_search_selected_offer_id,
        newState: OfferSearchStateEnum.PREPARING,
      }),
    icon: ArrowLeftCircleIcon,
    text: t('client-searches:clientSearchDetails.actions.prepare'),
  };

  const smVariant = (
    <ActionsMenu
      actions={[
        prepare,
        ...(selectedOffer.offer_variant ? [showToClientAgain] : []),
        openOriginalAction,
      ]}
      isLoading={isStatusChanging}
    />
  );
  const mdVariant = (
    <>
      <ActionsMenu
        actions={[
          ...(selectedOffer.offer_variant ? [showToClientAgain] : []),
          openOriginalAction,
        ]}
        isLoadingAfterClick={isStatusChanging}
        isDisabled={isStatusChanging}
      />
      <ActionButton
        action={prepare}
        isDisabled={isStatusChanging}
        isLoadingAfterClick={isStatusChanging}
        type="primary"
      />
    </>
  );
  const lgVariant = (
    <>
      <ActionButton action={openOriginalAction} isDisabled={isStatusChanging} />
      {selectedOffer.offer_variant && (
        <ActionButton
          action={showToClientAgain}
          isDisabled={isStatusChanging}
          isLoadingAfterClick={isStatusChanging}
        />
      )}
      <ActionButton
        action={prepare}
        isDisabled={isStatusChanging}
        isLoadingAfterClick={isStatusChanging}
        type="primary"
      />
    </>
  );

  return (
    <>
      <ItemBase
        {...offerProps}
        extraPropsContent={
          <>
            {feedback && (
              <div className="ml-2 border-l border-gray-300 pl-2">
                <FeedbackBadge feedback={feedback} />
              </div>
            )}
            {comment && (
              <div className="ml-2 border-l border-gray-300 pl-2">
                <CommentMenu comment={comment} />
              </div>
            )}
          </>
        }
        bottomPanelContent={
          <div className="flex w-full justify-end gap-2">
            {!isSm && smVariant}
            {isSm && !isLg && mdVariant}
            {isXl && lgVariant}
          </div>
        }
      />
    </>
  );
};

interface ItemBaseOfferProps {
  id?: string;
  offer_type: OfferTypeEnum;
  title: string;
  photos: string[];
  price?: number | null;
  property_size?: number | null;
  price_currency?: string | null;
  tag?: string;
  offer_original_url?: string;
  issuer_type?: IssuerTypeEnum | null;
}

interface ItemBaseProps extends ItemBaseOfferProps {
  bottomPanelContent?: React.ReactNode;
  extraPropsContent?: React.ReactNode;
}

const ItemBase = ({
  id,
  offer_type,
  title,
  photos,
  price,
  price_currency,
  property_size,
  tag,
  offer_original_url,
  issuer_type,
  bottomPanelContent,
  extraPropsContent,
}: ItemBaseProps) => {
  const isSm = useSm();
  return (
    <div className="flex w-full flex-row rounded border">
      <div className="relative min-h-[8rem] min-w-[5rem] overflow-hidden sm:min-w-[8rem]">
        <OfferImagesSlider images={photos} isOriginal={false} />
        {tag && offer_original_url && (
          <div className="absolute bottom-1 left-1 right-1 z-50 items-center truncate text-amber-800">
            <OfferTagBadge tag={tag} offerOriginalUrl={offer_original_url} />
          </div>
        )}
        {issuer_type && (
          <span className="absolute right-1 top-1 z-50 rounded-full border border-white">
            <IssuerTypeIcon
              issuerType={issuer_type}
              size="sm"
              showTooltip={true}
            />
          </span>
        )}
        <span className="absolute left-1 top-1 z-50 rounded-full border border-white">
          <OfferTypeIcon offerType={offer_type} size="sm" showTooltip={true} />
        </span>
      </div>
      <div
        style={{ maxWidth: isSm ? 'calc(100% - 8rem)' : 'calc(100% - 5rem)' }}
        className="flex flex-grow flex-col gap-2 p-2 pl-3"
      >
        {id && offer_type ? (
          <Link
            className="truncate overflow-ellipsis leading-tight"
            to={generateOfferLink({ id, offerType: offer_type })}
            state={{
              backPathname: location.pathname,
              backSearch: location.search,
            }}
          >
            <h1 className="truncate text-sm font-semibold leading-tight text-black">
              {title}
            </h1>
          </Link>
        ) : (
          <h1 className="truncate overflow-ellipsis text-sm font-semibold leading-tight text-black">
            {title}
          </h1>
        )}

        <div className="flex flex-wrap gap-1">
          {isDefined(price) && price_currency && (
            <div className="text-sm">
              <CurrencyFormatter value={price} currency={price_currency} />
            </div>
          )}
          {isDefined(property_size) && (
            <div className="ml-2 border-l border-gray-300 pl-2 text-sm">
              <SizeFormatter {...getValueAndUnit(property_size, offer_type)} />
            </div>
          )}
          {extraPropsContent}
        </div>
        <div className="mt-auto">{bottomPanelContent}</div>
      </div>
    </div>
  );
};

interface ActionsButtonProps {
  action: Action;
  className?: string;
  type?: 'primary' | 'secondary';
  isDisabled?: boolean;
  isLoading?: boolean;
  isLoadingAfterClick?: boolean;
}

const ActionButton = ({
  action,
  className = '',
  type = 'secondary',
  isLoadingAfterClick = false,
  isDisabled = false,
  isLoading = false,
}: ActionsButtonProps) => {
  const { t } = useTranslation('common');
  const tooltipId = React.useId();
  const [isClicked, setIsClicked] = React.useState(false);
  React.useEffect(() => {
    if (!isLoadingAfterClick) {
      setIsClicked(false);
    }
  }, [isLoadingAfterClick]);
  const Icon = action.icon;
  const isLocked = action.isLocked ?? false;
  return (
    <Button
      variant={type === 'primary' ? 'secondaryAccent' : 'secondary'}
      className={`${className} px-2.5 py-1.5 ${isLocked ? 'mr-0.5' : ''}`}
      type="button"
      isLoading={(isLoadingAfterClick && isClicked) || isLoading}
      disabled={
        (isLoadingAfterClick && !isClicked) ||
        isLoading ||
        isLocked ||
        isDisabled
      }
      onClick={() => {
        setIsClicked(true);
        action.onClick();
      }}
    >
      {
        <Icon
          className={`mr-3 h-5 w-5 ${
            type === 'primary'
              ? `${
                  (isLoadingAfterClick && !isClicked) || isLoading
                    ? 'text-indigo-400'
                    : 'text-indigo-700 group-hover:text-indigo-800'
                }`
              : 'text-gray-400 group-hover:text-gray-500'
          }`}
        />
      }
      {action.text}
      {isLocked && (
        <>
          <div
            data-tip={t('common:subscription.constrains.insufficientTier', {
              tier: t(
                `common:subscription.tier.${SubscriptionTierType.PREMIUM}`,
              ),
            })}
            data-for={tooltipId}
            className="absolute -right-2 -top-2 rounded-full bg-indigo-500 p-1 text-white"
          >
            <LockClosedIcon className="h-4 w-4" />
          </div>
          <ReactTooltip id={tooltipId} />
        </>
      )}
    </Button>
  );
};

interface ActionsMenuProps {
  actions: Action[];
  isDisabled?: boolean;
  isLoading?: boolean;
  isLoadingAfterClick?: boolean;
  ignoreLoadAfterClick?: Action[];
}

const ActionsMenu = ({
  isLoading = false,
  isDisabled = false,
  isLoadingAfterClick = false,
  ignoreLoadAfterClick = [],
  actions,
}: ActionsMenuProps) => {
  const { t } = useTranslation(['client-searches']);
  const [isClicked, setIsClicked] = React.useState(false);
  React.useEffect(() => {
    if (!isLoadingAfterClick) {
      setIsClicked(false);
    }
  }, [isLoadingAfterClick]);
  const tooltipId = React.useId();

  return (
    <Menu as="div" className="relative flex-none">
      <Menu.Button
        as={Button}
        variant="secondary"
        type="button"
        className="px-3 py-2"
        disabled={(isLoadingAfterClick && isClicked) || isLoading || isDisabled}
        isLoading={(isLoadingAfterClick && isClicked) || isLoading}
      >
        <>
          <span>
            {t('client-searches:clientSearchDetails.actions.actions')}
          </span>
          <ChevronDownIcon
            className={`-mr-1 h-5 w-5 text-gray-400 ${
              isLoading ? 'text-transparent' : ''
            }`}
            aria-hidden="true"
          />
        </>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-[100] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col py-1">
            {actions.map(({ text, icon: Icon, onClick, isLocked = false }) => (
              <Menu.Item key={text}>
                {({ active }) => (
                  <button
                    onClick={() => {
                      if (
                        !ignoreLoadAfterClick.some(
                          (action) => action.onClick === onClick,
                        )
                      ) {
                        setIsClicked(true);
                      }
                      onClick();
                    }}
                    disabled={isLocked}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group relative flex items-center px-4 py-2 text-sm',
                      isLocked ? '!text-gray-500' : '',
                    )}
                  >
                    {
                      <Icon
                        className={`mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 ${
                          isLocked
                            ? '!group-hover:text-gray-300 !text-gray-300'
                            : ''
                        }`}
                      />
                    }
                    {text}
                    {isLocked && (
                      <>
                        <div
                          data-tip={t('common:featureFlags.disabled')}
                          data-for={tooltipId}
                          className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full bg-indigo-500 p-1 text-white"
                        >
                          <LockClosedIcon className="h-3 w-3" />
                        </div>
                        <ReactTooltip id={tooltipId} />
                      </>
                    )}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const FeedbackBadge = ({ feedback }: { feedback: OfferVariantFeedback }) => {
  const { t } = useTranslation(['public']);

  const map = {
    [OfferVariantFeedback.YES]: 'bg-green-50 text-green-700 ring-green-600/20',
    [OfferVariantFeedback.NO]: 'bg-red-50 text-red-700 ring-red-600/10',
    [OfferVariantFeedback.MAYBE]:
      'bg-yellow-50 text-yellow-800 ring-yellow-600/20',
    [OfferVariantFeedback.EXPIRED]:
      'bg-gray-50 text-gray-600 ring-gray-500/10"',
  };

  return (
    <span
      className={`relative bottom-0.5 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${map[feedback]}`}
    >
      {t(`client-searches:clientSearchDetails.feedback.${feedback}`)}
    </span>
  );
};

const CommentMenu = ({ comment }: { comment: string }) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });

  return (
    <Popover>
      <Popover.Button
        className="relative flex items-baseline rounded px-1.5"
        ref={setReferenceElement}
      >
        <HiChatBubbleLeft className="h-6 w-6 text-gray-500" />
      </Popover.Button>
      <div
        style={styles.popper}
        ref={setPopperElement}
        {...attributes.popper}
        className="z-30 min-w-[180px] max-w-[300px]"
      >
        <Transition
          unmount={false}
          beforeEnter={() => setActive(true)}
          as={React.Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          afterLeave={() => setActive(false)}
        >
          <Popover.Panel unmount={false}>
            <div className="flex flex-col overflow-hidden whitespace-pre-wrap rounded-lg bg-white p-4 text-sm shadow-lg ring-1 ring-black ring-opacity-5">
              {comment}
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default OfferStateTableItem;
