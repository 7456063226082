import React from 'react';
import { classNames } from '../../style/class-names';

interface Props {
  length?: number;
  className?: string;
}

const ActivityFeedLoader = ({ length = 4, className = '' }: Props) => {
  return (
    <ul role="list" className={`space-y-8 ${className}`}>
      {Array.from({ length }).map((event, activityItemIdx) => (
        <li className="relative flex gap-x-4" key={activityItemIdx}>
          <div
            className={classNames(
              activityItemIdx === length - 1 ? 'h-6' : '-bottom-8',
              'absolute left-0 top-0 flex w-8 animate-pulse justify-center',
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <ActivityFeedLoaderItem />
        </li>
      ))}
    </ul>
  );
};

const ActivityFeedLoaderItem = () => {
  return (
    <div className="flex w-full">
      <div className="relative">
        <div className="absolute left-0 top-0 h-8 w-8 rounded-full bg-white" />
        <div className="left-0 top-0 h-8 w-8 animate-pulse rounded-full bg-gray-300" />
      </div>
      <div className="w-full">
        <div className="flex justify-between">
          <div className="ml-2 h-4 w-4/6 animate-pulse rounded bg-gray-300" />
          <div className="ml-2 h-4 w-1/6 animate-pulse rounded bg-gray-300" />
        </div>
        <div className="flex justify-between">
          <div className="ml-2 mt-4 h-4 w-1/4 animate-pulse rounded bg-gray-300"></div>
          <div className="ml-2 mt-4 h-4 w-1/4 animate-pulse rounded bg-gray-300"></div>
        </div>
        <div className="flex justify-between">
          <div className="ml-2 mt-4 h-4 w-1/3 animate-pulse rounded bg-gray-300"></div>
          <div className="ml-2 mt-4 h-4 w-1/6 animate-pulse rounded bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeedLoader;
