import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactNumberButtonModal from './contact-number-button-modal';
import { formatPhone } from '../../constants/phone-formatter';
import Button from '../button/button';

interface Props {
  contactNumber?: string | string[] | null;
  offerId: string;
  className?: string;
  hasPrefix?: boolean;
  textClassName?: string;
  disabled?: boolean;
  offerOriginalUrl?: string;
}

const ContactNumberButton = ({
  contactNumber,
  offerId,
  className = '',
  hasPrefix = false,
  textClassName,
  offerOriginalUrl,
}: Props) => {
  const { t } = useTranslation(['common']);
  const number = Array.isArray(contactNumber)
    ? contactNumber[0]
    : contactNumber;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button type="button" onClick={() => setOpen(true)} className={className}>
        <span className={textClassName}>
          {hasPrefix ? `${t('common:contact.buttonPrefix')} ` : ''}
          {formatPhone(number)}
        </span>
      </Button>
      <ContactNumberButtonModal
        offerId={offerId}
        phoneNumber={number ?? null}
        isOpen={open}
        offerOriginalUrl={offerOriginalUrl}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default ContactNumberButton;
