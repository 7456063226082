import React from 'react';
import { useTranslation } from 'react-i18next';
import { SIZE_SYMBOL, SizeUnit } from '../../constants/size-symbol';
import { OfferTypeEnum } from '../../api/api.types';

interface Props {
  value: number;
  unit: SizeUnit;
}

export const useSizeFormat = () => {
  const { i18n } = useTranslation();
  const sizeFormat = Intl.NumberFormat(i18n.language, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return { format: (x: number) => sizeFormat.format(x), symbol: 'm²' };
};

const SizeFormatter = ({ value, unit }: Props) => {
  const { format } = useSizeFormat();
  return (
    <>
      {format(value)} {SIZE_SYMBOL[unit]}
    </>
  );
};

export const getValueAndUnit = (
  value: number,
  offerType: OfferTypeEnum,
): { unit: SizeUnit; value: number } => {
  if (
    [OfferTypeEnum.LAND_SALES, OfferTypeEnum.LAND_RENTS].includes(offerType)
  ) {
    if (value >= 10000) {
      return { value: value / 10000, unit: 'hectare' };
    } else {
      return { value: value / 100, unit: 'are' };
    }
  } else {
    return { value, unit: 'square-meter' };
  }
};

export default SizeFormatter;
