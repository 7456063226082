import { Title } from '../../../components/text/title';
import { Paragraph } from '../../../components/text/paragraph';
import Button from '../../../components/button/button';
import { useTranslation } from 'react-i18next';
import EmptyBoxSvg from '../../../assets/svg/empty-box-svg';
import { CiEraser } from 'react-icons/all';

interface Props {
  clearFilters: () => void;
  variant?: 'regular' | 'small';
}

export const OfferCollectionsListEmptyStateFilters = ({
  clearFilters,
  variant = 'regular',
}: Props) => {
  const { t } = useTranslation(['offer-collections']);
  return (
    <div
      className={`flex h-full flex-col items-center justify-center gap-2 ${
        variant === 'small' ? 'p-4' : 'p-8 sm:gap-4'
      }`}
    >
      <EmptyBoxSvg
        className={`${
          variant === 'small'
            ? '-mt-4 h-48 w-48'
            : 'h-64 w-64 sm:h-72 sm:w-72 lg:-mb-6 lg:h-80 lg:w-80'
        } -mb-4 `}
      />
      <Title variant={variant === 'small' ? 'normal' : 'extra-large'}>
        {t('offer-collections:list.emptyFilters.title')}
      </Title>
      <Paragraph
        variant={variant === 'small' ? 'extra-small' : 'normal'}
        className="max-w-xl text-center"
      >
        {t('offer-collections:list.emptyFilters.text')}
      </Paragraph>
      <Button
        className={`${
          variant === 'small' ? '' : 'sm:mt-4 xl:px-3 xl:py-2 xl:text-sm'
        } mt-2 px-2 py-1.5 text-xs`}
        variant="primary"
        type="button"
        onClick={clearFilters}
      >
        <CiEraser className="mr-1 h-5 w-5" />
        <span>{t('offer-collections:list.emptyFilters.button')}</span>
      </Button>
    </div>
  );
};
