import {
  OfferCollectionItem,
  OfferCollectionVisibility,
} from '../../api/offer-collections/offer-collections';
import { useUser } from '../../context/auth/use-user';
import { OrganizationMemberPrivilege } from '../../api/api.types';

export const useOfferCollectionPermissions = (
  collection?: OfferCollectionItem,
) => {
  const user = useUser();

  if (!collection) {
    return { canEdit: false, canManageContent: false };
  }

  const canEdit =
    collection.created_by.user_id === user.userId ||
    (user.hasMinimumPrivilege(OrganizationMemberPrivilege.ADMIN) &&
      [
        OfferCollectionVisibility.PUBLIC_READ_ONLY,
        OfferCollectionVisibility.PUBLIC,
      ].includes(collection.visibility));

  const canManageContent =
    canEdit || collection.visibility === OfferCollectionVisibility.PUBLIC;

  return {
    canEdit,
    canManageContent,
  };
};
