import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  tag: string | string[];
  offerOriginalUrl: string;
  className?: string;
}

const OfferTagBadge = ({ tag, offerOriginalUrl, className = '' }: Props) => {
  const { t } = useTranslation(['data-model']);
  return (
    <a
      href={offerOriginalUrl}
      target="_blank"
      rel="nofollow noreferrer"
      className={`rounded bg-amber-100 px-2 py-0.5 text-xs font-medium uppercase !text-amber-800 ${className}`}
    >
      {t(`data-model:enumLabels.tag.${Array.isArray(tag) ? tag[0] : tag}`)}
    </a>
  );
};

export default OfferTagBadge;
