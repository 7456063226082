import React from 'react';
import { UseAddToClientSearchClientData } from './use-add-to-client-search-client';
import { UseAddToClientSearchClientSearchData } from './use-add-to-client-search-client-search';
import { UseAddToClientSearchSubmitData } from './use-add-to-client-search-submit';

enum State {
  INITIAL_LOADING = 'init',
  CLIENT_INPUT = 'client',
  CLIENT_SEARCH_LOADING = 'client_search_loading',
  CLIENT_SEARCH_INPUT = 'client_search',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
}

interface Props {
  client: UseAddToClientSearchClientData;
  clientSearch: UseAddToClientSearchClientSearchData;
  submit: UseAddToClientSearchSubmitData;
}

export const useAddToClientSearchState = ({
  client,
  clientSearch,
  submit,
}: Props) => {
  const [state, setState] = React.useState<State>(State.INITIAL_LOADING);

  React.useEffect(() => {
    switch (state) {
      case State.INITIAL_LOADING: {
        if (client.count !== undefined) {
          setState(State.CLIENT_INPUT);
        }
        return;
      }
      case State.CLIENT_INPUT: {
        if (client.isValid) {
          setState(State.CLIENT_SEARCH_LOADING);
        }
        return;
      }
      case State.CLIENT_SEARCH_LOADING: {
        if (!client.isValid) {
          setState(State.CLIENT_INPUT);
        } else if (clientSearch.haveClientSearches !== undefined) {
          setState(State.CLIENT_SEARCH_INPUT);
        }
        return;
      }
      case State.CLIENT_SEARCH_INPUT: {
        if (!client.isValid) {
          setState(State.CLIENT_INPUT);
        } else if (clientSearch.isValid && submit.isSubmitting) {
          setState(State.SUBMITTING);
        }
        return;
      }
    }
  }, [
    state,
    client.isValid,
    client.count,
    clientSearch.isValid,
    clientSearch.haveClientSearches,
    submit.isSubmitting,
  ]);

  React.useEffect(() => {
    switch (state) {
      case State.SUBMITTING: {
        if (submit.wasLastSubmitSuccess) {
          setState(State.SUBMITTED);
        } else {
          setState(State.CLIENT_SEARCH_INPUT);
        }
        return;
      }
    }
  }, [submit.wasLastSubmitSuccess]);

  const reset = () => {
    setState(State.INITIAL_LOADING);
  };

  return { value: state, reset };
};

export { State as UseAddToClientSearchState };
