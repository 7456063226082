import React from 'react';
import { useTranslation } from 'react-i18next';
import { SIZE_SYMBOL, SizeUnit } from '../../constants/size-symbol';

interface Props {
  currencyUnit: string;
  currencyValue: number;
  sizeValue: number;
  sizeUnit: SizeUnit;
}

const CurrencyPerSizeFormatter = ({
  currencyUnit,
  currencyValue,
  sizeValue,
  sizeUnit,
}: Props) => {
  const { i18n } = useTranslation();

  const currencyFormat = Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currencyUnit,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).formatToParts(sizeValue === 0 ? 0 : currencyValue / sizeValue);
  const filtered = currencyFormat.filter((part) => part.type !== 'currency');
  return (
    <>
      {currencyFormat
        .filter((part) => part.type !== 'currency')
        .map((v) => v.value)
        .join('')}
      {filtered.length > 0 && filtered[filtered.length - 1]?.type !== 'literal'
        ? ' '
        : ''}
      {currencyFormat.find((part) => part.type === 'currency')?.value}/
      {SIZE_SYMBOL[sizeUnit]}
    </>
  );
};

export default CurrencyPerSizeFormatter;
