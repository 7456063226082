import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Sort } from '../../models/sort';
import { useUser } from '../../context/auth/use-user';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { AVMResult, OfferTypeEnum } from '../../api/api.types';
import { getOffers } from '../../api/offers/offers';
import OfferCard from '../../components/offer/offer-card';
import Pagination from '../../components/pagination/pagination';
import { KeyIcon, PhoneIcon } from '@heroicons/react/20/solid';
import PaginationItemsSummary from '../../components/pagination/pagination-items-summary';
import OfferCardSkeleton from '../../components/offer/offer-card-skeleton';
import { useSearchParams } from '../../hooks/use-search/use-search-params';
import { FilterCondition } from '../../models/filter';
import { OFFERS_TYPES } from '../../constants/offers';

interface Props {
  phoneNumbers: string[];
  isNumbersLoading?: boolean;
}

const MyOffersList = ({ phoneNumbers, isNumbersLoading }: Props) => {
  const { t } = useTranslation(['data-model', 'views-builder', 'offers']);
  const user = useUser();

  const {
    page = 1,
    filters = '[]',
    sorts = JSON.stringify([
      { field: 'date_scraped', ascending: false } as Sort,
    ]),
  } = useSearchParams<{
    page: number;
    filters: string;
    sorts: string;
    offerType: OfferTypeEnum;
  }>();
  const location = useLocation();

  const parsedFilters = React.useMemo(() => JSON.parse(filters), [filters]);
  const parsedSorts = React.useMemo(() => JSON.parse(sorts), [sorts]);

  const { data, isLoading } = useQuery(
    [
      'offers',
      page,
      [
        ...parsedFilters,
        ...user.personalizedFilters(),
        {
          field: 'contact_number',
          value: phoneNumbers,
          condition: FilterCondition.IN,
        },
      ],
      parsedSorts,
      user.settings.itemsPerPage,
    ],
    ({ signal }) =>
      getOffers(
        OFFERS_TYPES,
        page,
        user.settings.itemsPerPage,
        [
          ...parsedFilters,
          ...user.personalizedFilters(),
          {
            field: 'contact_number',
            value: phoneNumbers,
            condition: FilterCondition.IN,
          },
        ],
        parsedSorts,
        { signal },
      ),
    {
      enabled: phoneNumbers.length > 0,
    },
  );

  if (phoneNumbers.length === 0) {
    return (
      <div className="mt-12 text-center">
        <PhoneIcon className="mx-auto h-10 w-10 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {t('my-offers:noPhoneNumber.title')}
        </h3>
        <NavLink
          to="/settings/account"
          className="mt-1 text-sm font-medium text-indigo-600 hover:text-indigo-500"
        >
          <span>
            {t('my-offers:noPhoneNumber.description')}
            <span aria-hidden="true"> &rarr;</span>
          </span>
        </NavLink>
      </div>
    );
  }

  return (
    <div className="h-full px-8 pb-16">
      <div className="mt-12" />
      {(isNumbersLoading || isLoading) && (
        <div className="mt-4 grid grid-cols-1 pt-10">
          {[...Array(user.settings.itemsPerPage).keys()].map((i) => (
            <OfferCardSkeleton key={i} />
          ))}
        </div>
      )}
      {data && (
        <>
          <div className="border-b border-gray-200 pb-3">
            <PaginationItemsSummary
              page={page}
              recordsPerPage={user.settings.itemsPerPage}
              totalRecords={data.total_items}
            />
          </div>
          <div className="grid grid-cols-1 pt-3">
            {data.results.length === 0 ? (
              <div className="mt-12 text-center">
                <KeyIcon className="mx-auto h-10 w-10 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  {t('my-offers:noOffers.title')}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t('my-offers:noOffers.description')}
                </p>
              </div>
            ) : (
              data.results.map((result) => (
                <OfferCard
                  {...(result.offer as any)}
                  activity={result.most_relevant_activity}
                  offerStatus={result.status}
                  key={(result.offer as any).id}
                  offerType={(result.offer as any).offer_type}
                  offerCollectionsCount={result.offer_collections_count}
                  activityCount={
                    (result.comments_count ?? 0) +
                    (result.phone_call_summary_count ?? 0)
                  }
                  duplicatesCount={result.duplicates_count}
                  spamProbability={result.spam_probability as number | null}
                  avm={result.avm as AVMResult | null}
                />
              ))
            )}
          </div>
          {data.results.length > 0 && (
            <Pagination
              totalPages={data.total_pages}
              basePath={location.pathname + location.search}
              page={data.page}
              totalRecords={data.total_items}
              recordsPerPage={user.settings.itemsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MyOffersList;
