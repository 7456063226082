import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectOption } from './select-input';
import SelectInputLabeledArrow, {
  SelectInputLabeledArrowProps,
} from './select-input-arrow';

interface Props
  extends Omit<
    SelectInputLabeledArrowProps,
    'labelFor' | 'value' | 'onChange'
  > {
  name: string;
  options: SelectOption[];
  control: any;
  className?: string;
  id: string;
  clearable?: boolean;
  showLabel?: boolean;
  label?: string;
  placeholder?: string;
  buttonClassName?: string;
  labelClassName?: string;
  isRequired?: boolean;
}

const SelectInputLabeledArrowController = ({
  control,
  name,
  ...rest
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SelectInputLabeledArrow
          isError={!!error}
          errorMessage={error?.message}
          {...{ ...rest, onChange, value }}
        />
      )}
    />
  );
};

export default SelectInputLabeledArrowController;
