import React from 'react';
import { useTranslation } from 'react-i18next';
import ExplanationIcon, { ExplanationIconProps } from './explanation-icon';

const UserAvatarExplanationIcon = (
  props: Omit<ExplanationIconProps, 'text'>,
) => {
  const { t } = useTranslation('settings');
  return (
    <ExplanationIcon text={t('settings:account.logoExplanation')} {...props} />
  );
};

export default UserAvatarExplanationIcon;
