import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import logoFull from '../../assets/logo-transparent.png';
import logoSmall from '../../assets/logo-small-transparent.png';
import logoSmallAnalytics from '../../assets/logo-small-analytics.png';
import logoFullAnalytics from '../../assets/logo-full-analytics.png';
import { useAppView } from '../../context/app-views/use-app-view';
import { ChevronUpDownIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../context/auth/use-user';
import Button from '../button/button';
import { ANALYTICS_URL } from '../../constants/branding';
interface Props {
  isSidebarSmall: boolean;
  center?: boolean;
}

const AppSelectPopover = ({ isSidebarSmall, center = true }: Props) => {
  const appView = useAppView();
  const { t } = useTranslation('button');
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 0] } },
      { name: 'eventListeners', enabled: active },
    ],
  });

  const logoSm = appView.isAnalyticsApp ? logoSmallAnalytics : logoSmall;
  const logo = appView.isAnalyticsApp ? logoFullAnalytics : logoFull;

  const renderAnalyticsButton = () => {
    const button = (
      <button
        className={`flex w-full items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 ${
          appView.isAnalyticsApp
            ? 'border-indigo-600 ring-2 ring-indigo-600'
            : 'border-gray-300'
        }`}
        onClick={() => appView.switchToAnalyticsApp()}
      >
        <img
          className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12"
          src={logoSmallAnalytics}
          alt="propertly.io Analytics"
        />
        <div className="ml-4">
          <p className="text-left text-sm font-medium text-gray-900">
            {t('common:apps.analytics.title')}
          </p>
          <p className="text-sm text-gray-500">
            {t('common:apps.analytics.description')}
          </p>
        </div>
      </button>
    );

    if (appView.isAnalyticsAvailable) {
      return button;
    }
    return (
      <div>
        <div
          className={`relative`}
          data-effect="solid"
          data-tip={t('common:featureFlags.disabled')}
          data-for="disabledByFF"
        >
          <div className="pointer-events-none flex opacity-50">{button}</div>
          <div className="absolute bottom-0 right-2 top-0 z-50 flex items-center justify-center text-white">
            <LockClosedIcon
              className={`h-5 w-5 rounded-full bg-indigo-500 p-1`}
            />
          </div>
          <ReactTooltip id="disabledByFF" />
        </div>
        <a
          href={ANALYTICS_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="mr-4 flex justify-end text-sm font-semibold leading-6 text-indigo-600"
        >
          {t('common:apps.learnMore')}
          <span aria-hidden="true" className="ml-1">
            &rarr;
          </span>
        </a>
      </div>
    );
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className="relative w-full" ref={setReferenceElement}>
            <div className="flex flex-shrink-0 cursor-pointer items-center pb-3 pt-1">
              {isSidebarSmall ? (
                <img
                  className={`h-12 ${center ? 'mx-auto' : 'ml-4'} w-auto`}
                  src={logoSm}
                  alt="Logo"
                />
              ) : (
                <img
                  className={`${
                    center ? 'mx-auto' : 'ml-4'
                  } my-1 -mb-1 h-12 w-auto object-cover object-left`}
                  src={logo}
                  alt="Logo"
                />
              )}
            </div>
            <div
              className={`absolute flex  ${
                isSidebarSmall
                  ? '-bottom-1 left-0 right-0'
                  : 'bottom-0 right-0.5 top-0 items-center justify-center'
              }`}
            >
              <ChevronUpDownIcon
                className={`${open ? 'text-indigo-300' : 'text-indigo-300/70'}
                  mx-auto h-6 w-6 transition duration-150 ease-in-out group-hover:text-indigo-300/80`}
                aria-hidden="true"
              />
            </div>
          </Popover.Button>
          <div
            style={styles.popper}
            ref={setPopperElement}
            {...attributes.popper}
            className="z-[60]"
          >
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              beforeEnter={() => setActive(true)}
              afterLeave={() => setActive(false)}
            >
              <Popover.Panel className="z-10 w-96 px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                  <div className="relative grid gap-y-2 bg-white p-2">
                    <button
                      className={`flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 ${
                        appView.isRealEstateApp
                          ? 'border-indigo-600 ring-2 ring-indigo-600'
                          : 'border-gray-300'
                      }`}
                      onClick={() => appView.switchToRealEstateApp()}
                    >
                      <img
                        className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12"
                        src={logoSmall}
                        alt="propertly.io"
                      />
                      <div className="ml-4">
                        <p className="text-left text-sm font-medium text-gray-900">
                          {t('common:apps.realEstate.title')}
                        </p>
                        <p className="text-left text-sm text-gray-500">
                          {t('common:apps.realEstate.description')}
                        </p>
                      </div>
                    </button>
                    {renderAnalyticsButton()}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        </>
      )}
    </Popover>
  );
};

export default AppSelectPopover;
