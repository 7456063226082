import { Menu } from '@headlessui/react';
import React from 'react';
import { classNames } from '../../../../style/class-names';
import { useTranslation } from 'react-i18next';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';

interface Props {
  onClick: () => void;
}

const CreateMeetingAction = ({ onClick }: Props) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={onClick}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'flex w-full px-4 py-2 text-sm',
            )}
          >
            <CalendarDaysIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              )}
            >
              {t('common:offerMenu.createMeeting')}
            </span>
          </button>
        )}
      </Menu.Item>
    </>
  );
};

export default CreateMeetingAction;
