import { axios } from '../axios';
import {
  GenerateOfferDescriptionRequest,
  GenerateOfferDescriptionResponse,
} from '../api.types';

export const postGenerateOfferDescription = ({
  body,
}: {
  body: GenerateOfferDescriptionRequest;
}) => {
  return axios
    .post<GenerateOfferDescriptionResponse>('/generate/description', body)
    .then((resp) => resp.data);
};
