import { Menu } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboardAlerted from '../../../copy-to-clipboard/copy-to-clipboard-alerted';
import { ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../style/class-names';

interface Props {
  offerOriginalUrl: string;
}

const CopyOriginalOfferLinkAction = ({ offerOriginalUrl }: Props) => {
  const { t } = useTranslation(['commmon']);
  return (
    <CopyToClipboardAlerted
      text={offerOriginalUrl}
      notificationText={t('common:copy.alerts.copiedOfferAddress')}
    >
      <Menu.Item>
        {({ active }) => (
          <button
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'flex w-full px-4 py-2 text-sm',
            )}
          >
            <ClipboardDocumentIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>{t('common:offerMenu.copyLink')}</span>
          </button>
        )}
      </Menu.Item>
    </CopyToClipboardAlerted>
  );
};

export default CopyOriginalOfferLinkAction;
