import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTeams } from '../../../api/teams/teams.api';
import TeamInput from './team-input';
import { MINUTES_5 } from '../../../constants/periods';

interface Props {
  value: string | null;
  onChange: (teamId: string | null) => void;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  isError?: boolean;
  clearable?: boolean;
}

const TeamInputPopulated = (props: Props) => {
  const { data: teams = [] } = useQuery(['organizations', 'teams'], getTeams, {
    staleTime: MINUTES_5,
    cacheTime: MINUTES_5,
  });

  return (
    <TeamInput
      teams={teams.map((t) => ({ teamId: t.team_id, name: t.name }))}
      {...props}
    />
  );
};

export default TeamInputPopulated;
