import { ICONS } from '../../ui-model/icons';
import { EXTRA_ICONS } from '../../ui-model/extra-icons';

interface Props {
  fieldName: string;
  className?: string;
}

const icons = { ...ICONS, ...EXTRA_ICONS };

const FieldIcon = ({ className = '', fieldName }: Props) => {
  if (!icons[fieldName as keyof typeof icons]) {
    return null;
  }
  return icons[fieldName as keyof typeof icons]({ className });
};

export default FieldIcon;
