import {
  AddViewRequest,
  CreateViewResponse,
  OfferTypeEnum,
  UpdateViewRequest,
  ViewListResponse,
  ViewResponse,
} from '../api.types';
import { axios } from '../axios';

export const getViewsByOfferType = (
  offerType: OfferTypeEnum | null,
  search: string | null,
  page: number,
  size: number,
): Promise<ViewListResponse> => {
  return axios
    .get<ViewListResponse>(`/views`, {
      params: { offer_type: offerType, search, page, size },
    })
    .then((resp) => resp.data);
};

export const getNotifiableViews = (): Promise<ViewResponse[]> => {
  return axios
    .get<ViewResponse[]>(`/views/notifiable`)
    .then((resp) => resp.data);
};

export const getViewById = (id: string): Promise<ViewResponse> => {
  return axios.get<ViewResponse>(`/views/${id}`).then((resp) => resp.data);
};

export const addView = ({
  body,
}: {
  body: AddViewRequest;
}): Promise<CreateViewResponse> => {
  return axios.post(`/views`, body).then((resp) => resp.data);
};

export const updateView = ({
  body,
  viewId,
}: {
  body: UpdateViewRequest;
  viewId: string;
}): Promise<void> => {
  return axios.put(`/views/${viewId}`, body);
};

export const deleteView = (viewId: string): Promise<void> => {
  return axios.delete(`/views/${viewId}`);
};
