import React from 'react';
import SpinnerIcon from '../spinner/spinner-icon';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isLoading?: boolean;
  variant?:
    | 'primary'
    | 'secondaryAccent'
    | 'secondary'
    | 'danger'
    | 'dangerSecondary';
  contentWrapperClassName?: string;
}

interface VariantStylesProps {
  isLoading?: boolean;
  disable?: boolean;
}

export const VARIANT_STYLES = {
  primary: ({ isLoading, disable }: VariantStylesProps) =>
    `${disable ? 'bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-700'} ${
      isLoading ? 'text-transparent' : 'text-white'
    } focus:ring-indigo-500 border-transparent`,
  secondaryAccent: ({ isLoading, disable }: VariantStylesProps) =>
    `${
      disable
        ? 'bg-gray-100 border-indigo-200'
        : 'bg-white hover:bg-gray-50 border-indigo-600'
    }
    ${
      isLoading
        ? 'text-transparent'
        : disable
        ? 'text-indigo-900'
        : 'text-indigo-700'
    }
     'focus:ring-indigo-500 border-1`,
  secondary: ({ isLoading, disable }: VariantStylesProps) =>
    `${disable ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'} ,
      ${isLoading ? 'text-transparent' : 'text-gray-700'}
     'focus:ring-indigo-500 border-gray-300`,
  danger: ({ isLoading, disable }: VariantStylesProps) =>
    `${disable ? 'bg-red-400' : 'bg-red-600 hover:bg-red-700'} ${
      isLoading ? 'text-transparent' : 'text-white'
    } focus:ring-red-500 border-transparent`,
  dangerSecondary: ({ isLoading, disable }: VariantStylesProps) =>
    `${disable ? 'bg-red-100' : 'bg-white hover:bg-red-50'} ,
      ${isLoading ? 'text-transparent' : 'text-red-700'}
     'focus:ring-red-500 border-red-300`,
};

const SPINNER_STYLES = {
  primary: 'text-white',
  secondaryAccent: 'text-indigo-700',
  secondary: 'text-gray-500',
  danger: 'text-white',
  dangerSecondary: 'text-red-500',
};

const Button: React.FC<ButtonProps> = ({
  isLoading,
  className,
  children,
  disabled,
  variant = 'primary',
  contentWrapperClassName = '',
  ...rest
}) => {
  const disable = disabled || isLoading;
  return (
    <button
      {...rest}
      disabled={disable}
      className={`${VARIANT_STYLES[variant]({
        isLoading,
        disable,
      })} relative flex items-center justify-center rounded-md border text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${className}`}
    >
      {isLoading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <SpinnerIcon
            className={`h-5 w-5 ${SPINNER_STYLES[variant]} absolute`}
          />
        </div>
      )}
      <div
        className={`flex items-center justify-center ${contentWrapperClassName}`}
      >
        {children}
      </div>
    </button>
  );
};

export default Button;
