import React from 'react';
import ErrorPage from './../../pages/error/error';
import { ErrorBoundary } from '@sentry/react';

interface Props {
  children: React.ReactNode;
}

const GlobalErrorHandler = ({ children }: Props) => {
  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <ErrorPage
          message={error.message}
          componentStack={componentStack ?? ''}
          errorName={error.name}
          errorStack={error.stack ?? ''}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorHandler;
