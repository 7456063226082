import React from 'react';
import { NoSymbolIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  size: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}

const SIZE_CLASSES = {
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-8 w-8',
};

const OfferContactRestrictionIndicator = ({
  className,
  size,
  style,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={style}
        data-effect="solid"
        data-tip={t('data-model:fieldLabels.contact_restriction')}
        className={`cursor-pointer ${className}`}
      >
        <NoSymbolIcon className={`text-red-500 ${SIZE_CLASSES[size]}`} />
      </div>
      <ReactTooltip />
    </>
  );
};

export default OfferContactRestrictionIndicator;
