import React from 'react';
import logoSmall from '../../assets/logo-small-transparent.png';

const SIZES = {
  sm: 'h-5 w-5',
  md: 'h-8 w-8',
  lg: 'h-10 w-10',
  xl: 'h-16 w-16',
  '2xl': 'h-20 w-20',
  '3xl': 'h-24 w-24',
  '4xl': 'h-32 w-32',
};

interface Props {
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
}

const PropertlyAvatar = ({ size, className }: Props) => {
  return (
    <div
      className={`${SIZES[size]} flex items-center justify-center rounded-full bg-white ${className} border border-gray-200`}
    >
      <img className="uppercase text-white" src={logoSmall} alt="propertly" />
    </div>
  );
};

export default PropertlyAvatar;
