import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface SelectOption {
  labelIcon?: JSX.Element;
  label: string;
  value: string;
}

export interface SelectInputProps {
  options: SelectOption[];
  onChange: (value: string[]) => void;
  labelComponent?: JSX.Element;
  value: string[];
  className?: string;
  id?: string;
  emptyInput?: (isOpen: boolean) => React.ReactElement;
  errorMessage?: string;
  isError?: boolean;
  saveErrorSpace?: boolean;
}

const MultiSelectInput = ({
  value = [],
  onChange,
  labelComponent,
  className = '',
  id = '',
  emptyInput = () => <>-</>,
  options,
  errorMessage = '',
  isError = false,
  saveErrorSpace = false,
}: SelectInputProps) => {
  const node = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const labelsMap = React.useMemo(
    () =>
      options.reduce((acc: Record<string, SelectOption>, opt) => {
        acc[opt.value] = opt;
        return acc;
      }, {}),
    [options],
  );

  const isSelected = (newValue: string) => {
    return !!value.find((el) => el === newValue);
  };

  const handleSelect = (newValue: string) => {
    if (!isSelected(newValue)) {
      const selectedOptionsUpdated = [...value, newValue];
      onChange(selectedOptionsUpdated);
    } else {
      removeSelection(newValue);
    }
  };

  const removeSelection = (selection: string) => {
    onChange(value.filter((opt) => opt !== selection));
  };

  const handleClick = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <div ref={node} className={className}>
      <Listbox
        as="div"
        className="space-y-1"
        value={value}
        onChange={(val) => handleSelect(val as unknown as string)}
      >
        {() => (
          <div>
            {labelComponent}
            <div className="relative">
              <span className="inline-block w-full rounded-md shadow-sm">
                <Listbox.Button
                  id={id}
                  as={Btn}
                  isError={isError}
                  isOpen={isOpen}
                  setIsOpen={() => setIsOpen(!isOpen)}
                >
                  {value.length <= 0
                    ? emptyInput(isOpen)
                    : value.sort().map((val) => (
                        <div
                          key={val}
                          className="inline-flex cursor-pointer items-center rounded-full bg-indigo-100 px-2 text-indigo-800"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeSelection(val);
                          }}
                        >
                          {labelsMap[val].labelIcon}
                          {labelsMap[val].label}
                          <XMarkIcon className="ml-1.5 h-4 w-4" />
                        </div>
                      ))}
                  {value.length > 0 && (
                    <span
                      className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange([]);
                      }}
                    >
                      <XMarkIcon
                        className={`${
                          isError ? 'text-red-500' : 'text-gray-400'
                        } h-5 w-5 cursor-pointer`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </Listbox.Button>
              </span>
              <Transition
                show={isOpen}
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => {
                    const selected = isSelected(option.value);
                    return (
                      <Listbox.Option
                        key={option.label}
                        className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
                        value={option.value}
                      >
                        {({ active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'flex truncate',
                              )}
                            >
                              {option.labelIcon}
                              {option.label}
                            </span>
                            {selected && (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      <p
        className={`mt-2 text-xs text-red-600 ${
          saveErrorSpace ? 'whitespace-pre-wrap' : ''
        }`}
      >
        {isError ? errorMessage : ' '}
      </p>
    </div>
  );
};

interface BtnProps {
  isOpen: boolean;
  setIsOpen: () => void;
  children: JSX.Element;
  isError: boolean;
  id: string;
}

const Btn = React.forwardRef(
  (
    { isOpen, setIsOpen, children, id, isError }: BtnProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        id={id}
        type="button"
        className={`${
          isError
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
        } relative flex w-full cursor-default flex-row flex-wrap gap-x-1 gap-y-1 rounded-md border  bg-white py-2 pl-2 pr-6 text-left shadow-sm  focus:outline-none focus:ring-1 sm:text-sm`}
        onClick={() => setIsOpen()}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

export default MultiSelectInput;
