import { useUser } from '../../context/auth/use-user';

const ALLOW_LIST = [
  'ba5dc3e5-20ea-4198-a1a0-6e23b24acc6b', // ko
  '3aa7845e-b4fc-44f3-a517-f13b4bb10878', // pie
];

export const useShowPieDataExport = () => {
  const { selectedOrganization } = useUser();
  return ALLOW_LIST.includes(selectedOrganization.organizationId);
};
