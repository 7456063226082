import React from 'react';
import { usePopper } from 'react-popper';
import { Combobox, Transition } from '@headlessui/react';
import {
  MagnifyingGlassIcon,
  MapIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import LocationTree from './location-tree';
import { useQuery } from '@tanstack/react-query';
import {
  getAddressAutocomplete,
  getDefaultAutocomplete,
  getFullAddressAutocomplete,
} from '../../../api/address/address';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import {
  Address,
  formatAddress,
  formatSimpleAddress,
  simplifyAddressString,
} from '../../../models/address';
import { HOURS_24 } from '../../../constants/periods';

export enum AddressSearchType {
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
  STREET_NUMBER = 'STREET_NUMBER',
}

export interface LocationInputProps {
  value: { label: string; value: Address }[];
  onChange: (value: { label: string; value: Address }[]) => void;
  id?: string;
  searchType?: AddressSearchType;
  isError?: boolean;
  errorMessage?: string;
  saveErrorSpace?: boolean;
  placeholder?: string;
}

const LocationInput = ({
  value = [],
  onChange,
  id,
  searchType = AddressSearchType.DEFAULT,
  isError = false,
  errorMessage = '',
  saveErrorSpace = false,
  placeholder = '',
}: LocationInputProps) => {
  const { t } = useTranslation(['common']);
  const [referenceElement, setReferenceElement] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const [wasSet, setWasSet] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const [search, setSearch] = React.useState('');

  const [debouncedSearch, control] = useDebounce(search, 300);

  const address = value?.[0]?.value;

  React.useEffect(() => {
    if (active && !wasSet && address) {
      if (searchType === AddressSearchType.STREET_NUMBER) {
        setSearch(formatSimpleAddress(address));
        inputRef.current!.value = formatSimpleAddress(address);
      } else {
        setSearch(simplifyAddressString(formatAddress(address)));
        inputRef.current!.value = simplifyAddressString(formatAddress(address));
      }
      setWasSet(true);
      control.flush();
    }
  }, [active, wasSet]);

  React.useEffect(() => {
    if (value.length === 0) {
      setSearch('');
    }
  }, [JSON.stringify(value)]);

  const getAutocomplete = () => {
    switch (searchType) {
      case AddressSearchType.FULL:
        return getAddressAutocomplete(debouncedSearch, 15);
      case AddressSearchType.DEFAULT:
        return getDefaultAutocomplete(debouncedSearch, 15);
      case AddressSearchType.STREET_NUMBER:
        return getFullAddressAutocomplete(debouncedSearch, 30);
    }
  };

  const { data: suggestions = [], isLoading: isSuggestionsLoading } = useQuery(
    ['addressAutocomplete', searchType, debouncedSearch],
    () => getAutocomplete(),
    {
      enabled: debouncedSearch.length > 0,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: HOURS_24,
      cacheTime: HOURS_24,
    },
  );

  return (
    <Combobox value={search} onChange={() => onChange([])}>
      <div
        ref={setReferenceElement}
        id={id}
        className={`relative flex w-full cursor-default flex-row flex-wrap gap-x-1 gap-y-1 rounded-md border bg-white text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm ${
          isError
            ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
        }`}
      >
        <Combobox.Button
          className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full"
          ref={buttonRef}
        />
        <Combobox.Input
          autoComplete="off"
          ref={inputRef}
          spellCheck={false}
          placeholder={placeholder}
          className={`${
            active || !address ? '' : 'text-white'
          } pointer-events-auto flex h-full w-full cursor-pointer flex-row flex-wrap gap-x-1 gap-y-1 rounded-md border-none bg-white py-2 pl-4 pr-6 text-left shadow-sm focus:outline-none focus:ring-2 sm:text-sm ${
            isError
              ? 'focus:border-red-500 focus:ring-red-500'
              : 'focus:border-indigo-500  focus:ring-indigo-500'
          }`}
          value={search}
          onClick={() => {
            if (!active) {
              buttonRef.current?.click();
              inputRef.current?.focus();
              inputRef.current?.setSelectionRange(search.length, search.length);
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
        />

        {value.length > 0 && !active && (
          <div className="pointer-events-none absolute grid py-2 pl-2 pr-6">
            {value.sort().map((val) => (
              <div
                key={val.label}
                className="cursor-pointer select-none items-center truncate rounded-full bg-indigo-100 px-2 text-indigo-800"
              >
                {val.label}
              </div>
            ))}
          </div>
        )}
        {(value.length > 0 || search.length > 0) && (
          <span
            className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-1"
            onClick={(e) => {
              e.stopPropagation();
              onChange([]);
              setSearch('');
            }}
          >
            <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        )}
      </div>
      <div
        style={styles.popper}
        ref={setPopperElement}
        {...attributes.popper}
        className="z-10"
      >
        <Transition
          appear={active}
          beforeEnter={() => setActive(true)}
          as={React.Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          afterLeave={() => {
            setActive(false);
            setWasSet(false);
          }}
        >
          <Combobox.Options className="rounded-xl border bg-white">
            {search ? (
              isSuggestionsLoading ? (
                <div
                  className="overflow-x-auto"
                  style={{ width: 400, height: 400 }}
                >
                  {[...new Array(20).keys()].map((i) => (
                    <div key={i} className="px-2">
                      <div className="mt-2 h-4 w-full animate-pulse rounded bg-gray-200" />
                    </div>
                  ))}
                </div>
              ) : suggestions.length === 0 ? (
                <div
                  className="overflow-x-auto"
                  style={{ width: 320, height: 400 }}
                >
                  <div className="mt-12 px-2 text-center">
                    <MapIcon className="mx-auto h-10 w-10 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      {t('common:locationInput.noLocations.title')}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {t('common:locationInput.noLocations.description')}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className="space-y-2 overflow-x-auto py-1"
                  style={{ width: 320, height: 400 }}
                >
                  {suggestions.map((sug) => (
                    <div
                      className="flex flex-row items-center"
                      key={sug.address}
                    >
                      <input
                        checked={value.some(
                          (v) => formatAddress(v.value) === formatAddress(sug),
                        )}
                        onChange={() => {
                          if (
                            value.some(
                              (v) =>
                                formatAddress(v.value) === formatAddress(sug),
                            )
                          ) {
                            onChange([]);
                          } else {
                            const { address, ...rest } = sug;
                            onChange([
                              {
                                label:
                                  searchType === AddressSearchType.STREET_NUMBER
                                    ? formatSimpleAddress(rest)
                                    : address,
                                value: rest,
                              },
                            ]);
                          }
                        }}
                        type="checkbox"
                        className="ml-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <div
                        className="ml-2 cursor-pointer select-none text-sm"
                        onClick={() => {
                          if (
                            value.some(
                              (v) =>
                                formatAddress(v.value) === formatAddress(sug),
                            )
                          ) {
                            onChange([]);
                          } else {
                            const { address, ...rest } = sug;
                            onChange([
                              {
                                label:
                                  searchType === AddressSearchType.STREET_NUMBER
                                    ? formatSimpleAddress(rest)
                                    : address,
                                value: rest,
                              },
                            ]);
                          }
                        }}
                      >
                        {searchType === AddressSearchType.STREET_NUMBER
                          ? formatSimpleAddress(sug)
                          : simplifyAddressString(formatAddress(sug))}
                      </div>
                    </div>
                  ))}
                </div>
              )
            ) : searchType === AddressSearchType.STREET_NUMBER ? (
              <div
                className="overflow-x-auto"
                style={{ width: 320, height: 400 }}
              >
                <div className="mt-12 px-2 text-center">
                  <MagnifyingGlassIcon className="mx-auto h-10 w-10 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    {t('common:locationInput.search.title')}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t('common:locationInput.search.description')}
                  </p>
                </div>
              </div>
            ) : (
              <LocationTree value={value} onChange={onChange} />
            )}
          </Combobox.Options>
        </Transition>
      </div>
      <p
        className={`mt-2 text-xs text-red-600 ${
          saveErrorSpace ? 'whitespace-pre-wrap' : ''
        }`}
      >
        {isError ? errorMessage : ' '}
      </p>
    </Combobox>
  );
};

export default LocationInput;
