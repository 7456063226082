import UserAvatar from '../../avatar/user-avatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferCollectionItem } from '../../../api/offer-collections/offer-collections';
import { OfferCollectionIconDisplay } from '../../icons/offer-collection-icon';
import { offerCollectionVisibilityToComponent } from '../../../models/offer-collections';
import { formatDistanceToNow } from 'date-fns';
import { DATE_FNS_LOCALES, SupportedLanguages } from '../../../i18n/languages';
import { useOfferCollectionNavigate } from '../../../hooks/use-navigate/use-offer-collection-navigation';

interface Props {
  className?: string;
  data: OfferCollectionItem;
  buttons?: React.ReactNode | ((open: () => void) => React.ReactNode);
  content?: React.ReactNode | ((open: () => void) => React.ReactNode);
}

export const OfferCollectionCardBase = ({
  className,
  data,
  buttons,
  content,
}: Props) => {
  const { t, i18n } = useTranslation(['offer-collections']);
  const { navigate } = useOfferCollectionNavigate({
    id: data.offer_collection_id,
  });

  const VisibilityIcon = offerCollectionVisibilityToComponent[data.visibility];

  const createdBy = (
    <div className="mt-2 flex gap-x-1 text-gray-600">
      <span className="truncate text-sm">
        <strong>{t('offer-collections:cards.owner')}:</strong>{' '}
        {data.created_by.first_name} {data.created_by.last_name}
      </span>
      <UserAvatar {...data.created_by} size="sm" />
    </div>
  );

  return (
    <>
      <li
        className={`col-span-1 overflow-hidden rounded-lg bg-white shadow ${className}`}
      >
        <div className="border-b-[1px] border-b-gray-200 p-6">
          <div className="grid w-full grid-cols-[1fr_auto] gap-x-2 gap-y-0.5">
            <button
              type="button"
              onClick={navigate}
              className="truncate overflow-ellipsis text-left text-base font-medium text-gray-900"
            >
              {data.name}
            </button>
            <div className="flex gap-x-1">
              <VisibilityIcon className="relative top-0.5 h-4 w-4 text-gray-400" />
              <p className="truncate text-sm text-gray-500">
                {t(`offer-collections:model:visibility:${data.visibility}`)}
              </p>
            </div>
            <OfferCollectionIconDisplay
              className="col-start-2 row-span-2 row-start-1"
              icon={data.icon}
              color={data.icon_color}
            />
          </div>

          {content ? (
            typeof content === 'function' ? (
              content(navigate)
            ) : (
              content
            )
          ) : (
            <>
              {data.description && (
                <>
                  <p className="mt-2 line-clamp-2 h-10 text-sm text-gray-600">
                    <strong>
                      {t('offer-collections:cards.description')}:{' '}
                    </strong>
                    <span>{data.description}</span>
                  </p>
                  {createdBy}
                </>
              )}

              {!data.description && (
                <>
                  <div className="mt-2.5 text-xs text-gray-600">
                    <strong>{t('offer-collections:cards.created')}: </strong>
                    <time dateTime={data.created_at}>
                      {formatDistanceToNow(new Date(data.created_at), {
                        locale:
                          DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
                        addSuffix: true,
                      })}
                    </time>
                  </div>
                  <div className="mb-2.5 mt-1 text-xs text-gray-600">
                    <strong>{t('offer-collections:cards.edited')}: </strong>
                    <time dateTime={data.updated_at}>
                      {formatDistanceToNow(new Date(data.updated_at), {
                        locale:
                          DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
                        addSuffix: true,
                      })}
                    </time>
                  </div>
                  {createdBy}
                </>
              )}
            </>
          )}
        </div>

        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            {typeof buttons === 'function' ? buttons(navigate) : buttons}
          </div>
        </div>
      </li>
    </>
  );
};
