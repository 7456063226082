function isObject(obj: any) {
  return typeof obj === 'object' && obj != null;
}

function deepCopy(val: any) {
  if (!isObject(val)) {
    return val;
  }
  const result: Record<any, any> = {};
  for (const key of Object.keys(val)) {
    result[key] = deepCopy(val[key]);
  }
  return result;
}

function deepEqual(val1: any, val2: any) {
  if (val1 === val2) {
    return true;
  }
  if (!isObject(val1) || !isObject(val2)) {
    return false;
  }
  if (Object.keys(val1).length !== Object.keys(val2).length) {
    return false;
  }
  for (const key of Object.keys(val1)) {
    if (!deepEqual(val1[key], val2[key])) {
      return false;
    }
  }
  return true;
}

function getFieldValuesRecursive(
  val: any,
  fieldName: string,
  allowedKeys: string[] | 'ALL' = 'ALL',
): any[] {
  if (typeof val != 'object' || val === null) {
    return [];
  }
  const result = [];
  for (const key of Object.keys(val)) {
    if (key === fieldName) {
      result.push(val[key]);
    } else if (
      allowedKeys === 'ALL' ||
      allowedKeys.some((v) => v.includes(key))
    ) {
      result.push(...getFieldValuesRecursive(val[key], fieldName));
    }
  }
  return result;
}

function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export { deepCopy, deepEqual, getFieldValuesRecursive, notNullOrUndefined };
