import React from 'react';
import { useTranslation } from 'react-i18next';
import ExplanationIcon, { ExplanationIconProps } from './explanation-icon';

const OfferCollectionVisibilityExplanationIcon = (
  props: Omit<ExplanationIconProps, 'text'>,
) => {
  const { t } = useTranslation('offer-collections');
  return (
    <ExplanationIcon
      text={`${t(
        'offer-collections:model.visibilityExplanation.PRIVATE',
      )}<br />${t(
        'offer-collections:model.visibilityExplanation.PUBLIC',
      )}<br />${t(
        'offer-collections:model.visibilityExplanation.PUBLIC_READ_ONLY',
      )}`}
      {...props}
    />
  );
};

export default OfferCollectionVisibilityExplanationIcon;
