import React from 'react';
import LocationMapInput, { LocationMapInputProps } from './location-map-input';
import { Controller } from 'react-hook-form';

interface Props extends Omit<LocationMapInputProps, 'onChange' | 'value'> {
  name: string;
  control: any;
}

const LocationMapInputController = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <LocationMapInput
          onChange={onChange}
          value={value}
          defaultZoom={value.longitude != null ? 14 : rest.defaultZoom}
          {...rest}
        />
      )}
    />
  );
};

export default LocationMapInputController;
