import React from 'react';
import InputLabel, { InputLabelProps } from '../shared/input-label';
import SmsMessageInput, { SmsMessageInputProps } from './sms-message-input';

export interface SmsMessageInputPropsLabeledProps
  extends SmsMessageInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
}

const SmsMessageInputLabeled = ({
  className = '',
  label,
  labelClassName,
  id,
  ...rest
}: SmsMessageInputPropsLabeledProps) => {
  return (
    <div className={className}>
      <InputLabel labelFor={id} label={label} labelClassName={labelClassName} />
      <SmsMessageInput {...rest} />
    </div>
  );
};

export default SmsMessageInputLabeled;
