export const IMAGE_SIZES = {
  xs: 128,
  sm: 256,
  md: 480,
  lg: 720,
  xl: 1080,
  '2xl': 1920,
};

export function convertDownloadedImageToSize(
  imageUrl: string,
  size: keyof typeof IMAGE_SIZES,
) {
  return `${imageUrl}?w=${IMAGE_SIZES[size]}`;
}

export function convertDownloadedImagesToSize(
  imageUrl: string[],
  size: keyof typeof IMAGE_SIZES,
) {
  return imageUrl.map((imageUrl) =>
    convertDownloadedImageToSize(imageUrl, size),
  );
}

export const isDownloadedImage = (imageUrl: string) => {
  return imageUrl.startsWith('https://cdn.propertly.io/');
};

export function convertIfDownloadedImagesToSize(
  imageUrl: string[],
  size: keyof typeof IMAGE_SIZES,
) {
  return imageUrl.map((imageUrl) =>
    isDownloadedImage(imageUrl)
      ? convertDownloadedImageToSize(imageUrl, size)
      : imageUrl,
  );
}
