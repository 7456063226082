import {
  GetOfferCollectionsMode,
  GetOfferCollectionsSort,
  OfferCollectionIcon,
  OfferCollectionIconColor,
  OfferCollectionVisibility,
} from '../api/offer-collections/offer-collections';
import { Icon, Nullable } from '../utils/types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SelectOption } from '../components/inputs/select-input/select-input';
import React from 'react';
import {
  offerCollectionIconColorToTextClassName,
  offerCollectionIconToComponent,
  offerCollectionVisibilityToComponent,
} from '../models/offer-collections';
import TextInputLabeledController from '../components/inputs/text-input/text-input-labeled-controller';
import TextAreaInputLabeledController from '../components/inputs/text-area-input/text-area-input-labeled-controller';
import SelectInputLabeledArrowController from '../components/inputs/select-input/select-input-arrow-controlled';
import OfferCollectionVisibilityExplanationIcon from '../components/explanaion-icons/offer-collection-visibility-explanation-icon';
import { IconPickerController } from '../components/offer-collections/icon-components/icon-picker';
import { ColorPickerController } from '../components/offer-collections/icon-components/color-picker';
import { Control } from 'react-hook-form/dist/types/form';

export enum OfferCollectionFieldKeys {
  NAME = 'name',
  DESCRIPTION = 'description',
  VISIBILITY = 'visibility',
  ICON = 'icon',
  ICON_COLOR = 'icon_color',
}

export interface OfferCollectionFieldTypesValidated {
  [OfferCollectionFieldKeys.NAME]: string;
  [OfferCollectionFieldKeys.DESCRIPTION]: string | null;
  [OfferCollectionFieldKeys.VISIBILITY]: OfferCollectionVisibility;
  [OfferCollectionFieldKeys.ICON]: OfferCollectionIcon;
  [OfferCollectionFieldKeys.ICON_COLOR]: OfferCollectionIconColor;
}

type OfferCollectionFieldTypes = Nullable<OfferCollectionFieldTypesValidated>;

export const OfferCollectionDefaultValues = {
  [OfferCollectionFieldKeys.NAME]: '',
  [OfferCollectionFieldKeys.DESCRIPTION]: '',
  [OfferCollectionFieldKeys.VISIBILITY]: OfferCollectionVisibility.PRIVATE,
  [OfferCollectionFieldKeys.ICON]: OfferCollectionIcon.HOME,
  [OfferCollectionFieldKeys.ICON_COLOR]: OfferCollectionIconColor.PURPLE,
} as OfferCollectionFieldTypes;

export const useGetOfferCollectionFields = () => {
  const { t } = useTranslation(['validation']);

  return ({
    exclude,
    mappings,
  }: {
    exclude?: OfferCollectionFieldKeys[];
    mappings?: Partial<
      Record<OfferCollectionFieldKeys, (schema: Yup.AnySchema) => Yup.AnySchema>
    >;
  }) =>
    Object.entries({
      [OfferCollectionFieldKeys.NAME]: Yup.string()
        .min(1, t('validation:required'))
        .typeError(t('validation:required')),
      [OfferCollectionFieldKeys.DESCRIPTION]: Yup.string().nullable(),
      [OfferCollectionFieldKeys.VISIBILITY]:
        Yup.mixed<OfferCollectionVisibility>().oneOf(
          Object.values(OfferCollectionVisibility),
          t('validation:required'),
        ),
      [OfferCollectionFieldKeys.ICON]: Yup.mixed<OfferCollectionIcon>().oneOf(
        Object.values(OfferCollectionIcon),
        t('validation:required'),
      ),
      [OfferCollectionFieldKeys.ICON_COLOR]:
        Yup.mixed<OfferCollectionIconColor>().oneOf(
          Object.values(OfferCollectionIconColor),
          t('validation:required'),
        ),
    }).reduce((acc, [key, value]) => {
      const typedKey = key as OfferCollectionFieldKeys;

      if (!(exclude ?? []).includes(typedKey)) {
        acc[typedKey] =
          typedKey in (mappings ?? {}) ? mappings?.[typedKey]?.(value) : value;
      }
      return acc;
    }, {} as Record<OfferCollectionFieldKeys, any>);
};

export const useCreateOfferCollectionFields = () => {
  const getSchema = useGetOfferCollectionFields();
  return useForm<OfferCollectionFieldTypes>({
    resolver: yupResolver(Yup.object(getSchema({}))),
    defaultValues: OfferCollectionDefaultValues,
  });
};

export interface UseEditOfferCollectionFieldValues {
  [OfferCollectionFieldKeys.NAME]: string;
  [OfferCollectionFieldKeys.DESCRIPTION]?: string | null;
  [OfferCollectionFieldKeys.VISIBILITY]: OfferCollectionVisibility;
  [OfferCollectionFieldKeys.ICON]: OfferCollectionIcon;
  [OfferCollectionFieldKeys.ICON_COLOR]: OfferCollectionIconColor;
}

export const useEditOfferCollectionFields = (
  defaultValues: UseEditOfferCollectionFieldValues,
) => {
  const getSchema = useGetOfferCollectionFields();
  return useForm<OfferCollectionFieldTypes>({
    resolver: yupResolver(Yup.object(getSchema({}))),
    defaultValues: {
      [OfferCollectionFieldKeys.NAME]:
        defaultValues[OfferCollectionFieldKeys.NAME],
      [OfferCollectionFieldKeys.DESCRIPTION]:
        defaultValues[OfferCollectionFieldKeys.DESCRIPTION],
      [OfferCollectionFieldKeys.VISIBILITY]:
        defaultValues[OfferCollectionFieldKeys.VISIBILITY],
      [OfferCollectionFieldKeys.ICON]:
        defaultValues[OfferCollectionFieldKeys.ICON],
      [OfferCollectionFieldKeys.ICON_COLOR]:
        defaultValues[OfferCollectionFieldKeys.ICON_COLOR],
    },
  });
};

export const useOfferCollectionVisibilityOptions = (): SelectOption[] => {
  const { t } = useTranslation(['offer-collections']);

  const PrivateIcon =
    offerCollectionVisibilityToComponent[OfferCollectionVisibility.PRIVATE];
  const PublicIcon =
    offerCollectionVisibilityToComponent[OfferCollectionVisibility.PUBLIC];
  const PublicReadOnlyIcon =
    offerCollectionVisibilityToComponent[
      OfferCollectionVisibility.PUBLIC_READ_ONLY
    ];

  return [
    {
      label: t('offer-collections:model.visibility.PRIVATE'),
      value: OfferCollectionVisibility.PRIVATE,
      icon: <PrivateIcon className="mr-2 h-5 w-5 text-gray-500" />,
    },
    {
      label: t('offer-collections:model.visibility.PUBLIC'),
      value: OfferCollectionVisibility.PUBLIC,
      icon: <PublicIcon className="mr-2 h-5 w-5 text-gray-500" />,
    },
    {
      label: t('offer-collections:model.visibility.PUBLIC_READ_ONLY'),
      value: OfferCollectionVisibility.PUBLIC_READ_ONLY,
      icon: <PublicReadOnlyIcon className="mr-2 h-5 w-5 text-gray-500" />,
    },
  ];
};

export const useOfferCollectionIconOptions = (): {
  name: string;
  IconComponent: Icon;
  value: OfferCollectionIcon;
}[] => {
  const { t } = useTranslation(['offer-collections']);

  return [
    {
      name: t('offer-collections:model.icons.HOME'),
      IconComponent: offerCollectionIconToComponent[OfferCollectionIcon.HOME],
      value: OfferCollectionIcon.HOME,
    },
    {
      name: t('offer-collections:model.icons.BUILDING_OFFICE'),
      IconComponent:
        offerCollectionIconToComponent[OfferCollectionIcon.BUILDING_OFFICE],
      value: OfferCollectionIcon.BUILDING_OFFICE,
    },
    {
      name: t('offer-collections:model.icons.BUILDING_STOREFRONT'),
      IconComponent:
        offerCollectionIconToComponent[OfferCollectionIcon.BUILDING_STOREFRONT],
      value: OfferCollectionIcon.BUILDING_STOREFRONT,
    },
    {
      name: t('offer-collections:model.icons.USER'),
      IconComponent: offerCollectionIconToComponent[OfferCollectionIcon.USER],
      value: OfferCollectionIcon.USER,
    },
    {
      name: t('offer-collections:model.icons.USERS'),
      IconComponent: offerCollectionIconToComponent[OfferCollectionIcon.USERS],
      value: OfferCollectionIcon.USERS,
    },
    {
      name: t('offer-collections:model.icons.BRIEFCASE'),
      IconComponent:
        offerCollectionIconToComponent[OfferCollectionIcon.BRIEFCASE],
      value: OfferCollectionIcon.BRIEFCASE,
    },
    {
      name: t('offer-collections:model.icons.HAND_THUMB_UP'),
      IconComponent:
        offerCollectionIconToComponent[OfferCollectionIcon.HAND_THUMB_UP],
      value: OfferCollectionIcon.HAND_THUMB_UP,
    },
    {
      name: t('offer-collections:model.icons.HAND_THUMB_DOWN'),
      IconComponent:
        offerCollectionIconToComponent[OfferCollectionIcon.HAND_THUMB_DOWN],
      value: OfferCollectionIcon.HAND_THUMB_DOWN,
    },
    {
      name: t('offer-collections:model.icons.KEY'),
      IconComponent: offerCollectionIconToComponent[OfferCollectionIcon.KEY],
      value: OfferCollectionIcon.KEY,
    },
    {
      name: t('offer-collections:model.icons.BANKNOTES'),
      IconComponent:
        offerCollectionIconToComponent[OfferCollectionIcon.BANKNOTES],
      value: OfferCollectionIcon.BANKNOTES,
    },
    {
      name: t('offer-collections:model.icons.BELL'),
      IconComponent: offerCollectionIconToComponent[OfferCollectionIcon.BELL],
      value: OfferCollectionIcon.BELL,
    },
    {
      name: t('offer-collections:model.icons.HEARTH'),
      IconComponent: offerCollectionIconToComponent[OfferCollectionIcon.HEARTH],
      value: OfferCollectionIcon.HEARTH,
    },
  ];
};

export const useOfferCollectionIconColorOptions = (): {
  name: string;
  colorClassNames: string;
  value: OfferCollectionIconColor;
}[] => {
  const { t } = useTranslation(['offer-collections']);

  return [
    {
      name: t('offer-collections:model.iconColors.PURPLE'),
      colorClassNames:
        offerCollectionIconColorToTextClassName[
          OfferCollectionIconColor.PURPLE
        ],
      value: OfferCollectionIconColor.PURPLE,
    },
    {
      name: t('offer-collections:model.iconColors.BLUE'),
      colorClassNames:
        offerCollectionIconColorToTextClassName[OfferCollectionIconColor.BLUE],
      value: OfferCollectionIconColor.BLUE,
    },
    {
      name: t('offer-collections:model.iconColors.GREEN'),
      colorClassNames:
        offerCollectionIconColorToTextClassName[OfferCollectionIconColor.GREEN],
      value: OfferCollectionIconColor.GREEN,
    },
    {
      name: t('offer-collections:model.iconColors.YELLOW'),
      colorClassNames:
        offerCollectionIconColorToTextClassName[
          OfferCollectionIconColor.YELLOW
        ],
      value: OfferCollectionIconColor.YELLOW,
    },
    {
      name: t('offer-collections:model.iconColors.ORANGE'),
      colorClassNames:
        offerCollectionIconColorToTextClassName[
          OfferCollectionIconColor.ORANGE
        ],
      value: OfferCollectionIconColor.ORANGE,
    },
    {
      name: t('offer-collections:model.iconColors.RED'),
      colorClassNames:
        offerCollectionIconColorToTextClassName[OfferCollectionIconColor.RED],
      value: OfferCollectionIconColor.RED,
    },
  ];
};

export const useOfferCollectionSortOptions = (): {
  label: string;
  value: GetOfferCollectionsSort;
}[] => {
  const { t } = useTranslation(['offer-collections']);

  return [
    {
      label: t('offer-collections:model.sortOptions.UPDATED_AT_ASC'),
      value: GetOfferCollectionsSort.UPDATED_AT_ASC,
    },
    {
      label: t('offer-collections:model.sortOptions.UPDATED_AT_DESC'),
      value: GetOfferCollectionsSort.UPDATED_AT_DESC,
    },
    {
      label: t('offer-collections:model.sortOptions.CREATED_AT_ASC'),
      value: GetOfferCollectionsSort.CREATED_AT_ASC,
    },
    {
      label: t('offer-collections:model.sortOptions.CREATED_AT_DESC'),
      value: GetOfferCollectionsSort.CREATED_AT_DESC,
    },
    {
      label: t('offer-collections:model.sortOptions.NAME_ASC'),
      value: GetOfferCollectionsSort.NAME_ASC,
    },
    {
      label: t('offer-collections:model.sortOptions.NAME_DESC'),
      value: GetOfferCollectionsSort.NAME_DESC,
    },
  ];
};

export const useOfferCollectionModeOptions = (): {
  label: string;
  value: GetOfferCollectionsMode;
}[] => {
  const { t } = useTranslation(['offer-collections']);

  return [
    {
      label: t('offer-collections:model.modeOptions.CREATED_BY_CURRENT_USER'),
      value: GetOfferCollectionsMode.CREATED_BY_CURRENT_USER,
    },
    // skipped due to UI limitation – additional user select required
    // {
    //   label: t('offer-collections:model.modeOptions.CREATED_BY_USER'),
    //   value: GetOfferCollectionsMode.CREATED_BY_USER,
    // },
    {
      label: t(
        'offer-collections:model.modeOptions.CREATED_NOT_BY_CURRENT_USER',
      ),
      value: GetOfferCollectionsMode.CREATED_NOT_BY_CURRENT_USER,
    },
    {
      label: t('offer-collections:model.modeOptions.VIEWABLE_BY_CURRENT_USER'),
      value: GetOfferCollectionsMode.VIEWABLE_BY_CURRENT_USER,
    },
    {
      label: t(
        'offer-collections:model.modeOptions.MANAGEABLE_CONTENT_BY_CURRENT_USER',
      ),
      value: GetOfferCollectionsMode.MANAGEABLE_CONTENT_BY_CURRENT_USER,
    },
    {
      label: t('offer-collections:model.modeOptions.EDITABLE_BY_CURRENT_USER'),
      value: GetOfferCollectionsMode.EDITABLE_BY_CURRENT_USER,
    },
  ];
};

interface OfferCollectionFormInputsProps {
  control: Control<OfferCollectionFieldTypes>;
  canChangeToPrivate?: boolean;
}

export const OfferCollectionFormInputs = ({
  control,
  canChangeToPrivate = true,
}: OfferCollectionFormInputsProps) => {
  const { t } = useTranslation(['offer-collections']);
  const visibilityOptions = useOfferCollectionVisibilityOptions();
  const iconOptions = useOfferCollectionIconOptions();
  const iconColorOptions = useOfferCollectionIconColorOptions();

  return (
    <>
      <TextInputLabeledController
        name={OfferCollectionFieldKeys.NAME}
        id={OfferCollectionFieldKeys.NAME}
        control={control}
        label={t('offer-collections:create.form.name')}
        labelClassName="mb-1"
        isRequired={true}
        saveErrorSpace={true}
      />

      <TextAreaInputLabeledController
        name={OfferCollectionFieldKeys.DESCRIPTION}
        id={OfferCollectionFieldKeys.DESCRIPTION}
        control={control}
        label={t('offer-collections:create.form.description')}
        labelClassName="mb-1"
        saveErrorSpace={true}
        rows={3}
      />

      <div className="relative">
        <SelectInputLabeledArrowController
          name={OfferCollectionFieldKeys.VISIBILITY}
          id={OfferCollectionFieldKeys.VISIBILITY}
          label={t('offer-collections:create.form.visibility')}
          labelClassName="mb-1"
          options={
            canChangeToPrivate
              ? visibilityOptions
              : visibilityOptions.filter(
                  (option) =>
                    option.value !== OfferCollectionVisibility.PRIVATE,
                )
          }
          control={control}
          isRequired={true}
          saveErrorSpace={true}
          showLabel={true}
        />
        <OfferCollectionVisibilityExplanationIcon classNameWrapper="absolute right-0.5 top-0.5" />
      </div>
      <IconPickerController
        name={OfferCollectionFieldKeys.ICON}
        label={t('offer-collections:create.form.icon')}
        options={iconOptions}
        control={control}
        isRequired={true}
        saveErrorSpace={true}
      />
      <ColorPickerController
        name={OfferCollectionFieldKeys.ICON_COLOR}
        control={control}
        options={iconColorOptions}
        label={t('offer-collections:create.form.iconColour')}
        isRequired={true}
        saveErrorSpace={true}
      />
    </>
  );
};
