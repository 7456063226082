import React from 'react';
import TextInputLabeledController from '../../inputs/text-input/text-input-labeled-controller';
import { useTranslation } from 'react-i18next';
import WysiwigTextInputLabeledController from '../../inputs/wysiwig-text-input/wysiwig-text-input-labeled-controller';
import { useMutation } from '@tanstack/react-query';
import { postGenerateOfferDescription } from '../../../api/generation/generation';
import { formToOfferSchema } from '../form-mappings';
import { OfferTypeEnum } from '../../../api/api.types';
import { useFormContext } from 'react-hook-form';
import STButton from '../../button/st-button';
import { SubscriptionTierType } from '../../../models/user';

interface Props {
  control: any;
  offerType: OfferTypeEnum;
}

const OfferVariantDescription = ({ control, offerType }: Props) => {
  const { t } = useTranslation(['data-model']);
  const { setValue, getValues } = useFormContext();

  const { mutate, isLoading } = useMutation(postGenerateOfferDescription, {
    onSuccess: (data) => {
      setValue('description', data.description);
    },
  });

  const generateDescription = () => {
    mutate({
      body: {
        offer_data: formToOfferSchema(getValues(), offerType),
        offer_type: offerType,
      },
    });
  };

  return (
    <div>
      <div>
        <TextInputLabeledController
          name="title"
          labelClassName="mb-2"
          control={control}
          id="title"
          label={t('data-model:fieldLabels.title')}
          saveErrorSpace={true}
        />
      </div>
      <div>
        <WysiwigTextInputLabeledController
          control={control}
          name="description"
          id="description"
          labelClassName="mb-2"
          className="h-[550px]"
          label={t('data-model:fieldLabels.description')}
        />
      </div>
      <STButton
        isLoading={isLoading}
        className="ml-auto mt-3 px-3 py-2"
        type="button"
        variant="secondaryAccent"
        onClick={generateDescription}
        requiredSubscriptionTier={SubscriptionTierType.PREMIUM}
      >
        {t('offers:offerForm.actions.generateDescription')}
      </STButton>
    </div>
  );
};

export default OfferVariantDescription;
