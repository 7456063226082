import {
  CommentResponse,
  DetailedPhoneCallSummaryResponse,
  OfferStatus,
  OfferStatusDetailsResponse,
  OfferTypeEnum,
  PhoneCallResultEnum,
  SmsLogResponse,
} from '../../api/api.types';
import {
  OfferActivity,
  OfferActivityEventType,
} from '../../models/offer-event';
import { useQuery } from '@tanstack/react-query';
import { getCommentsForOffer } from '../../api/comments/comments';
import {
  getCallSummariesForNumber,
  getCallSummariesForOffer,
} from '../../api/calls/calls';
import { getStatusesForOffer } from '../../api/offers/offers';
import { differenceInMilliseconds } from 'date-fns';
import { getSms } from '../../api/sms/sms';
import {
  getAssignmentsForOffer,
  OfferAssignmentResponse,
} from '../../api/offer-assignments/offer-assignments';
import { getMeetingsForActivity } from '../../api/meetings/meetings-endpoints';
import {
  MeetingActivityCreateLog,
  MeetingActivityReassignLog,
  MeetingActivityStatusChangeLog,
} from '../../api/meetings/meetings-dto';

const MERGE_EVENTS_INTERVAL_MS = 5000;

interface Props {
  offerId: string;
  offerType: OfferTypeEnum;
  enabled?: boolean;
}

const usePhoneNumberEvents = ({ phoneNumber }: { phoneNumber: string }) => {
  const { data: callSummaries = [], isLoading: isLoadingCallSummaries } =
    useQuery(['callSummariesByPhoneNumber', phoneNumber], () =>
      getCallSummariesForNumber({ phoneNumber }),
    );

  const { data: sms = [], isLoading: isLoadingSms } = useQuery(
    ['sms', phoneNumber],
    () => getSms({ receiverPhoneNumber: phoneNumber }),
  );

  return {
    events: buildEvents([], [], callSummaries, sms, [], [], [], []),
    isLoading: isLoadingCallSummaries || isLoadingSms,
  };
};

const useOfferEvents = ({ offerId, offerType, enabled }: Props) => {
  const { data: comments = [], isLoading: isLoadingComments } = useQuery(
    ['offerComments', offerType, offerId],
    () => getCommentsForOffer(offerType, offerId),
    { enabled },
  );

  const { data: callSummaries = [], isLoading: isLoadingCallSummaries } =
    useQuery(
      ['callSummaries', offerType, offerId],
      () => getCallSummariesForOffer({ offerId }),
      { enabled },
    );

  const { data: statuses = [], isLoading: isLoadingStatuses } = useQuery(
    ['offerStatuses', offerType, offerId],
    () => getStatusesForOffer(offerId, offerType),
    { enabled },
  );
  const { data: sms = [], isLoading: isLoadingSms } = useQuery(
    ['sms', offerId],
    () => getSms({ offerId }),
    { enabled },
  );

  const { data: assignments = [], isLoading: isLoadingAssignments } = useQuery(
    ['offerAssignments', offerId],
    () => getAssignmentsForOffer(offerId),
    { enabled },
  );

  const { data: meetings, isLoading: isMeetingsLogLoading } = useQuery(
    ['offer', 'meetings', offerId],
    () => getMeetingsForActivity(offerId),
    { enabled },
  );

  return {
    events: buildEvents(
      statuses,
      comments,
      callSummaries,
      sms,
      assignments,
      meetings?.create_events ?? [],
      meetings?.reassign_events ?? [],
      meetings?.status_change_events ?? [],
    ),
    isLoading:
      isLoadingComments ||
      isLoadingCallSummaries ||
      isLoadingStatuses ||
      isLoadingSms ||
      isLoadingAssignments ||
      isMeetingsLogLoading,
  };
};

function buildEvents(
  statuses: OfferStatusDetailsResponse[],
  comments: CommentResponse[],
  phoneCallSummaries: DetailedPhoneCallSummaryResponse[],
  sms: SmsLogResponse[],
  assignments: OfferAssignmentResponse[],
  meetingCreations: MeetingActivityCreateLog[],
  meetingAssignmentChanges: MeetingActivityReassignLog[],
  meetingStatusChanges: MeetingActivityStatusChangeLog[],
): OfferActivity[] {
  const statusesMap = statuses.reduce((acc, status) => {
    acc[status.offer_status_id] = status;
    return acc;
  }, {} as Record<string, OfferStatusDetailsResponse>);

  const statusChanges = getStatuses(statuses, statusesMap);

  const commentsEvents = comments.map((comment) => {
    return {
      type: OfferActivityEventType.COMMENT_ADDED,
      comment,
      time: new Date(comment.created_at),
    } as const;
  });

  const callEvents = phoneCallSummaries.map((phoneCallSummary) => {
    return {
      type: OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED,
      phoneCallSummary,
      time: new Date(phoneCallSummary.created_at),
    } as const;
  });

  const smsEvents = sms.map((sms) => {
    return {
      type: OfferActivityEventType.SMS_SENT,
      sms,
      time: new Date(sms.created_at),
    } as const;
  });

  const assignmentEvents = assignments.map((assignment) => {
    return {
      type: OfferActivityEventType.OFFER_ASSIGNED,
      assignment,
      time: new Date(assignment.created_at),
    } as const;
  });

  const meetingCreationEvents = meetingCreations.map(
    (createEvent) =>
      ({
        type: OfferActivityEventType.MEETING_ADDED,
        meeting: createEvent,
        time: new Date(createEvent.time),
      } as const),
  );

  const meetingAssignmentEvents = meetingAssignmentChanges.map(
    (createEvent) =>
      ({
        type: OfferActivityEventType.MEETING_ASSIGNMENT_CHANGE,
        meeting: createEvent,
        time: new Date(createEvent.time),
      } as const),
  );

  const meetingStatusEvents = meetingStatusChanges.map(
    (createEvent) =>
      ({
        type: OfferActivityEventType.MEETING_STATUS_CHANGE,
        meeting: createEvent,
        time: new Date(createEvent.time),
      } as const),
  );

  const result = [
    ...statusChanges,
    ...commentsEvents,
    ...callEvents,
    ...smsEvents,
    ...assignmentEvents,
    ...meetingCreationEvents,
    ...meetingAssignmentEvents,
    ...meetingStatusEvents,
  ];
  result.sort((a, b) => b.time.getTime() - a.time.getTime());

  return mergePhoneCallAndStatusUpdate(result);
}

function getStatuses(
  statuses: OfferStatusDetailsResponse[],
  statusesMap: Record<string, OfferStatusDetailsResponse>,
): OfferActivity[] {
  const changedToStatusesMap = new Set(
    statuses
      .filter((status) => status.changed_to_id)
      .map((status) => status.changed_to_id),
  );
  const result: OfferActivity[] = [];
  statuses.map((status) => {
    if (status.removed_at && status.removed_by) {
      if (status.changed_to_id) {
        if (
          status.offer_status === statusesMap[status.changed_to_id].offer_status
        ) {
          result.push({
            type: OfferActivityEventType.STATUS_ACTIVATED,
            user: statusesMap[status.changed_to_id].added_by,
            status: status.offer_status,
            time: new Date(statusesMap[status.changed_to_id].added_at),
          } as const);
        } else {
          result.push({
            type: OfferActivityEventType.STATUS_CHANGED,
            user: statusesMap[status.changed_to_id].added_by,
            fromStatus: status.offer_status,
            toStatus: statusesMap[status.changed_to_id].offer_status,
            time: new Date(statusesMap[status.changed_to_id].added_at),
          } as const);
        }
      } else {
        result.push({
          type: OfferActivityEventType.STATUS_REMOVED,
          user: status.removed_by,
          status: status.offer_status,
          time: new Date(status.removed_at),
        } as const);
      }
    }
    if (status.archived_at && status.archived_by) {
      result.push({
        type: OfferActivityEventType.STATUS_ARCHIVED,
        user: status.archived_by,
        status: status.offer_status,
        time: new Date(status.archived_at),
      } as const);
    }
    if (!changedToStatusesMap.has(status.offer_status_id)) {
      result.push({
        type: OfferActivityEventType.STATUS_ADDED,
        user: status.added_by,
        status: status.offer_status,
        time: new Date(status.added_at),
      });
    }
  });
  return result;
}

function mergePhoneCallAndStatusUpdate(activities: OfferActivity[]) {
  let skipNext = false;
  const result: OfferActivity[] = [];
  iterateOverTwoItemWindow({
    items: activities,
    forWindow: (firstItem, secondItem) => {
      if (skipNext) {
        skipNext = false;
        return;
      }
      if (
        Math.abs(differenceInMilliseconds(firstItem.time, secondItem.time)) >
        MERGE_EVENTS_INTERVAL_MS
      ) {
        result.push(firstItem);
        return;
      }
      let callResult: OfferActivity | undefined = undefined;
      if (
        firstItem.type === OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED &&
        firstItem.phoneCallSummary.result ===
          PhoneCallResultEnum.CLIENT_CONVERTED
      ) {
        callResult = firstItem;
      }
      if (
        secondItem.type === OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED &&
        secondItem.phoneCallSummary.result ===
          PhoneCallResultEnum.CLIENT_CONVERTED
      ) {
        callResult = secondItem;
      }
      if (callResult === undefined) {
        result.push(firstItem);
        return;
      }
      const maybeStatusChange =
        callResult === firstItem ? secondItem : firstItem;
      if (
        maybeStatusChange.type === OfferActivityEventType.STATUS_CHANGED &&
        maybeStatusChange.toStatus === OfferStatus.CLIENT_ACQUIRED
      ) {
        result.push({
          type: OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED,
          phoneCallSummary: callResult.phoneCallSummary,
          time: callResult.time,
          toStatus: OfferStatus.CLIENT_ACQUIRED,
          fromStatus: maybeStatusChange.fromStatus,
        });
        skipNext = true;
        return;
      }
      if (
        maybeStatusChange.type === OfferActivityEventType.STATUS_ADDED &&
        maybeStatusChange.status === OfferStatus.CLIENT_ACQUIRED
      ) {
        result.push({
          type: OfferActivityEventType.PHONE_CALL_SUMMARY_ADDED,
          phoneCallSummary: callResult.phoneCallSummary,
          time: callResult.time,
          toStatus: OfferStatus.CLIENT_ACQUIRED,
        });
        skipNext = true;
        return;
      }
      result.push(firstItem);
    },
    forLastItem: (item) => {
      if (!skipNext) {
        result.push(item);
      }
    },
  });
  return result;
}

function iterateOverTwoItemWindow<T>({
  items,
  forWindow,
  forLastItem,
}: {
  items: T[];
  forWindow: (item1: T, item2: T) => void;
  forLastItem: (item: T) => void;
}) {
  for (let i = 0; i < items.length; i++) {
    if (i + 1 == items.length) {
      forLastItem(items[i]);
    } else {
      forWindow(items[i], items[i + 1]);
    }
  }
}

export { useOfferEvents, usePhoneNumberEvents };
