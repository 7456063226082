import React from 'react';
import { classNames } from '../../../../style/class-names';
import { useTranslation } from 'react-i18next';
import { PhoneArrowUpRightIcon } from '@heroicons/react/20/solid';
import { Menu } from '@headlessui/react';

interface Props {
  contactNumber: string;
}

const CallAction = ({ contactNumber }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Menu.Item>
      {({ active }) => (
        <a
          href={`tel:${contactNumber}`}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'flex w-full px-4 py-2 text-sm',
          )}
        >
          <PhoneArrowUpRightIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            )}
          >
            {t('common:offerMenu.call')}
          </span>
        </a>
      )}
    </Menu.Item>
  );
};

export default CallAction;
