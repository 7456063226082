import React from 'react';
import { Controller } from 'react-hook-form';
import { InputLabelProps } from '../shared/input-label';
import ToggleInputLabeled from './toggle-input-labeled';

interface Props<T> extends Omit<InputLabelProps, 'labelFor'> {
  name: string;
  className?: string;
  control: any;
  id: string;
  valueMapper?: (value: T) => boolean;
  onChangeMapper?: (value: boolean) => T;
}

const ToggleInputLabeledController = <T,>({
  name,
  control,
  id,
  valueMapper,
  onChangeMapper,
  ...rest
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ToggleInputLabeled
          id={id}
          onChange={
            onChangeMapper ? (v) => onChange(onChangeMapper(v)) : onChange
          }
          isError={!!error}
          errorMessage={error?.message}
          value={valueMapper ? valueMapper(value) : value}
          {...rest}
        />
      )}
    />
  );
};

export default ToggleInputLabeledController;
