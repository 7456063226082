import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import bg from '../../assets/logout-bg.jpg';
import PageHeader from '../../components/header/page-header';

const Logout = () => {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-full flex-row bg-white lg:relative">
      <PageHeader title={t('common:pageTitle.logout')} />
      <div className="flex flex-grow flex-col">
        <main className="flex flex-grow flex-col bg-white">
          <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-6 sm:px-8 lg:px-10">
            <div className="my-auto flex-shrink-0 py-16 sm:py-32">
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                {t('common:logout.header')}
              </h1>
              <p className="mt-2 text-base text-gray-500">
                {t('common:logout.description')}
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {t('common:logout.login')}
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="relative hidden w-1/2 md:block md:block lg:w-2/3">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default Logout;
