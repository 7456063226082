import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { isPhoneSpaceAllowed } from '../../constants/validation';
import { useForm } from 'react-hook-form';
import { ClientFormData } from '../../pages/clients/client-drawer/client-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import debounce from 'lodash/debounce';
import { getClients } from '../../api/clients/clients';

export enum ClientSelection {
  NEW = 'new',
  EXISTING = 'existing',
}

export const useAddToClientSearchClient = () => {
  const [clientSelection, setClientSelection] = React.useState<ClientSelection>(
    ClientSelection.EXISTING,
  );
  const {
    control,
    validationState,
    getData,
    submit,
    clear: resetForm,
  } = useAddClientForm();
  const [clientId, setClientId] = React.useState<string | null>(null);
  const [count, setCount] = React.useState<number>();

  const init = () => {
    getClients()
      .then((list) => {
        setCount(list.length);
        setClientSelection(
          list.length === 0 ? ClientSelection.NEW : ClientSelection.EXISTING,
        );
      })
      .catch(() => {
        setCount(0);
      });
  };

  const clear = () => {
    setClientId(null);
    setCount(undefined);
    resetForm();
  };

  return {
    selection: {
      value: clientSelection,
      set: setClientSelection,
    },
    new: {
      control,
      validationState,
      data: getData(),
    },
    existing: {
      value: clientId,
      set: setClientId,
    },
    isValid: isValidClient({ clientId, validationState, clientSelection }),
    count,
    init,
    clear,
    submit,
    clientId: clientSelection === ClientSelection.NEW ? null : clientId,
  };
};

export type UseAddToClientSearchClientData = ReturnType<
  typeof useAddToClientSearchClient
>;

const isValidClient = ({
  clientSelection,
  validationState,
  clientId,
}: {
  clientSelection: ClientSelection;
  validationState: AddClientValidationState;
  clientId?: string | null;
}) => {
  if (clientSelection === ClientSelection.NEW) {
    return validationState === AddClientValidationState.VALID;
  } else if (clientSelection === ClientSelection.EXISTING) {
    return !!clientId;
  }
  return false;
};

export enum AddClientFormKeys {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  PHONE_NUMBER = 'phone_number',
}

export enum AddClientValidationState {
  UNKNOWN = 'unknown',
  VALID = 'valid',
  INVALID = 'invalid',
}

const useAddClientForm = () => {
  const { t } = useTranslation(['clients', 'common']);

  /* form */
  const schema = React.useMemo(() => {
    const result = {
      [AddClientFormKeys.FIRST_NAME]: yup
        .string()
        .required(t('common:validation.string.empty')),
      [AddClientFormKeys.LAST_NAME]: yup
        .string()
        .required(t('common:validation.string.empty')),
      [AddClientFormKeys.PHONE_NUMBER]: isPhoneSpaceAllowed(t, {
        required: true,
      }),
    } as any;
    return yup.object(result);
  }, [t]);
  const { trigger, watch, control, reset, clearErrors } =
    useForm<ClientFormData>({
      defaultValues: {
        first_name: '',
        last_name: '',
        phone_number: '',
      },
      resolver: yupResolver(schema),
    });

  const values = watch();

  /* Validation */
  const [validationState, setValidationState] =
    React.useState<AddClientValidationState>(AddClientValidationState.UNKNOWN);
  const [canValidate, setCanValidate] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const validate = debounce(async () => {
    const isValid = await trigger();
    setValidationState(
      isValid
        ? AddClientValidationState.VALID
        : AddClientValidationState.INVALID,
    );
  }, 100);
  React.useEffect(() => {
    if (
      !canValidate &&
      values.first_name &&
      values.last_name &&
      values.phone_number &&
      values.phone_number.replaceAll(/\D/g, '').match(/\d{9,11}/)
    ) {
      setCanValidate(true);
    }
    if (canValidate) {
      isSubmitted && validate();
      !isSubmitted && setValidationState(AddClientValidationState.VALID);
    }
  }, [values.first_name, values.last_name, values.phone_number, canValidate]);

  const submit = async () => {
    setIsSubmitted(true);
    const isValid = await trigger();
    setValidationState(
      isValid
        ? AddClientValidationState.VALID
        : AddClientValidationState.INVALID,
    );
    return isValid;
  };

  const clear = () => {
    reset();
    clearErrors();
    setValidationState(AddClientValidationState.UNKNOWN);
    setIsSubmitted(false);
    setCanValidate(false);
  };

  return {
    submit,
    clear,
    control,
    validationState,
    getData: () => {
      let data: ClientFormData = { ...values };
      if (data.phone_number) {
        data = {
          ...data,
          phone_number: `+48${data.phone_number.replaceAll(/\D/g, '')}`,
        };
      }
      return data;
    },
  };
};
