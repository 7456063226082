import React from 'react';
import useElementSize from '../../hooks/use-element-dimentions/use-element-size';

interface Props {
  className?: string;
  height?: number;
  children: React.ReactNode;
}

export const ScrollableContainer = ({
  children,
  className = '',
  height,
}: Props) => {
  const [insideElementRef, insideElementSize] = useElementSize({
    updateOnResize: true,
    updateOnContentChange: true,
    updateOnScroll: true,
  });

  const isScrolledToTop = insideElementSize.scrollTop === 0;
  const isScrolledToBottom =
    insideElementSize.scrollTop + insideElementSize.height >=
    insideElementSize.totalHeight;
  const isScrolledToLeft = insideElementSize.scrollLeft === 0;
  const isScrolledToRight =
    insideElementSize.scrollLeft + insideElementSize.width >=
    insideElementSize.totalWidth;

  return (
    <div
      style={{ height: height }}
      className={`${className} relative overflow-y-hidden`}
    >
      <div ref={insideElementRef} className="h-full overflow-y-scroll">
        {children}
      </div>
      <div
        style={{ boxShadow: '0px 10px 15px 15px rgba(229, 231, 235, 1)' }}
        className={`absolute bottom-0 left-0 right-0 h-0 border-0 transition-opacity duration-300 ease-in-out ${
          !isScrolledToBottom ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <div
        style={{ boxShadow: '0px -10px 15px 15px rgba(229, 231, 235, 1)' }}
        className={`absolute left-0 right-0 top-0 h-0 border-0 transition-opacity duration-300 ease-in-out ${
          !isScrolledToTop ? 'opacity-100' : 'opacity-0'
        }`}
      />
      {!isScrolledToLeft && (
        <div
          style={{ boxShadow: '10px 0px 15px 15px rgba(229, 231, 235, 1)' }}
          className={`absolute bottom-0 left-0 top-0 w-0 border-0 transition-opacity duration-300 ease-in-out ${
            !isScrolledToBottom ? 'opacity-100' : 'opacity-0'
          }`}
        />
      )}
      {!isScrolledToRight && (
        <div
          style={{ boxShadow: '-10px 0px 15px 15px rgba(229, 231, 235, 1)' }}
          className={`absolute bottom-0 right-0 top-0 w-0 border-0 transition-opacity duration-300 ease-in-out ${
            !isScrolledToBottom ? 'opacity-100' : 'opacity-0'
          }`}
        />
      )}
    </div>
  );
};
