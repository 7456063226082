import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bg from '../../assets/activate-your-account-bg.jpg';
import PageHeader from '../../components/header/page-header';

const AccountCreated = () => {
  const { t } = useTranslation(['auth', 'common']);

  return (
    <div className="flex h-full flex-row bg-white">
      <PageHeader title={t('common:pageTitle.accountCreated')} />
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mt-8">
            <div className="mt-6">
              <main>
                <div>
                  <div>
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                      {t('auth:activateAccount.header')}
                    </h2>
                    <p className="mt-2 text-sm text-gray-600">
                      {t('auth:activateAccount.description')}
                    </p>
                    <p className="ml-auto mt-4  text-sm text-gray-600">
                      {t('auth:activateAccount.alreadyActive')}{' '}
                      <Link
                        to="/login"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        {t('auth:activateAccount.login')}
                      </Link>
                    </p>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bg}
          alt="background"
        />
      </div>
    </div>
  );
};

export default AccountCreated;
