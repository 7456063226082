import React from 'react';
import { OfferAssignmentResponse } from '../../../api/offer-assignments/offer-assignments';
import UserAvatar from '../../avatar/user-avatar';
import PropertlyAvatar from '../../avatar/propertly-avatar';
import TeamAssignmentBadge from '../../teams/team-assignment-badge';
import {
  addMinutes,
  formatDistanceToNow,
  intervalToDuration,
  parseISO,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DATE_FNS_LOCALES, SupportedLanguages } from '../../../i18n/languages';
import ReactTooltip from 'react-tooltip';
import { RiTimerLine } from 'react-icons/all';

type Extensions = 'WITH_ASSIGNEE' | 'WITH_TIMER';

interface Props {
  assignment: OfferAssignmentResponse;
  extensions?: Extensions[];
  showBadgePrefix?: boolean;
  badgeTextClassName?: string;
  badgeContainerClassName?: string;
  variant?: 'condense' | 'descriptive';
}

const OfferAssignmentBadge = ({
  assignment,
  showBadgePrefix = true,
  badgeTextClassName = '',
  badgeContainerClassName = '',
  extensions = ['WITH_ASSIGNEE'],
  variant = 'descriptive',
}: Props) => {
  const id = React.useId();
  const { t, i18n } = useTranslation(['common']);
  const {
    formatted: timeLeft,
    colors: timerColors,
    hintText,
  } = useTimer(assignment.valid_until);

  const tooltip =
    (assignment.created_by
      ? `${t('common:offerAssignment.assignedByUser')} ${
          assignment.created_by.first_name
        } ${assignment.created_by.last_name}`
      : t('common:offerAssignment.assignedByAutomation')) +
    ' ' +
    formatDistanceToNow(new Date(assignment.created_at), {
      locale: DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
      addSuffix: true,
    }) +
    (extensions?.includes('WITH_TIMER') ? hintText : '');

  const timerStyles = {
    backgroundColor: timerColors['50'],
    color: timerColors['600'],
    '--tw-ring-color': timerColors['600'] + '30',
  };

  return (
    <>
      <div
        className={`flex flex-wrap items-center justify-center space-x-1 rounded p-0.5 text-xs font-medium`}
        data-tip={tooltip}
        data-for={id}
      >
        <TeamAssignmentBadge
          assignment={assignment}
          showPrefix={showBadgePrefix}
          textClassName={badgeTextClassName}
          className={badgeContainerClassName}
        />
        {extensions?.includes('WITH_ASSIGNEE') && (
          <div className="flex items-center gap-1 py-1">
            {variant === 'descriptive' && (
              <span>{t('common:offerAssignment.by')}</span>
            )}
            {assignment.created_by ? (
              <UserAvatar {...assignment.created_by} size="sm" />
            ) : (
              <PropertlyAvatar size="sm" />
            )}
          </div>
        )}
        {extensions?.includes('WITH_TIMER') && assignment.valid_until && (
          <div className="flex items-center gap-1">
            {variant === 'descriptive' && (
              <span>{t('common:offerAssignment.active')}</span>
            )}
            <span
              style={timerStyles}
              className="inline-flex items-center rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset"
            >
              <RiTimerLine className="mr-1 h-5 w-5" />
              <span>{timeLeft}</span>
            </span>
          </div>
        )}
      </div>
      <ReactTooltip id={id} multiline />
    </>
  );
};

const useTimer = (timestamp?: string) => {
  const { t, i18n } = useTranslation(['common']);
  const [formatted, setFormatted] = React.useState(formatTimeLeft(timestamp));
  const formatHintText = () => {
    if (!timestamp) {
      return '';
    }

    const endDate = parseISO(timestamp);
    const now = new Date();

    if (endDate <= now) {
      return t('common:offerAssignment.almostDone');
    } else {
      return (
        t('common:offerAssignment.activeFor') +
        ' ' +
        formatDistanceToNow(new Date(timestamp), {
          locale: DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
          addSuffix: false,
        })
      );
    }
  };
  const [hintText, setHintText] = React.useState(formatHintText);

  React.useEffect(() => {
    if (!timestamp) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setFormatted(formatTimeLeft(timestamp));
      setHintText(formatHintText());
    }, 60_000);
    return () => clearTimeout(timeoutId);
  }, [formatted]);

  return { formatted, colors: getColorByMinutesLeft(timestamp), hintText };
};

const formatTimeLeft = (timestamp?: string) => {
  if (!timestamp) {
    return undefined;
  }

  const endDate = parseISO(timestamp);
  const now = new Date();

  if (endDate <= now) {
    return NO_TIME_LEFT_LITERAL;
  }

  const initialDuration = intervalToDuration({ start: now, end: endDate });

  const duration =
    (initialDuration?.seconds ?? 0) > 30
      ? intervalToDuration({ start: now, end: addMinutes(endDate, 1) })
      : initialDuration;

  const days = ((duration.weeks ?? 0) * 7 + (duration.days ?? 0))
    .toString()
    .padStart(2, '0');
  const hours = (duration.hours ?? 0).toString().padStart(2, '0');
  const minutes = (duration.minutes ?? 0).toString().padStart(2, '0');

  return `${days}:${hours}:${minutes}`;
};

const getColorByMinutesLeft = (
  timestamp?: string,
): { 50: string; 600: string } => {
  if (!timestamp) {
    return { 50: '#fafafa', 600: '#525252' };
  }

  const endDate = parseISO(timestamp);
  const now = new Date();

  if (endDate <= now) {
    return { 50: '#fef2f2', 600: '#dc2626' };
  }

  const duration = intervalToDuration({ start: now, end: endDate });

  if (
    (duration.days ?? 0) +
      (duration.weeks ?? 0) +
      (duration.months ?? 0) +
      (duration.years ?? 0) >
    0
  ) {
    return { 50: '#ecfdf5', 600: '#059669' };
  }

  if ((duration.hours ?? 0) > 5) {
    return { 50: '#ecfdf5', 600: '#059669' };
  }

  if ((duration.hours ?? 0) > 4) {
    return { 50: '#f0fdf4', 600: '#16a34a' };
  }

  if ((duration.hours ?? 0) > 3) {
    return { 50: '#f7fee7', 600: '#65a30d' };
  }

  if ((duration.hours ?? 0) > 2) {
    return { 50: '#fefce8', 600: '#ca8a04' };
  }

  if ((duration.hours ?? 0) > 1) {
    return { 50: '#fffbeb', 600: '#d97706' };
  }

  if ((duration.minutes ?? 0) > 30) {
    return { 50: '#fff7ed', 600: '#ea580c' };
  }

  return { 50: '#fef2f2', 600: '#dc2626' };
};

const NO_TIME_LEFT_LITERAL = '00:00:00';

export default OfferAssignmentBadge;
