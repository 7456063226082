import React from 'react';
import logoSmall from '../../assets/logo-small-transparent.png';
import SpinnerIcon from './spinner-icon';

const Spinner = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center">
      <div className="relative">
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <img className="mx-auto h-16 w-auto" src={logoSmall} alt="Logo" />
        </div>
        <SpinnerIcon className="h-32 w-32 text-indigo-600" />
      </div>
    </div>
  );
};

export default Spinner;
