import React from 'react';
import ReactQuill from 'react-quill';
import './styles.css';
import 'react-quill/dist/quill.snow.css';

export interface WysiwigTextInputProps {
  type?: string;
  value: string;
  onChange: (newVal: string) => void;
  id?: string;
  isError?: boolean;
  errorMessage?: string;
  className?: string;
}

const WysiwigTextInput = ({
  value = '',
  onChange,
  id,
  isError = false,
  className = '',
  errorMessage = '',
}: WysiwigTextInputProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div className={className}>
        <ReactQuill
          theme="snow"
          formats={[
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'color',
            'background',
            'header',
            'blockquote',
            'indent',
            'list',
            'direction',
            'align',
          ]}
          value={value}
          id={id}
          onChange={(str) => onChange(str)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`custom-quill ${isFocused ? 'custom-quill-focus' : ''} ${
            isError ? 'custom-quill-error' : ''
          }`}
        />
      </div>
      {isError && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default WysiwigTextInput;
