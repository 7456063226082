import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useLoading } from '../../hooks/use-loading/use-loading';

const AppProgressBar = () => {
  const isLoading = useLoading();

  const [styles, api] = useSpring(() => ({
    loop: true,
    from: { width: '0%' },
    to: { width: '100%' },
    duration: 500,
  }));

  React.useEffect(() => {
    if (isLoading) {
      api.start({
        from: { width: '0%' },
        to: { width: '100%' },
      });
    } else {
      api.stop();
    }
  }, [api, isLoading]);

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 z-20 ${
        isLoading ? '' : 'hidden'
      }`}
    >
      <div className="flex h-1.5 overflow-hidden bg-indigo-200 text-xs">
        <animated.div
          style={styles}
          className="flex flex-col justify-center whitespace-nowrap bg-indigo-500 text-center text-white shadow-none"
        />
      </div>
    </div>
  );
};

export default AppProgressBar;
