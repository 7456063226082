import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../context/auth/use-user';

interface Props {
  toWhenAuth: string;
  toWhenNotAuth: string;
}

const AuthRedirect = ({ toWhenAuth, toWhenNotAuth }: Props) => {
  const user = useUser();
  return user.isAuthenticated ? (
    <Navigate to={toWhenAuth} replace />
  ) : (
    <Navigate to={toWhenNotAuth} replace />
  );
};

export default AuthRedirect;
