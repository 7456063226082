import React from 'react';

export const OfferCollectionCardSkeleton = ({
  withButtons = true,
  contentRows = 3,
}: {
  withButtons?: boolean;
  contentRows?: number;
}) => {
  return (
    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="p-6">
        <div className="flex w-full items-center justify-between space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3 pb-2">
              <div className="h-5 w-48 animate-pulse truncate rounded bg-gray-200" />
            </div>
            <div className="h-4 w-32 animate-pulse truncate rounded bg-gray-200" />
          </div>
          <div className="ml-4 h-10 w-10 animate-pulse rounded-md bg-gray-200" />
        </div>
        {Array.from({ length: contentRows }).map((_, idx) => (
          <div
            key={idx}
            className="mt-3 h-3 w-64 animate-pulse truncate rounded bg-gray-200"
          />
        ))}
      </div>
      {withButtons && (
        <div>
          <div className="h-14 animate-pulse rounded-b bg-gray-200" />
        </div>
      )}
    </li>
  );
};
