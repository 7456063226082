import React from 'react';
import SpinnerIcon from '../../../components/spinner/spinner-icon';

interface Props {
  url: string;
  width: number;
  height: number;
  isFullScreen?: boolean;
  refObj: React.LegacyRef<HTMLImageElement> | undefined;
  onChangeIsImageLoading?: (val: boolean) => void;
}

const ImageDisplay = ({
  url,
  width,
  height,
  refObj,
  onChangeIsImageLoading = () => {},
  isFullScreen = false,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [lastImgHeight, setLastImgHeight] = React.useState<number>();
  const [lastImgWidth, setLastImgWidth] = React.useState<number>();

  React.useEffect(() => {
    setIsLoading(true);
  }, [url]);

  React.useEffect(() => {
    if (width) {
      setLastImgWidth(width);
    }
    if (height) {
      setLastImgHeight(height);
    }
  }, [width, height]);

  React.useEffect(() => {
    onChangeIsImageLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div
          style={
            lastImgHeight && lastImgWidth
              ? {
                  width: lastImgWidth,
                  height: lastImgHeight,
                }
              : {}
          }
          className="flex h-[65vh] w-[80vw] items-center justify-center"
        >
          <SpinnerIcon className="h-10 w-10 text-indigo-600" />
        </div>
      )}
      <img
        ref={refObj}
        className={`${
          isLoading
            ? 'hidden '
            : isFullScreen
            ? 'w-full'
            : 'max-h-[65vh] max-w-[80vw]'
        } pointer-events-none select-none object-contain !outline !outline-2 !outline-black`}
        onLoad={() => setIsLoading(false)}
        src={url}
        alt=""
      />
    </>
  );
};

export default ImageDisplay;
