import React from 'react';
import { useAnalytics } from '../../context/analytics/use-analytics';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPhoneCalls, postPhoneCallSummary } from '../../api/calls/calls';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { AxiosError } from 'axios';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { Controller, useForm } from 'react-hook-form';
import { FeatureFlag, PhoneCallResultEnum } from '../../api/api.types';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import SelectInputArrowControlled from '../inputs/select-input/select-input-arrow-controlled';
import CallResultIcon from '../icons/call-result-icon';
import InputLabel from '../inputs/shared/input-label';
import Button from '../button/button';
import SelectPhoneCallList from './select-phone-call-list';
import { useUser } from '../../context/auth/use-user';
import { useOffersInvalidation } from '../../hooks/use-invalidation/use-offers-invalidation';
import { CONTACT_RESOLUTION } from '../../constants/call-result-icons';

interface CallSummaryForm {
  result: PhoneCallResultEnum;
  notes: string;
  linkedCalls: string[];
  offerId: string;
}

const DEFAULT_STATE = {
  result: PhoneCallResultEnum.CLIENT_NOT_INTERESTED,
  notes: '',
  linkedCalls: [],
};

interface Props {
  phoneNumber?: string;
  offerId: string;
  offerOriginalUrl?: string;
  isOpen: boolean;
  close: () => void;
}

const ContactNumberPhoneCall = ({
  phoneNumber,
  offerId,
  isOpen,
  close,
}: Props) => {
  const user = useUser();
  const analytics = useAnalytics();
  const { t } = useTranslation('common');

  const [debouncedPhoneNumber] = useDebounce(phoneNumber, 300);

  const queryClient = useQueryClient();
  const invalidateOffers = useOffersInvalidation();
  const { mutate, isLoading: isMutating } = useMutation(postPhoneCallSummary, {
    onSuccess: async () => {
      await invalidateOffers();
      await queryClient.invalidateQueries(['callSummaries']);
      await queryClient.invalidateQueries(['offerStatuses']);
      await queryClient.invalidateQueries(['fair-meetings-allowance', offerId]);
      analytics.track(ANALYTICS_EVENTS.PHONE_CALL_SUMMARY_ADDED, {
        phoneNumber,
        offerId,
      });
      close();
    },
    onError: (e: AxiosError) => defaultErrorToasts(e, t),
  });

  const {
    data: calls = [],
    refetch,
    isLoading,
    isRefetching,
  } = useQuery(
    ['phoneCalls', debouncedPhoneNumber],
    () => getPhoneCalls({ phoneNumber: debouncedPhoneNumber }),
    {
      refetchInterval: 20_000,
      enabled: isOpen && user.hasFeatureFlag(FeatureFlag.PHONE_CALLS_ENABLED),
    },
  );

  const { handleSubmit, control, reset, register } = useForm<CallSummaryForm>({
    defaultValues: {
      ...DEFAULT_STATE,
    },
  });

  React.useEffect(() => {
    reset({ ...DEFAULT_STATE });
  }, [reset, offerId]);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    analytics.track(ANALYTICS_EVENTS.PHONE_CALL_SUMMARY_MODAL_OPEN, {
      phoneNumber,
      offerId,
    });
  }, [isOpen]);

  const onSubmit = (form: CallSummaryForm) => {
    mutate({
      body: {
        result: form.result,
        linked_calls: form.linkedCalls,
        notes: form.notes ? form.notes : undefined,
        offer_id: offerId,
        phone_number: phoneNumber!,
      },
    });
  };
  return (
    <>
      <div className="mt-1 w-full justify-between">
        <form
          className="relative mt-6 pb-6"
          onSubmit={handleSubmit(onSubmit)}
          id="contact-form"
        >
          <div className="mt-2">
            <SelectInputArrowControlled
              id="result"
              name="result"
              options={CONTACT_RESOLUTION.map((item) => ({
                label: t(`common:callStatuses.values.${item}`),
                value: item,
                icon: <CallResultIcon callResult={item} className="mr-2" />,
              }))}
              control={control}
              label={t('common:contact.form.result')}
              showLabel={true}
              labelClassName="mb-1"
              clearable={false}
            />
          </div>
          {user.hasFeatureFlag(FeatureFlag.PHONE_CALLS_ENABLED) && (
            <div className="mt-4">
              <div className="mb-1 flex flex-row items-end">
                <InputLabel
                  label={t('common:contact.form.linkedCalls')}
                  labelFor="calls"
                />
                <Button
                  className="ml-auto p-1"
                  onClick={() => refetch()}
                  isLoading={isRefetching || isLoading}
                  type="button"
                >
                  <ArrowPathIcon className="h-5 w-5" />
                </Button>
              </div>
              <Controller
                name="linkedCalls"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectPhoneCallList
                    value={value}
                    calls={calls}
                    onChange={onChange}
                    isLoading={isLoading}
                    isOpen={isOpen}
                  />
                )}
              />
            </div>
          )}
          <div className="mt-4">
            <InputLabel
              label={t('common:contact.form.notes')}
              labelFor="notes"
              labelClassName="mb-1"
            />
            <div className="mt-1 overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
              <textarea
                rows={2}
                id="notes"
                className={`block w-full resize-none border-0 py-3 text-sm placeholder-red-300 focus:ring-0`}
                {...register('notes')}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="mt-5 flex sm:mt-4">
        <Button
          type="button"
          variant="secondary"
          className="ml-auto mt-3 px-3 py-2"
          onClick={close}
        >
          {t('common:contact.form.cancel')}
        </Button>
        <Button
          type="submit"
          form="contact-form"
          variant="primary"
          className="ml-3 mt-3 px-3 py-2 "
          isLoading={isMutating}
        >
          {t('common:contact.form.save')}
        </Button>
      </div>
    </>
  );
};

export default ContactNumberPhoneCall;
