import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectOption } from './multi-select-input';
import MultiSelectInputLabeled, {
  SelectInputLabeledProps,
} from './multi-select-input-labeled';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Control } from 'react-hook-form/dist/types/form';

export interface MultiSelectInputLabeledControllerProps<TFieldValues, TContext>
  extends Omit<
    SelectInputLabeledProps,
    'onChange' | 'value' | 'isError' | 'errorMessage'
  > {
  name: string;
  options: SelectOption[];
  control: Control<TFieldValues, TContext>;
  className?: string;
  id: string;
  useArrow?: boolean;
  placeholder?: string;
}

const MultiSelectInputLabeledController = <F, C>({
  control,
  name,
  useArrow,
  placeholder,
  ...rest
}: MultiSelectInputLabeledControllerProps<F, C>) => {
  const createArrowProps = (isError: boolean) => {
    if (!useArrow) {
      return {};
    }
    return {
      emptyInput: (isOpen: boolean) => (
        <>
          {placeholder ? (
            <p className={isError ? 'text-red-500' : 'text-gray-500'}>
              {placeholder}
            </p>
          ) : (
            <>&nbsp;</>
          )}
          &nbsp;
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            {isOpen && (
              <ChevronUpIcon
                className={`h-5 w-5 ${
                  isError ? 'text-red-500' : 'text-gray-400'
                }`}
                aria-hidden="true"
              />
            )}
            {!isOpen && (
              <ChevronDownIcon
                className={`h-5 w-5 ${
                  isError ? 'text-red-500' : 'text-gray-400'
                }`}
                aria-hidden="true"
              />
            )}
          </span>
        </>
      ),
    };
  };

  return (
    <Controller
      control={control}
      name={name as any}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MultiSelectInputLabeled
          onChange={onChange}
          value={value as any}
          isError={!!error}
          errorMessage={error?.message}
          {...createArrowProps(!!error)}
          {...rest}
        />
      )}
    />
  );
};

export default MultiSelectInputLabeledController;
