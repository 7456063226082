import React from 'react';
import { useMutation } from '@tanstack/react-query';
import Spinner from '../../components/spinner/spinner';
import { useParamsRequired } from '../../hooks/use-params-required/use-params-required';
import { postActivate } from '../../api/auth/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import SuccessToast from '../../components/toasts/success-toast';
import { useTranslation } from 'react-i18next';
import ErrorToast from '../../components/toasts/error-toast';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';
import PageHeader from '../../components/header/page-header';

const ActivateAccount = () => {
  const { activateId } = useParamsRequired<{ activateId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(['auth', 'common']);
  const mutated = React.useRef(false);
  const analytics = useAnalytics();

  const { mutate } = useMutation(() => postActivate(activateId), {
    onSuccess: async () => {
      navigate('/login');
      toast.custom((toast) => (
        <SuccessToast
          {...toast}
          title={t('auth:alerts.activationSuccessful.title')}
          body={t('auth:alerts.activationSuccessful.body')}
        />
      ));
      await analytics.track(ANALYTICS_EVENTS.ACCOUNT_ACTIVATED);
    },
    onError: () => {
      toast.custom((toast) => (
        <ErrorToast
          {...toast}
          title={t('auth:alerts.accountActivationUnsuccessful.title')}
          body={t('auth:alerts.accountActivationUnsuccessful.body')}
        />
      ));
      navigate('/login');
    },
    retry: 2,
  });

  React.useEffect(() => {
    if (!mutated.current) {
      mutate();
    }
    mutated.current = true;
  }, [activateId, mutate]);

  return (
    <>
      <PageHeader title={t('common:pageTitle.activateAccount')} />
      <Spinner />
    </>
  );
};

export default ActivateAccount;
