import React from 'react';
import { OfferTypeEnum } from '../../api/api.types';
import { useTranslation } from 'react-i18next';
import ActivityFeedLoader from './activity-feed-loader';
import { useOfferEvents } from '../../hooks/use-offer-events/use-offer-events';
import ActivityCommentForm from './activity-comment-form';
import Modal from '../modal/modal';
import ActivityFeedList from './activity-feed-list';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';

interface Props {
  isOpen: boolean;
  close: () => void;
  offerId: string;
  offerType: OfferTypeEnum;
}

const ActivityModal = ({ offerType, offerId, isOpen, close }: Props) => {
  const { t } = useTranslation(['offers']);
  const { events, isLoading } = useOfferEvents({
    offerId,
    offerType,
    enabled: isOpen,
  });
  const analytics = useAnalytics();

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    analytics.track(ANALYTICS_EVENTS.ACTIVITY_MODAL_OPEN, {
      offerType,
      offerId,
    });
  }, [isOpen]);

  return (
    <Modal {...{ isOpen, close }}>
      <div className="mx-auto w-[90%] md:w-[28rem] lg:w-[32rem]">
        <h1 className="mb-4 mt-2 text-3xl font-extrabold text-gray-900">
          {t('offers:timeline.activity')}
        </h1>
        <div className="h-96 max-w-[76vw] overflow-y-auto p-2 pt-6">
          {isLoading ? (
            <ActivityFeedLoader length={3} />
          ) : (
            <ActivityFeedList events={events} />
          )}
        </div>
        <ActivityCommentForm offerType={offerType} offerId={offerId} />
      </div>
    </Modal>
  );
};

export default ActivityModal;
