import React from 'react';
import { Controller } from 'react-hook-form';
import { InputLabelProps } from '../shared/input-label';
import CheckboxInputLabeled from './checkbox-input-labeled';

interface Props extends Omit<InputLabelProps, 'labelFor'> {
  name: string;
  className?: string;
  control: any;
  id: string;
  changeOnLabelClick?: boolean;
  saveErrorSpace?: boolean;
}

const CheckboxInputLabeledController = ({
  name,
  control,
  id,
  ...rest
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CheckboxInputLabeled
          id={id}
          onChange={onChange}
          value={value}
          isError={!!error}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};

export default CheckboxInputLabeledController;
