import { toast } from 'react-hot-toast';
import ErrorToast from '../components/toasts/error-toast';
import React from 'react';
import { AxiosError } from 'axios';

enum CommonErrors {
  FEATURE_FLAG_REQUIRED = 'FEATURE_FLAG_REQUIRED',
  ACTIVE_SUBSCRIPTION_REQUIRED = 'ACTIVE_SUBSCRIPTION_REQUIRED',
  INSUFFICIENT_PRIVILEGES = 'INSUFFICIENT_PRIVILEGES',
  INSUFFICIENT_SUBSCRIPTION_TIER = 'INSUFFICIENT_SUBSCRIPTION_TIER',
}

export const defaultErrorToasts = (
  error: AxiosError,
  t: (key: string) => string,
) => {
  if (
    error.response?.status === 422 &&
    (error.response.data as any).detail?.[0]?.msg ===
      'invalid phone number format'
  ) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.invalidPhone.title')}
        body={t('common:alerts.invalidPhone.body')}
      />
    ));
    return;
  }

  if (
    error.response?.status === 403 &&
    (error.response.data as any).detail === 'Insufficient permissions'
  ) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.missingPrivileges.title')}
        body={t('common:alerts.missingPrivileges.body')}
      />
    ));
    return;
  }

  if (
    error.response?.status === 401 &&
    (error.response.data as any).detail === CommonErrors.FEATURE_FLAG_REQUIRED
  ) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.missingFeatureFlag.title')}
        body={t('common:alerts.missingFeatureFlag.body')}
      />
    ));
    return;
  }

  if (
    error.response?.status === 401 &&
    (error.response.data as any).detail ===
      CommonErrors.ACTIVE_SUBSCRIPTION_REQUIRED
  ) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.activeSubscriptionRequired.title')}
        body={t('common:alerts.activeSubscriptionRequired.body')}
      />
    ));
    return;
  }

  if (
    error.response?.status === 401 &&
    (error.response.data as any).detail ===
      CommonErrors.INSUFFICIENT_SUBSCRIPTION_TIER
  ) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.insufficientSubscriptionTier.title')}
        body={t('common:alerts.insufficientSubscriptionTier.body')}
      />
    ));
    return;
  }

  if (
    error.response?.status === 401 &&
    (error.response.data as any).detail === CommonErrors.INSUFFICIENT_PRIVILEGES
  ) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.organizationPrivilegesRequired.title')}
        body={t('common:alerts.organizationPrivilegesRequired.body')}
      />
    ));
    return;
  }

  if (error.response?.status === 500) {
    toast.custom((toast) => (
      <ErrorToast
        {...toast}
        title={t('common:alerts.serverError.title')}
        body={t('common:alerts.serverError.body')}
      />
    ));
    return;
  }

  toast.custom((toast) => (
    <ErrorToast
      {...toast}
      title={t('common:alerts.unexpectedError.title')}
      body={t('common:alerts.unexpectedError.body')}
    />
  ));
};
