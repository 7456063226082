import { OfferSearchStateEnum } from '../api/api.types';
import { OfferStateTableSteps } from '../pages/client-searches/client-search-details/offer-state-table/offer-state-table';
import { stringifyUrl } from 'query-string';

interface ClientOfferSearchProps {
  id: string;
  state?: OfferSearchStateEnum;
}

export const generateClientOfferSearchLink = ({
  id,
  state,
}: ClientOfferSearchProps) => {
  return stringifyUrl({
    url: `/client-searches/details/${id}`,
    query: {
      ...(state
        ? { tab: offerSearchStateEnumToOfferStateTableSteps(state) }
        : {}),
    },
  });
};

interface ClientOfferSearchesProps {
  showClosed?: boolean;
  clientId?: string;
}

export const generateClientOfferSearchesLink = ({
  showClosed,
  clientId,
}: ClientOfferSearchesProps) => {
  return stringifyUrl({
    url: '/client-searches/list',
    query: {
      ...(showClosed ? { showClosed: true } : {}),
      ...(clientId !== undefined ? { clientId } : {}),
    },
  });
};

export const offerSearchStateEnumToOfferStateTableSteps = (
  e: OfferSearchStateEnum,
) => {
  switch (e) {
    case OfferSearchStateEnum.PREPARING:
      return OfferStateTableSteps.PREPARING;
    case OfferSearchStateEnum.SHOWN_TO_CLIENT:
      return OfferStateTableSteps.SHOWN;
    case OfferSearchStateEnum.REJECTED:
      return OfferStateTableSteps.REJECTED;
    default:
      return OfferStateTableSteps.PRESELECTED;
  }
};
