import React from 'react';
//@ts-ignore typescript types are invalid
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import SuccessToastSmall from '../toasts/success-toast-small';

interface Props {
  notificationText?: string;
  text: string;
  children: React.ReactNode;
}

const CopyToClipboardAlerted = ({
  text,
  children,
  notificationText,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CopyToClipboard
      text={text}
      onCopy={(_: any, result: any) => {
        if (result) {
          toast.custom(
            (toast) => (
              <SuccessToastSmall
                {...toast}
                title={
                  notificationText ?? t('common:copy.alerts.copiedGeneric')
                }
              />
            ),
            { position: 'bottom-center' },
          );
        }
      }}
    >
      {children}
    </CopyToClipboard>
  );
};

export default CopyToClipboardAlerted;
