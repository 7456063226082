import { OfferStatus } from '../api/api.types';
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  PhoneIcon,
} from '@heroicons/react/20/solid';
import React from 'react';

export const OFFER_STATUS_CLASSES: { [key in OfferStatus]: string } = {
  [OfferStatus.MARKED_FOR_CONTACT]: 'bg-amber-100 text-amber-800',
  [OfferStatus.CLIENT_ACQUIRED]: 'bg-emerald-100 text-emerald-800',
  [OfferStatus.CLIENT_REJECTED]: 'bg-red-100 text-red-800',
};

export const OFFER_STATUS_BACKGROUND_LIGHT_CLASSES: {
  [key in OfferStatus]: string;
} = {
  [OfferStatus.MARKED_FOR_CONTACT]: 'bg-amber-50',
  [OfferStatus.CLIENT_ACQUIRED]: 'bg-emerald-50',
  [OfferStatus.CLIENT_REJECTED]: 'bg-red-50',
};

export const OFFER_STATUS_BACKGROUND_CLASSES: { [key in OfferStatus]: string } =
  {
    [OfferStatus.MARKED_FOR_CONTACT]: 'bg-amber-100',
    [OfferStatus.CLIENT_ACQUIRED]: 'bg-emerald-100',
    [OfferStatus.CLIENT_REJECTED]: 'bg-red-100',
  };

export const OFFER_STATUS_BORDER_CLASSES: { [key in OfferStatus]: string } = {
  [OfferStatus.MARKED_FOR_CONTACT]: 'border-2 border-amber-800 box-border',
  [OfferStatus.CLIENT_ACQUIRED]: 'border-2 border-emerald-800 box-border',
  [OfferStatus.CLIENT_REJECTED]: 'border-2 border-red-800 box-border',
};

export const OFFER_STATUS_LIGHT_CLASSES_HOVER: {
  [key in OfferStatus]: string;
} = {
  [OfferStatus.MARKED_FOR_CONTACT]: 'hover:bg-amber-100',
  [OfferStatus.CLIENT_ACQUIRED]: 'hover:bg-emerald-100',
  [OfferStatus.CLIENT_REJECTED]: 'hover:bg-red-100',
};

export const OFFER_STATUS_TEXT_CLASSES: { [key in OfferStatus]: string } = {
  [OfferStatus.MARKED_FOR_CONTACT]: 'text-amber-800',
  [OfferStatus.CLIENT_ACQUIRED]: 'text-emerald-800',
  [OfferStatus.CLIENT_REJECTED]: 'text-red-800',
};

export const OFFER_BACKGROUND_TEXT_CLASSES: { [key in OfferStatus]: string } = {
  [OfferStatus.MARKED_FOR_CONTACT]: 'bg-amber-800',
  [OfferStatus.CLIENT_ACQUIRED]: 'bg-emerald-800',
  [OfferStatus.CLIENT_REJECTED]: 'bg-red-800',
};

export const OFFER_STATUS_ICONS: {
  [key in OfferStatus]: (
    props: React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>,
  ) => React.ReactElement;
} = {
  MARKED_FOR_CONTACT: (props) => <PhoneIcon {...props} />,
  CLIENT_ACQUIRED: (props) => <CheckBadgeIcon {...props} />,
  CLIENT_REJECTED: (props) => <ExclamationCircleIcon {...props} />,
};
