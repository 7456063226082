import React from 'react';
import Modal from '../modal/modal';
import { OfferTypeEnum } from '../../api/api.types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../button/button';
import {
  getOfferVariantById,
  updateOfferVariant,
} from '../../api/offer-variants/offer-variants';
import {
  formToOfferSchema,
  offerVariantImagesToForm,
  offerVariantToForm,
  postProcessImages,
} from './form-mappings';
import { OFFER_VARIANT_FORM_DEFAULT_VALUES } from './form-data';
import OfferVariantFormNavigation, {
  OfferVariantFormTabs,
} from './offer-variant-form-navigation';
import OfferVariantFormSections from './offer-variant-form-sections';
import { ImageTypeWitId } from '../offer-photos/offer-photos-upload';
import { useOfferVariantFormValidation } from './offer-schema';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useOfferVariantErrorHandling } from './use-offer-variant-error-handling';
import { getOfferByTypeAndId } from '../../api/offers/offers';
import { convertDownloadedImagesToSize } from '../../utils/convert-downloaded-image-to-size';

interface Props {
  phoneNumber?: string;
  isOpen: boolean;
  close: () => void;
  offerVariantId: string;
  offerId: string;
  offerType: OfferTypeEnum;
  forceDataReferch?: boolean;
  setForceDataRefetch?: (value: boolean) => void;
}

const OfferVariantUpdateModal = ({
  isOpen,
  close,
  offerType,
  offerId,
  offerVariantId,
  forceDataReferch = false,
  setForceDataRefetch = () => {},
}: Props) => {
  const [selected, setSelected] = React.useState(
    OfferVariantFormTabs.PARAMETERS,
  );
  const { t } = useTranslation(['offers']);

  const schema = useOfferVariantFormValidation({ offerType });
  const form = useForm<any>({
    defaultValues: OFFER_VARIANT_FORM_DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  const [images, setImages] = React.useState<ImageTypeWitId[]>([]);
  const [imagesLoading, setImagesLoading] = React.useState(false);

  React.useEffect(() => {
    if (offerId) {
      form.reset(OFFER_VARIANT_FORM_DEFAULT_VALUES);
      setImages([]);
    }
  }, [offerId]);

  const handleOfferVariantError = useOfferVariantErrorHandling();
  const { mutate: mutateUpdateVariant, isLoading: isUpdatingOfferVariant } =
    useMutation(updateOfferVariant, {
      onSuccess: () => {
        close();
      },
      onError: handleOfferVariantError,
    });

  const { isLoading: isOfferVariantLoading } = useQuery(
    ['offerVariants', offerVariantId],
    () => getOfferVariantById({ offerVariantId }),
    {
      retry: (retryCount, error: AxiosError) =>
        error.response?.status !== 404 &&
        error.response?.status !== 422 &&
        retryCount < 3,
      enabled: isOpen && !!offerId && !!offerType,
      onSuccess: (data) => {
        if (!form.formState.isDirty || forceDataReferch) {
          form.reset(offerVariantToForm(data));
        }
        if (!images.length || forceDataReferch) {
          setImages(offerVariantImagesToForm(data));
        }
        if (forceDataReferch) {
          setForceDataRefetch(false);
        }
      },
    },
  );

  const { data: offerDetails } = useQuery(
    ['offerDetails', offerType, offerId],
    () => getOfferByTypeAndId(offerType, offerId),
    {
      retry: (retryCount, error: AxiosError) =>
        error.response?.status !== 404 &&
        error.response?.status !== 422 &&
        retryCount < 3,
    },
  );

  const originalImages = React.useMemo(() => {
    const rawImagesUrls: string[] = offerDetails?.downloaded_photos_urls
      ?.downloaded_urls
      ? convertDownloadedImagesToSize(
          offerDetails?.downloaded_photos_urls.downloaded_urls,
          'xl',
        )
      : offerDetails?.offer?.photos_urls
      ? offerDetails.offer.photos_urls
      : [];
    const urlSet = new Set(images.map((image) => image.dataURL));
    const missingImages = rawImagesUrls.filter((url) => !urlSet.has(url));
    return postProcessImages(missingImages);
  }, [offerDetails, images]);

  const onSubmit = async (form: any) => {
    if (!offerId || !offerType) {
      return;
    }
    setImagesLoading(true);

    mutateUpdateVariant({
      offerVariantId,
      body: {
        variant_data: formToOfferSchema(form, offerType),
        photos_data_urls: images.map((p) => p.dataURL!) as string[],
      },
    });
    setImagesLoading(false);
  };

  const [displayMap, setDisplayMap] = React.useState(false);
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setDisplayMap(true);
      }, 1);
    } else {
      setDisplayMap(false);
    }
  }, [isOpen]);

  return (
    <Modal close={close} isOpen={isOpen}>
      <div className="max-w-4xl">
        <div className="border-b border-gray-200">
          <OfferVariantFormNavigation
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <form
          id="offer-variant-form"
          className="mt-6 h-[60vh] justify-between overflow-y-auto overflow-x-hidden px-1 lg:w-[896px]"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormProvider {...form}>
            <OfferVariantFormSections
              offerType={offerType}
              control={form.control}
              images={images}
              setImages={(img) => setImages(img)}
              selected={selected}
              originalImages={originalImages}
              displayMap={displayMap}
            />
          </FormProvider>
        </form>
        <div className="mt-6 flex flex-row">
          <Button
            type="button"
            variant="secondary"
            className="ml-auto mt-3 px-3 py-2"
            onClick={close}
          >
            {t('offers:offerForm.actions.cancel')}
          </Button>
          <Button
            type="submit"
            form="offer-variant-form"
            variant="primary"
            className="ml-3 mt-3 px-3 py-2"
            isLoading={
              isUpdatingOfferVariant || imagesLoading || isOfferVariantLoading
            }
          >
            {t('offers:offerForm.actions.update')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OfferVariantUpdateModal;
