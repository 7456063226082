import {
  formatDistanceToNow,
  formatDistanceToNowStrict,
  isValid,
} from 'date-fns';
import { DATE_FNS_LOCALES, SupportedLanguages } from '../../i18n/languages';
import { useTranslation } from 'react-i18next';

interface Props {
  date: Date | string;
  addSuffix?: boolean;
  className?: string;
  strict?: boolean;
}

const TimeToNow = ({
  date,
  className = '',
  strict = false,
  addSuffix = true,
}: Props) => {
  const { i18n } = useTranslation();
  if (typeof date === 'string' && !isValid(new Date(date))) {
    return <span>{date}</span>;
  }
  return (
    <span className={className}>
      {strict
        ? formatDistanceToNowStrict(new Date(date), {
            locale: DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
            addSuffix,
          })
        : formatDistanceToNow(new Date(date), {
            locale: DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
            addSuffix,
          })}
    </span>
  );
};

export default TimeToNow;
