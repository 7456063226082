import { classNames } from '../../style/class-names';
import React from 'react';
import { useElementWidth } from '../../hooks/use-element-dimentions/use-element-width';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid';

type TabsRender = 'list' | 'select';

interface TabRenderProps {
  isSelected: boolean;
  select: () => void;
  type: TabsRender;
  isActive?: boolean;
}

interface Tab {
  key: string;
  text: string | ((input: TabRenderProps) => React.ReactNode);
}

interface Props {
  tabs: Tab[];
  defaultTabId?: string;
  children?: React.ReactNode;
  title?: string;
  value?: string;
  onChange?: (tab: string) => void;
  settings?: {
    estimatedNavElWidth?: number;
  };
  classNames?: {
    navContainer?: string | ((type: TabsRender) => string);
    contentContainer?: string;
    wrapper?: string;
    title?: string | ((type: TabsRender) => string);
  };
}

const Tabs = ({
  tabs,
  defaultTabId,
  children,
  title,
  value,
  onChange,
  settings: { estimatedNavElWidth = 150 } = {},
  classNames: {
    navContainer: navContainerClassName = '',
    contentContainer: contentContainerClassName = '',
    wrapper: wrapperClassName = '',
    title: titleClassName = '',
  } = {},
}: Props) => {
  const navRef = React.useRef<HTMLDivElement>(null);
  const width = useElementWidth(navRef);
  const [internalSelectedTabId, setInternalSelectedTabId] = React.useState(
    defaultTabId ?? tabs?.[0]?.key,
  );

  const selectedTabId = value && onChange ? value : internalSelectedTabId;
  const setSelectedTabId =
    value && onChange ? onChange : setInternalSelectedTabId;
  const displaySelect = tabs.length * estimatedNavElWidth > width;

  const navContainerPreparedClassName =
    typeof navContainerClassName === 'string'
      ? navContainerClassName
      : navContainerClassName(displaySelect ? 'select' : 'list');
  const titlePreparedClassName =
    typeof titleClassName === 'string'
      ? titleClassName
      : titleClassName(displaySelect ? 'select' : 'list');

  return (
    <div className={`w-full ${wrapperClassName}`}>
      <div
        ref={navRef}
        className={`${navContainerPreparedClassName} block w-full`}
      >
        {displaySelect ? (
          <SelectTabs
            {...{
              tabs,
              title,
              setSelectedTabId,
              selectedTabId,
              titleClassName: titlePreparedClassName,
            }}
          />
        ) : (
          <HorizontalTabs
            {...{
              tabs,
              title,
              setSelectedTabId,
              selectedTabId,
              titleClassName: titlePreparedClassName,
            }}
          />
        )}
      </div>
      <div className={`${contentContainerClassName} pt-4`}>
        {React.Children.map(children, (child) =>
          isTabWithId(child, selectedTabId) ? cloneElement(child) : null,
        )}
      </div>
    </div>
  );
};

interface TabProps {
  key: string;
  children?: React.ReactNode;
}

Tabs.Tab = function Tab({ children }: TabProps) {
  return <>{children}</>;
};

export default Tabs;
export type { Props as TabsProps };

interface NavProps {
  title?: string;
  tabs: Tab[];
  setSelectedTabId: (tab: string) => void;
  selectedTabId: string;
  titleClassName?: string;
}

const HorizontalTabs = ({
  title,
  tabs,
  setSelectedTabId,
  selectedTabId,
  titleClassName = '',
}: NavProps) => {
  return (
    <div className="flex w-full space-x-4 overflow-hidden" aria-label="Tabs">
      {title && (
        <h1
          className={`${titleClassName} mr-auto py-2 text-base font-medium text-gray-700`}
        >
          {title}
        </h1>
      )}

      {tabs.map((tab) => (
        <TabItem key={tab.key} {...{ tab, selectedTabId, setSelectedTabId }} />
      ))}
    </div>
  );
};

interface TabItemProps {
  tab: Tab;
  setSelectedTabId: (tab: string) => void;
  selectedTabId: string;
}

const TabItem = ({ tab, selectedTabId, setSelectedTabId }: TabItemProps) => {
  const isSelected = tab.key === selectedTabId;
  const select = () => setSelectedTabId(tab.key);

  if (typeof tab.text !== 'string') {
    return <>{tab.text({ isSelected, select, type: 'list' })}</>;
  }

  return (
    <button
      key={tab.key}
      type="button"
      onClick={select}
      className={classNames(
        isSelected
          ? 'bg-indigo-100 text-indigo-700'
          : 'text-gray-500 hover:text-gray-700',
        'rounded-md px-3 py-2 text-sm font-medium',
      )}
      aria-current={isSelected ? 'page' : undefined}
    >
      {tab.text}
    </button>
  );
};

const SelectTabs = ({
  title,
  tabs,
  selectedTabId,
  setSelectedTabId,
  titleClassName = '',
}: NavProps) => {
  const id = React.useId();
  const selected = tabs.find((tab) => tab.key === selectedTabId);

  return (
    <Listbox value={selectedTabId} onChange={setSelectedTabId}>
      {({ open }) => (
        <div>
          {title && (
            <Listbox.Label
              htmlFor={id}
              className={`${titleClassName} mb-1 block text-sm font-medium text-gray-700`}
            >
              {title}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button
              id={id}
              className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            >
              {selected &&
                (typeof selected.text === 'string' ? (
                  <span className="flex items-center truncate">
                    {selected.text}
                  </span>
                ) : (
                  selected.text({
                    isSelected: false,
                    select: () => {},
                    type: 'select',
                  })
                ))}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                {open && (
                  <ChevronUpIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                {!open && (
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-[60] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {tabs.map((tab) => (
                  <Listbox.Option
                    key={tab.key}
                    value={tab.key}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                  >
                    {({ selected, active }) =>
                      typeof tab.text !== 'string' ? (
                        <>
                          {tab.text({
                            isSelected: selected,
                            select: () => {},
                            type: 'select',
                            isActive: active,
                          })}
                        </>
                      ) : (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'flex items-center truncate',
                            )}
                          >
                            {tab.text}
                          </span>
                          {selected && (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )
                    }
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

function isTabWithId(element: React.ReactNode, key: string) {
  return (
    React.isValidElement(element) &&
    typeof element.type === 'function' &&
    element.type.name === Tabs.Tab.name &&
    element.key === key
  );
}

function cloneElement(element: React.ReactNode) {
  if (!React.isValidElement(element)) {
    return null;
  }
  return React.cloneElement(element);
}
