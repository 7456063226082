import React from 'react';
import { PhoneCallResponse } from '../../api/api.types';
import { Listbox } from '@headlessui/react';
import PhoneCall from '../phone-call/phone-call';
import { classNames } from '../../style/class-names';
import PhoneCallSkeleton from '../phone-call/phone-call-skeleton';
import { PhoneXMarkIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import ScrollableWithShadowContainer from '../scrollable-with-shadow-container/scrollable-with-shadow-container';

interface Props {
  value: string[];
  calls: PhoneCallResponse[];
  onChange: (value: string[]) => void;
  isLoading: boolean;
  isOpen?: boolean;
}

const SelectPhoneCallList = ({
  value,
  onChange,
  calls,
  isLoading,
  isOpen,
}: Props) => {
  const { t } = useTranslation('common');
  const togglePhoneCall = (phoneCall: string) => {
    if (value.includes(phoneCall)) {
      onChange(value.filter((selected) => selected !== phoneCall));
    } else {
      onChange([...value, phoneCall]);
    }
  };

  return (
    <Listbox value={value} onChange={onChange}>
      <ScrollableWithShadowContainer
        className="h-52 w-[32rem] max-w-[76vw] p-2"
        refreshOnChangeOf={[isOpen, isLoading]}
      >
        {isLoading &&
          [...new Array(2)].map((i) => (
            <div
              key={i}
              className={`flex flex-row items-center justify-center border border-gray-200 p-4
                  ${i === 0 ? 'rounded-tl-md rounded-tr-md' : ''},
                   ${i === 2 ? 'rounded-bl-md rounded-br-md' : ''}`}
            >
              <div className="mr-4 flex h-4 w-4 animate-pulse items-center justify-center rounded bg-gray-200" />
              <PhoneCallSkeleton key={i} />
            </div>
          ))}
        {!isLoading && calls.length === 0 && (
          <div className="flex flex-col justify-center pt-6 text-center">
            <PhoneXMarkIcon className="mx-auto h-6 w-6 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              {t('common:contact.phoneCallsMissing.title')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {t('common:contact.phoneCallsMissing.description')}
            </p>
          </div>
        )}
        <div>
          {!isLoading &&
            calls.length > 0 &&
            calls.map((call, planIdx) => (
              <div
                key={call.phone_call_id}
                onClick={() => togglePhoneCall(call.phone_call_id)}
                className={classNames(
                  planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  planIdx === calls.length - 1
                    ? 'rounded-bl-md rounded-br-md'
                    : '',
                  value.includes(call.phone_call_id)
                    ? 'z-10 border-indigo-200 bg-indigo-100'
                    : 'border-gray-200',
                  'relative flex cursor-pointer flex-col border p-4 focus:outline-none',
                )}
              >
                <div className="flex flex-row items-center justify-center text-xs sm:text-sm">
                  <input
                    type="checkbox"
                    checked={value.includes(call.phone_call_id)}
                    className={classNames(
                      value.includes(call.phone_call_id)
                        ? 'border-transparent bg-indigo-600 text-indigo-600'
                        : 'border-gray-300 bg-white',
                      'mr-4 flex h-4 w-4 items-center justify-center rounded border focus:ring-indigo-500',
                    )}
                  />
                  <PhoneCall call={call}></PhoneCall>
                </div>
              </div>
            ))}
        </div>
      </ScrollableWithShadowContainer>
    </Listbox>
  );
};

export default SelectPhoneCallList;
