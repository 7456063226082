import { OfferStateTableSteps } from './offer-state-table';
import HouseSearchSvg from '../../../../assets/svg/house-search-svg';
import HouseSelectedSvg from '../../../../assets/svg/house-selected-svg';
import FolderSvg from '../../../../assets/svg/folder-svg';
import ThrowAwaySvg from '../../../../assets/svg/throw-away-svg';
import { useTranslation } from 'react-i18next';

interface Props {
  step: OfferStateTableSteps;
}

const OfferStateTableEmpty = ({ step }: Props) => {
  const { t } = useTranslation('client-searches');

  if (step === OfferStateTableSteps.REJECTED) {
    return (
      <div className="mx-auto max-w-sm">
        <ThrowAwaySvg className="mx-auto my-6 w-48 lg:my-8" />
        <h2 className="mb-2 mt-6 text-center text-base font-semibold leading-6 text-gray-900 lg:mb-4 lg:mt-8">
          {t('client-searches:clientSearchDetails.empty.rejected.title')}
        </h2>
        <p className="leading-snug text-gray-700">
          {t('client-searches:clientSearchDetails.empty.rejected.details')}
        </p>
      </div>
    );
  }

  if (step === OfferStateTableSteps.SHOWN) {
    return (
      <div className="mx-auto max-w-sm">
        <FolderSvg className="mx-auto my-6 w-48 lg:my-8" />
        <h2 className="mb-2 mt-6 text-center text-base font-semibold leading-6 text-gray-900 lg:mb-4 lg:mt-8">
          {t('client-searches:clientSearchDetails.empty.shownToClient.title')}
        </h2>
        <p className="leading-snug text-gray-700">
          {t(
            'client-searches:clientSearchDetails.empty.shownToClient.details1',
          )}
        </p>
        <p className="mt-2 leading-snug text-gray-700 lg:mt-4">
          {t(
            'client-searches:clientSearchDetails.empty.shownToClient.details2',
          )}
        </p>
      </div>
    );
  }

  if (step === OfferStateTableSteps.PREPARING) {
    return (
      <div className="mx-auto max-w-sm px-2 sm:px-0">
        <HouseSelectedSvg className="mx-auto my-6 w-48 lg:my-8" />
        <h2 className="mb-2 mt-6 text-center text-base font-semibold leading-6 text-gray-900 lg:mb-4 lg:mt-8">
          {t('client-searches:clientSearchDetails.empty.preparing.title')}
        </h2>
        <p className="leading-snug text-gray-700">
          {t('client-searches:clientSearchDetails.empty.preparing.details')}
        </p>
        <h2 className="mb-2 mt-6 text-center text-base font-semibold leading-6 text-gray-900 lg:mb-4 lg:mt-8">
          {t('client-searches:clientSearchDetails.empty.preparing.header')}
        </h2>
        <ul className="list-disc leading-snug text-gray-700">
          {Array.from({ length: 3 }).map((_, i) => (
            <li key={i} className="mb-2">
              <b>
                {t(
                  `client-searches:clientSearchDetails.empty.preparing.li${i}Title`,
                )}
              </b>
              :{' '}
              {t(
                `client-searches:clientSearchDetails.empty.preparing.li${i}Details`,
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-sm px-2 sm:px-0">
      <HouseSearchSvg className="mx-auto my-8 w-48" />
      <h2 className="mb-2 mt-6 text-center text-base font-semibold leading-6 text-gray-900 lg:mb-4 lg:mt-8">
        {t('client-searches:clientSearchDetails.empty.preselected.title')}
      </h2>
      <p className="leading-snug text-gray-700">
        {t('client-searches:clientSearchDetails.empty.preselected.details')}
      </p>
      <h2 className="mb-2 mt-6 text-center text-base font-semibold leading-6 text-gray-900 lg:mb-4 lg:mt-8">
        {t('client-searches:clientSearchDetails.empty.preselected.header')}
      </h2>
      <ul className="list-disc leading-snug text-gray-700">
        {Array.from({ length: 4 }).map((_, i) => (
          <li key={i} className="mb-2">
            <b>
              {t(
                `client-searches:clientSearchDetails.empty.preselected.li${i}Title`,
              )}
            </b>
            :{' '}
            {t(
              `client-searches:clientSearchDetails.empty.preselected.li${i}Details`,
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OfferStateTableEmpty;
