import React from 'react';
import Avatar, { IMAGE_WIDTHS_PER_AVATAR_SIZE } from './avatar';
import { NEW_IMAGE_PREFIX } from '../../constants/constants';

interface Props {
  userId: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string | null;
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  className?: string;
}

const AppUserAvatar = ({
  userId,
  firstName,
  lastName,
  size,
  avatarUrl,
  className = '',
}: Props) => {
  const isNew = avatarUrl?.startsWith(NEW_IMAGE_PREFIX);
  return (
    <Avatar
      size={size}
      avatarUrl={
        isNew
          ? `${avatarUrl}?w=${IMAGE_WIDTHS_PER_AVATAR_SIZE[size]}`
          : avatarUrl
      }
      initials={firstName.charAt(0) + lastName.charAt(0)}
      colorHash={userId}
      className={className}
    />
  );
};

export default AppUserAvatar;
