import DrawerWithDescription from '../../drawers/drawer-with-description';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOfferCollectionModeOptions } from '../../../forms/offer-collections';
import { useQuery } from '@tanstack/react-query';
import {
  GetOfferCollectionsMode,
  GetOfferCollectionsSort,
  getOfferCollectionsWithOfferInclusion,
} from '../../../api/offer-collections/offer-collections';
import { OfferCollectionCardForOffer } from '../offer-collection-card/offer-collection-card-for-offer';
import { FaEye } from 'react-icons/all';
import SelectInputArrow from '../../inputs/select-input/select-input-arrow';
import {
  OfferCollectionsListState,
  useOfferCollectionListState,
} from '../../../models/offer-collections';
import SimplePagination from '../../pagination/simple-pagination';
import useElementSize from '../../../hooks/use-element-dimentions/use-element-size';
import { OfferCollectionCardSkeleton } from '../offer-collection-card/offer-collection-card-skeleton';
import { ScrollableContainer } from '../../containers/scrollable-container';
import { OfferCollectionsListEmptyState } from '../../../pages/offer-collections/list/offer-collections-list-empty-state';
import { OfferCollectionsListEmptyStateFilters } from '../../../pages/offer-collections/list/offer-collections-list-empty-state-filters';
import { CreateOfferCollectionDrawer } from './create-offer-collection-drawer';
import Button from '../../button/button';
import { useUser } from '../../../context/auth/use-user';

interface Props {
  offerId: string;
  offerTitle: string;
  isOpen: boolean;
  close: () => void;
}

export const OfferCollectionsListForOfferDrawer = ({
  offerId,
  offerTitle,
  isOpen,
  close,
}: Props) => {
  const modeOptions = useOfferCollectionModeOptions();
  const user = useUser();
  const [searchParams, setSearchParams] = React.useState({
    page: 1,
    size: user.settings.itemsPerPage,
    mode: GetOfferCollectionsMode.CREATED_BY_CURRENT_USER,
    sort: GetOfferCollectionsSort.NAME_ASC,
    offerId,
  });

  const [isAddDrawerOpen, setIsAddDrawerOpen] = React.useState(false);

  const { data: offerCollections, isLoading: isOfferCollectionsLoading } =
    useQuery(
      ['offerCollections', 'forOffer', JSON.stringify(searchParams)],
      () => getOfferCollectionsWithOfferInclusion(searchParams),
      { enabled: isOpen },
    );

  const { t } = useTranslation(['offer-collections']);

  const state = useOfferCollectionListState({
    isLoading: isOfferCollectionsLoading,
    page: searchParams.page,
    mode: searchParams.mode,
    offerCollections,
  });

  const [contentContainerRef, contentContainerSize] = useElementSize();
  const [contentTopRef, contentTopSize] = useElementSize();
  const [contentBottomRef, contentBottomSize] = useElementSize();

  return (
    <DrawerWithDescription
      title={t('offer-collections:offer.title')}
      description={t('offer-collections:offer.description', {
        name:
          offerTitle.length > 100
            ? `${offerTitle.substring(0, 97)}...`
            : offerTitle,
      })}
      close={close}
      isOpen={isOpen}
      childrenSkipContainerClassName="h-full"
      childrenDirectContainerClassName="flex flex-col h-full"
    >
      {state === OfferCollectionsListState.NO_OFFER_SEARCHES_FOR_ORG ? (
        <OfferCollectionsListEmptyState
          variant="small"
          createOfferCollection={() => setIsAddDrawerOpen(true)}
        />
      ) : (
        <div ref={contentContainerRef} className="h-full">
          <div
            ref={contentTopRef}
            className="flex flex-wrap gap-2 py-2 sm:py-4"
          >
            <SelectInputArrow
              options={modeOptions}
              clearable={false}
              onChange={(value) => {
                setSearchParams((current) => ({
                  ...current,
                  page: 1,
                  mode: value as GetOfferCollectionsMode,
                }));
              }}
              prefix={() => <FaEye className="mr-2 h-4 w-4 text-gray-500" />}
              wrapperClassName="flex-grow"
              value={searchParams.mode}
            />
            <Button
              type="button"
              className="flex-grow px-2 py-1.5"
              onClick={() => setIsAddDrawerOpen(true)}
            >
              {t('offer-collections:offer.create')}
            </Button>
          </div>

          <ScrollableContainer
            height={
              contentContainerSize.height -
              contentTopSize.height -
              contentBottomSize.height
            }
            className="border-y-2 border-gray-200"
          >
            {state === OfferCollectionsListState.EMPTY && (
              <OfferCollectionsListEmptyStateFilters
                variant="small"
                clearFilters={() =>
                  setSearchParams((current) => ({
                    ...current,
                    page: 1,
                    mode: GetOfferCollectionsMode.CREATED_BY_CURRENT_USER,
                  }))
                }
              />
            )}
            {state ===
              OfferCollectionsListState.NO_OFFER_SEARCHES_FOR_CURRENT_USER && (
              <OfferCollectionsListEmptyState
                variant="small"
                createOfferCollection={() => setIsAddDrawerOpen(true)}
              />
            )}
            {[
              OfferCollectionsListState.LOADING,
              OfferCollectionsListState.DISPLAY,
            ].includes(state) && (
              <ul className="space-y-4 px-2 py-2 sm:py-4">
                {state === OfferCollectionsListState.LOADING &&
                  Array.from({ length: 5 }).map((_, idx) => (
                    <OfferCollectionCardSkeleton key={idx} />
                  ))}
                {state === OfferCollectionsListState.DISPLAY &&
                  offerCollections?.results.map((data) => (
                    <OfferCollectionCardForOffer
                      offerId={offerId}
                      data={data}
                    />
                  ))}
              </ul>
            )}
          </ScrollableContainer>

          <div ref={contentBottomRef} className="py-2 sm:py-4">
            <SimplePagination
              isLoading={isOfferCollectionsLoading}
              containerClassName="!my-0"
              page={searchParams.page}
              setPage={(page) =>
                setSearchParams((current) => ({ ...current, page }))
              }
              totalPages={offerCollections?.total_pages ?? 1}
              totalRecords={offerCollections?.total_items ?? 0}
              recordsPerPage={searchParams.size}
            />
          </div>
        </div>
      )}
      <CreateOfferCollectionDrawer
        isOpen={isAddDrawerOpen}
        close={() => setIsAddDrawerOpen(false)}
      />
    </DrawerWithDescription>
  );
};
