import React from 'react';
import SelectInput, { SelectInputProps } from './select-input';
import { Listbox } from '@headlessui/react';
import { InputLabelProps } from '../shared/input-label';

export interface SelectInputLabeledProps
  extends SelectInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
}

const SelectInputLabeled = ({
  label,
  labelClassName,
  id,
  ...rest
}: SelectInputLabeledProps) => {
  return (
    <SelectInput
      wrapperClassName="w-full"
      {...rest}
      id={id}
      labelComponent={
        <Listbox.Label
          htmlFor={id}
          className={`block text-sm font-medium text-gray-700 ${labelClassName}`}
        >
          {label}
        </Listbox.Label>
      }
    />
  );
};

export default SelectInputLabeled;
