import { SegmentedMessage } from 'sms-segments-calculator';

const noSpecialCharsBreakpoints = [
  160, // 1 parts
  306, // 2 parts
  459, // 3 parts
  612, // 4 parts
  765, // 5 parts
  918, // 6 parts
  1071, // 7 parts
  1224, // 8 parts
  1377, // 9 parts
  1530, // 10 parts
];

const withSpecialCharacters = [
  70, // 1 parts
  134, // 2 parts
  201, // 3 parts
  268, // 4 parts
  335, // 5 parts
  402, // 6 parts
  469, // 7 parts
  536, // 8 parts
  603, // 9 parts
  670, // 10 parts
];

export const calculateSmsMessageProperties = (message: string) => {
  if (!message) {
    return {
      parts: 0,
      charsTillEndOfPart: noSpecialCharsBreakpoints[0],
      hasSpecialChars: false,
    };
  }

  const segmentedMessage = new SegmentedMessage(message);
  const hasSpecialChars = segmentedMessage.encodingName !== 'GSM-7';

  const listToCheck = hasSpecialChars
    ? withSpecialCharacters
    : noSpecialCharsBreakpoints;

  return {
    parts: segmentedMessage.segmentsCount,
    charsTillEndOfPart:
      (listToCheck.find((item) => item >= message.length) ?? 0) -
      message.length,
    hasSpecialChars,
  };
};
