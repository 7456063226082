import React from 'react';

export enum AppView {
  REAL_ESTATE_APP = 'REAL_ESTATE_APP',
  ANALYTICS_APP = 'ANALYTICS_APP',
  PARCEL_APP = 'PARCEL_APP',
}
export interface AppViewContextProps {
  currentView: AppView;
  isAnalyticsApp: boolean;
  isRealEstateApp: boolean;
  isParcelApp: boolean;
  isAnalyticsAvailable: boolean;
  switchToRealEstateApp: () => void;
  switchToAnalyticsApp: () => void;
  viewMatchesAny: (views: AppView[]) => boolean;
}

export const AppViewContext = React.createContext<AppViewContextProps | null>(
  null,
);

export const useAppView = () => {
  const context = React.useContext(AppViewContext);
  if (!context) {
    throw new Error(`useAnalytics must be used within a AppViewContext`);
  }
  return context;
};
