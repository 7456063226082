import React from 'react';
import { useWatch } from 'react-hook-form';
import CurrencyPerSizeFormatter from '../../formatters/currency-per-size-formatter';
import { useTranslation } from 'react-i18next';

interface Props {
  control: any;
}

const PricePerMeterSummary = ({ control }: Props) => {
  const { t } = useTranslation(['data-model']);
  const [size, price] = useWatch({ name: ['property_size', 'price'], control });

  return (
    <div className="flex h-full flex-col">
      <span className={`block text-sm font-medium text-gray-700`}>
        {t('data-model:fieldLabels.price_per_meter')}
      </span>
      <div className="mb-2  flex h-full items-center justify-center rounded">
        <CurrencyPerSizeFormatter
          currencyUnit={'PLN'}
          currencyValue={price ? price : 0}
          sizeUnit="square-meter"
          sizeValue={size ? size : 0}
        />
      </div>
    </div>
  );
};

export default PricePerMeterSummary;
