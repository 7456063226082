import { AnalyticsInstance } from 'analytics';
import React from 'react';
import { AnalyticsContext } from './use-analytics';

export const AnalyticsProvider = ({
  children,
  instance,
}: {
  children?: React.ReactNode;
  instance: AnalyticsInstance;
}) => {
  const [currInstance, setInstance] = React.useState(instance);

  const afterTrack = React.useCallback(
    (afterTrackFunc: (e: string) => void) => {
      setInstance({
        ...instance,
        track: async (
          eventName: string,
          payload?: any,
          options?: any,
          callback?: (...params: any[]) => any,
        ) => {
          await instance.track(eventName, payload, options, callback);
          afterTrackFunc(eventName);
        },
      });
    },
    [],
  );

  return (
    <AnalyticsContext.Provider
      value={{
        instance: currInstance,
        afterTrack,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
