import { axios } from '../axios';

export interface CustomerFeedbackRequestDto {
  url: string;
  feedback: string;
}

export const sendCustomerFeedback = (input: CustomerFeedbackRequestDto) => {
  return axios.post(`/customer-feedback`, input);
};
