import React from 'react';
import { generateColorFromString } from '../../utils/generate-color-from-string';
import ImageWithPlaceholder from '../image/image-with-placeholder';

interface Props {
  initials: string;
  colorHash: string;
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  isRounded?: boolean;
  avatarUrl?: string | null;
  className?: string;
}

const SIZES = {
  sm: 'h-5 w-5',
  md: 'h-8 w-8',
  lg: 'h-10 w-10',
  xl: 'h-16 w-16',
  '2xl': 'h-20 w-20',
  '3xl': 'h-24 w-24',
  '4xl': 'h-32 w-32',
};

const TEXT_SIZES = {
  sm: 'text-[0.6rem] leading-[0.8rem]',
  md: '',
  lg: '',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
};

export const IMAGE_WIDTHS_PER_AVATAR_SIZE = {
  sm: 128,
  md: 128,
  lg: 256,
  xl: 256,
  '2xl': 480,
  '3xl': 480,
  '4xl': 480,
};

const Avatar = ({
  initials,
  colorHash,
  size,
  avatarUrl,
  className = '',
  isRounded = true,
}: Props) => {
  if (avatarUrl) {
    return (
      <ImageWithPlaceholder
        src={avatarUrl}
        alt={initials}
        placeholderClassName={`${SIZES[size]} ${
          isRounded ? 'rounded-full' : ''
        }`}
        className={`${SIZES[size]} ${
          isRounded ? 'rounded-full' : ''
        } object-cover ${className}`}
      />
    );
  }

  return (
    <div
      className={`${SIZES[size]} ${
        TEXT_SIZES[size]
      } flex items-center justify-center ${isRounded ? 'rounded-full' : ''}
         capitalize ${className}`}
      style={{ background: generateColorFromString(colorHash) }}
    >
      <span className="uppercase text-white">{initials}</span>
    </div>
  );
};

export default Avatar;
