import { OfferTypeEnum } from '../api/api.types';

export const OFFERS_TYPES = [
  OfferTypeEnum.LAND_SALES,
  OfferTypeEnum.LAND_RENTS,
  OfferTypeEnum.OFFICE_SALES,
  OfferTypeEnum.OFFICE_RENTS,
  OfferTypeEnum.INDUSTRIAL_BUILDING_SALES,
  OfferTypeEnum.INDUSTRIAL_BUILDING_RENTS,
  OfferTypeEnum.WAREHOUSE_SALES,
  OfferTypeEnum.WAREHOUSE_RENTS,
  OfferTypeEnum.COMMERCIAL_BUILDING_SALES,
  OfferTypeEnum.COMMERCIAL_BUILDING_RENTS,
  OfferTypeEnum.APARTMENT_SALES,
  OfferTypeEnum.APARTMENT_RENTS,
  OfferTypeEnum.HOUSE_SALES,
  OfferTypeEnum.HOUSE_RENTS,
  OfferTypeEnum.GARAGE_SALES,
  OfferTypeEnum.GARAGE_RENTS,
  OfferTypeEnum.ROOM_RENTS,
  OfferTypeEnum.AUCTIONS,
];
