import React from 'react';
import { useTranslation } from 'react-i18next';
import PaginationItemsSummary from './pagination-items-summary';

export interface PaginationProps {
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
  totalRecords: number;
  recordsPerPage: number;
  containerClassName?: string;
  isLoading?: boolean;
}

const SimplePagination = ({
  page,
  setPage,
  totalRecords,
  recordsPerPage,
  totalPages,
  containerClassName = '',
  isLoading = false,
}: PaginationProps) => {
  const { t } = useTranslation('common');

  const maxPage = Math.max(totalPages, 1);
  const prevPage = Math.max(1, page - 1);
  const nextPage = Math.min(maxPage, page + 1);

  return (
    <nav
      aria-label="Pagination"
      className={`mt-4 flex items-center justify-between py-1 ${containerClassName}`}
    >
      <div className="flex w-full flex-col">
        {isLoading ? (
          <div className="h-5 animate-pulse rounded-b bg-gray-200" />
        ) : (
          <PaginationItemsSummary
            page={page}
            recordsPerPage={recordsPerPage}
            totalRecords={totalRecords}
          />
        )}

        <div className="mt-2 flex flex-1 justify-between">
          {isLoading || prevPage === page ? (
            <div className="relative inline-flex cursor-not-allowed select-none items-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700">
              {t('common:pagination.previousPage')}
            </div>
          ) : (
            <button
              type="button"
              onClick={() => setPage(page - 1)}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >
              {t('common:pagination.previousPage')}
            </button>
          )}
          {isLoading || nextPage === page ? (
            <div className="relative inline-flex cursor-not-allowed select-none items-center rounded-md border border-gray-300 bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700">
              {t('common:pagination.nextPage')}
            </div>
          ) : (
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {t('common:pagination.nextPage')}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default SimplePagination;
