import React from 'react';
import { Controller } from 'react-hook-form';
import Tabs, { TabsProps } from './tabs';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types';

interface Props<F, C> extends Omit<TabsProps, ''> {
  control: Control<F, C>;
  name: FieldPath<F>;
}

const TabsController = <F, C>({ control, name, ...rest }: Props<F, C>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Tabs {...rest} value={value as any} onChange={onChange} />
      )}
    />
  );
};

TabsController.Tab = Tabs.Tab;
export default TabsController;
