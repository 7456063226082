import React from 'react';
import AppUserAvatar from './app-user-avatar';

interface Props {
  user_id: string;
  first_name: string;
  last_name: string;
  avatar_url?: string | null;
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
}

const UserAvatar = ({
  user_id,
  first_name,
  last_name,
  size,
  avatar_url,
  className = '',
}: Props) => {
  return (
    <AppUserAvatar
      size={size}
      firstName={first_name}
      lastName={last_name}
      userId={user_id}
      avatarUrl={avatar_url}
      className={className}
    />
  );
};

export default UserAvatar;
