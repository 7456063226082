import {
  OfferCollectionIconColor,
  OfferCollectionIcon,
} from '../../api/offer-collections/offer-collections';
import React from 'react';
import {
  offerCollectionIconColorToBgClassName,
  offerCollectionIconColorToTextClassName,
  offerCollectionIconToComponent,
} from '../../models/offer-collections';

interface Props {
  icon: OfferCollectionIcon;
  color: OfferCollectionIconColor;
  className?: string;
}

export const OfferCollectionIconDisplay = ({
  icon,
  color,
  className,
}: Props) => {
  const IconComponent = offerCollectionIconToComponent[icon];
  return (
    <IconComponent
      className={`h-10 w-10 rounded-sm p-1 ${offerCollectionIconColorToBgClassName[color]} ${offerCollectionIconColorToTextClassName[color]} ${className}`}
    />
  );
};
