import { toast, ToastOptions } from 'react-hot-toast';
import SuccessToast from '../../components/toasts/success-toast';
import React from 'react';
import ErrorToast from '../../components/toasts/error-toast';
import { defaultErrorToasts } from '../../utils/default-toasts';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import WarningToast from '../../components/toasts/warning-toast';

interface Props extends Partial<ToastOptions> {
  title: string;
  body: string;
}

type Toast = typeof SuccessToast | typeof ErrorToast | typeof WarningToast;

export const useToast = () => {
  const { t } = useTranslation(['common']);

  const createPostDispatcher = (Toast: Toast) => {
    return (props: Props) => {
      toast.custom((toast) => <Toast {...{ ...toast, ...props }} />, {
        ...props,
      });
    };
  };

  return {
    displaySuccessToast: createPostDispatcher(SuccessToast),
    displayErrorToast: createPostDispatcher(ErrorToast),
    displayWarningToast: createPostDispatcher(WarningToast),
    displayDefaultErrorToasts: (error: AxiosError) =>
      defaultErrorToasts(error, t),
  };
};
