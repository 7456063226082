import React from 'react';
import { classNames } from '../../../style/class-names';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  price: number;
  previousPrice: number;
  size: 'sm' | 'lg';
}

const OfferPriceChangeBadge = ({ price, previousPrice, size }: Props) => {
  const { t } = useTranslation('offers');
  const isIncrease = price > previousPrice;
  const priceChangePct = ((price - previousPrice) / previousPrice) * 100;

  const iconSize = size === 'sm' ? 'h-4 w-4' : 'h-5 w-5';
  const wrapperStyles =
    size === 'sm' ? 'px-1 text-xs py-0.5' : 'px-2 py-1 text-sm';

  const text = t(
    isIncrease ? 'offers:priceChange.increase' : 'offers:priceChange.decrease',
    {
      absPctChange: Math.abs(priceChangePct).toFixed(2),
    },
  );

  const id = 'price-change-badge';

  return (
    <>
      <div
        data-for={id}
        data-tip={text}
        className={classNames(
          isIncrease
            ? 'bg-green-100 text-green-800'
            : 'bg-red-100 text-red-800',
          'inline-flex items-center justify-center rounded-lg font-semibold',
          wrapperStyles,
        )}
      >
        {isIncrease ? (
          <ArrowUpIcon
            aria-hidden="true"
            className={`mr-0.5 flex-shrink-0 self-center text-green-500 ${iconSize}`}
          />
        ) : (
          <ArrowDownIcon
            aria-hidden="true"
            className={`mr-0.5 flex-shrink-0 self-center text-red-500 ${iconSize}`}
          />
        )}
        {priceChangePct.toFixed(2)}%
      </div>
      <ReactTooltip id={id} multiline className="font-normal leading-none" />
    </>
  );
};

export default OfferPriceChangeBadge;
