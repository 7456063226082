import UserAvatar from '../avatar/user-avatar';
import React from 'react';
import { UserPublicResponse } from '../../api/api.types';
import { notNullOrUndefined } from '../../utils/basic';
import PropertlyAvatar from '../avatar/propertly-avatar';

interface Props {
  user?: UserPublicResponse;
  properties?: (
    | {
        items: React.ReactNode[];
        type: 'row' | 'column';
      }
    | undefined
    | null
  )[];
}

const ActivityItemBase = ({ user, properties = [] }: Props) => {
  return (
    <div className="relative flex w-full gap-x-4">
      {user ? (
        <UserAvatar {...user} size="md" className="z-10" />
      ) : (
        <PropertlyAvatar size="md" className="z-10" />
      )}
      <div className="grow text-xs">
        {properties?.filter(notNullOrUndefined)?.map(({ type, items }, i) => {
          switch (type) {
            case 'row':
              return (
                <div
                  key={i}
                  className="flex items-center justify-between gap-x-4 space-x-2 py-0.5"
                >
                  {items}
                </div>
              );
            case 'column':
              return (
                <div
                  key={i}
                  className="items-left flex flex-col justify-between gap-x-4 space-y-1 py-0.5"
                >
                  {items}
                </div>
              );
          }
        })}
      </div>
    </div>
  );
};

export default ActivityItemBase;
