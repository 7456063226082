import React from 'react';
import ImageWithPlaceholder from '../image/image-with-placeholder';
import CurrencyFormatter from '../formatters/currency-formatter';
import SizeFormatter, { getValueAndUnit } from '../formatters/size-formatter';
import OfferCardMenu from './offer-card-menu';
import {
  IssuerTypeEnum,
  OfferStatusResponse,
  OfferTypeEnum,
} from '../../api/api.types';
import { generateOfferLink } from '../../utils/generate-offer-link';
import OfferTypeIcon from '../icons/offer-type-icon';
import { Link, useLocation } from 'react-router-dom';
import IssuerTypeIcon from '../icons/issuer-type-icon';
import ContactNumberButton from '../contact-number-button/contact-number-button';
import { convertDownloadedImagesToSize } from '../../utils/convert-downloaded-image-to-size';
import ActivityIcon from '../activity/activity-icon';
import OfferTagBadge from './offer-badges/offer-tag-badge';

interface Props {
  id: string;
  title: string;
  contact_number?: string | null;
  photos_urls: string[];
  downloaded_photos_urls?: string[];
  className?: string;
  price: number;
  price_currency?: string | null;
  property_size?: number | null;
  tag?: string;
  issuer_type?: IssuerTypeEnum | null;
  showContact?: boolean;

  offer_original_url: string;
  offer_type: OfferTypeEnum;
  activityCount?: number;
  selectedOfferStatus?: OfferStatusResponse;
  showMenu?: boolean;
  onTitleClick?: () => void;
}

const OfferSmallCard = React.memo(
  ({
    title,
    contact_number,
    photos_urls,
    className = '',
    price,
    price_currency,
    property_size,
    id,
    offer_type,
    offer_original_url,
    downloaded_photos_urls,
    activityCount,
    selectedOfferStatus,
    issuer_type,
    showMenu = true,
    showContact = true,
    tag,
    onTitleClick = () => {},
  }: Props) => {
    const location = useLocation();
    const photos = downloaded_photos_urls
      ? convertDownloadedImagesToSize(downloaded_photos_urls, 'sm')
      : photos_urls;
    return (
      <div className="relative text-black">
        <div
          className={`flex flex-row overflow-hidden rounded border ${className}`}
        >
          <div className="relative overflow-hidden pb-16 pr-24">
            <ImageWithPlaceholder
              src={photos?.[0]}
              fallbackImages={photos?.slice(1, 4)}
              placeholderClassName="absolute h-full w-full object-cover"
              className="absolute h-full w-full object-cover"
              alt={title}
              loading="lazy"
            />
            {tag ? (
              <div className="absolute bottom-1 left-1 right-1 items-center truncate text-amber-800">
                <OfferTagBadge
                  tag={tag}
                  offerOriginalUrl={offer_original_url}
                />
              </div>
            ) : null}
            <span className="absolute right-1 top-1 rounded-full border border-white">
              {issuer_type && (
                <IssuerTypeIcon
                  issuerType={issuer_type}
                  size="sm"
                  showTooltip={true}
                />
              )}
            </span>
          </div>
          <div className="ml-1 grid w-full p-2">
            <Link
              className="row-start-1 truncate leading-tight"
              to={generateOfferLink({ id, offerType: offer_type })}
              state={{
                backPathname: location.pathname,
                backSearch: location.search,
              }}
              onClick={() => onTitleClick()}
            >
              <h1 className="truncate text-sm font-semibold leading-tight text-black">
                {title}
              </h1>
            </Link>

            <div className="mt-1 flex flex-row">
              {price !== null && price_currency && (
                <div className="text-sm">
                  <CurrencyFormatter value={price} currency={price_currency} />
                </div>
              )}
              {property_size !== null && property_size !== undefined ? (
                <div className="ml-1 border-l border-gray-300 pl-1 text-sm">
                  <SizeFormatter
                    {...getValueAndUnit(property_size, offer_type)}
                  />
                </div>
              ) : null}
            </div>
            <div className="mr-2 mt-1 flex h-6 flex-row">
              {showContact ? (
                <ContactNumberButton
                  className="px-1 py-0.5"
                  textClassName="text-xs"
                  offerId={id}
                  offerOriginalUrl={offer_original_url}
                  contactNumber={contact_number ?? undefined}
                  hasPrefix={!contact_number}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="absolute bottom-2 right-1 flex flex-row items-center">
          <OfferTypeIcon offerType={offer_type} size="md" showTooltip={true} />
          {activityCount !== undefined ? (
            <ActivityIcon
              count={activityCount}
              offerId={id}
              offerType={offer_type}
            />
          ) : null}
          {showMenu ? (
            <OfferCardMenu
              contactNumber={contact_number}
              id={id}
              strategy="absolute"
              offerType={offer_type}
              showStatuses={false}
              selectedOfferStatus={selectedOfferStatus}
              offerOriginalUrl={offer_original_url}
              showDownloadPdf={false}
            />
          ) : null}
        </div>
      </div>
    );
  },
);

export default OfferSmallCard;
