import { axios } from '../axios';
import {
  CreateMeetingRequest,
  GetMeetingResponse,
  ListMeetingsResponse,
  MeetingActivityLogForOffer,
  MeetingResponseStatus,
  UpdateMeetingRequest,
} from './meetings-dto';

export const getMeetingById = async ({
  meetingId,
}: {
  meetingId: string;
}): Promise<GetMeetingResponse> => {
  return axios.get(`/meetings/${meetingId}`).then((response) => response.data);
};

export const getMeetings = async ({
  page,
  size,
  createdById,
  assignedUserId,
  timeFrom,
  timeTo,
  offerId,
  responseStatus,
}: {
  page: number;
  size: number;
  createdById?: string;
  assignedUserId?: string;
  timeFrom?: Date;
  timeTo?: Date;
  offerId?: string;
  responseStatus?: MeetingResponseStatus;
}): Promise<ListMeetingsResponse> => {
  return axios
    .get('/meetings', {
      params: {
        page,
        size,
        created_by_id: createdById,
        assigned_user_id: assignedUserId,
        time_from: timeFrom,
        time_to: timeTo,
        linked_offer_id: offerId,
        response_status: responseStatus,
      },
    })
    .then((response) => response.data);
};

export const getMeetingsForActivity = async (
  offerId: string,
): Promise<MeetingActivityLogForOffer> => {
  return axios
    .get(`/meetings/offer-activity/${offerId}`)
    .then((response) => response.data);
};

export const putMeeting = async ({
  id,
  body,
}: {
  id: string;
  body: UpdateMeetingRequest;
}): Promise<ListMeetingsResponse> => {
  return axios.put(`/meetings/${id}`, body);
};

export const postMeeting = async (
  body: CreateMeetingRequest,
): Promise<ListMeetingsResponse> => {
  return axios.post('/meetings', body);
};

export const postMeetingFairAssignment = async (
  body: CreateMeetingRequest,
): Promise<ListMeetingsResponse> => {
  return axios.post('/meetings/fair-assignment', body);
};

export const deleteMeeting = async (id: string): Promise<void> => {
  return axios.delete(`/meetings/${id}`);
};

export const getAllowanceForFairMeetingAssignment = async (
  offerId: string,
): Promise<boolean> => {
  return axios
    .get(`/meetings/fair-assignment/can-create/offer/${offerId}`)
    .then((response) => response.data);
};

export const putMeetingFairAssignment = async ({
  id,
  body,
}: {
  id: string;
  body: UpdateMeetingRequest;
}): Promise<ListMeetingsResponse> => {
  return axios.put(`/meetings/fair-assignment/${id}`, body);
};
