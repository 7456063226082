import React from 'react';
import { OfferTypeEnum } from '../../api/api.types';
import FieldIcon from './field-icon';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export const OFFER_TYPE_BG: { [key in OfferTypeEnum]: string } = {
  LAND_SALES: 'bg-emerald-600',
  LAND_RENTS: 'bg-emerald-100',
  OFFICE_SALES: 'bg-red-600',
  OFFICE_RENTS: 'bg-red-100',
  INDUSTRIAL_BUILDING_SALES: 'bg-indigo-600',
  INDUSTRIAL_BUILDING_RENTS: 'bg-indigo-100',
  WAREHOUSE_SALES: 'bg-pink-600',
  WAREHOUSE_RENTS: 'bg-pink-100',
  COMMERCIAL_BUILDING_SALES: 'bg-amber-700',
  COMMERCIAL_BUILDING_RENTS: 'bg-amber-200',
  APARTMENT_SALES: 'bg-blue-600',
  APARTMENT_RENTS: 'bg-blue-100',
  HOUSE_SALES: 'bg-lime-600',
  HOUSE_RENTS: 'bg-lime-100',
  GARAGE_SALES: 'bg-gray-700',
  GARAGE_RENTS: 'bg-gray-100',
  ROOM_RENTS: 'bg-violet-100',
  AUCTIONS: 'bg-orange-600',
};

export const OFFER_TYPE_TEXT: { [key in OfferTypeEnum]: string } = {
  LAND_SALES: 'text-emerald-100',
  LAND_RENTS: 'text-emerald-600',
  OFFICE_SALES: 'text-red-100',
  OFFICE_RENTS: 'text-red-600',
  INDUSTRIAL_BUILDING_SALES: 'text-indigo-100',
  INDUSTRIAL_BUILDING_RENTS: 'text-indigo-600',
  WAREHOUSE_SALES: 'text-pink-100',
  WAREHOUSE_RENTS: 'text-pink-600',
  COMMERCIAL_BUILDING_SALES: 'text-amber-200',
  COMMERCIAL_BUILDING_RENTS: 'text-amber-700',
  APARTMENT_SALES: 'text-blue-100',
  APARTMENT_RENTS: 'text-blue-600',
  HOUSE_SALES: 'text-lime-100',
  HOUSE_RENTS: 'text-lime-600',
  GARAGE_SALES: 'text-gray-100',
  GARAGE_RENTS: 'text-gray-700',
  ROOM_RENTS: 'text-violet-600',
  AUCTIONS: 'text-orange-100',
};

export const OFFER_TYPE_COLORS: { [key in OfferTypeEnum]: string } = {
  LAND_SALES: '#059669',
  LAND_RENTS: '#059669',
  OFFICE_SALES: '#DC2626',
  OFFICE_RENTS: '#DC2626',
  INDUSTRIAL_BUILDING_SALES: '#4F46E5',
  INDUSTRIAL_BUILDING_RENTS: '#4F46E5',
  WAREHOUSE_SALES: '#DB2777',
  WAREHOUSE_RENTS: '#DB2777',
  COMMERCIAL_BUILDING_SALES: '#B45309',
  COMMERCIAL_BUILDING_RENTS: '#B45309',
  APARTMENT_SALES: '#2563EB',
  APARTMENT_RENTS: '#2563EB',
  HOUSE_SALES: '#FBBF24',
  HOUSE_RENTS: '#FBBF24',
  GARAGE_SALES: '#374151',
  GARAGE_RENTS: '#374151',
  ROOM_RENTS: '#7C3AED',
  AUCTIONS: '#EA580C',
};

interface Props {
  offerType: OfferTypeEnum;
  className?: string;
  size: 'sm' | 'md' | 'lg';
  showTooltip?: boolean;
}

const SIZE_CLASSES = {
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-6 w-6',
};

const OfferTypeIcon = ({
  offerType,
  className = '',
  size,
  showTooltip = true,
}: Props) => {
  const { t } = useTranslation('data-model');

  return (
    <>
      <div
        data-effect="solid"
        data-tip={t(`data-model:offerTypeLabels.${offerType}`)}
        className={`rounded-full ${OFFER_TYPE_BG[offerType]} ${
          size === 'lg' ? 'p-1.5' : 'p-1'
        } flex items-center justify-center ${className}`}
      >
        <FieldIcon
          fieldName={offerType.toLowerCase()}
          className={`${SIZE_CLASSES[size]} ${OFFER_TYPE_TEXT[offerType]}`}
        />
      </div>
      {showTooltip && <ReactTooltip />}
    </>
  );
};

export default OfferTypeIcon;
