import { OfferTypeEnum } from '../../api/api.types';
import { useMutation } from '@tanstack/react-query';
import { addOfferVariant } from '../../api/offer-variants/offer-variants';
import { getOfferByTypeAndId } from '../../api/offers/offers';
import { OFFER_VARIANT_FORM_DEFAULT_VALUES } from './form-data';
import {
  formToOfferSchema,
  offerDetailsToForm,
  offerImagesToForm,
} from './form-mappings';

interface Props {
  offerId: string;
  offerType: OfferTypeEnum;
}

export const useVariantCreationFromOffer = ({ offerId, offerType }: Props) => {
  const { mutateAsync: getOffer } = useMutation(() =>
    getOfferByTypeAndId(offerType, offerId),
  );
  const { mutateAsync: createOfferVariant } = useMutation(addOfferVariant);

  return async () => {
    const offer = await getOffer();
    const offerVariantData = {
      ...OFFER_VARIANT_FORM_DEFAULT_VALUES,
      ...offerDetailsToForm(offer),
    };
    const offerVariantImages = offerImagesToForm(offer);
    const response = await createOfferVariant({
      body: {
        original_offer_id: offerId,
        original_offer_type: offerType,
        variant_data: formToOfferSchema(offerVariantData, offerType),
        photos_data_urls: offerVariantImages.map((p) => p.dataURL!) as string[],
      },
    });
    return response.offer_variant_id;
  };
};
