import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from '../../style/class-names';
import { useTranslation } from 'react-i18next';
import { UserIcon, UsersIcon } from '@heroicons/react/20/solid';

const callListTabs = [
  {
    name: 'my-offers:menu.personal',
    to: '/my-offers/personal',
    icon: UserIcon,
  },
  {
    name: 'my-offers:menu.organization',
    to: '/my-offers/organization',
    icon: UsersIcon,
  },
];

const MyOffersNavigation = () => {
  const location = useLocation();
  const { t } = useTranslation(['my-offers']);
  return (
    <nav className="max-w-screen flex  space-x-8 overflow-auto lg:max-w-7xl">
      {callListTabs.map((tab) => (
        <Link
          key={tab.name}
          to={tab.to}
          className={classNames(
            location.pathname === tab.to
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'group inline-flex items-center border-b-2 px-2 py-4 text-sm font-medium',
          )}
        >
          <tab.icon
            className={classNames(
              location.pathname === tab.to
                ? 'text-indigo-500'
                : 'text-gray-400 group-hover:text-gray-500',
              '-ml-0.5 mr-2 h-5 w-5',
            )}
          />
          <span>{t(tab.name)}</span>
        </Link>
      ))}
    </nav>
  );
};

export default MyOffersNavigation;
