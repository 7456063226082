import React from 'react';

export const useElementHeight = (
  elementRef: React.RefObject<HTMLElement>,
  deps: any[] = [],
): number => {
  const [height, setHeight] = React.useState(getTotalHeight(elementRef));
  React.useEffect(() => {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        setHeight(getTotalHeight(elementRef));
      });
    }, 0);
  }, deps);
  return height;
};

export const useElementScrollHeight = (
  elementRef: React.RefObject<HTMLDivElement>,
  deps: any[] = [],
): number => {
  const [height, setHeight] = React.useState(getTotalScrollHeight(elementRef));
  React.useEffect(() => {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        setHeight(getTotalScrollHeight(elementRef));
      });
    }, 0);
  }, deps);
  return height;
};

function getTotalHeight(ref: React.RefObject<HTMLElement>): number {
  if (!ref.current) {
    return 0;
  }
  let style = window.getComputedStyle(ref.current);
  return ['height', 'padding-top', 'padding-bottom']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((prev, cur) => prev + cur);
}

function getTotalScrollHeight(ref: React.RefObject<HTMLElement>): number {
  if (!ref.current) {
    return 0;
  }
  let style = window.getComputedStyle(ref.current);
  return (
    ['padding-top', 'padding-bottom']
      .map((key) => parseInt(style.getPropertyValue(key), 10))
      .reduce((prev, cur) => prev + cur) + ref.current.scrollHeight
  );
}

export function getTotalHeightDOM(el?: HTMLElement): number {
  if (!el) {
    return 0;
  }
  let style = window.getComputedStyle(el);
  return ['height', 'padding-top', 'padding-bottom']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((prev, cur) => prev + cur);
}
