import { Popover, Transition } from '@headlessui/react';
import React from 'react';
import { usePopper } from 'react-popper';
import { classNames } from '../../../style/class-names';
import { AVMResult } from '../../../api/api.types';
import {
  calculatePropertyIndexValue,
  shouldHideIndex,
} from '../../../models/avm';
import { useTranslation } from 'react-i18next';

interface Props {
  avm: AVMResult;
  price_per_meter: number;
}

const OfferIndexBadge = ({ avm, price_per_meter }: Props) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });

  const index = calculatePropertyIndexValue(
    avm.predicted_price,
    price_per_meter,
  );
  const { t } = useTranslation(['offers']);

  return (
    <Popover className="inline-flex">
      <Popover.Button
        ref={setReferenceElement}
        className={classNames(
          index <= 0
            ? 'bg-emerald-100 text-emerald-800'
            : 'bg-red-100 text-red-800',
          'inline-flex items-baseline rounded px-1.5 py-0.5 text-xs font-medium uppercase',
        )}
      >
        <>
          {shouldHideIndex(index) ? (
            ''
          ) : index >= 0 ? (
            <span className="mr-1 font-bold">+</span>
          ) : (
            <span className="mr-1 font-bold">-</span>
          )}
          {shouldHideIndex(index)
            ? index > 0
              ? t('offers:estimatedMarketValue.shortInfo.highPriced')
              : t('offers:estimatedMarketValue.shortInfo.lowPriced')
            : `${Math.abs(index).toFixed(2)}%`}
        </>
      </Popover.Button>
      <div
        style={styles.popper}
        ref={setPopperElement}
        {...attributes.popper}
        className="z-30 w-64"
      >
        <Transition
          beforeEnter={() => setActive(true)}
          as={React.Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          afterLeave={() => setActive(false)}
        >
          <Popover.Panel>
            <div className="flex flex-col overflow-hidden rounded-lg bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5">
              {shouldHideIndex(index) ? (
                <div className="mb-1">
                  {t(
                    'offers:estimatedMarketValue.shortInfo.descriptionPriceBegin',
                  )}{' '}
                  <span
                    className={classNames(
                      index <= 0
                        ? 'bg-emerald-100 text-emerald-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex rounded px-1 text-sm font-medium',
                    )}
                  >
                    {index > 0
                      ? t('offers:estimatedMarketValue.shortInfo.priceAbove')
                      : t('offers:estimatedMarketValue.shortInfo.priceBelow')}
                  </span>{' '}
                  {t(
                    'offers:estimatedMarketValue.shortInfo.descriptionPriceEndNoInfo',
                  )}
                </div>
              ) : (
                <div className="mb-1">
                  {t(
                    'offers:estimatedMarketValue.shortInfo.descriptionPriceBegin',
                  )}{' '}
                  <span
                    className={classNames(
                      index <= 0
                        ? 'bg-emerald-100 text-emerald-800'
                        : 'bg-red-100 text-red-800',
                      'inline-flex rounded px-1 text-sm font-medium',
                    )}
                  >
                    {Math.abs(index).toFixed(2)}%{' '}
                  </span>{' '}
                  {index > 0
                    ? t('offers:estimatedMarketValue.shortInfo.priceAbove')
                    : t('offers:estimatedMarketValue.shortInfo.priceBelow')}
                  {t(
                    'offers:estimatedMarketValue.shortInfo.descriptionPriceEnd',
                  )}
                </div>
              )}
              <div className="ml-auto text-xs text-gray-600">
                Propertly Index <sup>©</sup>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default OfferIndexBadge;
