import React, { MutableRefObject } from 'react';
import { usePopper } from 'react-popper';
import { Popover, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import TextInput from '../text-input/text-input';
import DayPicker from 'react-day-picker';
import { format, isValid, parse } from 'date-fns';
import './date-time-input.css';
import 'react-day-picker/lib/style.css';
import '../calendar-input/calendar-input.css';
import { useUser } from '../../../context/auth/use-user';
import { useTranslation } from 'react-i18next';

export interface DateTimeInputProps {
  id: string;
  value: Date | null;
  onChange: (newValue: Date | null) => void;
  prefixIcon?: React.ReactNode;
  isError?: boolean;
  errorMessage?: string;
  saveErrorSpace?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

const DateTimeInput = ({
  value = null,
  id = '',
  onChange,
  isError = false,
  errorMessage = '',
  isDisabled = false,
  saveErrorSpace = false,
}: DateTimeInputProps) => {
  const formId = React.useId();
  const { t } = useTranslation(['common', 'forms']);
  const user = useUser();

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState(false);

  const [lastValidDate, setLastValidDate] = React.useState<Date | null>(value);
  const [inputText, setInputText] = React.useState('');
  const [dateText, setDateText] = React.useState('');
  const [timeText, setTimeText] = React.useState('');

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'eventListeners', enabled: active },
    ],
  });

  const updateTextValues = () => {
    setDateText(value ? format(value, user.settings.dateFormat) : '');
    setTimeText(value ? format(value, 'HH:mm') : '');
    setInputText(
      value ? format(value, `${user.settings.dateFormat} HH:mm`) : '',
    );
  };

  React.useEffect(() => {
    updateTextValues();
    setLastValidDate(value);
  }, [value]);

  const handleConfirm = (
    close: (
      focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>,
    ) => void,
  ) => {
    const finalDateText = dateText
      ? dateText
      : format(new Date(), user.settings.dateFormat);
    const finalTimeText = timeText ? timeText : '00:00';
    const parsedDate = parse(
      `${finalDateText} ${finalTimeText}`,
      `${user.settings.dateFormat} HH:mm`,
      new Date(),
    );

    onChange(isValid(parsedDate) ? parsedDate : lastValidDate);
    updateTextValues();
    setActive(false);
    close();
  };

  const handleInputValueSubmit = () => {
    const parsedDate = parse(
      inputText,
      `${user.settings.dateFormat} HH:mm`,
      new Date(),
    );
    onChange(isValid(parsedDate) ? parsedDate : lastValidDate);
    updateTextValues();
  };

  return (
    <Popover className="w-full">
      {({ close }) => (
        <>
          <div className="relative">
            {value && !isDisabled && (
              <span
                className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-1"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                }}
              >
                <XMarkIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            )}
            <Popover.Button
              as="input"
              ref={setReferenceElement}
              id={id}
              autoComplete="off"
              type="text"
              value={inputText}
              disabled={isDisabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputText(e.target.value)
              }
              onBlur={handleInputValueSubmit}
              className={`block w-full rounded-md shadow-sm focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm ${
                isError
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            <div
              style={styles.popper}
              ref={setPopperElement}
              {...attributes.popper}
              className="z-40"
            >
              <Transition
                beforeEnter={() => setActive(true)}
                as={React.Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                afterLeave={() => setActive(false)}
              >
                <Popover.Panel className="rounded-xl border bg-white">
                  <form
                    id={formId}
                    onSubmitCapture={(e) => {
                      e.preventDefault();
                      handleConfirm(close);
                    }}
                  >
                    <DayPicker
                      onDayClick={(value) => {
                        setDateText(format(value, user.settings.dateFormat));
                      }}
                      selectedDays={
                        dateText !== ''
                          ? parse(
                              dateText,
                              user.settings.dateFormat,
                              new Date(),
                            )
                          : undefined
                      }
                      firstDayOfWeek={1}
                      months={[
                        t('common:date.months.january'),
                        t('common:date.months.february'),
                        t('common:date.months.march'),
                        t('common:date.months.april'),
                        t('common:date.months.may'),
                        t('common:date.months.june'),
                        t('common:date.months.july'),
                        t('common:date.months.august'),
                        t('common:date.months.september'),
                        t('common:date.months.october'),
                        t('common:date.months.november'),
                        t('common:date.months.december'),
                      ]}
                      weekdaysShort={[
                        t('common:date.daysShort.sunday'),
                        t('common:date.daysShort.monday'),
                        t('common:date.daysShort.tuesday'),
                        t('common:date.daysShort.wednesday'),
                        t('common:date.daysShort.thursday'),
                        t('common:date.daysShort.friday'),
                        t('common:date.daysShort.saturday'),
                      ]}
                    />
                    <div className="-mt-2 flex content-end gap-2 p-2">
                      <div className="flex-1">
                        <TextInput
                          type="time"
                          value={timeText}
                          onChange={setTimeText}
                        />
                      </div>
                      <button
                        type="submit"
                        form={formId}
                        className="rounded-md bg-indigo-600 px-4 text-white"
                      >
                        {t('forms:datetimeInput.confirm')}
                      </button>
                    </div>
                  </form>
                </Popover.Panel>
              </Transition>
            </div>
          </div>
          {(saveErrorSpace || isError) && (
            <div
              className={`mt-2 text-xs text-red-600 ${
                saveErrorSpace ? 'whitespace-pre' : ''
              }`}
            >
              {isError ? errorMessage : ' '}
            </div>
          )}
        </>
      )}
    </Popover>
  );
};

export default DateTimeInput;
