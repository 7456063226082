import React from 'react';
import InputLabel, { InputLabelProps } from '../shared/input-label';
import LocationInput, { LocationInputProps } from './location-input';

export interface LocationInputLabeledProps
  extends LocationInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
}

const LocationInputLabeled = ({
  className,
  id,
  label,
  labelClassName,
  ...rest
}: LocationInputLabeledProps) => {
  return (
    <div className={className}>
      <InputLabel labelFor={id} label={label} labelClassName={labelClassName} />
      <LocationInput {...rest} id={id} />
    </div>
  );
};

export default LocationInputLabeled;
