import { SupportedLanguages } from '../i18n/languages';
import { DEFAULT_DATE_FORMAT } from '../constants/date-format';
import { Address } from './address';
import { IssuerTypeEnum } from '../api/api.types';
import { Sort } from './sort';

export interface UserSettings {
  readonly language: SupportedLanguages;
  readonly dateFormat: string;
  readonly showDuplicates: boolean;
  readonly fastSearch: boolean;
  readonly defaultLocation: Address | null;
  readonly itemsPerPage: number;
  readonly isSidebarSmall: boolean;
  readonly defaultIssuerType: IssuerTypeEnum[] | null;
  readonly defaultSorts: Sort[] | null;
}

export const DEFAULT_SETTINGS: UserSettings = {
  language: SupportedLanguages.PL,
  dateFormat: DEFAULT_DATE_FORMAT,
  showDuplicates: false,
  fastSearch: true,
  defaultLocation: null,
  itemsPerPage: 35,
  isSidebarSmall: false,
  defaultIssuerType: null,
  defaultSorts: null,
};
