import React from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

export interface TextInputProps {
  type?: string;
  value: string;
  onChange: (newVal: string) => void;
  autoComplete?: string;
  id?: string;
  isError?: boolean;
  errorMessage?: string;
  prefixIcon?: JSX.Element;
}

const PasswordInput = ({
  value = '',
  onChange,
  id,
  autoComplete,
  isError = false,
  errorMessage = '',
}: TextInputProps) => {
  const [isShown, setShown] = React.useState(false);

  return (
    <>
      <div className="relative">
        <input
          value={value}
          id={id}
          type={isShown ? 'text' : 'password'}
          autoComplete={autoComplete}
          onChange={(e) => onChange(e.target.value)}
          className={`block w-full rounded-md pr-10 shadow-sm focus:outline-none sm:text-sm ${
            isError
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
          }`}
        />
        <div className="absolute inset-y-0 right-0 mr-0.5 flex items-center pr-2">
          {isShown ? (
            <EyeSlashIcon
              className="h-6 w-6 cursor-pointer text-gray-400"
              onClick={() => setShown(false)}
            />
          ) : (
            <EyeIcon
              className="h-6 w-6 cursor-pointer text-gray-400"
              onClick={() => setShown(true)}
            />
          )}
        </div>
      </div>
      {isError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </>
  );
};

export default PasswordInput;
