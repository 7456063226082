import {
  GetOfferCollectionsMode,
  OfferCollectionIcon,
  OfferCollectionIconColor,
  OfferCollectionVisibility,
} from '../api/offer-collections/offer-collections';
import {
  BanknotesIcon,
  BellIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  EyeIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HeartIcon,
  HomeIcon,
  KeyIcon,
  LockClosedIcon,
  StarIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import { stringifyUrl } from 'query-string';
import React from 'react';

export const offerCollectionVisibilityToComponent = {
  [OfferCollectionVisibility.PRIVATE]: LockClosedIcon,
  [OfferCollectionVisibility.PUBLIC]: UserGroupIcon,
  [OfferCollectionVisibility.PUBLIC_READ_ONLY]: EyeIcon,
} as const;

export const offerCollectionIconToComponent = {
  [OfferCollectionIcon.HOME]: HomeIcon,
  [OfferCollectionIcon.BUILDING_OFFICE]: BuildingOfficeIcon,
  [OfferCollectionIcon.BANKNOTES]: BanknotesIcon,
  [OfferCollectionIcon.BUILDING_STOREFRONT]: BuildingStorefrontIcon,
  [OfferCollectionIcon.BRIEFCASE]: BriefcaseIcon,
  [OfferCollectionIcon.STAR]: StarIcon,
  [OfferCollectionIcon.HAND_THUMB_DOWN]: HandThumbDownIcon,
  [OfferCollectionIcon.HAND_THUMB_UP]: HandThumbUpIcon,
  [OfferCollectionIcon.BELL]: BellIcon,
  [OfferCollectionIcon.KEY]: KeyIcon,
  [OfferCollectionIcon.USER]: UserIcon,
  [OfferCollectionIcon.USERS]: UsersIcon,
  [OfferCollectionIcon.HEARTH]: HeartIcon,
} as const;

export const offerCollectionIconColorToTextClassName = {
  [OfferCollectionIconColor.RED]: 'text-rose-700',
  [OfferCollectionIconColor.ORANGE]: 'text-orange-600',
  [OfferCollectionIconColor.GREEN]: 'text-emerald-600',
  [OfferCollectionIconColor.BLUE]: 'text-sky-600',
  [OfferCollectionIconColor.PURPLE]: 'text-indigo-600',
  [OfferCollectionIconColor.YELLOW]: 'text-yellow-600',
  [OfferCollectionIconColor.PINK]: 'text-pink-600',
};

export const offerCollectionIconColorToBgClassName = {
  [OfferCollectionIconColor.RED]: 'bg-rose-50',
  [OfferCollectionIconColor.ORANGE]: 'bg-orange-50',
  [OfferCollectionIconColor.GREEN]: 'bg-emerald-50',
  [OfferCollectionIconColor.BLUE]: 'bg-sky-50',
  [OfferCollectionIconColor.PURPLE]: 'bg-indigo-50',
  [OfferCollectionIconColor.YELLOW]: 'bg-yellow-50',
  [OfferCollectionIconColor.PINK]: 'bg-pink-50',
};

export const generateOfferCollectionLink = ({ id }: { id: string }) => {
  return stringifyUrl({
    url: `/offer-collections/details/${id}`,
  });
};

export enum OfferCollectionsListState {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  NO_OFFER_SEARCHES_FOR_CURRENT_USER = 'NO_OFFER_SEARCHES_FOR_CURRENT_USER',
  NO_OFFER_SEARCHES_FOR_ORG = 'NO_OFFER_SEARCHES_FOR_ORG',
  DISPLAY = 'DISPLAY',
  ERROR = 'ERROR',
}

export const useOfferCollectionListState = <
  T extends { results: unknown[]; total_items_no_filter: number },
>({
  isLoading,
  page,
  mode,
  offerCollections,
}: {
  isLoading: boolean;
  page: number;
  mode: GetOfferCollectionsMode;
  offerCollections?: T;
}) => {
  const [state, setState] = React.useState(OfferCollectionsListState.LOADING);
  React.useEffect(() => {
    if (isLoading) {
      setState(OfferCollectionsListState.LOADING);
    } else if (offerCollections && offerCollections.results.length > 0) {
      setState(OfferCollectionsListState.DISPLAY);
    } else if (
      offerCollections &&
      offerCollections.total_items_no_filter === 0
    ) {
      setState(OfferCollectionsListState.NO_OFFER_SEARCHES_FOR_ORG);
    } else if (
      mode === GetOfferCollectionsMode.CREATED_BY_CURRENT_USER &&
      page === 1 &&
      offerCollections &&
      offerCollections.results.length === 0
    ) {
      setState(OfferCollectionsListState.NO_OFFER_SEARCHES_FOR_CURRENT_USER);
    } else if (offerCollections && offerCollections.results.length === 0) {
      setState(OfferCollectionsListState.EMPTY);
    } else {
      setState(OfferCollectionsListState.ERROR);
    }
  }, [state, mode, page, isLoading, offerCollections?.results?.length]);
  return state;
};
