import React from 'react';
import InputLabel, { InputLabelProps } from '../shared/input-label';

interface Props extends Omit<InputLabelProps, 'labelFor'> {
  value: boolean;
  onChange: (newValue: boolean) => void;
  className?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
  changeOnLabelClick?: boolean;
  saveErrorSpace?: boolean;
}

const CheckboxInputLabeled = ({
  label,
  labelClassName,
  value,
  onChange,
  id,
  className = '',
  isError,
  errorMessage,
  changeOnLabelClick = true,
  saveErrorSpace = false,
}: Props) => {
  return (
    <div className={`${className} flex flex-col`}>
      <div className="flex items-center">
        <input
          checked={Boolean(value)}
          onChange={() => onChange(!value)}
          type="checkbox"
          id={id}
          className="mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
        <InputLabel
          label={label}
          labelClassName={labelClassName}
          labelFor={id}
          onClick={() => {
            !changeOnLabelClick && onChange(!value);
          }}
        />
      </div>
      <div
        className={`text-xs text-red-600 ${
          saveErrorSpace ? 'mt-2 whitespace-pre' : ''
        }`}
      >
        {isError ? errorMessage : ' '}
      </div>
    </div>
  );
};

export default CheckboxInputLabeled;
