import React from 'react';
import { SmsCampaignState, SmsType } from '../../api/api.types';
import { useTranslation } from 'react-i18next';

const STYLES = {
  [SmsType.AUTOMATED]: 'bg-indigo-50 text-indigo-700 ring-indigo-600/20',
  [SmsType.MANUAL]: 'bg-blue-50 text-blue-700 ring-blue-600/20',
};
interface Props {
  smsType: SmsType;
}

const SmsCampaignStateBadge = ({ smsType }: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${STYLES[smsType]}`}
    >
      {t(`common:sms.smsType.${smsType}`)}
    </span>
  );
};

export default SmsCampaignStateBadge;
