import React from 'react';

export interface TextAreaInputProps {
  type?: string;
  value: string;
  onChange: (newVal: string) => void;
  autoComplete?: string;
  id?: string;
  isError?: boolean;
  errorMessage?: string;
  rows?: number;
  saveErrorSpace?: boolean;
}

const TextAreaInput = ({
  value = '',
  onChange,
  id,
  autoComplete,
  isError = false,
  errorMessage = '',
  rows,
  saveErrorSpace = false,
}: TextAreaInputProps) => {
  return (
    <>
      <textarea
        value={value}
        id={id}
        rows={rows}
        autoComplete={autoComplete}
        onChange={(e) => onChange(e.target.value)}
        className={`block w-full rounded-md shadow-sm focus:outline-none sm:text-sm ${
          isError
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
        }`}
      />
      {(saveErrorSpace || isError) && (
        <p
          className={`mt-2 text-xs text-red-600 ${
            saveErrorSpace ? 'whitespace-pre-wrap' : ''
          }`}
        >
          {isError ? errorMessage : ' '}
        </p>
      )}
    </>
  );
};

export default TextAreaInput;
