import React from 'react';
import { OfferTypeEnum } from '../../../api/api.types';
import OfferVariantParameterGrid from './offer-variant-parameter-grid';
import { getEnumOptions, schemaHasField } from '../../../schemas/schemas';
import TextInputLabeledController from '../../inputs/text-input/text-input-labeled-controller';
import SelectInputLabeledController from '../../inputs/select-input/select-input-labeled-controller';
import { useTranslation } from 'react-i18next';
import PricePerMeterSummary from './price-per-meter-summary';
import { ICONS } from '../../../ui-model/icons';
import { EXTRA_ICONS } from '../../../ui-model/extra-icons';
import OfferTypeIcon from '../../icons/offer-type-icon';
import {
  BUILDING_BOOLEAN_FIELDS,
  BUILDING_ENUM_FIELDS,
  BUILDING_TEXT_FIELDS,
  EXTRA_BOOLEAN_FIELDS,
  EXTRA_ENUM_FIELDS,
  LAND_ENUM_FIELDS,
  LAND_TEXT_FIELDS,
  LEGAL_BOOLEAN_FIELDS,
  LEGAL_ENUM_FIELDS,
  MEDIA_BOOLEAN_FIELDS,
  MEDIA_ENUM_FIELDS,
} from '../offer-variant-fields';

interface Props {
  offerType: OfferTypeEnum;
  control: any;
}

const OfferVariantParameters = ({ offerType, control }: Props) => {
  const { t } = useTranslation(['data-model', 'offers']);
  return (
    <div>
      <div className="mb-6">
        <div className="flex flex-row items-center border-b border-gray-200 pb-2">
          <OfferTypeIcon
            offerType={offerType}
            showTooltip={false}
            size="lg"
            className="mr-2 text-indigo-600"
          />
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            {t('offers:offerForm.parametersSection.basicInformation')}
          </h3>
        </div>
        <div className="mt-4 gap-x-4 gap-y-2 lg:grid lg:grid-cols-3">
          <TextInputLabeledController
            name="price"
            control={control}
            id="price"
            label={t('data-model:fieldLabels.price')}
            suffixIcon={'zł'}
            saveErrorSpace={true}
          />
          <TextInputLabeledController
            name="property_size"
            control={control}
            id="property_size"
            label={t('data-model:fieldLabels.property_size')}
            suffixIcon={'m²'}
            saveErrorSpace={true}
          />
          <PricePerMeterSummary control={control} />
          {schemaHasField(offerType, 'rent') && (
            <TextInputLabeledController
              name="rent"
              control={control}
              id="rent"
              label={t('data-model:fieldLabels.rent')}
              suffixIcon={'zł'}
              saveErrorSpace={true}
            />
          )}
          {schemaHasField(offerType, 'garden_size') && (
            <TextInputLabeledController
              name="garden_size"
              control={control}
              id="garden_size"
              label={t('data-model:fieldLabels.garden_size')}
              suffixIcon={'m²'}
              saveErrorSpace={true}
            />
          )}
          <TextInputLabeledController
            name="rooms"
            control={control}
            id="rooms"
            label={t('data-model:fieldLabels.rooms')}
            saveErrorSpace={true}
          />
          <SelectInputLabeledController
            name="market_type"
            options={getEnumOptions('market_type', t)}
            control={control}
            id="market_type"
            label={t('data-model:fieldLabels.market_type')}
            saveErrorSpace={true}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-6">
        <OfferVariantParameterGrid
          header={t('offers:offerForm.parametersSection.buildingInformation')}
          offerType={offerType}
          control={control}
          icon={ICONS.building_type}
          enumFields={BUILDING_ENUM_FIELDS}
          textFields={BUILDING_TEXT_FIELDS}
          booleanFields={BUILDING_BOOLEAN_FIELDS}
        />

        <OfferVariantParameterGrid
          header={t('offers:offerForm.parametersSection.landInformation')}
          offerType={offerType}
          control={control}
          className="mt-4"
          icon={EXTRA_ICONS.land}
          enumFields={LAND_ENUM_FIELDS}
          textFields={LAND_TEXT_FIELDS}
        />
        <OfferVariantParameterGrid
          header={t(
            'offers:offerForm.parametersSection.legalStatusInformation',
          )}
          offerType={offerType}
          control={control}
          icon={ICONS.ownership_type}
          booleanFields={LEGAL_BOOLEAN_FIELDS}
          enumFields={LEGAL_ENUM_FIELDS}
        />
        <OfferVariantParameterGrid
          header={t('offers:offerForm.parametersSection.mediaInformation')}
          icon={ICONS.has_electricity_access}
          offerType={offerType}
          control={control}
          booleanFields={MEDIA_BOOLEAN_FIELDS}
          enumFields={MEDIA_ENUM_FIELDS}
        />
        <OfferVariantParameterGrid
          header={t('offers:offerForm.parametersSection.additionalInformation')}
          offerType={offerType}
          control={control}
          icon={ICONS.has_balcony}
          booleanFields={EXTRA_BOOLEAN_FIELDS}
          enumFields={EXTRA_ENUM_FIELDS}
        />
      </div>
    </div>
  );
};

export default OfferVariantParameters;
