import { OfferTypeEnum } from '../../api/api.types';
import React from 'react';
import { ANALYTICS_EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../context/analytics/use-analytics';
import {
  getExportedOfferPdfById,
  getOfferPdfByTypeAndId,
  getOfferVariantPdfById,
} from '../../api/pdf/pdf';
import { toast } from 'react-hot-toast';
import ErrorToast from '../../components/toasts/error-toast';
import { useTranslation } from 'react-i18next';

interface Props {
  offerType?: OfferTypeEnum;
  offerId?: string;
  offerVariantId?: string;
  exportedOfferId?: string;
  fileName: string;
}

export const useOfferPdfDownload = ({
  offerType,
  offerId,
  exportedOfferId,
  offerVariantId,
  fileName,
}: Props) => {
  const analytics = useAnalytics();

  return usePdfDownload({
    pdfDataFetcher: () =>
      offerVariantId
        ? getOfferVariantPdfById(offerVariantId)
        : exportedOfferId
        ? getExportedOfferPdfById(exportedOfferId)
        : getOfferPdfByTypeAndId(offerType!, offerId!),
    fileName,
    onStart: () =>
      analytics.track(ANALYTICS_EVENTS.PDF_DOWNLOAD_START, {
        offerType,
        offerId,
        exportedOfferId,
        offerVariantId,
        fileName,
      }),
    onFailure: () =>
      analytics.track(ANALYTICS_EVENTS.PDF_DOWNLOAD_ERROR, {
        offerType,
        offerId,
        exportedOfferId,
        offerVariantId,
        fileName,
      }),
    onSuccess: () =>
      analytics.track(ANALYTICS_EVENTS.PDF_DOWNLOAD_SUCCESS, {
        offerType,
        offerId,
        exportedOfferId,
        offerVariantId,
        fileName,
      }),
  });
};

export const usePdfDownload = ({
  pdfDataFetcher,
  fileName,
  onStart,
  onSuccess,
  onFailure,
}: {
  onStart?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
  pdfDataFetcher: () => Promise<any>;
  fileName: string;
}) => {
  const { t } = useTranslation(['common']);
  const [pdfData, setPdfData] = React.useState<BlobPart>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  return {
    isLoading,
    isError,
    download: () => {
      if (isLoading) {
        return;
      }
      if (pdfData) {
        saveAsPdf(pdfData, fileName);
      } else {
        setIsLoading(true);
        pdfDataFetcher()
          .then((data) => {
            setPdfData(data);
            saveAsPdf(data, fileName);
            setIsError(false);
            onSuccess?.();
          })
          .catch(() => {
            setIsError(true);
            onFailure?.();
            toast.custom((toast) => (
              <ErrorToast
                {...toast}
                title={t('common:pdfDownloadError.title')}
                body={t('common:pdfDownloadError.description')}
              />
            ));
          })
          .finally(() => {
            setIsLoading(false);
          });
        onStart?.();
      }
    },
  };
};

function saveAsPdf(data: any, name: string = 'export.pdf') {
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(fileURL);
}
