import React from 'react';
import TextInput, { TextInputProps } from './text-input';
import InputLabel, { InputLabelProps } from '../shared/input-label';

interface TextInputLabeledProps
  extends TextInputProps,
    Omit<InputLabelProps, 'labelFor'> {
  className?: string;
  id: string;
  isError?: boolean;
  errorMessage?: string;
}

const TextInputLabeled = ({
  className = '',
  label,
  labelClassName,
  id,
  isRequired,
  ...rest
}: TextInputLabeledProps) => {
  return (
    <div className={className}>
      <InputLabel
        labelFor={id}
        label={label}
        labelClassName={labelClassName}
        isRequired={isRequired}
      />
      <TextInput {...rest} />
    </div>
  );
};

export default TextInputLabeled;
