import React from 'react';
import { formatPhone } from '../../constants/phone-formatter';
import { formatDistanceToNow } from 'date-fns';
import { DATE_FNS_LOCALES, SupportedLanguages } from '../../i18n/languages';
import { IncomingSmsResponse } from '../../api/api.types';
import { useTranslation } from 'react-i18next';

const MESSAGE_CLASSES = {
  IS_EXPIRED: 'bg-red-50 text-red-700 ring-red-600/20',
  IS_ACTIVE: 'bg-green-50 text-green-700 ring-green-600/20',
  NO_AGENCY: 'bg-red-50 text-red-700 ring-red-600/20',
  DETAIL_PROVIDED: 'bg-green-50 text-green-700 ring-green-600/20',
  PLEASE_CONTACT: 'bg-green-50 text-green-700 ring-green-600/20',
  QUESTION: 'bg-green-50 text-green-700 ring-green-600/20',
  PLEASE_CONTACT_CITO: 'bg-green-50 text-green-700 ring-green-600/20',
};

interface Props {
  response: IncomingSmsResponse;
  receiverPhoneNumber: string;
}

const IncomingSms = ({ receiverPhoneNumber, response }: Props) => {
  const { i18n, t } = useTranslation(['common']);
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <h3 className="font-medium text-gray-900">
          {formatPhone(receiverPhoneNumber)}
        </h3>
        <p className="ml-auto">
          <time dateTime={response.created_at}>
            {formatDistanceToNow(new Date(response.created_at), {
              locale: DATE_FNS_LOCALES[i18n.language as SupportedLanguages],
              addSuffix: true,
            })}
          </time>
        </p>
      </div>
      <div className="prose prose-sm mt-2 max-w-full break-words text-gray-500">
        {response.message}
      </div>
      {response.message_classification && (
        <div className="ml-auto mt-1">
          <span
            className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${
              MESSAGE_CLASSES[response.message_classification]
            }`}
          >
            {t(
              `common:sms.smsClassification.${response.message_classification}`,
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default IncomingSms;
