import {
  Rectangle,
  Dimensions,
  RectangleWithMetadata,
} from './use-image-selection';
import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  KeyboardKey,
  useKeyPressed,
} from '../../../hooks/use-keyboard/use-key-pressed';
import ReactTooltip from 'react-tooltip';
import { BiImageAdd } from 'react-icons/all';
import { useTranslation } from 'react-i18next';

interface Props {
  rectangle: RectangleWithMetadata;
  margins: Dimensions;
  imageDimensions: Dimensions;
  removeRectangle: (rectangle: Rectangle, forAllImages: boolean) => void;
  copyRectangleToAll: (rectangle: Rectangle) => void;
  removeOtodom: () => void;
  removeSprzedajemy: () => void;
  removeOkolica: () => void;
}

const ImagePartSelectionButtons = ({
  rectangle,
  margins,
  imageDimensions,
  removeRectangle,
  copyRectangleToAll,
  removeOtodom,
  removeSprzedajemy,
  removeOkolica,
}: Props) => {
  const { t } = useTranslation('client-searches');
  const { x, y, height } = rectangle;
  const marginLeft = margins.width - 4; // 4px because there is outline
  const marginTop = margins.height - 4; // 4px because there is outline
  const { width: imageWidth, height: imageHeight } = imageDimensions;
  const { x: deltaX, y: deltaY } = getButtonsTranslation({
    x,
    y,
    selectionHeight: height,
    totalHeight: imageHeight,
    totalWidth: imageWidth,
  });
  const isShiftPressed = useKeyPressed({ key: KeyboardKey.Shift });
  const removeButtonId = React.useId();
  const copyButtonId = React.useId();

  if (rectangle.isOtodom || rectangle.isSprzedajemy || rectangle.isOkolica) {
    return (
      <>
        <button
          key={removeButtonId}
          style={{
            left: x + marginLeft + deltaX,
            top: y + marginTop + deltaY,
          }}
          onClick={() => {
            if (isShiftPressed) {
              if (rectangle.isSprzedajemy) {
                removeSprzedajemy();
              } else if (rectangle.isOtodom) {
                removeOtodom();
              } else if (rectangle.isOkolica) {
                removeOkolica();
              }
            } else {
              removeRectangle(rectangle, false);
            }
          }}
          data-for={`for${removeButtonId}`}
          data-tip
          type="button"
          className={`absolute rounded-md border-2 bg-white p-1 hover:bg-gray-100 active:bg-gray-200 ${
            isShiftPressed
              ? 'border-pink-600 text-pink-600'
              : 'border-indigo-600 text-indigo-600'
          }`}
        >
          <XMarkIcon className="h-5 w-5" />
          <span className="sr-only">
            {isShiftPressed
              ? t(
                  'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectAll',
                )
              : t(
                  'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectCurrent',
                )}
          </span>
        </button>
        <ReactTooltip key={`for${removeButtonId}`} id={`for${removeButtonId}`}>
          {isShiftPressed
            ? t(
                'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectAll',
              )
            : t(
                'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectCurrent',
              )}
        </ReactTooltip>
      </>
    );
  }

  return (
    <>
      <button
        key={removeButtonId}
        style={{
          left: x + marginLeft + deltaX,
          top: y + marginTop + deltaY,
        }}
        onClick={() => removeRectangle(rectangle, isShiftPressed)}
        data-for={`for${removeButtonId}`}
        data-tip
        type="button"
        className={`absolute rounded-md border-2 bg-white p-1 hover:bg-gray-50 active:bg-gray-100 ${
          isShiftPressed
            ? 'border-pink-600 text-pink-600'
            : 'border-indigo-600 text-indigo-600'
        }`}
      >
        <XMarkIcon className="h-5 w-5" />
        <span className="sr-only">
          {isShiftPressed
            ? t(
                'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectAll',
              )
            : t(
                'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectCurrent',
              )}
        </span>
      </button>
      <button
        key={copyButtonId}
        style={{
          left: x + marginLeft + deltaX + 36,
          top: y + marginTop + deltaY,
        }}
        onClick={() => copyRectangleToAll(rectangle)}
        data-for={`for${copyButtonId}`}
        data-tip
        type="button"
        className=" hover:bg-gray-10 absolute rounded-md border-2 border-indigo-600 bg-white p-1 text-indigo-600 active:bg-gray-200"
      >
        <BiImageAdd className="h-5 w-5" />
        <span className="sr-only">
          {t(
            'client-searches:clientSearchDetails.watermarkRemoval.tooltip.copyAll',
          )}
        </span>
      </button>
      <ReactTooltip key={`for${removeButtonId}`} id={`for${removeButtonId}`}>
        {isShiftPressed
          ? t(
              'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectAll',
            )
          : t(
              'client-searches:clientSearchDetails.watermarkRemoval.tooltip.deselectCurrent',
            )}
      </ReactTooltip>
      <ReactTooltip key={`for${copyButtonId}`} id={`for${copyButtonId}`}>
        {t(
          'client-searches:clientSearchDetails.watermarkRemoval.tooltip.copyAll',
        )}
      </ReactTooltip>
    </>
  );
};

export default ImagePartSelectionButtons;

const getButtonsTranslation = ({
  x,
  y,
  selectionHeight,
  totalWidth,
  totalHeight,
}: {
  x: number;
  y: number;
  selectionHeight: number;
  totalWidth: number;
  totalHeight: number;
}) => {
  const mid = totalHeight / 2;
  const buttonStartHeight = mid - 20;
  const buttonEndHeight = mid + 60;
  if (y < 38 && totalWidth - x < 76) {
    // Top right corner
    return {
      x: -4 - 4 - 32 - 32,
      y: 4,
    };
  }
  if (y < 50) {
    // Top corner
    return {
      x: 4,
      y: 4,
    };
  }
  if (y >= buttonStartHeight && y <= buttonEndHeight) {
    // Can overlap with next/preview button

    if (x < 76) {
      // Left side
      return {
        x: 4,
        y: selectionHeight + 4,
      };
    }

    if (totalWidth - x < 76) {
      // Right side
      return {
        x: -4 - 4 - 32 - 32,
        y: selectionHeight + 4,
      };
    }
  }
  if (totalWidth - x < 76) {
    // Left corner
    return {
      x: -4 - 4 - 32 - 32,
      y: -4 - 32,
    };
  }
  // Default
  return {
    x: 4,
    y: -4 - 32,
  };
};
