import { axios } from '../axios';
import { OfferListResponse, UserPublicResponse } from '../api.types';

export enum OfferCollectionVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  PUBLIC_READ_ONLY = 'PUBLIC_READ_ONLY',
}

export enum OfferCollectionIcon {
  HOME = 'HOME',
  BUILDING_OFFICE = 'BUILDING_OFFICE',
  BANKNOTES = 'BANKNOTES',
  BUILDING_STOREFRONT = 'BUILDING_STOREFRONT',
  BRIEFCASE = 'BRIEFCASE',
  USER = 'USER',
  USERS = 'USERS',
  STAR = 'STAR',
  HAND_THUMB_DOWN = 'HAND_THUMB_DOWN',
  HAND_THUMB_UP = 'HAND_THUMB_UP',
  BELL = 'BELL',
  KEY = 'KEY',
  HEARTH = 'HEARTH',
}

export enum OfferCollectionIconColor {
  RED = 'RED',
  PINK = 'PINK',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  YELLOW = 'YELLOW',
}

interface OfferCollectionBase {
  offer_collection_id: string;
  organization_id: string;
  created_by_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  description?: string | null;
  visibility: OfferCollectionVisibility;
  icon: OfferCollectionIcon;
  icon_color: OfferCollectionIconColor;
}

export interface OfferCollectionItem extends OfferCollectionBase {
  created_by: UserPublicResponse;
}

export interface OfferCollectionOfferInclusion {
  offer_id: string;
  is_included: boolean;
}

export interface OfferCollectionWithOfferInclusionItem
  extends OfferCollectionItem {
  offer_inclusion: OfferCollectionOfferInclusion;
}

interface CreateOfferCollectionBody {
  name: string;
  description: string | null;
  visibility: OfferCollectionVisibility;
  icon?: OfferCollectionIcon;
  icon_color?: OfferCollectionIconColor;
}

export const createOfferCollection = (body: CreateOfferCollectionBody) => {
  return axios
    .post<{ offer_collection_id: string }>(`/offer-collections`, body)
    .then((resp) => resp.data);
};

interface EditOfferCollectionBody extends CreateOfferCollectionBody {
  id: string;
}

export const updateOfferCollection = ({
  id,
  ...body
}: EditOfferCollectionBody) => {
  return axios.post(`/offer-collections/${id}`, body).then((resp) => resp.data);
};

export enum GetOfferCollectionsMode {
  CREATED_BY_CURRENT_USER = 'CREATED_BY_CURRENT_USER',
  CREATED_BY_USER = 'CREATED_BY_USER',
  CREATED_NOT_BY_CURRENT_USER = 'CREATED_NOT_BY_CURRENT_USER',
  EDITABLE_BY_CURRENT_USER = 'EDITABLE_BY_USER',
  MANAGEABLE_CONTENT_BY_CURRENT_USER = 'MANAGEABLE_CONTENT_BY_CURRENT_USER',
  VIEWABLE_BY_CURRENT_USER = 'VIEWABLE_BY_CURRENT_USER',
}

export enum GetOfferCollectionsSort {
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

interface GetOfferCollectionsSearchParams {
  size: number;
  page: number;
  mode: GetOfferCollectionsMode;
  created_by_id?: string;
  sort?: GetOfferCollectionsSort;
}

interface GetOfferCollectionsResponse {
  results: OfferCollectionItem[];
  page: number;
  total_items: number;
  total_pages: number;
  total_items_no_filter: number;
}

export const getOfferCollections = (
  searchParams: GetOfferCollectionsSearchParams,
) => {
  return axios
    .get<GetOfferCollectionsResponse>(`/offer-collections`, {
      params: searchParams,
    })
    .then((resp) => resp.data);
};

interface GetOfferCollectionsWithOfferInclusionProps
  extends GetOfferCollectionsSearchParams {
  offerId: string;
}

interface GetOfferCollectionsWithOfferInclusionResponse {
  results: OfferCollectionWithOfferInclusionItem[];
  page: number;
  total_items: number;
  total_pages: number;
  total_items_no_filter: number;
}

export const getOfferCollectionsWithOfferInclusion = ({
  offerId,
  ...searchParams
}: GetOfferCollectionsWithOfferInclusionProps) => {
  return axios
    .get<GetOfferCollectionsWithOfferInclusionResponse>(
      `/offer-collections/with-offer-inclusion/${offerId}`,
      {
        params: searchParams,
      },
    )
    .then((resp) => resp.data);
};

export const getOfferCollectionsCountForOffer = ({
  offerId,
}: {
  offerId: string;
}) => {
  return axios
    .get<{ count: number }>(`/offer-collections/count-for-offer/${offerId}`)
    .then((resp) => resp.data);
};

export const putOfferToOfferCollection = ({
  offerId,
  offerCollectionId,
}: {
  offerId: string;
  offerCollectionId: string;
}) => {
  return axios.put(`/offer-collections/${offerCollectionId}/add/${offerId}`);
};

export const removeOfferFromOfferCollection = ({
  offerId,
  offerCollectionId,
}: {
  offerId: string;
  offerCollectionId: string;
}) => {
  return axios.delete(
    `/offer-collections/${offerCollectionId}/remove/${offerId}`,
  );
};

export const getOfferCollection = ({
  offerCollectionId,
}: {
  offerCollectionId: string;
}) => {
  return axios
    .get<OfferCollectionItem>(`/offer-collections/details/${offerCollectionId}`)
    .then((resp) => resp.data);
};

export const getOfferCollectionMembers = ({
  offerCollectionId,
  size,
  page,
}: {
  offerCollectionId: string;
  size: number;
  page: number;
}) => {
  return axios
    .get<OfferListResponse>(`/offer-collections/members/${offerCollectionId}`, {
      params: { size, page },
    })
    .then((resp) => resp.data);
};

export const removeOfferCollection = ({
  offerCollectionId,
}: {
  offerCollectionId: string;
}) => {
  return axios.delete(`/offer-collections/delete/${offerCollectionId}`);
};
