import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import notFound from '../../assets/image-not-found.png';
import { Navigation, Pagination, Virtual } from 'swiper';
import { useMd } from '../../style/media-queries';
import OfferImageViewer from './offer-image-viewer';
import SliderImage from './slider-image';
import { removeDuplicates } from '../../utils/remove-duplicates';
import { convertDownloadedImagesToSize } from '../../utils/convert-downloaded-image-to-size';
import './swiper-styles.css';

interface Props {
  images: string[];
  isOriginal?: boolean;
  enableViewer?: boolean;
}

const OfferImagesSlider = ({
  images,
  isOriginal = true,
  enableViewer = true,
}: Props) => {
  const style = {
    '--swiper-navigation-color': '#fff',
    '--swiper-pagination-color': '#fff',
    '--swiper-navigation-size': '28px',
  } as any;

  const [isViewerOpen, setViewerOpen] = React.useState(false);

  const isMd = useMd();
  const uniqueImages = removeDuplicates(images);

  const canTransform = isOriginal && uniqueImages.length !== 0;

  const shownImages = uniqueImages.length === 0 ? [notFound] : uniqueImages;

  return (
    <div className="absolute h-full w-full">
      <Swiper
        className="swiper-small h-full w-full"
        style={style}
        spaceBetween={30}
        observeParents
        observer
        pagination={
          isMd
            ? false
            : {
                clickable: false,
                dynamicBullets: true,
              }
        }
        navigation
        virtual
        modules={[Navigation, Virtual, Pagination]}
      >
        {(canTransform
          ? convertDownloadedImagesToSize(shownImages, 'md')
          : shownImages
        ).map((img, idx) => (
          <SwiperSlide key={img} virtualIndex={idx}>
            <SliderImage
              src={img}
              alt={`${img}-${idx}`}
              loading="lazy"
              onClick={() => setViewerOpen(true)}
              key={img}
            />
          </SwiperSlide>
        ))}
        {enableViewer && (
          <OfferImageViewer
            canTransform={canTransform}
            images={shownImages}
            open={isViewerOpen}
            close={() => setViewerOpen(false)}
          />
        )}
      </Swiper>
    </div>
  );
};

export default OfferImagesSlider;
