import React from 'react';

const VARIANT_TO_STYLE_MAP = {
  'extra-large': 'text-base lg:text-lg text-gray-700 font-semibold leading-6',
  large: 'text-base lg:text-lg text-gray-700 leading-6',
  normal: 'text-sm lg:text-base text-gray-700 leading-6',
  small: 'text-xs lg:text-sm text-gray-700 leading-6',
  'extra-small': 'text-xs text-gray-600 leading-5',
};

interface Props {
  variant?: 'extra-large' | 'large' | 'normal' | 'small' | 'extra-small';
  Component?: 'p';
  className?: string;
  children?: JSX.Element | string;
}

export const Paragraph = ({
  Component = 'p',
  variant = 'normal',
  children,
  className = '',
}: Props) => {
  return (
    <Component className={`${VARIANT_TO_STYLE_MAP[variant]} ${className}`}>
      {children}
    </Component>
  );
};
