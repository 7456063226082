import { useUser } from '../../../context/auth/use-user';
import { format } from 'date-fns';
import { DATE_FNS_LOCALES } from '../../../i18n/languages';

interface Props {
  from: Date;
  to: Date;
}

const TimeRangeFormat = ({ from, to }: Props) => {
  const user = useUser();

  const fromFormattedDate = format(from, user.settings.dateFormat);
  const fromFormattedTime = format(from, 'p', {
    locale: DATE_FNS_LOCALES[user.settings.language],
  });
  const toFormattedDate = format(to, user.settings.dateFormat);
  const toFormattedTime = format(to, 'p', {
    locale: DATE_FNS_LOCALES[user.settings.language],
  });

  if (to < from) {
    return null;
  }

  if (from.getTime() === to.getTime()) {
    return (
      <span>
        {fromFormattedDate} {fromFormattedTime}
      </span>
    );
  }

  if (from.toDateString() === to.toDateString()) {
    return (
      <span>{`${fromFormattedDate} ${fromFormattedTime} – ${toFormattedTime}`}</span>
    );
  }

  return (
    <span>{`${fromFormattedDate} ${fromFormattedTime} – ${toFormattedDate} ${toFormattedTime}`}</span>
  );
};

export default TimeRangeFormat;
