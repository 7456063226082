import React from 'react';
import { useImage } from 'react-image';
import notFound from '../../assets/image-not-found.png';

export interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;
  alt: string;
  fallbackImages?: string[];
}

const Img = ({ src, alt, fallbackImages = [], ...rest }: Props) => {
  const { src: imageSrc } = useImage({
    srcList: [src, ...fallbackImages, notFound],
    useSuspense: true,
  });

  return <img alt={alt} src={imageSrc} {...rest} />;
};

interface ImageProps extends Props {
  placeholderClassName?: string;
}

export const ImageWithPlaceholder = ({
  placeholderClassName,
  ...rest
}: ImageProps) => {
  return (
    <ErrorBoundary fallback={<img {...rest} src={notFound} alt={rest.alt} />}>
      <React.Suspense
        fallback={
          <div
            className={`animate-pulse bg-gray-300 ${placeholderClassName}`}
          />
        }
      >
        <Img {...rest} />
      </React.Suspense>
    </ErrorBoundary>
  );
};

class ErrorBoundary extends React.Component<
  { fallback: React.ReactNode; children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { fallback: React.ReactNode; children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ImageWithPlaceholder;
