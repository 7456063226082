import React from 'react';

export interface Dimensions {
  width: number;
  height: number;
}

export interface Rectangle extends Dimensions {
  x: number;
  y: number;
}

export interface RectangleWithMetadata extends Rectangle {
  isOtodom?: boolean;
  isSprzedajemy?: boolean;
  isOkolica?: boolean;
}

interface Props {
  initialUrl: string;
  imageUrls: string[];
  onImagesDimensionsLoaded?: (allSucceeded: boolean) => void;
  isEnabled?: boolean;
}

export const useImageSelection = ({
  imageUrls,
  initialUrl,
  onImagesDimensionsLoaded,
  isEnabled = false,
}: Props) => {
  const [urlToDimensions, setUrlToDimensions] = React.useState<
    Record<string, Dimensions>
  >({});
  React.useEffect(() => {
    if (!isEnabled) {
      return;
    }

    setUrlToDimensions({});
    let isImageUrlsCurrent = true;

    const promises: Promise<void>[] = [];
    for (const url of imageUrls) {
      const promise = getImgDimensions(url).then((dimensions) => {
        if (!isImageUrlsCurrent) return;
        setUrlToDimensions((current) => ({ ...current, [url]: dimensions }));
      });
      promises.push(promise);
    }

    Promise.allSettled(promises).then((results) => {
      if (!isImageUrlsCurrent) return;
      const allSucceeded = results.every(
        (promiseResult) => promiseResult.status === 'fulfilled',
      );
      onImagesDimensionsLoaded?.(allSucceeded);
    });

    return () => {
      isImageUrlsCurrent = false;
    };
  }, [imageUrls, isEnabled]);

  const scaleFunctionBuilder = (from: Dimensions, to: Dimensions) => {
    const xScale = from.width / to.width;
    const yScale = from.height / to.height;
    return <T extends Rectangle>(rectangle: T) => {
      const x = rectangle.x * xScale;
      const y = rectangle.y * yScale;
      const width = rectangle.width * xScale;
      const height = rectangle.height * yScale;
      return { ...rectangle, x, y, width, height };
    };
  };

  const [currentUrl, setCurrentUrl] = React.useState(() =>
    imageUrls.includes(initialUrl) ? initialUrl : imageUrls[0],
  );
  const photo = React.useMemo(() => {
    const currentIndex = imageUrls.indexOf(currentUrl);
    return {
      url: currentUrl,
      canMoveLeft: currentIndex !== 0,
      canMoveRight: currentIndex !== imageUrls.length - 1,
      moveLeft: () => {
        if (currentIndex !== 0) {
          setCurrentUrl(imageUrls[currentIndex - 1]);
        }
      },
      moveRight: () => {
        if (currentIndex !== imageUrls.length - 1) {
          setCurrentUrl(imageUrls[currentIndex + 1]);
        }
      },
    };
  }, [imageUrls, currentUrl]);
  React.useEffect(() => {
    setCurrentUrl(initialUrl);
  }, [initialUrl]);

  const [urlToRectangles, setUrlToRectangles] = React.useState<
    Record<string, RectangleWithMetadata[]>
  >({});
  React.useEffect(() => {
    const newRectangles: Record<string, Rectangle[]> = {};
    for (const url of imageUrls) {
      if (url in urlToRectangles) {
        newRectangles[url] = urlToRectangles[url];
      } else {
        newRectangles[url] = [];
      }
    }
    setUrlToRectangles(newRectangles);
  }, [imageUrls]);

  return {
    photo,
    rectangles: {
      photosWithRectangleCount: Object.values(urlToRectangles).reduce(
        (count, rectangles) => count + (rectangles.length > 0 ? 1 : 0),
        0,
      ),
      data: urlToRectangles,
      add: (rectangle: Rectangle) => {
        const override: Record<string, Rectangle[]> = {
          [currentUrl]: [...urlToRectangles[currentUrl], { ...rectangle }],
        };
        setUrlToRectangles((current) => ({ ...current, ...override }));
      },
      addOkolica: () => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          const dimensions = urlToDimensions[url];
          const width = dimensions.width * 0.15;
          const height = width * 0.32;
          const offsetBottom = dimensions.width * 0.003;
          const offsetLeft = dimensions.width * 0.006;
          const rectangle: RectangleWithMetadata = {
            x: offsetLeft,
            y: dimensions.height - offsetBottom - height,
            width,
            height,
            isOkolica: true,
          };
          override[url] = [
            ...urlToRectangles[url].filter(
              (current) => !rectangleEqual(current, rectangle),
            ),
            { ...rectangle },
          ];
        }
        setUrlToRectangles(override);
      },
      removeOkolica: () => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          override[url] = [
            ...urlToRectangles[url].filter((current) => !current.isOkolica),
          ];
        }
        setUrlToRectangles((current) => ({ ...current, ...override }));
      },
      addOtodom: () => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          const dimensions = urlToDimensions[url];
          const width = dimensions.width * 0.15;
          const height = width * 0.22;
          const offset = dimensions.height * 0.007;
          const rectangle: RectangleWithMetadata = {
            x: offset,
            y: dimensions.height - offset - height,
            width,
            height,
            isOtodom: true,
          };
          override[url] = [
            ...urlToRectangles[url].filter(
              (current) => !rectangleEqual(current, rectangle),
            ),
            { ...rectangle },
          ];
        }
        setUrlToRectangles(override);
      },
      removeOtodom: () => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          override[url] = [
            ...urlToRectangles[url].filter((current) => !current.isOtodom),
          ];
        }
        setUrlToRectangles((current) => ({ ...current, ...override }));
      },
      addSprzedajemy: () => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          const dimensions = urlToDimensions[url];
          const width = 180;
          const height = 45;
          const offset = 15;
          const rectangle: RectangleWithMetadata = {
            x: dimensions.width - offset - width,
            y: dimensions.height - offset - height,
            width,
            height,
            isSprzedajemy: true,
          };
          override[url] = [
            ...urlToRectangles[url].filter(
              (current) => !rectangleEqual(current, rectangle),
            ),
            { ...rectangle },
          ];
        }
        setUrlToRectangles(override);
      },
      removeSprzedajemy: () => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          override[url] = [
            ...urlToRectangles[url].filter((current) => !current.isSprzedajemy),
          ];
        }
        setUrlToRectangles((current) => ({ ...current, ...override }));
      },
      remove: (rectangle: Rectangle) => {
        const override: Record<string, Rectangle[]> = {
          [currentUrl]: [
            ...urlToRectangles[currentUrl].filter(
              (current) => !rectangleEqual(current, rectangle),
            ),
          ],
        };
        setUrlToRectangles((current) => ({ ...current, ...override }));
      },
      removeAll: (rectangle: Rectangle) => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          override[url] = [
            ...urlToRectangles[url].filter(
              (current) => !rectangleEqual(current, rectangle),
            ),
          ];
        }
        setUrlToRectangles((current) => ({ ...current, ...override }));
      },
      copyToAll: (rectangle: Rectangle) => {
        const override: Record<string, Rectangle[]> = {};
        for (const url of imageUrls) {
          override[url] = [
            ...urlToRectangles[url].filter(
              (current) => !rectangleEqual(current, rectangle),
            ),
            { ...rectangle },
          ];
        }
        setUrlToRectangles(override);
      },
      clear: () => {
        setUrlToRectangles(
          imageUrls.reduce((acc, curr) => {
            acc[curr] = [];
            return acc;
          }, {} as Record<string, Rectangle[]>),
        );
      },
    },
    scale: {
      toRealImageDimensions: <T extends Rectangle>({
        rectangle,
        scaleToUrl,
        displayedImageHeight,
        displayedImageWidth,
      }: ScaleImageProps<T>) => {
        const scale = scaleFunctionBuilder(
          {
            width: urlToDimensions[scaleToUrl]?.width ?? 1,
            height: urlToDimensions[scaleToUrl]?.height ?? 1,
          },
          {
            width: displayedImageWidth || 1,
            height: displayedImageHeight || 1,
          },
        );
        return { ...rectangle, ...scale(rectangle) };
      },
      toDisplayedImageDimensions: <T extends Rectangle>({
        rectangle,
        scaleToUrl,
        displayedImageHeight,
        displayedImageWidth,
      }: ScaleImageProps<T>) => {
        const scale = scaleFunctionBuilder(
          {
            width: displayedImageWidth || 1,
            height: displayedImageHeight || 1,
          },
          {
            width: urlToDimensions[scaleToUrl]?.width ?? 1,
            height: urlToDimensions[scaleToUrl]?.height ?? 1,
          },
        );
        return { ...rectangle, ...scale(rectangle) };
      },
    },
  };
};

interface ScaleImageProps<T extends Rectangle> {
  rectangle: T;
  scaleToUrl: string;
  displayedImageWidth: number;
  displayedImageHeight: number;
}

const getImgDimensions = (url: string) => {
  return new Promise<Dimensions>((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });
};

const rectangleEqual = (left: Rectangle, right: Rectangle) => {
  return (
    Math.abs(left.x - right.x) < 1 &&
    Math.abs(left.y - right.y) < 1 &&
    Math.abs(left.height - right.height) < 1 &&
    Math.abs(left.width - right.width) < 1
  );
};
