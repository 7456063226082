export function removeDuplicates<T>(list?: T[]): T[] {
  const set = new Set<T>();
  const deduplicatedList: T[] = [];

  for (const item of list ?? []) {
    if (!set.has(item)) {
      set.add(item);
      deduplicatedList.push(item);
    }
  }

  return deduplicatedList;
}
