import React from 'react';
import { useTranslation } from 'react-i18next';
import { MeetingResponseStatus } from '../../../api/meetings/meetings-dto';

interface Props {
  status: MeetingResponseStatus;
  className?: string;
}

const MeetingResponseStatusBadge = ({ status, className }: Props) => {
  const { t } = useTranslation(['meetings']);
  return (
    <span
      className={`${className} ${getClassNames(
        status,
      )} inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset`}
    >
      {t(`meetings:enums.responseStatus.${status}`)}
    </span>
  );
};
const getClassNames = (status: MeetingResponseStatus) => {
  switch (status) {
    case MeetingResponseStatus.ACCEPTED:
      return 'text-emerald-700 ring-emerald-600/20 bg-emerald-50';
    case MeetingResponseStatus.DECLINED:
      return 'text-pink-700 ring-pink-600/20 bg-pink-50';
    case MeetingResponseStatus.PENDING:
      return 'text-amber-700 ring-amber-600/20 bg-amber-50';
  }
};

export default MeetingResponseStatusBadge;
