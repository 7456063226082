import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../../context/analytics/use-analytics';

interface Props {
  title?: string;
}

const PageHeader = ({ title }: Props) => {
  const location = useLocation();
  const instance = useAnalytics();
  const { t } = useTranslation('common');

  const pageTitle = `${title} | ${t('common:pageTitle.suffix')}`;

  React.useEffect(() => {
    document.title = pageTitle;
    instance.page();
  }, [location]);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
    </>
  );
};

export default PageHeader;
